import React from "react";
import { ListGroup } from "react-bootstrap";
import CheckTest from "./CheckTest";

const FunctionListExist = ({
  showInstead,
  formData,
  isSelectMax,
  removedInsideMath,
  selectedItem,
  selectedItemStyle,
  handleClickAll,
  handleGetFunction1,
  handleGetFunction,
  getEntryText,
  searchQuery,
  functionType,
  remove
}) => {
  return (
    <>
      <ListGroup style={{ maxHeight: "300px", overflowY: "auto" }}>
        <ListGroup.Item>
          {showInstead.function_type_id == 3 ? (
            <ListGroup.Item
              onClick={() => {
                handleClickAll("3");
              }}
              className="m-1 btn-color"
              style={
                showInstead.function_type_id == 3
                  ? selectedItemStyle
                  : { cursor: "pointer" }
              }
            >
              Indicators
            </ListGroup.Item>
          ) : showInstead.function_type_id === 2 ? (
            <ListGroup.Item
              onClick={() => {
                handleClickAll("2");
              }}
              className="m-1 btn-color"
              style={
                showInstead.function_type_id == 2
                  ? selectedItemStyle
                  : { cursor: "pointer" }
              }
            >
              Math Functions
            </ListGroup.Item>
          ) : showInstead.function_type_id === 5 ? (
            <ListGroup.Item
              onClick={() => {
                handleClickAll("5");
              }}
              className="m-1 btn-color"
              style={
                selectedItem === "5" ? selectedItemStyle : { cursor: "pointer" }
              }
            >
              Comparators
            </ListGroup.Item>
          ) : showInstead.function_type_id === 4 ? (
            <ListGroup.Item
              onClick={() => {
                handleClickAll("4");
              }}
              className="m-1 btn-color"
              style={
                selectedItem === "4" ? selectedItemStyle : { cursor: "pointer" }
              }
            >
              Math Operators
            </ListGroup.Item>
          ) : showInstead.function_type_id === 6 ? (
            <ListGroup.Item
              onClick={() => {
                handleClickAll("6");
              }}
              className="m-1 btn-color"
              style={
                selectedItem === "6" ? selectedItemStyle : { cursor: "pointer" }
              }
            >
              Logical Operators
            </ListGroup.Item>
          ) : (
            <ListGroup horizontal className="tab-function-type">
              {!remove ? (
                <>
                  {formData.functionsBox888[
                    formData.functionsBox888?.length - 1
                  ]?.function_type_id != 3 &&
                    formData.functionsBox888[
                      formData.functionsBox888?.length - 1
                    ]?.function_type_id != 2 &&
                    formData.functionsBox888[
                      formData.functionsBox888?.length - 1
                    ]?.function_type_id != 5 && (
                      <ListGroup.Item
                        onClick={() => {
                          handleClickAll("1");
                        }}
                        className="m-1"
                        style={
                          selectedItem === "1"
                            ? selectedItemStyle
                            : { cursor: "pointer" }
                        }
                      >
                        All
                      </ListGroup.Item>
                    )}
                  {formData.functionsBox888[
                    formData.functionsBox888?.length - 1
                  ]?.function_type_id != 3 &&
                    formData.functionsBox888[
                      formData.functionsBox888?.length - 1
                    ]?.function_type_id != 2 && (
                      <ListGroup.Item
                        onClick={() => {
                          handleClickAll("2");
                        }}
                        className="m-1"
                        style={
                          selectedItem === "2"
                            ? selectedItemStyle
                            : { cursor: "pointer" }
                        }
                      >
                        Math Functions
                      </ListGroup.Item>
                    )}
                  {(formData.functionsBox888[
                    formData.functionsBox888?.length - 1
                  ]?.function_type_id != 3 ||
                    isSelectMax ||
                    formData.selectedOption3 === "Max1" ||
                    formData.selectedOption3 === "Min1" ||
                    removedInsideMath) && (
                    <ListGroup.Item
                      onClick={() => {
                        handleClickAll("3");
                      }}
                      className="m-1"
                      style={
                        selectedItem === "3"
                          ? selectedItemStyle
                          : { cursor: "pointer" }
                      }
                    >
                      Indicators
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item
                    onClick={() => {
                      handleClickAll("4");
                    }}
                    className="m-1"
                    style={
                      selectedItem === "4"
                        ? selectedItemStyle
                        : { cursor: "pointer" }
                    }
                  >
                    Math Operators
                  </ListGroup.Item>

                  {formData.functionsBox888[
                    formData.functionsBox888?.length - 1
                  ]?.function_type_id != 5 && (
                    <ListGroup.Item
                      onClick={() => {
                        handleClickAll("5");
                      }}
                      className="m-1"
                      style={
                        selectedItem === "5"
                          ? selectedItemStyle
                          : { cursor: "pointer" }
                      }
                    >
                      Comparators
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item
                    onClick={() => {
                      handleClickAll("6");
                    }}
                    className="m-1"
                    style={
                      selectedItem === "6"
                        ? selectedItemStyle
                        : { cursor: "pointer" }
                    }
                  >
                    Logical Operators
                  </ListGroup.Item>
                </>
              ) : (
                formData?.mode === "Function" &&
                !remove && (
                  <ListGroup.Item
                    onClick={() => {
                      handleClickAll("3");
                    }}
                    className="m-1 btn-color"
                  >
                    Indicators
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          )}
        </ListGroup.Item>
        {showInstead.isOpen
          ? functionType
              ?.filter(
                (item) =>
                  item.function_type_id === showInstead.function_type_id &&
                  item.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item) => (
                <ListGroup.Item
                  key={item?.id}
                  onClick={() => {
                    handleGetFunction1(item);
                    setTimeout(() => {
                      getEntryText();
                    }, 1000);
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item?.name}</div>
                    <div>
                      <CheckTest isTest={item?.name} />
                      {/* // this is check that indicator is tested or not */}
                    </div>
                  </div>
                </ListGroup.Item>
              ))
          : functionType
              ?.filter((item) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item) => (
                <ListGroup.Item
                  key={item?.id}
                  onClick={() => {
                    handleGetFunction(item);
                    setTimeout(() => {
                      getEntryText();
                    }, 1000);
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item?.name}</div>
                    <div>
                      <CheckTest isTest={item?.name} />
                      {/* // this is check that indicator is tested or not */}
                    </div>
                  </div>
                </ListGroup.Item>
              ))}

        {functionType?.length === 0 && (
          <ListGroup.Item>No data available</ListGroup.Item>
        )}
      </ListGroup>
    </>
  );
};

export default FunctionListExist;
