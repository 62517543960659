import React from "react";
import DailyUsageCard from "./DailyUsageCard";
import { Button, Card } from "react-bootstrap";
import BalanceCard from "./BalanceCard";
import RequestNumberCard from "./RequestNumberCard";

const RightSideBar = () => {
  return (
    <div className="right-side-bar">
      <RequestNumberCard />
      <div className="row pt-5 right-bar-dash-balance-card">
        <BalanceCard />
      </div>
      <div className="row pt-5 ">
        <DailyUsageCard />
      </div>
    </div>
  );
};

export default RightSideBar;
