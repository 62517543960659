import React from "react";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import Layout from "../components/Layout";

function Home() {
  return <Content />;
}

export default Home;
