import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProfileCard from "./ProfileCard";
import { Button, Col, Form, Row } from "react-bootstrap";
import UploadPhotoModal from "../components/account/UploadPhotoModal";
import { getCountries, getStates, saveprofiledetails } from "../api/profileapi";
import { saveData, getUser } from "../Session";
import AlertShow from "./AlertShow";
import {checkExistEmail} from "../api/api";
const Profile = () => {
  const navigate = useNavigate();
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const [images, setImages] = useState("");
  const [userData, setUserData] = useState("");
  const [usernm, setUsernm] = useState();
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingstate, setLoadingstate] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [removeprofilepic, setRemoveprofilepic] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [mail,setEmail]=useState(false);
  // const [phoneError, setPhoneError] = useState('');
  const featchDataUser = () => {
    const userData = getUser();
    console.log("userData", userData);
    setUserData(userData);
  };

  useEffect(() => {
    if (selectedFile) {
      setUserProfile((prev) => ({
        ...prev,
        profile_image: selectedFile,
      }));
    }
    //   else
    //   {
    //     setUserProfile((prev)=>({
    //       ...prev,
    //       profile_image: null,
    //  }
    // ))
    //   }
  }, [selectedFile]);

  const [userProfile, setUserProfile] = useState({
    user_name: userData?.user_name,
    address_line_1: userData?.address_line_1,
    address_line_2: userData?.address_line_2,
    state_id: userData?.state_id,
    country_id: userData?.country_id,
    about: userData?.about,
    id: userData?.id,
    email: userData?.email,
    profile_image: userData?.profile_image,
    phone:userData?.phone,
  });

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    featchDataUser();
  }, []);

  useEffect(() => {
    if (userProfile?.country_id) {
      fetchStates(userProfile?.country_id);
    } else {
      setStates([]);
    }
  }, [userProfile?.country_id, userProfile?.state_id]);

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
    setIsConfirmation(false);
  };
  const fetchCountries = async () => {
    try {
      const result = await getCountries();
      console.log("@resultpp", result);
      setCountry(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchStates = async (country) => {
    try {
      const result = await getStates(country);
      console.log("@resultpp", result);
      setStates(result);
      setLoadingstate(false);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const validateEmail =async(email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   
    if (emailRegex.test(email))
      { 
        return true;
      }
     
  };
  const existemail=async(email)=>{
    const isExistEmail = await checkExistEmail(email);
        if(isExistEmail===true)
            return false;
         else
          return true;
                
  }
  const saveprofiledata = async () => {
    const formData = new FormData();
    if(await validateEmail(userProfile?.email))
      {
        if(await existemail(userProfile?.email) || userProfile?.email===userData.email)
          {
            for (const key in userProfile) {
          formData.append(key, userProfile[key]);
                 }
              if (!userProfile?.id) {
          formData.append("id", userData.id);
               }
    
             for (let pair of formData.entries()) {
          console.log("???", pair[0] + ": " + pair[1]);
              }
               const result = await saveprofiledetails(formData);
               if (result?.success === true) {
          const apidata = result?.user;
          console.log("APIDATA", result?.user);
          const currentsessiondata = getUser();
          const updatedata = { ...currentsessiondata, ...apidata };
          const setSessionData = await saveData(updatedata);
          setUserProfile(setSessionData);
          var msg = "Updated successfully!!!";
          window.location.reload();
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          fetchStates(updatedata?.country_id);
             } else {
          console.log("aaa", result);
          alert("Error in updating profile details");
           }
        }
        else
        {
          alert("Email Already exist, Try differet mail-id");
        }
      }
      else
      {
        setValidationMessage('Invalid email');
      }
  
  };

  // const removePicture = () => {
  //   setAlertMsg("Do you want to change the profile pic?");
  //   setSeverity("warning");
  //   setIsConfirmation(true); // Set to true for confirmation dialog
  //   setShowAlert(true);
  // };

  useEffect(() => {
    console.log("removeprofilepic", removeprofilepic);
    if (removeprofilepic) {
      setUserProfile((prev) => ({
        ...prev,
        profile_image: null,
      }));
    }
  }, [removeprofilepic]);

  useEffect(() => {
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      user_name: userData?.user_name,
      address_line_1: userData?.address_line_1,
      address_line_2: userData?.address_line_2,
      state_id: userData?.state_id,
      country_id: userData?.country_id,
      about: userData?.about,
      id: userData?.id,
      email: userData?.email,
      profile_image: userData?.profile_image ? userData?.profile_image : null,
      phone: userData?.phone,
    }));
  }, [userData, removeprofilepic]);

  useEffect(() => {
    console.log("@@@", userProfile);
  }, [userProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // if(name==='phone')
    //   {
    //     if (/^\d{0,10}$/.test(value)) {
    //          setUserProfile({ ...userProfile, [name]: value });
    //          setPhoneError('');
    //     }
    //     else {
    //       setPhoneError('Phone number must be exactly 10 digits');
    //     }
    //   }
    if(name==='email')
      {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setValidationMessage('Invalid email format');
          // break();
        }
        else
        {
          setValidationMessage('');
        }
      }
    setUserProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
 

  useEffect(() => {
    console.log("pp@", userProfile, selectedFile);
  }, [userProfile, selectedFile]);

  return (
    <>
      <div className="info-div">
        <div className="card p-0">
          <div className="card-body ">
            <Form>
              <Row>
                <Col sm="12">
                  <Form.Label>
                    <img
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : removeprofilepic
                          ? "/icon-images/avatar-placeholder.png"
                          : userProfile?.profile_image
                          ? "https://dalrotibackend.shrikanttapkeer.com" +
                            userProfile?.profile_image
                          : "/icon-images/avatar-placeholder.png"
                      }
                      alt="userimage"
                      accept=".jpg,.jpeg,.png" 
                      className="profile-pic"
                    />

                    <span
                      onClick={() => setUploadModalShow(true)}
                      className="change-profile-photo pointer-cursor"
                    >
                      Change Profile Photo
                    </span>
                  </Form.Label>
                </Col>
              </Row>
              {/* <div className="text-end">  <button>Edit</button></div> */}

              <Row>
                <Col sm="6">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_name"
                    onChange={handleChange}
                    value={userProfile?.user_name}
                    placeholder="Name"
                  />
                </Col>
                <Col sm="6">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={userProfile?.email}
                    placeholder="Enter Mail-id"
                  />
                   {validationMessage && <p style={{ color: 'red' }}>{validationMessage}</p>}
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Form.Label>Street Address Line 1</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="address_line_1"
                    value={userProfile?.address_line_1}
                    placeholder="Name"
                  />
                </Col>

                <Col sm="6">
                  <Form.Label>Street Address Line 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="address_line_2"
                    onChange={handleChange}
                    value={userProfile?.address_line_2}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="country_id"
                    onChange={handleChange}
                    value={userProfile?.country_id}
                  >
                    <option value="">Select Country</option>
                    {loading ? (
                      <option>Loading...</option>
                    ) : (
                      country?.map((country) => (
                        <option key={country?.id} value={country?.id}>
                          {country?.name}
                        </option>
                      ))
                    )}
                  </Form.Select>
                </Col>
                <Col sm="6">
                  <Form.Label>State</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="state_id"
                    onChange={handleChange}
                    value={userProfile?.state_id}
                  >
                    <option value="">Select State</option>
                    {loading ? (
                      <option>Loading...</option>
                    ) : (
                      states?.map((state) => (
                        <option key={state?.id} value={state?.id}>
                          {state?.name}
                        </option>
                      ))
                    )}
                  </Form.Select>
                </Col>
              </Row>
              <Row>
              <Col sm="6">
                  <Form.Label>Mobile number</Form.Label>
                  <Form.Control
                    type="number"
                    name="phone"
                     maxLength="10"
                     pattern="\d{10}"
                    onChange={handleChange}
                    value={userProfile?.phone}
                    placeholder="Enter Mobile Number"
                  />
                    {/* {phoneError && <div style={{ color: 'red' }}>{phoneError}</div>} */}
                </Col>
                <Col sm="6">
                  <Form.Label>About</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="about"
                    rows={3}
                    onChange={handleChange}
                    value={userProfile?.about}
                    placeholder="About Me"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button className="btn-color" onClick={saveprofiledata}>
                    &nbsp;&nbsp;&nbsp; Save &nbsp;&nbsp;&nbsp;
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        //  alertConfirm={isConfirmation ?  removePicture : null}
        severity={severity}
      />
      <UploadPhotoModal
        uploadModalShow={uploadModalShow}
        setUploadModalShow={setUploadModalShow}
        setImages={setImages}
        setSelectedFile={setSelectedFile}
        setRemoveprofilepic={setRemoveprofilepic}
        id={userData?.id}
        userData={userData}
        // isConfirmation={setIsConfirmation}
      />
    </>
  );
};

export default Profile;
