import React from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";

const Billing = () => {
  return (
    <Container id="account-billing" className="mt-3">
      <Row>
        <Col lg={5} md={5} className="p-0">
          <span>Current Plan</span>
          <Card>
            <Card.Body className="d-flex flex-column justify-content-center align-items-center p-5"> 
              <span>
                <i className="fa fa-gift fs-1" ></i>
              </span>
              <span>Free Trial Expired</span>

            </Card.Body>
          </Card>
        </Col>
        <Col lg={7} md={7} sm={12} className="p-0 px-2">
          <span>Invoices</span>
          <Card>
            <Card.Body >
              <span>Recent Invoices</span>
              <hr />
              <div className="pt-5"></div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={5}  md={6} sm={6}>
          <Card>
            <Card.Body>
              <span>Billing Info</span>
              <Table>
                  <tbody>
                    <tr>
                      <td>
                        Type of Card
                      </td>
                      <td>
                        Visa
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Last 4 digits
                      </td>
                      <td>
                        4567
                      </td>
                    </tr>
                  </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Billing;
