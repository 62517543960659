import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import NumberInput from "../NumberInput";

const PhasePowerMovingAverage = ({
  show,
  setIsShow,
  handleModalData,
  mainData,
  clickedFunction,
  exit,
  index,
  instance,
}) => {
    const [name, setName] = useState("");
    let data;

    if (exit) {
      const exitIndicators = mainData?.exit_indicators?.[clickedFunction];

      if (exitIndicators?.body) {
        data = exitIndicators.body[index];
      } else if (instance && exitIndicators?.body1) {
        data = exitIndicators[instance]?.[0];
      } else if (exitIndicators?.body2) {
        data = exitIndicators[instance]?.[0];
      } else {
        data = exitIndicators;
      }
    } else {
      const indicators = mainData?.indicators?.[clickedFunction];

      if (indicators?.body) {
        data = indicators.body[index];
      } else if (instance && indicators?.body1) {
        data = indicators[instance]?.[0];
      } else if (indicators?.body2) {
        data = indicators[instance]?.[0];
      } else {
        data = indicators;
      }
    }

    const [formData, setFormData] = useState(data);

    useEffect(() => {
      // Update formData when data changes
      setFormData(data);
      setName(data?.name);
    }, [data]);
    const handleClose = () => {
      setIsShow(false);
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
    const handleDone = () => {
      handleModalData(formData);
      setIsShow(false);
    };


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        dialogClassName="formula-modal"
      >
        <Modal.Header className="pt-4 border-0">
          <Modal.Title className="fs-5 fw-bold px-4">
            Phase Power Moving Average
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Field
                </Form.Label>
                <Col sm="4">
                  <Form.Select
                    size="sm"
                    name={exit ? "e_field" : "field"}
                    value={exit ? formData?.e_field : formData?.field}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="high">High</option>
                    <option value="low">Low</option>
                    <option value="close">Close</option>
                    <option value="Volume">Volume</option>
                  </Form.Select>
                </Col>
                <Form.Label column sm="2">
                  Length
                </Form.Label>
                <Col sm="4">
                  <NumberInput
                    type="text"
                    size="sm"
                    name={exit ? "e_powerLength" : "powerLength"}
                    value={
                      exit ? formData?.e_powerLength : formData?.powerLength
                    }
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Phase
                </Form.Label>
                <Col sm="4">
                  <NumberInput
                    type="text"
                    size="sm"
                    name={exit ? "e_phase" : "phase"}
                    value={exit ? formData?.e_phase : formData?.phase}
                    onChange={handleChange}
                  />
                </Col>
                <Form.Label column sm="2">
                  Power
                </Form.Label>
                <Col sm="4">
                  <NumberInput
                    type="text"
                    size="sm"
                    name={exit ? "e_power" : "power"}
                    value={exit ? formData?.e_power : formData?.power}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Row}
                // className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Offset
                </Form.Label>
                <Col sm="4">
                  <NumberInput
                    type="text"
                    size="sm"
                    name={exit ? "e_offset" : "offset"}
                    value={exit ? formData?.e_offset : formData?.offset}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pb-1   border-0">
          <Button variant="danger" size="sm" onClick={handleClose}>
            Delete
          </Button>
          <Button variant="primary" size="sm" onClick={handleDone}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhasePowerMovingAverage;
