import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const EditStrategyModal = ({
    showEditPopup,
    setEditPopup,
    handleClickEditName,
    nameError,
    strategiesName,
    setStrategiesName
  }) => {

    console.log("ssssname",strategiesName);

    const [isDisable, setIsDisable] = useState(true);
  
    const handleClose = () => {
        setEditPopup(false);
    };
    const handelChange = (e) => {
      const value = e.target.value;
      if (value.length > 2) {
        setIsDisable(false);
      }
      setStrategiesName(value);
    };
    return(
        <>
      <Modal
        show={showEditPopup}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Body
          id="create-strategy-modal"
          className="d-flex align-items-center justify-content-center"
        >
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Edit strategy name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Strategy Name"
                value={strategiesName}
                onChange={handelChange}
                autoComplete="off"
              />
                <span className="text-error">{nameError}</span>
            </Form.Group>
            <Button
              className="btn-color"
              variant="primary"
              // disabled={isDisable}
              onClick={() => handleClickEditName(strategiesName)}
              size="sm"
            >
              Save
            </Button>
            <br />
            <Button variant="default" onClick={handleClose} size="sm">
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
    )
}

export default EditStrategyModal;