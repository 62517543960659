import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useTheme } from "../../ThemeContext";
import { useEffect } from "react";
const Preferences = () => {

  const { isDarkMode, toggleTheme } = useTheme(); // Use the theme context
  const [theme, setTheme] = useState(isDarkMode);

  const [notifictaionShow, setNotificationShow] = useState(false);
  const [animationShow, setAnimationShow] = useState(false);
  const [globalAssistShow, setGlobalAssistShow] = useState(false);
  const [singlePageShow, setSinglePageShow] = useState(false);
  const [nudgeShow, setNudegeShow] = useState(false);
  const [optionFormatShow, setShowFormatShow] = useState(false);
  const [multipleFormatShow, setMultipleFormatShow] = useState(false);

  useEffect(() => {
    console.log("Theme state changed:", isDarkMode);
  }, [isDarkMode]);

  const handleShowHide = (card, cardBoolean) => {
    console.log("card-----", card);
    if (card === "theme") {
      setTheme(cardBoolean);
    }
    if (card === "notification") {
      setNotificationShow(cardBoolean);
    }
    if (card === "animation") {
      setAnimationShow(cardBoolean);
    }
    if (card === "globalAssist") {
      setGlobalAssistShow(cardBoolean);
    }
    if (card === "singlePage") {
      setSinglePageShow(cardBoolean);
    }
    if (card === "nudge") {
      setNudegeShow(cardBoolean);
    }
    if (card === "optionFormat") {
      setShowFormatShow(cardBoolean);
    }
    if (card === "multitimeframe") {
      setMultipleFormatShow(cardBoolean);
    }
  };

  return (
    <>
      <Card className="mt-3">
        {/* <Card.Body> */}
          {/* <Row> */}
            {/* <Col lg md sm>
              <Row>
                <Col>
                  <span>
                    <i className="fa fa-pie-chart" aria-hidden="true"></i> Theme
                    &nbsp;
                    {isDarkMode ? "DARK" : "LIGHT"}
                  </span>
                  <span className="pull-right">
                    {theme ? (
                      <i
                        className="fa fa-chevron-up"
                        aria-hidden="true"
                        onClick={() => {
                          handleShowHide("theme", false);
                        }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-chevron-down"
                        aria-hidden="true"
                        onClick={() => {
                          handleShowHide("theme", true);
                        }}
                      ></i>
                    )}
                  </span>
                </Col>
              </Row>
              {theme && (
                <>
                  <Row className="p-3">
                    <Card className="p-2">
                      <Card.Body className="p-0">
                        <Form className="d-flex justify-content-center align-items-center">
                          <Form.Check
                            inline
                            name="group1"
                            type="radio"
                            id={`inline-radio-2`}
                            checked={isDarkMode}
                            onChange={toggleTheme} 
                          />
                          <i
                            className="fa fa-moon icon-moon"
                            aria-hidden="true"
                          ></i>
                          &nbsp;&nbsp;
                          <Form.Control
                            plaintext
                            readOnly
                            defaultValue="Dark"
                            checked={!isDarkMode}
                            onChange={toggleTheme}  
                            style={{cursor:"pointer"}}
                          />
                        </Form>
                      </Card.Body>
                    </Card>
                  </Row>
                  <Row className="p-3">
                    <Card className="p-2">
                      <Card.Body className="p-0">
                        <Form className="d-flex justify-content-center align-items-center">
                          <Form.Check
                            inline
                            name="group1"
                            type="radio"
                            id={`inline-radio-2`}
                          />
                          <i className="fa fa-sun-o" aria-hidden="true"></i>
                          &nbsp;&nbsp;
                          <Form.Control
                            plaintext
                            readOnly
                            defaultValue="Light"
                            style={{cursor:"pointer"}}
                          />
                        </Form>
                      </Card.Body>
                    </Card>
                  </Row>
                
                </>
              )}
            </Col> */}
          {/* </Row> */}
        {/* </Card.Body> */}
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col lg md sm>
              <Row>
                <Col>
                  <span className="main-title">
                    <i className="fa fa-bell-o" aria-hidden="true"></i>{" "}
                    Notifications
                  </span>
                  <span className="pull-right">
                    {notifictaionShow ? (
                      <i
                        className="fa fa-chevron-up"
                        aria-hidden="true"
                        onClick={() => {
                          handleShowHide("notification", false);
                        }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-chevron-down"
                        aria-hidden="true"
                        onClick={() => {
                          handleShowHide("notification", true);
                        }}
                      ></i>
                    )}
                  </span>
                </Col>
              </Row>
              {notifictaionShow && (
                <>
                  <Row>
                    <Col className="d-flex flex-row justify-content-between">
                      <span>Silent</span>
                      <span>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-row justify-content-between">
                      <span>Show Pop up Notification</span>
                      <span>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                        />
                      </span>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col lg md sm>
              <span className="main-title">
                <i className="fa fa-gg-circle" aria-hidden="true"></i>
                &nbsp;Animation
              </span>
              <span className="pull-right">
                {animationShow ? (
                  <i
                    className="fa fa-chevron-up"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("animation", false);
                    }}
                  ></i>
                ) : (
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("animation", true);
                    }}
                  ></i>
                )}
              </span>
            </Col>
          </Row>
          {animationShow && (
            <Row>
              <Col>
                Show Animation
                <span className="pull-right">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </span>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col lg md sm>
              <span className="main-title">
                <i className="fa fa-plus"></i>&nbsp; Global Assist
              </span>
              <span className="pull-right">
                {globalAssistShow ? (
                  <i
                    className="fa fa-chevron-up"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("globalAssist", false);
                    }}
                  ></i>
                ) : (
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("globalAssist", true);
                    }}
                  ></i>
                )}
              </span>
            </Col>
          </Row>
          {globalAssistShow && (
            <Row>
              <Col>
                Global Assist
                <span className="pull-right">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </span>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col lg md sm>
              <span className="main-title">
                <i className="fa fa-plus"></i>&nbsp;Create in single page
              </span>
              <span className="pull-right">
                {singlePageShow ? (
                  <i
                    className="fa fa-chevron-up"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("singlePage", false);
                    }}
                  ></i>
                ) : (
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("singlePage", true);
                    }}
                  ></i>
                )}
              </span>
            </Col>
          </Row>
          {singlePageShow && (
            <Row>
              <Col>
                Create in single page
                <span className="pull-right">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </span>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col lg md sm>
              <span className="main-title">
                <i className="fa fa-plus"></i> &nbsp;Nudge
              </span>
              <span className="pull-right">
                {nudgeShow ? (
                  <i
                    className="fa fa-chevron-up"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("nudge", false);
                    }}
                  ></i>
                ) : (
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("nudge", true);
                    }}
                  ></i>
                )}
              </span>
            </Col>
          </Row>
          {nudgeShow && (
            <Row>
              <Col>
                Show Nudge
                <span className="pull-right">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </span>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col lg md sm>
              <span className="main-title">
                <i className="fa fa-plus"> </i> &nbsp;Options Format
              </span>
              <span className="pull-right">
                {optionFormatShow ? (
                  <i
                    className="fa fa-chevron-up"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("optionFormat", false);
                    }}
                  ></i>
                ) : (
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("optionFormat", true);
                    }}
                  ></i>
                )}
              </span>
            </Col>
          </Row>

          {optionFormatShow && (
            <Row>
              <Col>
                Show Old Options Format ( Ex. FINNIFTY2350919050PE )
                <span className="pull-right">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </span>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col lg md sm>
              <span className="main-title">
                <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                &nbsp;Multitime Frame warning
              </span>
              <span className="pull-right">
                {multipleFormatShow ? (
                  <i
                    className="fa fa-chevron-up"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("multitimeframe", false);
                    }}
                  ></i>
                ) : (
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"
                    onClick={() => {
                      handleShowHide("multitimeframe", true);
                    }}
                  ></i>
                )}
              </span>
            </Col>
          </Row>
          {multipleFormatShow && (
            <Row>
              <Col>
                Show MTF Warnings
                <span className="pull-right">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </span>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col lg md sm>
              <span className="main-title">
                <i className="fa fa-television" aria-hidden="true"></i>
                &nbsp;Version V0.0.1
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Preferences;
