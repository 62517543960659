import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import NumberInput from '../NumberInput';

const ATRTrailingModal = ({
  show,
  setIsShow,
  handleModalData,
  mainData,
  clickedFunction,
  exit,
  index,
  instance,
}) => {
    let data;
    if (exit) {
      const exitIndicators = mainData?.exit_indicators?.[clickedFunction];

      if (exitIndicators?.body) {
        data = exitIndicators.body[index];
      } else if (instance && exitIndicators?.body1) {
        data = exitIndicators[instance]?.[0];
      } else if (exitIndicators?.body2) {
        data = exitIndicators[instance]?.[0];
      } else {
        data = exitIndicators;
      }
    } else {
      const indicators = mainData?.indicators?.[clickedFunction];

      if (indicators?.body) {
        data = indicators.body[index];
      } else if (instance && indicators?.body1) {
        data = indicators[instance]?.[0];
      } else if (indicators?.body2) {
        data = indicators[instance]?.[0];
      } else {
        data = indicators;
      }
    }
    const [formData, setFormData] = useState(data);
    useEffect(() => {
      setFormData(data);
    }, [data]);
    const handleClose = () => {
      setIsShow(false);
    };

    const handelChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
    const handleDone = () => {
      handleModalData(formData);
      console.log("VALUE", exit, formData);

      setIsShow(false);
    };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        dialogClassName="formula-modal"
      >
        <Modal.Header className="pt-4 border-0">
          <Modal.Title className="fs-5 fw-bold px-4">
            ATR Trailing Stoploss
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Multiplier
                </Form.Label>
                <Col sm="4">
                  <NumberInput
                    type="number"
                    size="sm"
                    name={exit ? "e_multiplier" : "multiplier"}
                    value={exit ? formData?.e_multiplier : formData?.multiplier}
                    onChange={handelChange}
                  />
                </Col>
                <Form.Label column sm="2">
                  Period
                </Form.Label>
                <Col sm="4">
                  <NumberInput
                    type="number"
                    size="sm"
                    name={exit ? "e_period" : "period"}
                    value={exit ? formData?.e_period : formData?.period}
                    onChange={handelChange}
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Offset
                </Form.Label>
                <Col sm="4">
                  <NumberInput
                    type="number"
                    size="sm"
                    name={exit ? "e_offset" : "offset"}
                    value={exit ? formData?.e_offset : formData?.offset}
                    onChange={handelChange}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pb-1   border-0">
          <Button variant="danger" size="sm" onClick={handleClose}>
            Delete
          </Button>
          <Button variant="primary" size="sm" onClick={handleDone}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ATRTrailingModal