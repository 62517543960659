import React, { useEffect ,useState} from "react";
import { Button, Card, Table } from "react-bootstrap";
import { getZerodhaHoldings, getZerodhaMargin, getZerodhaPositions } from "../../api/zerodhaapi";
import { getUser } from "../../Session";

const ZerodhaHMPDetails = () => {
    const [margin ,setMargin]= useState();
    const [Holding ,setHolding]= useState();
    const [Position ,setPosition]= useState();
    const getDetails = async() => {
        const user = getUser();
        const mData = await getZerodhaMargin({user_id : user.id});
        const hData = await getZerodhaHoldings({user_id : user.id});
        const pData = await getZerodhaPositions({user_id : user.id});
        setMargin(mData);
        setHolding(Holding);
        setPosition(pData);
    }

    useEffect(() => {

    },[]);  

    return(
        <>
      <Button variant="light" size="sm">Positions</Button>
      <Button variant="light" size="sm">Holdings</Button>
      <br />

      <Table>
        <thead className="holding-thead">
          <th>
            Instruments
          </th>
          <th>
            Qty
          </th>
          <th>LTP</th>
          <th>P&L</th>
        </thead>
        {/* <tbody>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
        </tbody> */}
      </Table>

      <Card className="holding-card">
        <Card.Body>
        <Card.Title className="protfolio-card-title"><i className="fa fa-folder-open-o"></i></Card.Title>
        <Card.Subtitle className="mb-2 ">Nothing to see here</Card.Subtitle>
        <Card.Text className="text-muted">
            Take a live position to see your position here
        </Card.Text>
        <Card.Link className="link" href="#">Go to marketwatch </Card.Link>&nbsp;&nbsp; <i className="fa fa-chevron-right"></i>
        </Card.Body>
      </Card>
    </>
    )
}
export default ZerodhaHMPDetails;