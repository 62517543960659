import React from "react";
import { Col, Container, ListGroup, Row, Table } from "react-bootstrap";

const OrderBook = () => {
  return (
    <>
      <Row>
        <Col lg={6}>
          <ListGroup horizontal>
            <ListGroup.Item>Open orders</ListGroup.Item>
            <ListGroup.Item>Executed Orders</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col lg={6} className="d-flex justify-content-end">
          <ListGroup horizontal>
            <ListGroup.Item>
              <i className="fa fa-check"></i>
              All
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        {/* <Col lg md sm className="d-flex flex-column align-items-center justify-content-center"  style={{height : "47vh"}} >
                    <span>
                <i className="fa fa-folder-open-o fs-1" aria-hidden="true"></i></span>
                <span>No position found</span>
                <span>
                    Go to dashboard
                </span>
                </Col> */}
        <Col lg md sm>
          <Table>
            <thead>
              <tr>
                <th>Instrument</th>
                <th>Type, quantity & time</th>
                <th>Avg. Price</th>
                <th>Product</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Garisam</td>
                <td>B 1 at 20 feb 2024, 11:30 Am</td>
                <td>0.00</td>
                <td>MIS</td>
                <td>Rejected</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default OrderBook;
