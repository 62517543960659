import React, { useEffect, useState } from "react";
import { Button, Card, Col, ListGroup, Row, Table } from "react-bootstrap";
import BuySymbolModal from "./BuySymbolModal";
import SellSymbolModal from "./SellSymbolModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Chart from "react-apexcharts";
import {
  getMovingAverages,
  getTechnicalChartData,
} from "../../api/technicalapi";
import { getActiveAccountdata } from "../../Session";
import MovingAverageChart from "./MovingAverageChart";
import SupportResistanceChart from "./SupportResistanceChart";
// timeInterval data //
const timeIntervals = [
  { key: "minute", label: "1min" },
  { key: "3minute", label: "3min" },
  { key: "5minute", label: "5min" },
  { key: "10minute", label: "10min" },
  { key: "15minute", label: "15min" },
  { key: "30minute", label: "30min" },
  { key: "hour", label: "hour" },
  { key: "day", label: "day" },
];

// condition style of icon //
const getCellStyle = (sentiment) => {
  let color = "";
  let backgroundColor = "";
  let borderColor = "";

  switch (sentiment) {
    case "bearish":
      color = "#FF9D91";
      backgroundColor = "#FFEDED";
      borderColor = "#FF9D91";
      break;
    case "bullish":
      color = "#8AE08C";
      backgroundColor = "#E6F9F1";
      borderColor = "#8AE08C";
      break;
    case "neutral":
      color = "#79C3F2";
      backgroundColor = "#F0F8FF";
      borderColor = "#79C3F2";
      break;
    default:
      break;
  }

  return {
    color,
    backgroundColor,
    borderColor,
  };
};

const Symbol = () => {
  const navigate = useNavigate();
  const [buyModal, setBuyModal] = useState(false);
  const [sellModal, setSellModal] = useState(false);
  const [activeTab, setActiveTab] = useState("minute");
  const [oscillatorExapnd, setExpanded] = useState(false);
  const [averageExapnd, setAverageExpand] = useState(false);
  const [userData, setUserData] = useState(null);
  const [movingAverageData, setMovingAverage] = useState(null);
  const [averageSegregatingData, setSegregating] = useState([]);
  const [technicalData, setTechnicalData] = useState(null);
  const [paramsData, setParamsData] = useState(null);
  const [searchParams] = useSearchParams();
  const [maxTechProps, setMaxTechProps] = useState(null);
  const [maxTectOcillatorProp, setMaxTechOscillProps] = useState(null);
  const [segregrateOscillator, setSegregrateOscill] = useState([]);
  const [maxPercentage, setMaxPercentage] = useState({
    type: "percentBearish",
    val: "87",
  });
  const averageBody = {
    api_key: userData?.api_key,
    access_key: userData?.access_token,
    scripCode: paramsData?.id,
    interval: activeTab,
  };

  const technicalBody = {
    api_key: userData?.api_key,
    access_key: userData?.access_token,
    scripCode: paramsData?.id,
    interval: activeTab,
  };

  useEffect(() => {
    const userData = getActiveAccountdata();
    setUserData(userData);
    console.log("userData", userData);
  }, []);

  // table oscillator expand fn //
  const toggleOscillatorExpand = () => {
    setExpanded(!oscillatorExapnd);
  };
  const toggleAverageExpand = () => {
    setAverageExpand(!averageExapnd);
  };

  useEffect(() => {
    const itemNameFromParams = searchParams.get("itemName");
    const itemIdFromParams = searchParams.get("id");
    if (itemNameFromParams) {
      setParamsData({
        id: itemIdFromParams,
        itemName: itemNameFromParams,
      });
    }
  }, [searchParams]);

  const fetchAverageData = async ({
    api_key,
    access_key,
    scripCode,
    interval,
  }) => {
    try {
      // console.log("22", averageBody);
      const movingAverageData = await getMovingAverages({
        api_key,
        access_key,
        scripCode,
        interval,
      });

      if (!movingAverageData) {
        console.error(
          "Error: Received undefined or null data from getMovingAverages"
        );
        return;
      }
      // console.log("movingAverageData", movingAverageData);
      if (movingAverageData) {
        setMovingAverage(movingAverageData);
        let segregatingData = Object.keys(movingAverageData).reduce(
          (acc, key) => {
            if (key?.includes("_Sentiment") || key?.includes("_sentiment")) {
              const correspondingValueKey = key?.endsWith("_sentiments")
                ? key?.replace("_sentiments", "_values")
                : key?.replace("_Sentiment", "_Values");
              if (movingAverageData[correspondingValueKey] !== undefined) {
                acc.push({
                  [key]: movingAverageData[key],
                  [correspondingValueKey]:
                    movingAverageData[correspondingValueKey],
                });
              }
            }
            return acc;
          },
          []
        );
        setSegregating(segregatingData);
      }

      // console.log("segregatingData", segregatingData);
    } catch (error) {
      console.error("Error fetching moving averages", error);
    }
  };
  useEffect(() => {
    fetchAverageData({
      api_key: averageBody?.api_key,
      access_key: averageBody?.access_key,
      scripCode: averageBody?.scripCode,
      interval: averageBody?.interval,
    });
  }, [userData, userData?.id, activeTab]);

  useEffect(() => {
    const percentages = {
      percentBearish: movingAverageData?.percentBearish,
      percentBullish: movingAverageData?.percentBullish,
      percentNeutral: movingAverageData?.percentNeutral,
    };
    // find max type as a count //
    let totalBearish = movingAverageData?.totalBearish;
    let totalBullish = movingAverageData?.totalBullish;
    let totalNeutral = movingAverageData?.totalNeutral;

    const maxValueCount = Math.max(totalBearish, totalBullish, totalNeutral);
    // console.log("maxValueCount", maxValueCount  )
    let maxTypeCount;

    if (maxValueCount === totalBearish) {
      maxTypeCount = "percentBearish";
    } else if (maxValueCount === totalBullish) {
      maxTypeCount = "percentBullish";
    } else if (maxValueCount === totalNeutral) {
      maxTypeCount = "percentNeutral";
    }
    const extractNumericValue = (percentageString) =>
      parseFloat(percentageString?.replace("%", ""));

    const bearish = extractNumericValue(percentages?.percentBearish);
    const bullish = extractNumericValue(percentages?.percentBullish);
    const neutral = extractNumericValue(percentages?.percentNeutral);
    const maxValue = Math.max(bearish, bullish, neutral);
    let maxType;

    if (maxValue === bearish) {
      maxType = "percentBearish";
    } else if (maxValue === bullish) {
      maxType = "percentBullish";
    } else if (maxValue === neutral) {
      maxType = "percentNeutral";
    }
    setMaxPercentage({
      type: maxType,
      val: Math.floor(maxValue),
      count: maxTypeCount,
    });
    // setMaxPercentage({
    //   type: "percentNeutral",
    //   val: 80 ,
    //   count: "percentBullish" ,
    // });
  }, [movingAverageData]);

  function formatData(inputData) {
    const formattedData = [];

    for (const key in inputData) {
      if (key.endsWith("_Sentiment")) {
        const sentiment = inputData[key];
        const name = key.slice(0, -10);

        if (sentiment.bullish === 1) {
          formattedData.push({
            name: name,
            type: "bullish",
            value: inputData[name].toFixed(2),
          });
        } else if (sentiment.bearish === 1) {
          formattedData.push({
            name: name,
            type: "bearish",
            value: inputData[name].toFixed(2),
          });
        } else if (sentiment.neutral === 1) {
          formattedData.push({
            name: name,
            type: "neutral",
            value: inputData[name].toFixed(2),
          });
        }
      }
    }

    return formattedData;
  }
  const fetchTechnicalData = async (technicalBody) => {
    try {
      const getTechnicalData = await getTechnicalChartData(technicalBody);
      if (!getTechnicalData) {
        console.error(
          "Error: Received undefined or null data from fetchTechnicalData"
        );
        return;
      }
      setTechnicalData(getTechnicalData);
      const segregatedDataOscill = formatData(getTechnicalData);
      setSegregrateOscill(segregatedDataOscill);
      console.log("segregatedDataOscill", segregatedDataOscill);
    } catch (error) {
      console.error("Error fetching technical technicalData", error);
    }
  };
  useEffect(() => {
    fetchTechnicalData(technicalBody);
  }, [userData?.id, activeTab]);
  // console.log("technicalData>>", technicalData);
  useEffect(() => {
    let totalBearish = technicalData?.TotalBearish;
    let totalBullish = technicalData?.TotalBullish;
    let totalNeutral = technicalData?.TotalNeutral;

    const maxValueCount = Math.max(totalBearish, totalBullish, totalNeutral);
    // console.log("maxValueCount", maxValueCount  )
    let maxTypeCount;

    if (maxValueCount === totalBearish) {
      maxTypeCount = "percentBearish";
    } else if (maxValueCount === totalBullish) {
      maxTypeCount = "percentBullish";
    } else if (maxValueCount === totalNeutral) {
      maxTypeCount = "percentNeutral";
    }
    let findMaxPer = {
      percentBearish: technicalData?.Berish_Percentage,
      percentBullish: technicalData?.Bullish_Percentage,
      percentNeutral: technicalData?.Neutral_Percentage,
    };
    let maxPercentage = -Infinity;
    let maxType = "";

    for (const key in findMaxPer) {
      if (findMaxPer[key] > maxPercentage) {
        maxPercentage = findMaxPer[key];
        maxType = key;
      }
    }
    setMaxTechProps({ type: maxType, val: maxPercentage, count: maxTypeCount });
    console.log("findMaxPer", maxTechProps);
  }, [technicalData]);

  const formatNumber = (value) => {
    if (typeof value === "number") {
      return value.toFixed(2);
    } else if (typeof value === "string") {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return parsedValue.toFixed(2);
      }
    }
    return "N/A";
  };

  useEffect(() => {
    let totalBearish = technicalData?.Sentiment_Oscillator?.bearish;
    let totalBullish = technicalData?.Sentiment_Oscillator?.bullish;
    let totalNeutral = technicalData?.Sentiment_Oscillator?.neutral;

    const maxValueCount = Math.max(totalBearish, totalBullish, totalNeutral);
    // console.log("maxValueCount", maxValueCount  )
    let maxTypeCount;

    if (maxValueCount === totalBearish) {
      maxTypeCount = "percentBearish";
    } else if (maxValueCount === totalBullish) {
      maxTypeCount = "percentBullish";
    } else if (maxValueCount === totalNeutral) {
      maxTypeCount = "percentNeutral";
    }
    let findMaxPer = {
      percentBearish: technicalData?.Sentiment_Oscillator?.bearishPercentage,
      percentBullish: technicalData?.Sentiment_Oscillator?.bullishPercentage,
      percentNeutral: technicalData?.Sentiment_Oscillator?.neutralPercentage,
    };
    let maxPercentage = -Infinity;
    let maxType = "";

    for (const key in findMaxPer) {
      if (findMaxPer[key] > maxPercentage) {
        maxPercentage = findMaxPer[key];
        maxType = key;
      }
    }
    setMaxTechOscillProps({
      type: maxType,
      val: maxPercentage,
      count: maxTypeCount,
    });
    console.log("maxValueOscc", maxTectOcillatorProp);
  }, [technicalData]);

  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer" id="symbol-card">
          <Row id="symbol-active-list">
            <Col lg sm xs className="d-flex justify-content-center p-0">
              <ListGroup horizontal className="p-0">
                {timeIntervals?.map((interval) => (
                  <ListGroup.Item
                    key={interval.key}
                    className={`cursor-pointer custom-list-item ${
                      activeTab === interval.key ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(interval.key)}
                  >
                    {interval.label}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col className="title-section">
              {paramsData?.itemName && (
                <i
                  className="fa fa-chevron-left chev fs-5 pointer-cursor"
                  onClick={() => {
                    navigate(-1);
                  }}
                ></i>
              )}
              &nbsp;&nbsp;
              <span className="fs-4 fw-bold ">{paramsData?.itemName}</span>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={6}>
              <span className="fs-5">
                {technicalData?.LivePrice && (
                  <>
                    <i className="fa fa-rupee"></i> {technicalData.LivePrice}
                  </>
                )}{" "}
                &nbsp; &nbsp;
                {technicalData?.ChangePercentage && (
                  <span
                    className={
                      technicalData?.ChangePercentage > 0
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    {" "}
                    {technicalData?.ChangePercentage > 0 ? (
                      <i className="fa fa-arrow-up" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-arrow-down" aria-hidden="true"></i>
                    )}
                    &nbsp;{technicalData?.ChangePercentage}%
                  </span>
                )}
              </span>
            </Col>
            <Col lg={6} md={6} sm={6}>
              {/* <span className="pull-right"> */}
              <div className="d-flex flex-row justify-content-end">
                <Button
                  size="sm"
                  variant="primary"
                  className="w-25"
                  onClick={() => {
                    setBuyModal(true);
                  }}
                >
                  &nbsp;&nbsp;Buy&nbsp;&nbsp;
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="sm"
                  variant="danger"
                  className="w-25"
                  onClick={() => {
                    setSellModal(true);
                  }}
                >
                  &nbsp;&nbsp;Sell&nbsp;&nbsp;
                </Button>
              </div>
              {/* </span> */}
            </Col>
          </Row>
          <br />
          <Row className="pt-4">
            <Col lg={6} md={6} sm={12}>
              <Card style={{ position: "relative" }}>
                <Card.Body>
                  <Row>
                    <Col className="heading-card">
                      <div className="fw-bold">Summary</div>
                      <div>
                        <i className="fa fa-info"></i>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="d-flex align-items-center justify-content-center">
                      <div
                        style={{
                          width: "90%",
                          height: "200px",
                        }}
                      >
                        {/* <Chart
                        options={options}
                        series={series}
                        type="bar"
                        height="200"
                      /> */}
                        <MovingAverageChart maxPercentage={maxTechProps} />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-0">
                    <Col className="d-flex flex-row justify-content-center align-items-center">
                      <div className="d-grid gap-2 col">
                        <span className="text-center">
                          <span>{technicalData?.TotalBearish}</span>
                          <br />
                          <Button size="sm" className="btn-bearish w-100">
                            Bearish
                          </Button>
                        </span>
                      </div>
                      &nbsp;
                      <div className="d-grid gap-2 col">
                        <span className="text-center">
                          <span>{technicalData?.TotalNeutral}</span>
                          <br />
                          <Button size="sm" className="btn-natural  w-100">
                            Neutral
                          </Button>
                        </span>
                      </div>
                      &nbsp;
                      <div className="d-grid gap-2 col">
                        <span className="text-center">
                          <span>{technicalData?.TotalBullish}</span>
                          <br />
                          <Button size="sm" className="btn-bulish  w-100">
                            Bullish
                          </Button>
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column align-items-center p-2"
                    >
                      <span className="text-number">
                        {formatNumber(technicalData?.EMA_Values_20)}
                      </span>
                      <span className="text-title">EMA (20)</span>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column  align-items-center p-2"
                    >
                      <span className="text-number">
                        {formatNumber(technicalData?.SMA_Values_20)}
                      </span>
                      <span className="text-title">SMA (20)</span>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column  align-items-center p-2"
                    >
                      <span className="text-number">
                        {formatNumber(technicalData?.RSI)}
                      </span>
                      <span className="text-title">RSI (14)</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column  align-items-center p-2 "
                    >
                      <span className="text-number">
                        {formatNumber(technicalData?.Awesome)}
                      </span>
                      <span className="text-title">Awesome Osc.</span>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column  align-items-center p-2"
                    >
                      <span className="text-number">
                        {formatNumber(technicalData?.MACD)}
                      </span>
                      <span className="text-title">Macd (12, 26, 9)</span>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column  align-items-center p-2"
                    >
                      <span className="text-number">
                        {formatNumber(technicalData?.CCI)}
                      </span>
                      <span className="text-title">CCI (20)</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Card className="py-1">
                <Card.Body>
                  <Row>
                    <Col className="heading-card">
                      <span className="fw-bold">Support & Resistance</span>
                      <span>
                        <i className="fa fa-info"></i>
                      </span>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center align-items-center">
                    <Col className="d-flex align-items-center justify-content-center mt-3">
                      <div
                        style={{
                          //  display : "flex" ,
                          //  flexDirection : "column" ,
                          //  justifyContent : "center" ,
                          width: "90%",
                          height: "150px",
                          marginLeft: "12%",
                          // marginRight : "0%" ,
                          // border: "1px solid",
                          padding: "5px",
                        }}
                      >
                        <SupportResistanceChart technicalData={technicalData} />
                        <div
                          className="d-flex ms-2 fs-6  justify-content-around"
                          style={{
                            width: "80%",
                            height: "20px",
                            color: "#483091",
                            // border: "1px solid",
                            padding: "5px",
                          }}
                        >
                          <p>S3</p>
                          <p>S2</p>
                          <p>S1</p>
                          <p>R1</p>
                          <p>R2</p>
                          <p>R3</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <Row className="d-flex justify-content-center align-items-center mt-5">
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column text-center "
                    >
                      <span className="text-number">
                        {technicalData?.supportResistanceLevels?.s1}
                      </span>
                      <span className="text-title">S1</span>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column text-center"
                    >
                      <span className="text-number">
                        {technicalData?.supportResistanceLevels?.s2}
                      </span>
                      <span className="text-title">S2</span>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column text-center"
                    >
                      <span className="text-number">
                        {technicalData?.supportResistanceLevels?.s3}
                      </span>
                      <span className="text-title">S3</span>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center align-items-center mt-3">
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column text-center"
                    >
                      <span className="text-number">
                        {technicalData?.supportResistanceLevels?.r1}
                      </span>
                      <span className="text-title">R1</span>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column text-center"
                    >
                      <span className="text-number">
                        {technicalData?.supportResistanceLevels?.r2}
                      </span>
                      <span className="text-title">R2</span>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={6}
                      xs={4}
                      className="d-flex flex-column text-center"
                    >
                      <span className="text-number">
                        {technicalData?.supportResistanceLevels?.r3}
                      </span>
                      <span className="text-title">R3</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={6} md={6} sm={12}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col lg sm md className="heading-card">
                      <span className="fw-bold">Moving averages</span>
                      <i className="fa fa-info pull-right"></i>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex align-items-center justify-content-center">
                      <div
                        style={{
                          width: "90%",
                          height: "200px",
                          // border: "1px solid"
                        }}
                      >
                        {/* <Chart
                        options={options}
                        series={[
                          {
                            name: series[0].name,
                            data: series[0].data.map((item) => ({
                              x: item.x,
                              y: item.y,
                              fillColor: item.fillColor,
                            })),
                          },
                        ]}
                        type="bar"
                        height={200}
                      /> */}
                        <MovingAverageChart maxPercentage={maxPercentage} />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-0">
                    <Col className="d-flex flex-row justify-content-center align-items-center">
                      <div className="d-grid gap-2 col">
                        <span className="text-center">
                          <span>{movingAverageData?.totalBearish}</span>
                          <br />
                          <Button size="sm" className="btn-bearish w-100">
                            Bearish
                          </Button>
                        </span>
                      </div>
                      &nbsp;
                      <div className="d-grid gap-2 col">
                        <span className="text-center">
                          <span>{movingAverageData?.totalNeutral}</span>
                          <br />
                          <Button size="sm" className="btn-natural  w-100">
                            Neutral
                          </Button>
                        </span>
                      </div>
                      &nbsp;
                      <div className="d-grid gap-2 col">
                        <span className="text-center">
                          <span>{movingAverageData?.totalBullish}</span>
                          <br />
                          <Button size="sm" className="btn-bulish  w-100">
                            Bullish
                          </Button>
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Table borderless id="moving-average-table" className="mt-2">
                    <tbody className="border-0">
                      {averageSegregatingData
                        ?.slice(0, 2)
                        .map((item, index) => {
                          const sentimentKey = Object.keys(item)[0];
                          const valueKey = sentimentKey?.replace(
                            "_Sentiment",
                            "_Values"
                          );
                          const sentiment = item[sentimentKey];
                          const value =
                            movingAverageData && movingAverageData[valueKey];

                          return (
                            <tr key={index}>
                              <td className="text-left col-5 border-0">
                                {valueKey}
                              </td>
                              <td className="text-center border-0">
                                <div
                                  className="round_icon text-center"
                                  style={getCellStyle(sentiment)}
                                >
                                  {sentiment == "neutral" ? "N" : "B"}
                                </div>
                              </td>
                              <td className="text-center col-5 border-0">
                                {value}
                              </td>
                            </tr>
                          );
                        })}
                      {averageExapnd &&
                        averageSegregatingData?.slice(2).map((item, index) => {
                          const sentimentKey = Object.keys(item)[0];
                          const valueKey = sentimentKey.replace(
                            "_Sentiment",
                            "_Values"
                          );
                          const sentiment = item[sentimentKey];
                          const value =
                            movingAverageData && movingAverageData[valueKey];

                          return (
                            <tr key={index}>
                              <td className="text-left">{valueKey}</td>
                              <td>
                                <div
                                  className="round_icon"
                                  style={getCellStyle(sentiment)}
                                >
                                  {sentiment == "neutral" ? "N" : "B"}
                                </div>
                              </td>
                              <td className="text-center">{value}</td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td colSpan="3">
                          <div
                            onClick={toggleAverageExpand}
                            style={{ cursor: "pointer", textAlign: "right" }}
                          >
                            {averageExapnd ? (
                              <p>
                                <span>Show less </span>
                                <i className="fa-solid fa-angle-up"></i>
                              </p>
                            ) : (
                              <p>
                                <span>View Details </span>
                                <i className="fa-solid fa-angle-down"></i>
                              </p>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col lg sm md className="heading-card">
                      <span className="fw-bold">Oscillators</span>
                      <i className="fa fa-info pull-right"></i>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex align-items-center justify-content-center">
                      <div
                        style={{
                          width: "80%",
                          height: "200px",
                        }}
                      >
                        {/* <Chart
                        options={options}
                        series={series}
                        type="bar"
                        height="150"
                      /> */}
                        <MovingAverageChart
                          maxPercentage={maxTectOcillatorProp}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-0">
                    <Col className="d-flex flex-row justify-content-center align-items-center">
                      <div className="d-grid gap-2 col">
                        <span className="text-center">
                          <span>
                            {technicalData?.Sentiment_Oscillator?.bearish}
                          </span>
                          <br />
                          <Button size="sm" className="btn-bearish w-100">
                            Bearish
                          </Button>
                        </span>
                      </div>
                      &nbsp;
                      <div className="d-grid gap-2 col">
                        <span className="text-center">
                          <span>
                            {technicalData?.Sentiment_Oscillator?.neutral}
                          </span>
                          <br />
                          <Button size="sm" className="btn-natural  w-100">
                            Neutral
                          </Button>
                        </span>
                      </div>
                      &nbsp;
                      <div className="d-grid gap-2 col">
                        <span className="text-center">
                          <span>
                            {technicalData?.Sentiment_Oscillator?.bullish}
                          </span>
                          <br />
                          <Button size="sm" className="btn-bulish  w-100">
                            Bullish
                          </Button>
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Table borderless id="moving-average-table" className="mt-2">
                    <tbody className="border-0">
                      {segregrateOscillator &&
                        segregrateOscillator?.slice(0, 2).map((item, i) => {
                          return (
                            <tr key={i}>
                              <td className="text-left col-5 border-0">
                                {item.name}
                              </td>
                              <td className="text-center border-0">
                                <div
                                  className="round_icon text-center"
                                  style={getCellStyle(item.type)}
                                >
                                  {item.type == "neutral" ? "N" : "B"}
                                </div>
                              </td>
                              <td className="text-center col-5 border-0">
                                {item.value}
                              </td>
                            </tr>
                          );
                        })}
                      {oscillatorExapnd &&
                        segregrateOscillator &&
                        segregrateOscillator?.slice(2).map((item, i) => {
                          return (
                            <tr key={i}>
                              <td className="text-left col-5 border-0">
                                {item.name}
                              </td>
                              <td className="text-center border-0">
                                <div
                                  className="round_icon text-center"
                                  style={getCellStyle(item.type)}
                                >
                                  {item.type == "neutral" ? "N" : "B"}
                                </div>
                              </td>
                              <td className="text-center col-5 border-0">
                                {item.value}
                              </td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td colSpan="3">
                          <div
                            onClick={toggleOscillatorExpand}
                            style={{ cursor: "pointer", textAlign: "right" }}
                          >
                            {oscillatorExapnd ? (
                              <p>
                                <span>Show less </span>
                                <i className="fa-solid fa-angle-up"></i>
                              </p>
                            ) : (
                              <p>
                                <span>View Details </span>
                                <i className="fa-solid fa-angle-down"></i>
                              </p>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <BuySymbolModal buyModal={buyModal} setBuyModal={setBuyModal} />
          <SellSymbolModal sellModal={sellModal} setSellModal={setSellModal} />
        </div>
      </section>
    </>
  );
};

export default Symbol;
