import React, { useEffect, useState } from "react";

const SelStockBox = ({ item, onRemove, index }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleRemoveClick = () => {
    onRemove(index);
  };
 
  const symbol=item?.tradingsymbol;
  const istoolong=symbol?.length>7;
  const displaytext=istoolong? `${symbol.slice(0, 8)}...`:symbol;
  return (
    <div className="mx-2"
      style={{
        position: "relative",
        height: "160px",
        width: "160px",
        borderRadius: "10px",
        border: "1px solid lightgray",
        background: "white",
        padding: "12px",
        // marginRight: "20px",
        // transition: "opacity 0.3s ease",
      
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{width:"160px",height:"100%"}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width:"100%",
       
        }}
      >
        <img
          src="stockPlaceholder.png"
          alt="stock logo"
          height={40}
          width={40}
        />

        <div style={{ paddingLeft: "6px" }}>
          <div
            style={{
              fontSize: "12px",
              // wordBreak: "break-word",
              overflowWrap: "break-word",
              // flexWrap: "wrap",
              // width: "80%",
              maxHeight: "30px",
              fontWeight: "500",
            
            }}
          >
            {/* {(item?.tradingsymbol).length>11?<div>not </div>:item?.tradingsymbol} */}
            {/* {displaytext}
            {istoolong && <span className="tooltip-text">{symbol}</span>} */}
             <div className="trading-symbol-container">
           <div className="trading-symbol-tooltip">
            <span className="trading-symbol-text">{displaytext}</span>
                {istoolong && <span className="tooltip-text">{symbol}</span>}
           </div>
           </div>
          </div>
          <div style={{ fontSize: "12px", marginTop: "6px" }}>
            {item?.exchange}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "40px" }}>
        <div
          style={{
            fontSize: "15px",
            fontWeight: "600",
            color:
              item?.ltp !== 0 && item?.closePrice !== 0
                ? item.ltp > item.closePrice
                  ? "#008000"
                  : "#BA0F04"
                : "#060606",
          }}
        >
          <span style={{ fontSize: "18px" }}>{item?.ltp}</span>
          <br />
          {item.ltp && item.closePrice && (
            <>
              {(((item.ltp - item.closePrice) / item.closePrice) * 100).toFixed(
                3
              )}
              %
            </>
          )}
        </div>
      </div>
      </div>
       {/* {isHovered && (
        <div
          style={{
            position: "absolute",
            top: 30,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Light color overlay
            borderRadius: "10px",
          }}
        />
      )}
    */}
      <div
        style={{
          position: "absolute",
          top: "35%",
          right: "5%",
          padding: "8px",
          cursor: "pointer",
          opacity: isHovered ? 1 : 0,
          transition: "opacity 0.3s ease",
        }}
        onClick={handleRemoveClick}
      >
        <i className="fa-solid fa-trash" style={{ fontSize: "20px" }}></i>
      </div> 
    </div>
  );
};
export default SelStockBox;
