import React, { useState } from "react";
import { Button, Col, Container, ListGroup, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const NotificationM = () => {
    const navigate = useNavigate();
    const [dataPresentShow , setDataPresentShow] = useState(true);
    const [allNotifyBtn , setAllNotifyBtn] = useState(true);
    const [liveStrategyBtn , setLiveStrategyBtn] = useState(false);
    const [paperTradingBtn , setPaperTradingBtn] = useState(false);
    const [scannetBtn , setScannerBtn] = useState(false);
    const [activeTab, setActiveTab] = useState("all");

    const handleClickShowNofification = (btn) => {
        // if(btn === "all"){
        //     setAllNotifyBtn(true);
        //     setLiveStrategyBtn(false);
        //     setPaperTradingBtn(false);
        //     setScannerBtn(false);
        // }else if(btn === "strategies"){
        //     setLiveStrategyBtn(true);
        //     setAllNotifyBtn(false);
        //     setPaperTradingBtn(false);
        //     setScannerBtn(false);
        // }else if(btn === "paper"){
        //     setPaperTradingBtn(true);
        //     setAllNotifyBtn(false);
        //     setLiveStrategyBtn(false);
        //     setScannerBtn(false);
        // }else if(btn === "scanner"){
        //     setScannerBtn(true);
        //     setAllNotifyBtn(false);
        //     setLiveStrategyBtn(false);
        //     setPaperTradingBtn(false);
        // }
        setActiveTab(btn)
    }


    const NotificationData = [
        {
            instrument : {
                img : "/icons/Ellipse 12.png",
                title :"NFTY",
                subTitle : "nse"
            },
            strategyName : "Buy",
            price : "-",
            qty : "1",
            time : "11:23:11 AM",
            status : {
                img : "img",
                status : "REJECTED"
            },
            url : "DIRECT ORDER"
        },
        {
            instrument : {
                img : "/icons/Ellipse 12.png",
                title :"NFTY",
                subTitle : "nse"
            },
            strategyName : "Buy",
            price : "-",
            qty : "1",
            time : "11:23:11 AM",
            status : {
                img : "img",
                status : "REJECTED"
            },
            url : "DIRECT ORDER"
        }
    ]

  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer">
            <Row>
                <Container className="title-section p-0">
                        <Col className="d-flex justify-content-between align-items-center">
                            <div>
                                <i className="fa fa-chevron-left pointer-cursor " onClick={() => navigate(-1)}></i>
                                <span>Notifications</span>
                            </div>
                            
                        </Col>
                </Container>
            </Row>
            <Row id="notification-menu">
                <Col >
                        <ListGroup horizontal className="pull-right">
                            <ListGroup.Item className={`${activeTab == "all" && 'active'}`} onClick={() => {handleClickShowNofification("all")}}>
                                <i className="fa fa-bell-o" ></i>
                               {activeTab == "all" && (<>&nbsp;All Notifications</>)}                             </ListGroup.Item>
                            <ListGroup.Item className={`${activeTab == "strategies" && 'active'}`} onClick={() => {handleClickShowNofification("strategies")}}>
                                <i className="fa fa-wifi" ></i>
                               {activeTab == "strategies" && (<>&nbsp;Live Strategies</>)} 
                            </ListGroup.Item>
                            <ListGroup.Item className={`${activeTab == "paper" && 'active'}`} onClick={() => {handleClickShowNofification("paper")}}>
                                <i className="fa fa-file-text-o" ></i>
                                {activeTab == "paper" && (<>&nbsp;Paper Trading</>)}
                            </ListGroup.Item>
                            <ListGroup.Item className={`${activeTab == "scanner" && 'active'}`} onClick={() => {handleClickShowNofification("scanner")}}>
                                <i className="fa fa-minus-square-o" ></i>
                                {activeTab == "scanner"  && (<>&nbsp;Scanners</>)}
                            </ListGroup.Item>
                        </ListGroup>
                </Col>
            </Row>
            {!dataPresentShow ? (
            <Row className="notifiy-no-data">
                <Col lg md sm xs className="d-flex justify-content-center align-items-center flex-column">
                    <span>
                    <i className="fa fa-folder-o fa-5x"></i></span>
                    <span><h6>You don't have any notifications</h6></span>
                    <span><Link to="/dashboard" className="text-decoration-none"> Go to Dashboard</Link></span>
                </Col>
            </Row>
            ) : (
                <Table className="mt-3" id="notification-table"  borderless >
                    <thead >
                        <tr>
                            <th>
                                Instrument
                            </th>
                            <th>
                                Strategy Name
                            </th>
                            <th>
                                Price
                            </th><th>
                                Qty
                            </th>
                            <th>
                                Time
                            </th>
                            <th>
                                Status
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            NotificationData.map((item , i) => (
                                <tr key={i}>
                                    <td className="d-flex justify-content-center align-items-center">
                                        <span>
                                            <img src={item.instrument.img} alt="notification-img" width={20} height={20} />
                                              </span>&nbsp;
                                        <span className="d-flex flex-row ">
                                            <span className="main-title fw-5"> {item.instrument.title}</span>&nbsp;
                                            <span className="sub-title">{item.instrument.subTitle}</span>
                                        </span>

                                    </td>
                                    <td>
                                        {
                                            item.strategyName
                                        }
                                    </td>
                                    <td>
                                        {
                                            item.price
                                        }
                                    </td>
                                    <td>
                                        {
                                            item.qty
                                        }
                                    </td>
                                    <td>
                                        {
                                            item.time
                                        }
                                    </td>
                                    <td>
                                        {
                                            // item.status.img
                                        }
                                        <Button className="item-status-rejected">{
                                            item.status.status
                                        }</Button>
                                    </td>
                                    <td className="order">
                                        {item.url} <i class="fa fa-caret-right" aria-hidden="true"></i>
                                    </td>
                                </tr>
                            ))
                        }
                        
                    </tbody>
                </Table>

            )}
        </div>
      </section>
    </>
  );
};

export default NotificationM;
