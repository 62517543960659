import React, { useEffect, useState, useMemo } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import StocksList from "../../mcomponents/StocksListBox";
import SelStockBox from "./selStockBox";
import { ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  get5paisaToken,
  getZerodhaToken,
  save5paisaToken,
} from "../../Session";

import { useMenuContext } from "./StrategyCreateComponent";
import CreateStockBox from "./CreateStockBox";

import dayjs from "dayjs";
import moment from "moment";
import { getStockLTPDataAPI } from "../../api/api";
import NumberInput from "../NumberInput";
import { useLocation } from "react-router-dom";
import CustomAlert from "../componentCommon/CustomAlert";

const StrategyPositionComponent = ({
  formData,
  onFormChange,
  stocks,
  setStocks,
  handleSaveStocks,
  handleDates,
  save,
  strategyName,
  activeButton,
  handleChangeCharType,
  setCreateStratgyPage,
}) => {
  console.log("formData.candle_interval_id", formData);
  const { menu, setMenu } = useMenuContext();
  const location = useLocation();
  const [showList, setShowList] = useState(false);
  const [noStock, setNostock] = useState();
  const [subscriptionRequestToken, setsubscriptionRequestToken] = useState();
  const [ltpData, setLtpData] = useState();
  const [allStocks, setAllStocks] = useState();
  const [quantityReadOnly, setQuantityReadOnly] = useState(false);
  const [maxAllocationReadOnly, setMaxAllocationReadOnly] = useState(false);
  const [stockTokens, setStockTokens] = useState([]);
  const [menuChanged, setMenuChanged] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [data, setData] = useState([]);
  const [transparentBackground, setTransparentBackground] = useState(false);
  const [isListDone, setIsListDone] = useState(false);

  useEffect(() => {
    setData(formData.stocks);
  }, [formData.stocks]);
  useEffect(() => {
    console.log("formData>>", formData.stocks);
    let tokens = formData.stocks?.map((i) => parseInt(i?.instrument_token));
    console.log("tokens>2", tokens);
    const interval = setInterval(() => {
      getStocksData(tokens);
    }, 2000);
    setIntervalId(interval);
    return () => {
      clearInterval(interval); // Clear interval when component is unmounted
      setIntervalId(null);
    };
  }, [location.pathname, formData.stocks]);

  useEffect(() => {
    if (save) handleNext();
  }, [save]);

  useEffect(() => {
    return () => {
      console.log("Component will unmount");
      clearInterval(intervalId);
      setIntervalId(null);
      // Clear interval when component is unmounted or when dependencies change
    };
  }, []);

  useEffect(() => {
    if (formData.candle_interval_id === "1d")
      onFormChange({
        ...formData,
        holding_type: "CNC/NRML",
      });
  }, [formData.candle_interval_id]);

  const getSelectedItems = (items) => {
    console.log("items>", items);
    const itemArray = [];
    items.map((i) => {
      itemArray.push(i.token);
    });

    let tokens = items?.map((i) => parseInt(i?.instrument_token));
    console.log("tokens3>", tokens);
    setStockTokens(tokens);

    // getStocksData(tokens);
    setsubscriptionRequestToken(itemArray);
    setStocks(items);
    console.log("itemsUpdated>", items);

    // Create a new array with modified values
    const newStocks = items?.map((item) => ({
      ...item,
      // exch: item?.exch_seg.charAt(0).toLowerCase(),
      exch: item?.exchange,
      exchType: item?.ExchType,
      scripCode: item?.instrument_token,
      stock_name: item?.tradingsymbol + " " + item?.exchange,
    }));

    // Log the new array
    console.log("newArray", newStocks);
    formData.stocks = newStocks;
    onFormChange(formData);

    onFormChange((prevData) => ({
      ...prevData,
      noStock: items?.length > 0 ? true : false,
      // stock_name: items[0]?.name,
      // script_code: items[0]?.token,
      // scripCode: items[0]?.token,
      // exch: items[0]?.exch_seg === "NSE" ? "n" : "b",
    }));
    console.log("JJJJ", items[0]?.token);
  };

  const handleRemove = (index) => {
    const updatedSelectedItems = [...formData.stocks];
    updatedSelectedItems.splice(index, 1);
    setStocks(updatedSelectedItems);

    console.log("updatedSelectedItems", updatedSelectedItems);
    if (updatedSelectedItems?.length === 0) setStockTokens([]);

    formData.stocks = updatedSelectedItems;
    onFormChange(formData);
  };
  const handleClickCandleInterval = (timeid) => {

    let startDate = "";
    let endDate = "";
    if (timeid === 1) {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      endDate = moment().subtract(29, "days").format("YYYY-MM-DD"); // Assign end date for 1min and 2min intervals
      // if (formData.monthOrWeekAdded) formData.monthOrWeekAdded = false;
    } else if (
      timeid === 3 ||
      timeid === 5 ||
      timeid === 10 ||
      timeid === 15 ||
      timeid === 30
    ) {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      endDate = moment().subtract(90, "days").format("YYYY-MM-DD"); // Assign
      // if (formData.monthOrWeekAdded) formData.monthOrWeekAdded = false;
    } else if (timeid === 60) {
      // if (formData.monthOrWeekAdded) formData.monthOrWeekAdded = false;
      // if (formData.monthOrWeekAdded) {
        startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
        endDate = moment().subtract(1, "year").format("YYYY-MM-DD"); // Assign
      // } else {
        // startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
        // endDate = moment().subtract(1, "year").format("YYYY-MM-DD"); // Assign
        // endDate = moment().subtract(90, "days").format("YYYY-MM-DD");
      }
    // } 
    else if(timeid == '1day') {
      // if (formData.monthOrWeekAdded) formData.monthOrWeekAdded = false;
      // if (formData.monthOrWeekAdded) {
        startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
        endDate = moment().subtract(5, "year").format("YYYY-MM-DD"); // Assign
      // } else {
      //   if (formData.monthOrWeekAdded) formData.monthOrWeekAdded = false;
      //   startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      //   endDate = moment().subtract(90, "days").format("YYYY-MM-DD"); // Assign
      // }
    }

    onFormChange({
      ...formData,
      candleIntervalId: timeid,
      start_date: endDate,
      end_date: startDate,
      candle_interval_id:
        timeid === 1
          ? "1m"
          : timeid === 3
          ? "3m"
          : timeid === 5
          ? "5m"
          : timeid === 10
          ? "10m"
          : timeid === 15
          ? "15m"
          : timeid === 30
          ? "30m"
          : timeid === 60
          ? "60m"
          : timeid === "1day"
          ? "1d"
          : "",
    });
    console.log("CHANGETIME", formData);
  };

  const handleClickHoldingType = (value) => {
    onFormChange({
      ...formData,
      holding_type: value,
    });
  };

  const handleCheckboxChange = (value) => {
    onFormChange({
      ...formData,
      previous_day: value === true ? 1 : 0,
    });
  };

  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    console.log("checkName", value, name);
    if (name === "max_allocation" || name === "maxSlPerTrade") {
      value > 0 || value !== ""
        ? setQuantityReadOnly(true)
        : setQuantityReadOnly(false);

      onFormChange({
        ...formData,
        [name]: value > 0 ? parseInt(value) : value || "",
        quantity: 0,
      });
    } else if (name === "quantity") {
      onFormChange({
        ...formData,
        max_allocation: 0,
        [name]: parseInt(value) || "",
      });
    } else {
      onFormChange({
        ...formData,
        [name]: value > 0 ? parseInt(value) : value || "",
      });
    }
  };

  const handleSelectChange = (event) => {
    onFormChange((prevData) => ({
      ...prevData,
      position_sizing_type: event.target.value,
    }));
  };
  const handleNext = () => {
    // Perform any necessary actions before navigating to the next step
    // For example, validate form data
    if (stocks?.length === 0 && formData.stocks?.length === 0) {
      setNostock(true);
    } else {
      // Navigate to the next step
      setMenu("entry");
      setMenuChanged(true);
    }
    clearInterval(intervalId);
    setIntervalId(null);
  };
  const getStocksData = async (tokens) => {
    const token = getZerodhaToken();
    const apiKey = "3wxj01wpd2rnnn9c";

    console.log("token--", token);
    console.log("stockTokensSent", tokens);

    const queryParams = tokens?.map((token) => `i=${token}`).join("&");
    console.log("queryParams??", queryParams);
    if (tokens?.length > 0) {
      const response = await getStockLTPDataAPI({
        apiKey: apiKey,
        token: token,
        queryParams: queryParams,
      });
      if (response) {
        if (response?.data)
          updateStocksDataNew(formData.stocks, response?.data);
      }
    }
  };

  const handleCloseDoor = () => {
    setTransparentBackground(false);
    setShowList(false);
  };
  const updateStocksDataNew = (stocks, data) => {
    // Iterate through each item in the response data
    Object.keys(data).forEach((instrumentToken) => {
      const stockToUpdate = stocks.find(
        (stock) => stock.instrument_token == instrumentToken
      );
      // console.log("stockToUpdate", stockToUpdate);

      // If the stock is found in the initial array
      if (stockToUpdate) {
        const newData = data[instrumentToken];
        stockToUpdate.ltp = newData.last_price;
        stockToUpdate.closePrice = newData.ohlc.close;
        // Add any other properties you need to update here
      }
    });

    // Assuming setLtpData and onFormChange are functions to update state or perform some action
    setLtpData(stocks);
    // setData(stocks);
    setData([...stocks]);
    // formData.stocks = stocks;
    // onFormChange(formData);
  };
  useEffect(() => {
    if (isListDone) {
      setTimeout(() => {
        setIsListDone(false);
      }, 1500);
    }
  }, [isListDone]);
  const MemoizedSelStockBox = useMemo(
    () => React.memo(SelStockBox),
    [formData?.stocks, stockTokens, data]
  );

  const memoizedStocksList = useMemo(
    () => (
      <StocksList
        storeSelectedItems={getSelectedItems}
        setShowList={setShowList}
        selectedStock={formData.stocks ? formData.stocks : stocks}
        setTransparentBackground={setTransparentBackground}
        setIsListDone={setIsListDone}
        isListDone={isListDone}
      />
    ),
    [
      getSelectedItems,
      setShowList,
      formData.stocks,
      stocks,
      formData,
      allStocks,
      isListDone,
    ]
  );

  return (
    <>
      {isListDone && (
        <CustomAlert
          message="Add and Update stocks successfully"
          type="alert-success"
          setIsListDone={setIsListDone}
        />
      )}
      <div className="row create-strategy" id="position-first-stock-div">
        <div className="col-lg-12" style={{ marginBottom: "20px" }}>
          <h4 className="fw-bolder d-inline">{strategyName}</h4>
        </div>
        <h5>Instruments</h5>
        <div
          className={transparentBackground && "transparent_background"}
          onClick={handleCloseDoor}
        ></div>
        {stocks?.length !== 0 || formData.stocks?.length > 0 ? (
          <div
            className="col-lg-12 pt-5"
            style={{
              display: "flex",
              alignItems: "center",
              overflowX: "auto",
              marginBottom: "15px",
              overflowY: "hidden",
            }}
          >
            <CreateStockBox
              setShowList={setShowList}
              setTransparentBackground={setTransparentBackground}
            />
            {data?.map((item, index) => (
              <MemoizedSelStockBox
                item={item}
                onRemove={handleRemove}
                index={index}
                // ltpData={ltpData}
              />
            ))}
          </div>
        ) : (
          <>
            {noStock && (
              <div className="col-lg-12 p-0 d-flex justify-content-between">
                <span style={{ marginLeft: "20px" }} className="text-danger">
                  Please select some stocks
                </span>
                {/* <span className="text-danger ml-auto">Error message</span> */}
              </div>
            )}
            <Card className="uplad-first-stock-card ">
              <Card.Body>
                <div className="col-lg-12 lh-lg">
                  <div>
                    <i className="fa fa-plus"></i>
                  </div>
                  <div>
                    <h3>Add your first stock</h3>
                  </div>
                  <div>
                    <Button
                      className="btn-color"
                      onClick={() => setShowList(true)}
                    >
                      <i className="fa fa-plus"></i> Add Symbol, F&O etc
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
        <div className="pt-3 p-0" id="create-strategy-chart-tab">
          <h6>Chart</h6>
          <div
            className="col-lg-12 technical-nifty-tab"
            id="technical-nifty-tab"
          >
            <Card>
              <Card.Body>
                <div className="row p-0 justify-content-center">
                  <div className="col-lg-2 p-0">
                    <div style={{ marginLeft: "20px" }}>
                      <span className="fw-bold">Chart Type</span>
                      {/* chart type for strategy */}
                      <div id="strategy-chart-type-btn" className="pt-2">
                        <ListGroup
                          horizontal
                          style={{ height: "60%" }}
                          className="p-0"
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <strong>Candlestick</strong>
                              </Tooltip>
                            }
                          >
                            <ListGroup.Item
                              action
                              variant="primary"
                              className={activeButton === 1 ? "active" : ""}
                              onClick={() => {
                                handleChangeCharType(1);
                              }}
                            >
                              {activeButton === 1 ? (
                                <img
                                  src="/candle1white.png"
                                  alt="nt found"
                                  style={{ height: "22px", width: "22px" }}
                                ></img>
                              ) : (
                                <img
                                  src="/candle1black.png"
                                  alt="nt found"
                                  style={{ height: "22px", width: "22px" }}
                                ></img>
                              )}
                            </ListGroup.Item>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <strong>Heikin-Ashi</strong>
                              </Tooltip>
                            }
                          >
                            <ListGroup.Item
                              action
                              // disabled
                              variant="primary"
                              className={activeButton === 2 ? "active" : ""}
                              onClick={() => {
                                handleChangeCharType(2);
                              }}
                            >
                              {activeButton === 2 ? (
                                <img
                                  src="/candle2white.png"
                                  alt="nt found"
                                  style={{ height: "22px", width: "22px" }}
                                ></img>
                              ) : (
                                <img
                                  src="/candle2black.png"
                                  alt="nt found"
                                  style={{ height: "22px", width: "22px" }}
                                ></img>
                              )}
                            </ListGroup.Item>
                          </OverlayTrigger>
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-5 col-md-5 col-sm-7"
                    style={{ paddingLeft: "125px" }}
                  >
                    <div>
                      <span className="fw-bold">Candle Interval</span>
                    </div>
                    <ListGroup
                      horizontal
                      style={{ height: "60%" }}
                      className="pt-2 p-0"
                    >
                      {[1, 3, 5, 10, 15, 30, 60, "1day"].map((interval) => (
                        <ListGroup.Item
                          key={interval}
                          action
                          variant="primary"
                          className={`custom-list-item${
                            formData.candleIntervalId === interval
                              ? " active"
                              : ""
                          }`}
                          onClick={() => handleClickCandleInterval(interval)}
                        >
                          {typeof interval === "number"
                            ? `${interval}min`
                            : interval}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                  <div className="col-lg-3 col-md-3 previous-day-alert-candle">
                    <div>
                      <span className="fw-bold">Previous Day Alert Candle</span>
                    </div>
                    <div
                      className="mt-2 d-flex"
                      style={{ paddingLeft: "30px" }}
                    >
                      <Form.Check // prettier-ignore
                        type="checkbox"
                        id={`default-checkbox`}
                        label={`Yes`}
                        value={1}
                        name="previous_day"
                        checked={formData.previous_day}
                        onChange={() => handleCheckboxChange(true)}
                        className="fw-5"
                      />{" "}
                      &nbsp;&nbsp;
                      <Form.Check // prettier-ignore
                        type="checkbox"
                        id={`default-checkbox`}
                        label={`No`}
                        value={0}
                        checked={!formData.previous_day}
                        onChange={() => handleCheckboxChange(false)}
                        name="previous_day"
                        className="fw-5"
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-2 col-md-3 col-sm-3 p-0 support-resistance-btn"
                    id="strategy-holding-type-btn"
                  >
                    <div>
                      <span className="fw-bold">Holding type</span>
                    </div>
                    <ListGroup horizontal className="pt-2 p-0">
                      <ListGroup.Item
                        action
                        variant="primary"
                        onClick={() => {
                          if (formData.candle_interval_id === "1d") {
                            alert("For 1Day candle interval cannot select MIS");
                            return;
                          }
                          handleClickHoldingType("MIS");
                        }}
                        className={
                          formData.holding_type === "MIS" ? "active" : ""
                        }
                      >
                        MIS
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        className={
                          formData.holding_type === "CNC/NRML" ? "active" : ""
                        }
                        onClick={() => {
                          handleClickHoldingType("CNC/NRML");
                        }}
                        variant="primary"
                      >
                        CNC/NRML
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="pt-3 p-0">
          <h6>Quantity</h6>
          <Card className="quantity-card">
            <Card.Body>
              <Form>
                <Row>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label style={{ fontWeight: "600" }}>
                        Quantity (in lots)
                      </Form.Label>
                      <NumberInput
                        type="number"
                        name="quantity"
                        readOnly={quantityReadOnly}
                        className={quantityReadOnly ? "not-allowed-cursor" : ""}
                        onChange={handleChangeValues}
                        value={formData.quantity}
                        placeholder="Quantity in lots(1=1)"
                        disabled={quantityReadOnly}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label style={{ fontWeight: "600" }}>
                        {formData.position_sizing_type === "risk based"
                          ? "Max SL per trade"
                          : "Max Allocation"}
                      </Form.Label>
                      <NumberInput
                        type="number"
                        name={
                          formData.position_sizing_type === "risk based"
                            ? "maxSlPerTrade"
                            : "max_allocation"
                        }
                        onChange={handleChangeValues}
                        value={
                          formData.position_sizing_type === "risk based"
                            ? formData.maxSlPerTrade
                            : formData.max_allocation
                        }
                        placeholder="Amount per trade (Max allocation)"
                        disabled={maxAllocationReadOnly}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label style={{ fontWeight: "600" }}>
                        Max Quantity ( in lots)
                      </Form.Label>
                      <NumberInput
                        type="number"
                        name="max_quantity"
                        value={formData.max_quantity}
                        onChange={handleChangeValues}
                        placeholder="Max quantity in lots(1=1)"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label style={{ fontWeight: "600" }}>
                        Position Sizing type
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleSelectChange}
                        value={formData.position_sizing_type}
                      >
                        <option value="">-</option>
                        <option value="capital based">Capital Based</option>
                        <option value="risk based">Risk Based</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
      {showList && memoizedStocksList}
    </>
  );
};

export default StrategyPositionComponent;
