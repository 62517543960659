// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-wrapper {
    position: relative;
    overflow: hidden;
    background: var(--skeleton-bg, #e0e0e0); 
    border-radius: var(--skeleton-radius, 4px); 
    width: var(--skeleton-width, 100%); 
    height: var(--skeleton-height, 100%); 
    margin-top: var(--skeleton-marginTop, 2%);
  }
  /* Skeleton animation */
  .skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, var(--skeleton-bg, #e0e0e0) 25%, var(--skeleton-highlight, #f5f5f5) 50%, var(--skeleton-bg, #e0e0e0) 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  /* Shimmer animation */
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/SkeletonLoader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,uCAAuC;IACvC,0CAA0C;IAC1C,kCAAkC;IAClC,oCAAoC;IACpC,yCAAyC;EAC3C;EACA,uBAAuB;EACvB;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,4IAA4I;IAC5I,0BAA0B;IAC1B,gCAAgC;EAClC;EACA,sBAAsB;EACtB;IACE;MACE,4BAA4B;IAC9B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".skeleton-wrapper {\n    position: relative;\n    overflow: hidden;\n    background: var(--skeleton-bg, #e0e0e0); \n    border-radius: var(--skeleton-radius, 4px); \n    width: var(--skeleton-width, 100%); \n    height: var(--skeleton-height, 100%); \n    margin-top: var(--skeleton-marginTop, 2%);\n  }\n  /* Skeleton animation */\n  .skeleton {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(90deg, var(--skeleton-bg, #e0e0e0) 25%, var(--skeleton-highlight, #f5f5f5) 50%, var(--skeleton-bg, #e0e0e0) 75%);\n    background-size: 200% 100%;\n    animation: shimmer 1.5s infinite;\n  }\n  /* Shimmer animation */\n  @keyframes shimmer {\n    0% {\n      background-position: -200% 0;\n    }\n    100% {\n      background-position: 200% 0;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
