import { colors } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Container, ListGroup, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getActiveAccountdata } from "../Session";
import { getMarginandFO } from "../api/zerodhaapi";
const PortFolio = () => {
  const [tab, setTab] = useState("Positions");
  const [positiondata, setPositiondata] = useState([]);
  const [holdingsdata, setHoldingsdata] = useState([]);
  const [position, setPosition] = useState(false);
  const d = getActiveAccountdata();

  const settabactive = (tab) => {
    setTab(tab);
  };
  useEffect(() => {
    if (d) {
      getPosition();
    }
  }, []);
  const getPosition = async () => {
    const positions_holdings = await getMarginandFO({
      api_key: d.api_key,
      access_key: d.access_token,
    });
    const [, , result, holdingresult] = positions_holdings;
    console.log("!!!", result);
    if (result) {
      setPosition(true);
      setPositiondata(result);
    } else {
      setPosition(false);
    }
    if (holdingresult) {
      setPosition(true);
      setHoldingsdata(holdingresult);
    } else {
      setPosition(false);
    }
  };
  return (
    <>
      <Row>
        <Col lg={6}>
          <ListGroup horizontal>
            <ListGroup.Item
              onClick={() => settabactive("Positions")}
              className={`${
                tab === "Positions" ? "clrportfolio" : "portfoliotab"
              }`}
            >
              Positions
            </ListGroup.Item>
            <ListGroup.Item
              onClick={() => settabactive("Holdings")}
              className={` ${
                tab === "Holdings" ? "clrportfolio" : "portfoliotab"
              }`}
            >
              Holdings
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col lg={6} className="d-flex justify-content-end">
          <ListGroup horizontal>
            <ListGroup.Item
              onClick={() => settabactive("All")}
              className={` ${tab === "All" ? "clrportfolio" : "portfoliotab"}`}
            >
              <i className="fa fa-check"></i>
              All
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      {positiondata === false && holdingsdata === false && (
        <Row>
          <Col
            lg
            md
            sm
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: "47vh" }}
          >
            <span>
              <i className="fa fa-folder-open-o fs-1" aria-hidden="true"></i>
            </span>
            <span>No position found</span>
            <span>
              <Link to="/dashboard" className="text-decoration-none">
                Go to dashboard
              </Link>
            </span>
          </Col>
        </Row>
      )}
      {(tab === "Positions" || tab === "All") && positiondata && (
        <>
          <h6 className="mt-5">Positions</h6>
          <Table className="holding-table mt-2">
            <thead className="">
              <tr>
                <th>Instruments</th>
                <th>Qty.</th>
                <th>LTP.</th>
                <th>P&L</th>
              </tr>
            </thead>
            <tbody>
              {positiondata.map((item, index) => (
                <tr>
                  <td>{item.instrument}</td>
                  <td>{item.quantity}</td>
                  <td>{item.last_traded_price}</td>
                  <td>{item.profit_loss}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {(tab === "Holdings" || tab === "All") && holdingsdata && (
        <>
          <h6 className="mt-5">Holdings</h6>
          <Table className="holding-table mt-2">
            <thead className="">
              <tr>
                <th>Instruments</th>
                <th>Qty.</th>
                <th>LTP.</th>
                <th>P&L</th>
              </tr>
            </thead>
            <tbody>
              {holdingsdata.map((item, index) => (
                <tr>
                  <td>{item.instrument}</td>
                  <td>{item.quantity}</td>
                  <td>{item.last_traded_price}</td>
                  <td>{item.profit_loss}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default PortFolio;
