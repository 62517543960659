import React, { useEffect, useState, useMemo } from "react";
import "./Strategy.css";
import SMA_I from "./Indicators/SMA_I";
import EMA_I from "./Indicators/EMA_I";
import Number_I from "./Indicators/Number_I";
import Close_I from "./Indicators/Close_I";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import SearchableDropdown from "./SearchDropdown";

const buttonStyle = {
  padding: "3px",
  marginRight: "10px",
  fontSize: "14px",
  backgroundColor: "white",
  color: "black",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  border: "1px solid #1579c9",
  position: "relative",
};

const addButton = {
  backgroundColor: "transparent", // No background color
  color: "#0e6efd", // Text color
  fontWeight: "500",
  fontSize: "16px",
  height: "30px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px dashed #1579c9", // Dashed border
  borderRadius: "4px",
  cursor: "pointer",
  transition: "border-color 0.3s", // Only transition for border color
  marginRight: "5px",
};
const FormulaTab = ({ ...restProps }) => {
  const {
    formData,
    handleRemoveButtonClick,
    setIsShow,
    setIsShow3,
    onFormChange,
    handleButtonClick,
    setShowDropdown,
    setInnerAdd,
    setOuterAdd,
    handleAddButtonClick,
    outerAdd,
    setShowClose,
    setShowOpen,
    setShowLowPrice,
    setShowHighPrice,
    setShowUlcer,
    setShowDEMA,
    setShowTEMA,
    setShowTMA,
    setShowVWMA,
    setShowWMA,
    setShowSMA,
    setShowEMA,
    setShowNumber,
    setShowMoneyFlowIndex,
    setShowWilliams,
    setShowMad,
    setShowObv,
    setShowAroonDown,
    setShowAroonUp,
    setShowOscilator,
    setShowVWAP,
    setShowpsar,
    setShowVortexI,
    setShowVolume,
    setShowDonchian,
    setShowStochastic,
    setShowChaikin,
    setShowMacdSignal,
    setShowCCI,
    setShowRoc,
    setShowRSI,
    setShowRSIMA,
    setShowMacd,
    setShowBB,
    setShowTR,
    setShowATR,
    setShowNATR,
    setShowSuperT,
    setShowMfiMa,
    allStocks,
    setShowIchimoku,
    setShowKST,
    setShowCandleModal,
    getIndex,
    setShowMOM,
    showInstead,
    setAddInstead,
    handleEdit,
    handleAdd,
    setPeriodMaxModal,
    setPeriodMinModal,
    setisStdDeviation,
    setShowUltimateOscillator,
    setShowNthCandle
  } = restProps;

  // console.log("restProps", formData);
  // console.log("showInstead>>", showInstead);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const onInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    setFilteredStocks(allStocks);
  }, []);
  // console.log("isEdit", isEdit);
  const parseCSV = (csvText) => {
    const lines = csvText.split("\n");
    const headers = lines[0].split(",");

    const jsonData = [];
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentLine[j];
        }
        jsonData.push(obj);
      }
    }

    return jsonData;
  };

  const indicators = formData?.indicators || {};
  const order = formData.order || [];
  // console.log("order---", order, indicators);
  const handleToggle = () => {
    console.log("toggle");
  };

  return (
    <>
      {Object.entries(formData?.indicators || {}).map(
        ([instanceName, instanceData], index) => {
          const isShowingAddButton =
            showInstead?.isOpen &&
            showInstead?.function_id == instanceData?.function_id &&
            showInstead.index == index;
          console.log("instanceName", instanceName);
          console.log("instanceData", instanceData);
          // console.log("isShowingAddButton", isShowingAddButton);
          return (
            <span
              key={instanceName}
              className={`ms-2 ${
                instanceData?.name === "&&" || instanceData?.name === "||"
                  ? "w-100 py-2"
                  : ""
              }`}
            >
              {instanceData?.name === "Max" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body1 &&
                          instanceData?.body1?.map((item, index) => {
                            let data = item
                              ? item
                              : instanceData?.body
                              ? instanceData?.body[0]
                              : instanceData;
                            return (
                              <>
                                {item?.name ===
                                "SMA (Simple Moving Average)" ? (
                                  <SMA_I
                                    item={item}
                                    formData={formData}
                                    setShowSMA={setShowSMA}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    instanceData={instanceData}
                                    instanceName={instanceName}
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                  />
                                ) : item?.name ===
                                  "EMA (Exponential Moving Average)" ? (
                                  <EMA_I
                                    item={item}
                                    formData={formData}
                                    setShowEMA={setShowEMA}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    instanceData={instanceData}
                                    instanceName={instanceName}
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                  />
                                ) : item?.name === "Close" ? (
                                  <Close_I
                                    item={item}
                                    instanceName={instanceName}
                                    formData={formData}
                                    setShowClose={setShowClose}
                                    instanceData={instanceData}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                  />
                                ) : item?.name === "Open price" ? (
                                  <div
                                    className="d-flex"
                                    style={{ border: "1px solid black" }}
                                  >
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowOpen(true);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.offset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "&&" ? (
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    and
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                ) : item?.name === "||" ? (
                                  <div>
                                    <button
                                      onClick={() => {
                                        setShowClose(true);
                                        formData.selectedOption1 =
                                          instanceData?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      or
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "High price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowHighPrice(true);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.offset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Low price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowLowPrice(true);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.lowOffset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "RSI (Relative Strength Index)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                        setShowRSI(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Ulcer" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowUlcer(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "DEMA (Double Exponential Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowDEMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.field},${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "TEMA (Triple Exponential Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData, data);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTEMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "TriMA (Triangular Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "VWMA (Volume Weighted Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVWMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "WMA (Weighted Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowWMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Number" ? (
                                  <Number_I
                                    item={item}
                                    instanceName={instanceName}
                                    formData={formData}
                                    setShowNumber={setShowNumber}
                                    instanceData={instanceData}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                  />
                                ) : item?.name === "Money Flow Index" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMoneyFlowIndex(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Williams %R" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowWilliams(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "Moving Average Deviation (MA Dev)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMad(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MACD (Moving Average Convergence/Divergence)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacd(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MACD (Moving Average Convergence/Divergence)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacd(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "OBV (On Balance Volume)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowObv(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.obvOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Aroon-Down" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowAroonDown(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Aroon-Up" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowAroonUp(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Awesome Oscillator" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowOscilator(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${data?.aOscilatorOffset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "VWAP (Volume Weighted Average Price)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVWAP(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.vwapOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Parabolic SAR" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowpsar(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Vortex Indicator" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVortexI(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Volume of a candle" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVolume(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${`${data?.volume}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Donchian Channel" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowDonchian(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Stochastic" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowStochastic(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Chaikin Money Flow" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowChaikin(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "MACD-signal" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacdSignal(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Commodity Channel Index" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCCI(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Price Rate of Change" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowRoc(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "RSI Moving Average" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowRSIMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "TR (True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${`${data?.trOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "ATR (Average True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowATR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "NATR (Normalized Average True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowNATR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Supertrend" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowSuperT(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Bollinger %B" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowBB(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMfiMa(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Ichimoku" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowIchimoku(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Know Sure Thing" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowKST(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "+" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getIndex(index, "body1");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "-" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getIndex(index, "body1");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "/" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getIndex(index, "body1");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "*" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getIndex(index, "body1");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : 
                                  item?.name === "MOM (Momentum Indicator)" ? (
                                    <div className="d-flex">
                                      <div
                                        className="hover-underline"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          // console.log("showDrop", data);
                                          formData.selectedOption1 = data?.name;
                                          formData.clickedFunction =
                                            instanceName;
                                          setShowCandleModal(true);
                                          getIndex(index, "body1");
                                        }}
                                      >
                                        {data?.candleIntervalId}
                                      </div>
                                      &ensp;
                                      <button
                                        onClick={() => {
                                          // console.log("pppp", instanceName, data);
                                          formData.selectedOption1 = data?.name;
                                          formData.clickedFunction =
                                            instanceName;
                                          setShowMOM(true);
                                          getIndex(index, "body1");
                                        }}
                                        style={{
                                          ...buttonStyle,
                                          color: "#0e6efd",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {`${
                                          data?.name
                                        } (${`${data.period},${data.offset}`})`}
                                        <i
                                          onClick={(e) => {
                                             e.stopPropagation();
                                            getIndex(index, "body1");
                                            handleRemoveButtonClick(
                                              instanceName,
                                              index,
                                              "body1"
                                            );
                                          }}
                                          className={`fa-solid fa-xmark iconContainer`}
                                          style={{
                                            marginLeft: "6px",
                                            cursor: "pointer",
                                            color: "#0e6efd",
                                          }}
                                        ></i>
                                        &ensp;
                                      </button>
                                    </div>
                                  ) :  item?.name === "Standard Deviation" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body1");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setisStdDeviation(true);
                                      getIndex(index, "body1");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                         e.stopPropagation();
                                        getIndex(index, "body1");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : null
                                }
                              </>
                            );
                          })}
                        {instanceData?.body1?.length === 0 && (
                          <button
                            onClick={() => {
                              // console.log(
                              //   "instanceData?.name",
                              //   instanceData,
                              //   instanceName
                              // );
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body1");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                            {"Add"}
                            {/* &ensp; */}
                          </button>
                        )}
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body2?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption1 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.field},${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      getIndex(index, "body2");
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      getIndex(index, "body2");
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      getIndex(index, "body2");
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      getIndex(index, "body2");
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "MOM (Momentum Indicator)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMOM(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                         e.stopPropagation();
                                        getIndex(index, "body2");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Standard Deviation" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setisStdDeviation(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                         e.stopPropagation();
                                        getIndex(index, "body2");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : null}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        {instanceData?.body2?.length === 0 && (
                          <button
                            onClick={() => {
                              // console.log("instanceData?.name", instanceName);
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body2");
                            }}
                            className="stratergyAddBtn"
                          >
                            {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                            {"Add"}
                            {/* &ensp; */}
                          </button>
                        )}
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Min" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body1 &&
                          instanceData?.body1?.map((item, index) => {
                            let data = item
                              ? item
                              : instanceData?.body
                              ? instanceData?.body[0]
                              : instanceData;
                            return (
                              <>
                                {item?.name ===
                                "SMA (Simple Moving Average)" ? (
                                  <SMA_I
                                    item={item}
                                    formData={formData}
                                    setShowSMA={setShowSMA}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    instanceData={instanceData}
                                    instanceName={instanceName}
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                  />
                                ) : item?.name ===
                                  "EMA (Exponential Moving Average)" ? (
                                  <EMA_I
                                    item={item}
                                    formData={formData}
                                    setShowEMA={setShowEMA}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    instanceData={instanceData}
                                    instanceName={instanceName}
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                  />
                                ) : item?.name === "Close" ? (
                                  <Close_I
                                    item={item}
                                    instanceName={instanceName}
                                    formData={formData}
                                    setShowClose={setShowClose}
                                    instanceData={instanceData}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                  />
                                ) : item?.name === "Open price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowOpen(true);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.offset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "&&" ? (
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    and
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                ) : item?.name === "||" ? (
                                  <div>
                                    <button
                                      onClick={() => {
                                        setShowClose(true);
                                        formData.selectedOption1 =
                                          instanceData?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      or
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "High price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowHighPrice(true);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.offset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Low price" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        setShowLowPrice(true);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                      }}
                                    >
                                      {`${data?.name} (${data?.lowOffset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Add any logic for removing the item from the state
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "RSI (Relative Strength Index)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        getIndex(index, "body1");
                                        setShowRSI(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Ulcer" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowUlcer(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "DEMA (Double Exponential Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowDEMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.field},${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "TEMA (Triple Exponential Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData, data);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTEMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "TriMA (Triangular Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "VWMA (Volume Weighted Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVWMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "WMA (Weighted Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowWMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Number" ? (
                                  <Number_I
                                    item={item}
                                    instanceName={instanceName}
                                    formData={formData}
                                    setShowNumber={setShowNumber}
                                    instanceData={instanceData}
                                    handleRemoveButtonClick={
                                      handleRemoveButtonClick
                                    }
                                    index={index}
                                    setShowCandleModal={setShowCandleModal}
                                    getIndex={getIndex}
                                    instance="body1"
                                  />
                                ) : item?.name === "Money Flow Index" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMoneyFlowIndex(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Williams %R" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowWilliams(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "Moving Average Deviation (MA Dev)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMad(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MACD (Moving Average Convergence/Divergence)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacd(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MACD (Moving Average Convergence/Divergence)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacd(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "OBV (On Balance Volume)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowObv(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.obvOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Aroon-Down" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowAroonDown(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Aroon-Up" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowAroonUp(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Awesome Oscillator" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowOscilator(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${data?.aOscilatorOffset})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "VWAP (Volume Weighted Average Price)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVWAP(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.vwapOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Parabolic SAR" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowpsar(true);
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Vortex Indicator" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVortexI(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Volume of a candle" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowVolume(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${`${data?.volume}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Donchian Channel" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowDonchian(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Stochastic" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowStochastic(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Chaikin Money Flow" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowChaikin(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "MACD-signal" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMacdSignal(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Commodity Channel Index" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCCI(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Price Rate of Change" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowRoc(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "RSI Moving Average" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowRSIMA(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "TR (True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowTR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${data?.name} (${`${data?.trOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "ATR (Average True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowATR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "NATR (Normalized Average True Range)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowNATR(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Supertrend" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowSuperT(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Bollinger %B" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowBB(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name ===
                                  "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMfiMa(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Ichimoku" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowIchimoku(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Know Sure Thing" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", instanceData);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowKST(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#7AB1DD",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "+" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getIndex(index, "body2");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "-" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getIndex(index, "body2");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "/" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getIndex(index, "body2");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name === "*" ? (
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        // console.log(
                                        //   "pppp",
                                        //   instanceName,
                                        //   instanceData
                                        // );
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                      }}
                                      className="operatorButton"
                                    >
                                      {data?.name}
                                    </button>
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        getIndex(index, "body2");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body1"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        marginLeft: "-10px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </div>
                                ) : item?.name ===
                                  "MOM (Momentum Indicator)" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", data);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log("pppp", instanceName, data);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowMOM(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data.period},${data.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                           e.stopPropagation();
                                          getIndex(index, "body1");
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          marginLeft: "6px",
                                          cursor: "pointer",
                                          color: "#0e6efd",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : item?.name === "Standard Deviation" ? (
                                  <div className="d-flex">
                                    <div
                                      className="hover-underline"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // console.log("showDrop", data);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setShowCandleModal(true);
                                        getIndex(index, "body1");
                                      }}
                                    >
                                      {data?.candleIntervalId}
                                    </div>
                                    &ensp;
                                    <button
                                      onClick={() => {
                                        // console.log("pppp", instanceName, data);
                                        formData.selectedOption1 = data?.name;
                                        formData.clickedFunction = instanceName;
                                        setisStdDeviation(true);
                                        getIndex(index, "body1");
                                      }}
                                      style={{
                                        ...buttonStyle,
                                        color: "#0e6efd",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {`${
                                        data?.name
                                      } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                      <i
                                        onClick={(e) => {
                                           e.stopPropagation();
                                          getIndex(index, "body1");
                                          handleRemoveButtonClick(
                                            instanceName,
                                            index,
                                            "body1"
                                          );
                                        }}
                                        className={`fa-solid fa-xmark iconContainer`}
                                        style={{
                                          color: "#0e6efd",
                                        }}
                                      ></i>
                                      &ensp;
                                    </button>
                                  </div>
                                ) : null}
                              </>
                            );
                          })}
                        {instanceData?.body1?.length === 0 && (
                          <button
                            onClick={() => {
                              // console.log(
                              //   "instanceData?.name",
                              //   instanceData,
                              //   instanceName
                              // );
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body1");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                            {"Add"}
                            {/* &ensp; */}
                          </button>
                        )}
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body2?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    <h3>4</h3>
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption1 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index, "body2");
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.field},${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body2"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index,
                                        "body2"
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "MOM (Momentum Indicator)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMOM(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                         e.stopPropagation();
                                        getIndex(index, "body2");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Standard Deviation" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index, "body2");
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setisStdDeviation(true);
                                      getIndex(index, "body2");
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                         e.stopPropagation();
                                        getIndex(index, "body2");
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index,
                                          "body2"
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : null}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        {instanceData?.body2?.length === 0 && (
                          <button
                            onClick={() => {
                              // console.log("instanceData?.name", instanceName);
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body2");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                            {"Add"}
                            {/* &ensp; */}
                          </button>
                        )}
                        )
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Floor" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption1 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.field},${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "MOM (Momentum Indicator)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMOM(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Standard Deviation" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setisStdDeviation(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                       e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : null}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer `}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Ceil" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    <h3>6</h3>
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption1 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.field},${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "MOM (Momentum Indicator)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMOM(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Standard Deviation" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setisStdDeviation(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                         e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : null}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Abs" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    <h3>7</h3>
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption1 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.field},${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "MOM (Momentum Indicator)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMOM(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Standard Deviation" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setisStdDeviation(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                         e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : null}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Symbol" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <SearchableDropdown
                          instanceName={instanceName}
                          formData={formData}
                          onFormChange={onFormChange}
                          stocks={allStocks}
                        />
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    <h3>8</h3>
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption1 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.field},${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "MOM (Momentum Indicator)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMOM(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Standard Deviation" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setisStdDeviation(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                         e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : null}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Period min" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <button
                          onClick={() => {
                            setPeriodMinModal(true);
                            formData.selectedOption1 = instanceName;
                            formData.clickedFunction = instanceName;
                          }}
                          style={{
                            ...addButton,
                            background: "white",
                          }}
                        >
                          {formData.indicators[instanceName].period}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    <h3>9</h3>
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption1 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      getIndex(index);
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.field},${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(
                                        "showDrop",
                                        instanceData,
                                        data
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                      getIndex(index);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "MOM (Momentum Indicator)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMOM(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Standard Deviation" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setisStdDeviation(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                         e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : null}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            // console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Period max" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <button
                          onClick={() => {
                            setPeriodMaxModal(true);
                            formData.selectedOption1 = instanceName;
                            formData.clickedFunction = instanceName;
                          }}
                          style={{
                            ...addButton,
                            background: "white",
                          }}
                        >
                          {formData.indicators[instanceName].period}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              {item?.name === "SMA (Simple Moving Average)" ? (
                                <SMA_I
                                  item={item}
                                  formData={formData}
                                  setShowSMA={setShowSMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name ===
                                "EMA (Exponential Moving Average)" ? (
                                <EMA_I
                                  item={item}
                                  formData={formData}
                                  setShowEMA={setShowEMA}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  instanceData={instanceData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Close" ? (
                                <Close_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowClose={setShowClose}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Open price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowOpen(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "&&" ? (
                                <button
                                  onClick={() => {
                                    setShowClose(true);
                                    formData.selectedOption1 =
                                      instanceData?.name;
                                    formData.clickedFunction = instanceName;
                                  }}
                                  style={{
                                    ...buttonStyle,
                                    color: "#0e6efd",
                                  }}
                                >
                                  and
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // Add any logic for removing the item from the state
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                  &ensp;
                                </button>
                              ) : item?.name === "||" ? (
                                <div>
                                  <button
                                    onClick={() => {
                                      setShowClose(true);
                                      formData.selectedOption1 =
                                        instanceData?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    or
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "High price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowHighPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.offset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Add any logic for removing the item from the state
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Low price" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      setShowLowPrice(true);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                    }}
                                  >
                                    {`${data?.name} (${data?.lowOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "RSI (Relative Strength Index)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsiPeriod},${data.rsiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ulcer" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowUlcer(true);
                                    }}
                                    style={{
                                      color: "#7AB1DD",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.ulcerField},${data.ulcerPeriod},${data.ulcerOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "DEMA (Double Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDEMA(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.field},${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TEMA (Triple Exponential Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTEMA(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.temaField},${data.temaPeriod},${data.temaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "TriMA (Triangular Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTMA(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.tmaField},${data.tmaPeriod},${data.tmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWMA (Volume Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWMA(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vwmaField},${data.vwmaPeriod},${data.vwmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "WMA (Weighted Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWMA(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.wmaField},${data.wmaPeriod},${data.wmaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Number" ? (
                                <Number_I
                                  item={item}
                                  instanceName={instanceName}
                                  formData={formData}
                                  setShowNumber={setShowNumber}
                                  instanceData={instanceData}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  index={index}
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  instance="body1"
                                />
                              ) : item?.name === "Money Flow Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMoneyFlowIndex(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.mfiPeriod},${data.mfiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Williams %R" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowWilliams(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    }  (${`${data?.wrPeriod}`},${`${data?.wrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "Moving Average Deviation (MA Dev)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMad(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.madField},${data.madPeriod},${data.madType},${data.madOffset},${data.madPop}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MACD (Moving Average Convergence/Divergence)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacd(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macd_fastMaPeriod},${data.macd_slowMaPeriod},${data.macd_signalPeriod},${data.macdOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "OBV (On Balance Volume)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowObv(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.obvOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Down" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonDown(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonDownPeriod},${data.aroonDownOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Aroon-Up" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowAroonUp(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.aroonUpPeriod},${data.aroonUpOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Awesome Oscillator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowOscilator(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${data?.aOscilatorOffset})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "VWAP (Volume Weighted Average Price)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVWAP(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.vwapOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Parabolic SAR" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowpsar(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.psarMinimumAF},${data.psarMaximumAF},${data.psarOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Vortex Indicator" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVortexI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.vortexIPeriod},${data.vortexISignal},${data.vortexIOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Volume of a candle" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowVolume(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.volume}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Donchian Channel" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowDonchian(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.donchianChannel},${data.donchianHighPeriod},${data.donchianLowPeriod},${data.donchianOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Stochastic" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowStochastic(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.stochasticPeriod},${data.stochasticType},${data.stochasticSmooth},${data.stochasticOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Chaikin Money Flow" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowChaikin(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.chaikinPeriod},${data.chaikinOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "MACD-signal" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMacdSignal(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.macdsFastMaPeriod},${data.macdsSlowMaPeriod},${data.macdsSignalPeriod},${data.macdsOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Commodity Channel Index" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCCI(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.cciPeriod},${data.cciOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Price Rate of Change" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "pppp",
                                        instanceName,
                                        instanceData
                                      );
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRoc(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rocField},${data.rocPeriod},${data.rocOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "RSI Moving Average" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowRSIMA(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.rsimaPeriod},${data.rsimaType},${data.rsimaMaPeriod},${data.rsimaOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "TR (True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowTR(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.name} (${`${data?.trOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "ATR (Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowATR(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.atrPeriod},${data?.atrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "NATR (Normalized Average True Range)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowNATR(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.natrPeriod},${data?.natrOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Supertrend" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowSuperT(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.superTPeriod},${data?.superTMultiply},${data?.superTOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Bollinger %B" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowBB(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.bolingerBField},${data?.bolingerBPeriod},${data?.bolingerBDev},${data?.bolingerBType},${data?.bolingerBOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name ===
                                "MFI Moving Average (Money Flow Index Moving Average)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMfiMa(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.mfiMAPeriod},${data?.mfiMAType},${data?.mfiMAMAPeriod},${data?.mfiMAOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Ichimoku" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowIchimoku(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.ichiConversion},${data?.ichiBase},${data?.leadingSpanB},${data?.laggingSpan},${data?.ichiLine},${data?.cloudShift},${data?.ichiOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Know Sure Thing" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowKST(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.kstField},${data?.kstLTRoc},${data?.kstLTSma},${data?.kstRoc},${data?.kstLSma},${data?.kstHRoc},${data?.kstHSma},${data?.kstHeRoc},${data?.kstHeSma},${data?.kstSPeriod},${data?.kstType},${data?.kstOffset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "+" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "-" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "/" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "*" ? (
                                <div className="d-flex">
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, instanceData);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                    }}
                                    className="operatorButton"
                                  >
                                    {data?.name}
                                  </button>
                                  <i
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveButtonClick(
                                        instanceName,
                                        index
                                      );
                                    }}
                                    className={`fa-solid fa-xmark iconContainer`}
                                    style={{
                                      marginLeft: "-10px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              ) : item?.name === "MOM (Momentum Indicator)" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowMOM(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data.period},${data.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#7AB1DD",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : item?.name === "Standard Deviation" ? (
                                <div className="d-flex">
                                  <div
                                    className="hover-underline"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log("showDrop", data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setShowCandleModal(true);
                                      getIndex(index);
                                    }}
                                  >
                                    {data?.candleIntervalId}
                                  </div>
                                  &ensp;
                                  <button
                                    onClick={() => {
                                      // console.log("pppp", instanceName, data);
                                      formData.selectedOption1 = data?.name;
                                      formData.clickedFunction = instanceName;
                                      setisStdDeviation(true);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      color: "#0e6efd",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${
                                      data?.name
                                    } (${`${data?.stdPeriod},${data?.stdField},${data?.stdDev},${data?.maType},${data?.offset}`})`}
                                    <i
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveButtonClick(
                                          instanceName,
                                          index
                                        );
                                      }}
                                      className={`fa-solid fa-xmark iconContainer`}
                                      style={{
                                        color: "#0e6efd",
                                      }}
                                    ></i>
                                    &ensp;
                                  </button>
                                </div>
                              ) : null}
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            // console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Close" && (
                <Close_I
                  instanceName={instanceName}
                  formData={formData}
                  setShowClose={setShowClose}
                  instanceData={instanceData}
                  handleRemoveButtonClick={handleRemoveButtonClick}
                  index={index}
                  setShowCandleModal={setShowCandleModal}
                  getIndex={getIndex}
                  isShowingAddButton={isShowingAddButton}
                  handleAdd={handleAdd}
                  handleEdit={handleEdit}
                />
              )}

              {instanceData?.name === "Open price" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          setShowOpen(true);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                        }}
                      >
                        {`${instanceData?.name} (${instanceData?.offset})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                            // setAddInstead({function_id : 71 , isToggle : true})
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "High price" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          setShowHighPrice(true);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                        }}
                      >
                        {`${instanceData?.name} (${instanceData?.offset})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Low price" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          setShowLowPrice(true);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                        }}
                      >
                        {`${instanceData?.name} (${instanceData?.lowOffset})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "RSI (Relative Strength Index)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowRSI(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.rsiPeriod},${instanceData.rsiOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name === "Ulcer" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowUlcer(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.ulcerField},${instanceData.ulcerPeriod},${instanceData.ulcerOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "DEMA (Double Exponential Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowDEMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.field},${instanceData.period},${instanceData.offset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "TEMA (Triple Exponential Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowTEMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.temaField},${instanceData.temaPeriod},${instanceData.temaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "TriMA (Triangular Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowTMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.tmaField},${instanceData.tmaPeriod},${instanceData.tmaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "VWMA (Volume Weighted Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowVWMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.vwmaField},${instanceData.vwmaPeriod},${instanceData.vwmaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "SMA (Simple Moving Average)" && (
                <SMA_I
                  instanceName={instanceName}
                  formData={formData}
                  setShowSMA={setShowSMA}
                  instanceData={instanceData}
                  handleRemoveButtonClick={handleRemoveButtonClick}
                  index={index}
                  setShowCandleModal={setShowCandleModal}
                  getIndex={getIndex}
                  isShowingAddButton={isShowingAddButton}
                  handleEdit={handleEdit}
                  handleAdd={handleAdd}
                />
              )}

              {instanceData?.name === "EMA (Exponential Moving Average)" && (
                <EMA_I
                  instanceName={instanceName}
                  formData={formData}
                  setShowEMA={setShowEMA}
                  instanceData={instanceData}
                  handleRemoveButtonClick={handleRemoveButtonClick}
                  index={index}
                  setShowCandleModal={setShowCandleModal}
                  getIndex={getIndex}
                  isShowingAddButton={isShowingAddButton}
                  handleEdit={handleEdit}
                  handleAdd={handleAdd}
                />
              )}

              {instanceData?.name === "WMA (Weighted Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowWMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.wmaField},${instanceData.wmaPeriod},${instanceData.wmaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.add === true && (
                <button
                  onClick={() => {
                    // console.log(
                    //   "instanceData?.name",
                    //   instanceData,
                    //   instanceName
                    // );
                    setShowDropdown(true);
                    setInnerAdd(true);
                    setOuterAdd(false);
                    handleAddButtonClick(instanceName);
                  }}
                  style={addButton}
                >
                  {"Add"}
                </button>
              )}
              {instanceData?.name === "Number" && (
                <Number_I
                  instanceName={instanceName}
                  formData={formData}
                  setShowNumber={setShowNumber}
                  instanceData={instanceData}
                  handleRemoveButtonClick={handleRemoveButtonClick}
                  index={index}
                  setShowCandleModal={setShowCandleModal}
                  getIndex={getIndex}
                  isShowingAddButton={isShowingAddButton}
                  handleEdit={handleEdit}
                  handleAdd={handleAdd}
                />
              )}

              {instanceData?.name === "Money Flow Index" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMoneyFlowIndex(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.mfiPeriod},${instanceData.mfiOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Williams %R" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowWilliams(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        }  (${`${instanceData?.wrPeriod}`},${`${instanceData?.wrOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Moving Average Deviation (MA Dev)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMad(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.madField},${instanceData.madPeriod},${instanceData.madType},${instanceData.madOffset},${instanceData.madPop}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "MACD (Moving Average Convergence/Divergence)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMacd(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.macd_fastMaPeriod},${instanceData.macd_slowMaPeriod},${instanceData.macd_signalPeriod},${instanceData.macdOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "OBV (On Balance Volume)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowObv(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.obvOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Aroon-Down" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowAroonDown(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.aroonDownPeriod},${instanceData.aroonDownOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Aroon-Up" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowAroonUp(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.aroonUpPeriod},${instanceData.aroonUpOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Awesome Oscillator" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowOscilator(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${instanceData?.name} (${instanceData?.aOscilatorOffset})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "VWAP (Volume Weighted Average Price)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowVWAP(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.vwapOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Parabolic SAR" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowpsar(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.psarMinimumAF},${instanceData.psarMaximumAF},${instanceData.psarOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Vortex Indicator" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowVortexI(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.vortexIPeriod},${instanceData.vortexISignal},${instanceData.vortexIOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Volume of a candle" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowVolume(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${instanceData?.name} (${`${instanceData?.volume}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Donchian Channel" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowDonchian(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.donchianChannel},${instanceData.donchianHighPeriod},${instanceData.donchianLowPeriod},${instanceData.donchianOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Stochastic" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowStochastic(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.stochasticPeriod},${instanceData.stochasticType},${instanceData.stochasticSmooth},${instanceData.stochasticOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Chaikin Money Flow" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowChaikin(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.chaikinPeriod},${instanceData.chaikinOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "MACD-signal" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMacdSignal(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.macdsFastMaPeriod},${instanceData.macdsSlowMaPeriod},${instanceData.macdsSignalPeriod},${instanceData.macdsOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Commodity Channel Index" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCCI(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.cciPeriod},${instanceData.cciOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Price Rate of Change" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowRoc(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.rocField},${instanceData.rocPeriod},${instanceData.rocOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "&&" &&
                (isShowingAddButton ? (
                  <button
                    className="stratergyAddBtn"
                    onClick={() => {
                      handleAdd(instanceData, index);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setShowClose(true);
                      formData.selectedOption1 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                    }}
                  >
                    and
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add any logic for removing the item from the state
                        // handleRemoveButtonClick(instanceName, index);
                        handleEdit(instanceData, index);
                      }}
                      className={`fa-solid fa-xmark iconContainer`}
                      style={{
                        color: "#7AB1DD",
                      }}
                    ></i>
                    &ensp;
                  </button>
                ))}

              {instanceData?.name === "||" &&
                (isShowingAddButton ? (
                  <button
                    className="stratergyAddBtn"
                    onClick={() => {
                      handleAdd(instanceData, index);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setShowClose(true);
                      formData.selectedOption1 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                    }}
                  >
                    or
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add any logic for removing the item from the state
                        // handleRemoveButtonClick(instanceName, index);
                        handleEdit(instanceData, index);
                      }}
                      className={`fa-solid fa-xmark iconContainer`}
                      style={{
                        color: "#7AB1DD",
                      }}
                    ></i>
                    &ensp;
                  </button>
                ))}

              {instanceName.startsWith("comparator") &&
                (isShowingAddButton ? (
                  <button
                    className="stratergyAddBtn"
                    onClick={() => {
                      handleAdd(instanceData, index);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    key={instanceName}
                    onClick={() => {
                      // Handle button click logic here
                      // setIsShow3(true);
                      formData.selectedOption1 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                    }}
                  >
                    {`${instanceData?.name}`}
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add any logic for removing the item from the state
                        // handleRemoveButtonClick(instanceName, index);
                        handleEdit(instanceData, index);
                      }}
                      className={`fa-solid fa-xmark iconContainer`}
                      style={{
                        color: "#7AB1DD",
                      }}
                    ></i>
                    &ensp;
                  </button>
                ))}

              {instanceName.startsWith("operators") &&
                (isShowingAddButton ? (
                  <button
                    className="stratergyAddBtn"
                    onClick={() => {
                      handleAdd(instanceData, index);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    key={instanceName}
                    onClick={() => {
                      // Handle button click logic here
                      // setIsShow3(true);
                      formData.selectedOption1 = instanceData?.name;
                      formData.clickedFunction = instanceName;
                    }}
                    style={{
                      ...buttonStyle,
                      color: "#0e6efd",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    {`${instanceData?.name}`}
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add any logic for removing the item from the state
                        // handleRemoveButtonClick(instanceName, index);
                        handleEdit(instanceData, index);
                      }}
                      className={`fa-solid fa-xmark iconContainer`}
                      style={{
                        color: "#7AB1DD",
                      }}
                    ></i>
                    &ensp;
                  </button>
                ))}

              {instanceData?.name === "RSI Moving Average" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowRSIMA(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.rsimaPeriod},${instanceData.rsimaType},${instanceData.rsimaMaPeriod},${instanceData.rsimaOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "TR (True Range)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowTR(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.trOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name === "ATR (Average True Range)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowATR(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.atrPeriod},${instanceData?.atrOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name ===
                "NATR (Normalized Average True Range)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowNATR(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.natrPeriod},${instanceData?.natrOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name === "Supertrend" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowSuperT(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.superTPeriod},${instanceData?.superTMultiply},${instanceData?.superTOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Bollinger %B" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowBB(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.bolingerBField},${instanceData?.bolingerBPeriod},${instanceData?.bolingerBDev},${instanceData?.bolingerBType},${instanceData?.bolingerBOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name ===
                "MFI Moving Average (Money Flow Index Moving Average)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMfiMa(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.mfiMAPeriod},${instanceData?.mfiMAType},${instanceData?.mfiMAMAPeriod},${instanceData?.mfiMAOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Ichimoku" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowIchimoku(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.ichiConversion},${instanceData?.ichiBase},${instanceData?.leadingSpanB},${instanceData?.laggingSpan},${instanceData?.ichiLine},${instanceData?.cloudShift},${instanceData?.ichiOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Know Sure Thing" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowKST(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData?.kstField},${instanceData?.kstLTRoc},${instanceData?.kstLTSma},${instanceData?.kstRoc},${instanceData?.kstLSma},${instanceData?.kstHRoc},${instanceData?.kstHSma},${instanceData?.kstHeRoc},${instanceData?.kstHeSma},${instanceData?.kstSPeriod},${instanceData?.kstType},${instanceData?.kstOffset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "MOM (Momentum Indicator)" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowMOM(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.period},${instanceData.offset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Standard Deviation" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setisStdDeviation(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.stdPeriod},${instanceData.stdField},${instanceData.stdDev},${instanceData.maType},${instanceData.offset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name === "Ultimate Oscillator" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowUltimateOscillator(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.length1},${instanceData.length2},${instanceData.length3},${instanceData.offset}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name === "Nth Candle" && (
                <div className="d-flex">
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div
                        className="hover-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log("showDrop", instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowCandleModal(true);
                          getIndex(index);
                        }}
                      >
                        {instanceData?.candleIntervalId}
                      </div>
                      &ensp;
                      <button
                        onClick={() => {
                          // console.log("pppp", instanceName, instanceData);
                          formData.selectedOption1 = instanceData?.name;
                          formData.clickedFunction = instanceName;
                          setShowNthCandle(true);
                        }}
                        style={{
                          ...buttonStyle,
                          color: "#0e6efd",
                          fontWeight: "500",
                        }}
                      >
                        {`${
                          instanceData?.name
                        } (${`${instanceData.field},${instanceData.range},${instanceData.num}`})`}
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleRemoveButtonClick(instanceName, index);
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                          }}
                        ></i>
                        &ensp;
                      </button>
                    </>
                  )}
                </div>
              )}
            </span>
          );
        }
      )}
    </>
  );
};

export default FormulaTab;
