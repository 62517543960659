import { Navigate } from "react-router-dom";

export const saveData = (user) => {
  console.log("session", user);
  if (user && Object.keys(user).length !== 0) {
    sessionStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("logged_user", JSON.stringify(true));
    if (user?.activePlan) {
      if (user?.activePlan?.length !== 0) {
        sessionStorage.setItem(
          "subscribe_plan",
          JSON.stringify(user.activePlan[0])
        );
      }
    }
  } else {
    // Redirect to the login page
    // navigateToLogin();
    // Navigate('/login');
  }
};

export const savePlanSession = (planDetails) => {
  sessionStorage.setItem("subscribe_plan", JSON.stringify(planDetails));
};

export const getUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
};

export const getUserPlan = () => {
  const plan = sessionStorage.getItem("subscribe_plan");
  if (plan) {
    const planDetails = JSON.parse(plan);
    console.log("planDetails", planDetails?.name);
    const planName = planDetails.name;
    return planName;
  }
};

export const saveActiveAccount = (token, data) => {
  if (data) sessionStorage.setItem("accountData", JSON.stringify(data));
  if (token) sessionStorage.setItem("ActiveAccount", JSON.stringify(token));
};
export const getActiveAccount = () => {
  const token = sessionStorage.getItem("ActiveAccount");
  if (token) return JSON.parse(token);
  // return 'acacacdacd';
};

export const getActiveAccountdata = () => {
  const accountDetails = sessionStorage.getItem("accountData");
  if (accountDetails) return JSON.parse(accountDetails);
};
export const clearSessionData = () => {
  //Removing username from sesstion storage
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("logged_user");
  sessionStorage.removeItem("subscribe_plan");
  sessionStorage.removeItem("5paisaToken");
  sessionStorage.clear();
  window.location.reload();
  // setIsCleared(true);
  // setTimeout(() => {
  // //   setIsCleared(false);
  // }, 2000);
};

export const save5paisaToken = (token) => {
  // token='acadcdccd';
  sessionStorage.setItem("5paisaToken", JSON.stringify(token));
};

export const get5paisaToken = () => {
  const token = sessionStorage.getItem("5paisaToken");
  return JSON.parse(token);
};
export const saveZerodhaToken = (token) => {
  const statickToken = "abcd";
  sessionStorage.setItem("ZerodhaToken", JSON.stringify(token));
};

export const getZerodhaToken = () => {
  const token = sessionStorage.getItem("ZerodhaToken");
  return JSON.parse(token);
};
