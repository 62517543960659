import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const SupportResistanceChart = ({ technicalData }) => {
  const [options, setOptions] = useState({
    chart: {
      id: "support-resistance-chart",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        barHeight: "50%",
        barWidth: "50%",
        columnWidth: "15%",
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 8,
      colors: ["transparent"],
    },
    xaxis: {
      type: "category",
      categories: [
        -100, -90, -80, -70, -60, -50, -40, -30, -20, -10, 0, 10, 20, 30, 40,
        50, 60, 70, 80, 90, 100,
      ],
      min: -100,
      max: 100,
      stepSize: 20,
      decimalsInFloat: Number,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    annotations: {
      points: [],
    },
  });

  // Prepare series data in the required format for ApexCharts
  const series = [
    {
      name: "Support/Resistance Levels",
      data: [
        { x: -100, y: 1200, fillColor: "#4E4EFD" },
        { x: -90, y: 1200, fillColor: "#4E4EFD" },
        { x: -80, y: 1200, fillColor: "#8081FE" },
        { x: -70, y: 1200, fillColor: "#8081FE" },
        { x: -60, y: 1200, fillColor: "#888DFF" },
        { x: -50, y: 1200, fillColor: "#888DFF" },
        { x: -40, y: 1200, fillColor: "#8B8FFE" },
        { x: -30, y: 1200, fillColor: "#8B8FFE" },
        { x: -20, y: 1200, fillColor: "#AEB4FF" },
        { x: -10, y: 1200, fillColor: "#A9AFFF" },
        { x: 0, y: 1200, fillColor: "#CBD3FF" },
        { x: 10, y: 1200, fillColor: "#A9AFFF" },
        { x: 20, y: 1200, fillColor: "#AEB4FF" },
        { x: 30, y: 1200, fillColor: "#8B8FFE" },
        { x: 40, y: 1200, fillColor: "#8B8FFE" },
        { x: 50, y: 1200, fillColor: "#888DFF" },
        { x: 60, y: 1200, fillColor: "#888DFF" },
        { x: 70, y: 1200, fillColor: "#8081FE" },
        { x: 80, y: 1200, fillColor: "#8081FE" },
        { x: 90, y: 1200, fillColor: "#4E4EFD" },
        { x: 100, y: 1200, fillColor: "#4E4EFD" },
      ],
    },
  ];

  useEffect(() => {
    const val = technicalData?.LivePrice;
    const s1Value = parseFloat(technicalData?.supportResistanceLevels?.s1);
    const s2Value = parseFloat(technicalData?.supportResistanceLevels?.s2);
    const s3Value = parseFloat(technicalData?.supportResistanceLevels?.s3);
    const r1Value = parseFloat(technicalData?.supportResistanceLevels?.r1);
    const r2Value = parseFloat(technicalData?.supportResistanceLevels?.r2);
    const r3Value = parseFloat(technicalData?.supportResistanceLevels?.r3);
  
    // Calculate absolute differences from val to each support/resistance level
    const diffToS1 = Math.abs(val - s1Value);
    const diffToS2 = Math.abs(val - s2Value);
    const diffToS3 = Math.abs(val - s3Value);
    const diffToR1 = Math.abs(val - r1Value);
    const diffToR2 = Math.abs(val - r2Value);
    const diffToR3 = Math.abs(val - r3Value);
  
    // console.log("Difference to S1:", diffToS1);
    // console.log("Difference to S2:", diffToS2);
    // console.log("Difference to S3:", diffToS3);
    // console.log("Difference to R1:", diffToR1);
    // console.log("Difference to R2:", diffToR2);
    // console.log("Difference to R3:", diffToR3);
  
    // Find the minimum difference
    let minDifference = Math.min(diffToS1, diffToS2, diffToS3, diffToR1, diffToR2, diffToR3);
  
    let maxPercentage;
  
    // Determine the type of level with the minimum difference
    if (minDifference === diffToS1) {
      maxPercentage = { value: diffToS1, type: "s1" };
    } else if (minDifference === diffToS2) {
      maxPercentage = { value: diffToS2, type: "s2" };
    } else if (minDifference === diffToS3) {
      maxPercentage = { value: diffToS3, type: "s3" };
    } else if (minDifference === diffToR1) {
      maxPercentage = { value: diffToR1, type: "r1" };
    } else if (minDifference === diffToR2) {
      maxPercentage = { value: diffToR2, type: "r2" };
    } else {
      maxPercentage = { value: diffToR2, type: "r3" };
    }
  
    console.log("Closest level:", maxPercentage);
    let xCoordinate;

    switch (maxPercentage.type) {
      case "s1":
        xCoordinate = -25; 
        break;
      case "s2":
        xCoordinate = -60; 
        break;
      case "s3":
        xCoordinate = -90; 
        break;
      case "r1":
        xCoordinate = 25; 
        break;
      
      case "r2":
        xCoordinate = 50;
        break;
     
      case "r3":
        xCoordinate = 90;
        break;
      default:
        xCoordinate = 10; 
    }
    // console.log(maxPercentage);
    // const xCoordinate =
    //   maxPercentage.type === "s3"
    //     ? -(100 - Math.abs(maxPercentage.value))
    //     : 100 - Math.abs(maxPercentage.value);
    // console.log("xCoordinate",xCoordinate );
    setOptions({
      ...options,
      annotations: {
        points: [
          {
            x: xCoordinate,
            y: 600,
            marker: {
              size: 8,
              fillColor: "#fff",
              strokeColor: "#2698FF",
              radius: 4,
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560",
              },
            },
          },
        ],
      },
    });
  }, [technicalData]);
  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height="100%"
      width="80%"
    />
  );
};

export default SupportResistanceChart;
