import React, { useEffect, useState, useRef, useMemo } from "react";
import Slider from "./Slider";
import Cards from "./Cards";
import { Button, Card } from "react-bootstrap";
import { getAllAccounts, getTopMovers } from "../api/dashboardApi";
import RightSideBar from "./RightSideBar";
import UserHeader from "./UserHeader";
import DashboardActiveAccount from "../components/DashboardActiveAccount";
import OrderCard from "./OrderCard";
import DeployedCard from "./DeployedCard";
import { getUser, getActiveAccountdata } from "../Session";
import DiscoverStrategiesCard from "./DiscoverStrategiesCard";
import DiscoverStrategiesChart from "./DiscoverStrategiesChart";
import DiscoverScanner from "./DiscoverScanner";
import TopMovers from "./TopMovers";
import { useMediaQuery } from "react-responsive";
import DematAccounts from "./DematAccounts";
import { useNavigate } from "react-router-dom";
import {
  getAllDiscoveredStrategyApi,
  getDiscoverStrategyByIdApi,
  getStrategyCategories,
  getStrategyCategoriesApi,
} from "../api/strategyapi";
import {
  getAllDiscoveredScannerApi,
  getDiscoverScannersByIdApi,
  getScannerCategoriesApi,
} from "../api/scannerapi";
import SwipeableTemporaryDrawer from "../mcomponents/SwipeableTemporaryDrawer";


const Dashboard = () => {
  const user = getUser();
  const navigate = useNavigate();
  const intervalRef = useRef(null);
  const [accdetails, setAccdetails] = useState({});
  const [strategies, setStrategies] = useState();
  const [scanners, setScanners] = useState();
  const [strategyCategories, setStrategyCategories] = useState();
  const [scannerCategories, setScannerCategories] = useState();
  const [selectedScanId, setSelectedScanId] = useState(0);
  const [selectedStrategyId, setSelectedStrategyId] = useState(0);
  const [hoverScan, setHoverScan] = useState(0);
  const [hoverStrategy, setHoverStrategy] = useState(0);
  const [topmovers, setTopmovers] = useState([]);
  const [toplosers, setToplosers] = useState([]);
  const [toplosersslice, setToplosersslice] = useState([]);
  const [topgainersslice, setTopgainersslice] = useState([]);
  const [accountdata, setAccountData] = useState(null);
  const isTabScreen = useMediaQuery({
    query: "(max-width: 800px)",
  });
  useEffect(() => {
    const data = getActiveAccountdata();
    setAccountData(data);
  }, []);
  useEffect(() => {
    // const user = getUser();
    gettingallacc();
    getDiscoveredStrategies();
    getDiscoveredScanners();
    getStrategyCategories();
    getScannerCategories();
  }, []);

  useEffect(() => {
    const gettopmoversdashboard = async () => {
      const now = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      const c = new Date(now);
      const currentHr = c.getHours();
      const currentmin = c.getMinutes();
  
      if (accountdata) {
        const result = await getTopMovers({
          apiKey: accountdata?.api_key,
          token: accountdata?.access_token,
        });
  
        // Ensure the API is called at least once even outside business hours
        if (currentHr < 9 || (currentHr >= 15 && currentmin >= 30)) {
          if (result) {
            const Gainers = result?.topGainers.slice(0, 5);
            setTopmovers(result?.topGainers);
            const losers = result?.topLosers.slice(0, 5);
            setToplosers(result?.topLosers);
            setTopgainersslice(Gainers);
            setToplosersslice(losers);
          }
          clearInterval(intervalRef.current);
          return;
        }
  
        if (result) {
          const Gainers = result?.topGainers.slice(0, 5);
          setTopmovers(result?.topGainers);
          const losers = result?.topLosers.slice(0, 5);
          setToplosers(result?.topLosers);
          setTopgainersslice(Gainers);
          setToplosersslice(losers);
        }
      } else {
        console.log("Error in topmovers API");
      }
    };
  
    if (accountdata) {
      // Call the API once initially
      gettopmoversdashboard();
  
      // Set interval to fetch data every 5 minutes within business hours
      intervalRef.current = setInterval(() => {
        const now = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
        const c = new Date(now);
        const currentHr = c.getHours();
        const currentmin = c.getMinutes();
  
        // Only fetch data during business hours
        if (currentHr >= 9 && (currentHr < 15 || (currentHr === 15 && currentmin < 30))) {
          gettopmoversdashboard();
        } else {
          clearInterval(intervalRef.current);
        }
      }, 300000); // 5 minutes interval
  
      return () => clearInterval(intervalRef.current);
    }
  }, [accountdata]);
  
  const gettingallacc = async () => {
    const accdetails = await getAllAccounts({ user_id: user.id });
    setAccdetails(accdetails?.data);
  };

  const getDiscoveredStrategies = async () => {
    const result = await getAllDiscoveredStrategyApi();
    setStrategies(result);
  };

  const getDiscoveredScanners = async () => {
    const result = await getAllDiscoveredScannerApi();
    console.log("getAllDiscoveredScannerApi", result);
    setScanners(result);
  };

  const getStrategyCategories = async () => {
    const result = await getStrategyCategoriesApi();
    if (result) setStrategyCategories(result);
  };

  const getScannerCategories = async () => {
    const result = await getScannerCategoriesApi();
    if (result) setScannerCategories(result);
  };

  const getScannersById = async (id) => {
    const result = await getDiscoverScannersByIdApi({
      category_id: id,
    });
    if (result) setScanners(result);
  };

  const getStrategiesById = async (id) => {
    const result = await getDiscoverStrategyByIdApi({
      category_id: id,
    });
    if (result) setStrategies(result);
  };

  function scrollRight() {
    const list = document.querySelector(".mobile-sub-header");
    list.scrollBy({ left: 200, behavior: "smooth" }); // Adjust the scroll value as needed
  }
  const [drawerOpen, setDrawerOpen] = useState(false);
 const [nm,setNm]=useState();
  const toggleDrawer = (open,content,list) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    console.log("AAA----",list,content);
    if(content==='topGainers')
      {
        setNm('topGainers');
       
      }
    if(content==='topLosers')
      {
        setNm('toplosers');
    
      }
    setDrawerOpen(open);
  };
  const mycontent = nm === 'topGainers' ? topmovers : toplosers;
 
  return (
    <section className="mobile-view">
      <div className="container web-cotainer">
        <div className="row">
          <div className="col-lg-9 col-sm-12">
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 col-12 p-0">
                {/* <Slider /> */}
                <UserHeader user={user} />
              </div>
            </div>

            <div className="demat-accounts">
              {/* <DashboardActiveAccount /> */}

              <DashboardActiveAccount demataccounts={accdetails} />
            </div>
            {/* <div className="demat-accounts">

              <DematAccounts demataccounts={accdetails}/>
            </div> */}
            {/* Account details */}
            {/* <DashboardActiveAccount /> */}
            <div className="row card-data">
              <div id="dashboar-card-data">
                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                  <Cards button="true" />
                </div>
                <div className="vertical-line"></div>
                <div className="col-lg-3 col-md-4 p-2 col-12">
                  <OrderCard />
                </div>
                <div className="vertical-line-sec"></div>
              </div>
            </div>
            <div className="row strategy-card">
              <div id="dashboard-strategy-card-tab">
                <div className="col-lg-5 col-12 dashboard-stretagy-pickup">
                  <Card>
                    <Card.Body>
                      <h5 className="fw-bold py-3 fs-6">
                        Pick where you left off
                      </h5>
                      <div className="text-muted text-center py-4">
                        <img
                          src="icon-images/strategyIcon.png"
                          alt="Strategies Images"
                          className="img-fluid"
                        />
                      </div>
                      <Button
                        variant="primary"
                        className="btn-color w-lg-50 w-100"
                        onClick={() => {
                          if(accountdata)
                          navigate("/strategies?active=create_strategies");
                         else
                          alert("To create strategies you need to activate your account first");
                        }}
                      >
                        
                        Create New Strategy{" "}
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
                <div className="vertical-line-third"></div>
                <div className="col-lg-7 col-12 dashboard-deploey-card">
                  <DeployedCard />
                </div>
              </div>
            </div>

            <div className="row py-3 my-3" style={{backgroundColor:"rgb(203 221 242)",borderRadius:"8px"}}>
              <div className="col-lg-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-8">
                    <h6 className="fw-bold">Discover Strategies</h6>
                  </div>
                  <div
                    className="col-lg-6 col-sm-6 col-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => 
                      {
                        if(accountdata)
                            navigate("/discover");
                        else
                          alert("You need to activate your account first");
                        
                      }
                    }
                  >
                    <h6 className="second-text text-primary text-end fw-bold">
                      View all
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-12 col-sm-12 col-12 px-1 m-0">
                    <ul
                  className="mobile-sub-header p-1"
                  style={{ overflowX: "auto" }}
                >
                  <li onClick={() => {setSelectedStrategyId(0);
                  getDiscoveredStrategies();
                   }}  onMouseEnter={() => setHoverStrategy(0)}
                   onMouseLeave={() => setHoverStrategy()}
            style={{
              color: selectedStrategyId === 0 && selectedStrategyId !== 0 ? "#28419F" : "black",
              border: selectedStrategyId === 0 && "1px solid white",
              background: selectedStrategyId === 0 ? "white" : "",
              padding: selectedStrategyId === 0 && "6px",
              borderRadius: selectedStrategyId === 0 && "4px",
            }}
         >
                 <i className="fa fa-bank"></i> All</li>
                 {strategyCategories?.map((item) => (
                     <li
              onMouseEnter={() => setHoverStrategy(item.id)}
              onMouseLeave={() => setHoverStrategy()}
              onClick={() => {
                setSelectedStrategyId(item.id);
                getStrategiesById(item.id);
              }}
              key={item.id}
            >
      <span
        style={{
          color:
            hoverStrategy === item.id && selectedStrategyId != item.id
              ? "#28419F"
              : "black",
          border: selectedStrategyId === item.id && "1px solid white",
          background: selectedStrategyId === item.id ? "white" : "",
          padding: selectedStrategyId === item.id && "6px",
          borderRadius: selectedStrategyId === item.id && "4px",
        }}
      >
        <i
          className="fa fa-bank"
          style={{
            color:
              hoverStrategy === item.id && selectedStrategyId !== item.id
                ? "#28419F"
                : "black",
          }}
        ></i>{" "}
        {item?.name}
      </span>
                     </li>
                 ))}
                 </ul>
                </div>

              <div className="col-lg-12 px-1 m-0">
              <div className="card-d-s p-0 m-0 w-100">
                <div
                  className="d-flex"
                  style={
                    strategies?.length === 0
                      ? {
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }
                      : {}
                  }
                >
                  {strategies &&
                    strategies.length > 0 &&
                    strategies.map((item) => (
                      <DiscoverStrategiesChart item={item} />
                    ))}
                    {strategies?.length === 0 && (
                    <div style={{}}>No Strategies Available</div>
                   )}
                </div>
              </div>
              </div>
            </div>

            <div className="row py-3 my-3" style={{backgroundColor:"rgb(203 221 242)",borderRadius:"8px"}}>
              <div className="col-lg-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-8">
                    <h6 className="fw-bold">Discover Scanners</h6>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-4 ">
                    <h6
                      style={{ cursor: "pointer" }}
                      className="second-text text-primary text-end fw-bold"
                      onClick={() => 
                        {
                          if(accountdata)
                            navigate("/scans/popular");
                        else
                          alert("You need to activate your account first");
                        }
                      }
                    >
                      View all
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-sm-12 col-12 px-1 m-0">
                <ul
                  className="mobile-sub-header p-1"
                  style={{ overflowX: "auto" }}
                >
                  <li
                    style={{
                      color:
                        selectedScanId === 0 && selectedScanId != 0
                          ? "#28419F"
                          : "black",
                      border: selectedScanId === 0 && "1px solid white",
                      background: selectedScanId === 0 ? "white" : "",
                      padding: selectedScanId === 0 && "6px",
                      borderRadius: selectedScanId === 0 && "4px",
                    }}
                    onClick={() => {
                      setSelectedScanId(0);
                      getDiscoveredScanners();
                    }}
                  >
                    <i className="fa fa-bank"></i> All
                  </li>
                  {scannerCategories?.map((item) => (
                    <li
                      onMouseEnter={() => setHoverScan(item.id)}
                      onMouseLeave={() => setHoverScan()}
                      onClick={() => {
                        setSelectedScanId(item?.id);
                        getScannersById(item.id);
                      }}
                    >
                      <span
                        style={{
                          color:
                            hoverScan === item.id && selectedScanId != item.id
                              ? "#28419F"
                              : "black",
                          border:
                            selectedScanId === item.id && "1px solid white",
                          background: selectedScanId === item.id ? "white" : "",
                          padding: selectedScanId === item.id && "6px",
                          borderRadius: selectedScanId === item.id && "4px",
                        }}
                      >
                        <i
                          style={{
                            color:
                              hoverScan === item.id && selectedScanId != item.id
                                ? "#28419F"
                                : "black",
                          }}
                          className="fa fa-bank"
                        ></i>{" "}
                        {item?.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-12 px-1 m-0">  
              <div className="card-d-s p-0 m-0 w-100">
                <div
                  className="d-flex"
                  style={
                    scanners?.length === 0
                      ? {
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }
                      : {}
                  }
                >
                  {scanners &&
                    scanners.length > 0 &&
                    scanners?.map((item) => <DiscoverScanner item={item} />)}
                  {scanners?.length === 0 && (
                    <div style={{}}>No Scanners Available</div>
                  )}
                </div>
              </div>
              </div>
            </div>

            <div className="row py-3">
              <div className="col-lg-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-8">
                    <h6 className="fw-bold">Top Gainers</h6>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-4 second-text text-primary text-end fw-bold" 
                  onClick={toggleDrawer(true,'topGainers',topmovers)}  style={{ cursor: "pointer" }}
                     >View all</div>
                </div>
              </div>

              <div className="d-flex">
                {topgainersslice && topgainersslice?.map((item, index) => {
                  return (
                    <>
                      <div className=" ">
                        <TopMovers item={item} type="gainers"/>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {/* TOP LOSERS */}
            <div className="row py-3 ">
              <div className="col-lg-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-8">
                    <h6 className="fw-bold">Top Losers</h6>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-4 second-text text-primary text-end fw-bold"  onClick={toggleDrawer(true,'topLosers',toplosers)}  style={{ cursor: "pointer" }}
                     >View all</div>
                </div>
              </div>

              <div className="d-flex">
                {toplosersslice && toplosersslice?.map((item, index) => {
                  return (
                    <>
                      <div className=" ">
                        <TopMovers item={item} type="losers"/>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12">
            <RightSideBar />
          </div>
          <SwipeableTemporaryDrawer
        anchor="right"
        open={drawerOpen}
        toggleDrawer={toggleDrawer}
        mycontent={mycontent}
        nm={nm}
      />
        </div>
      </div>
     
    </section>
  );
};

export default Dashboard;
