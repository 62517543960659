import React from "react";
import DiscoverScanner from "./DiscoverScanner";
import TopMovers from "./TopMovers";
import MobileUser from "./MobileUser";
import DiscoverStrategiesChart from "./DiscoverStrategiesChart";

const DiscoverDetails = () => {
  return (
    <section className="mobile-view">
      <div className="container web-cotainer">
        <MobileUser />
        <div className="row py-5">
          <div className="col-lg-12 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-8">
                <h6 className="fw-bold">Discover Strategies</h6>
              </div>
              <div className="col-lg-6 col-sm-6 col-4 ">
                <h6 className="second-text text-primary text-end fw-bold">
                  View all
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 col-12">
            <ul className="mobile-sub-header p-2">
              <li>
                <i className="fa fa-bank"></i> All
              </li>
              <li>
                <i className="fa fa-bank"></i> Trend Following{" "}
              </li>
              <li>
                <i className="fa fa-bank"></i> Mean
              </li>
              <li>
                <i className="fa fa-chevron-right"></i>
              </li>
            </ul>
          </div>
          <div className="card-d-s">
            <div className="col-9 col-sm-6">
              <DiscoverStrategiesChart />
            </div>
            <div className="col-9 col-sm-6">
              <DiscoverStrategiesChart />
            </div>
            <div className="col-9 col-sm-6">
              <DiscoverStrategiesChart />
            </div>
          </div>
        </div>
        <div className="row py-5 ">
          <div className="col-lg-12 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-8">
                <h6 className="fw-bold">Discover Scanner</h6>
              </div>
              <div className="col-lg-6 col-sm-6 col-4 ">
                <h6 className="second-text text-primary text-end fw-bold">
                  View all
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 col-12">
            <ul className="mobile-sub-header p-2">
              <li>
                <i className="fa fa-bank"></i> All
              </li>
              <li>
                <i className="fa fa-bank"></i> Barish{" "}
              </li>
              <li>
                <i className="fa fa-bank"></i> Chart patterns
              </li>
              <li>
                <i className="fa fa-chevron-right"></i>
              </li>
            </ul>
          </div>
          <div className="card-d-s">
            <div className="col-9 col-sm-6">
              <DiscoverScanner />
            </div>
            <div className="col-9 col-sm-6">
              <DiscoverScanner />
            </div>
            <div className="col-9 col-sm-6">
              <DiscoverScanner />
            </div>
          </div>
        </div>
        {/*----- Top Movers----- */}
        <div className="row py-5 ">
          <div className="col-lg-12 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-8">
                <h6 className="fw-bold">Top Movers</h6>
              </div>
              <div className="col-lg-6 col-sm-6 col-4 ">
                <h6 className="second-text text-primary text-end fw-bold">
                  Day <i className="fa fa-chevron-down"></i>
                </h6>
              </div>
            </div>
          </div>
          <div className="card-t-m">
            {/* <div className="col-6 col-sm-5"> */}
            <TopMovers />
            {/* </div> */}
            {/* <div className="col-6 col-sm-5"> */}
            <TopMovers />
            {/* </div> */}
            {/* <div className="col-8 col-sm-5">
                <TopMovers/>
            </div> */}
            <div className="top-icon ms-auto bd-highlight">
              <i className="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiscoverDetails;
