import React from "react";
import Header from "../Pages/Header";
import Footer from "../Pages/Footer";
import { useLocation, Navigate } from "react-router-dom";
import MobileHeader from "../mcomponents/MobileHeader";

const Layout = ({ children }) => {
  const userData = sessionStorage.getItem("logged_user");
  const { pathname } = useLocation();

  // Define an array of paths that should render the desktop layout
  const desktopPaths = [
    "/dashboard",
    "/orderdashboard",
    "/strategies",
    "/strategies/viewall",
    "/discover",
    "/account",
    "/daily-usage",
    "/trading",
    "/techincal",
    "/createstrategies",
    "/scanners",
    "/new",
    "/subscribe",
    "/deployed",
    "/partial_complete",
    "/symbol",
    "/trades",
    "/scans/popular",
    "/notification",
    "/scanner/singel",
    "/backtests",
    "/canvas",
    "/scanner/result"
  ];

  // Check if the current path should render the desktop layout
  const isDesktopPath = desktopPaths.some((path) => pathname.startsWith(path));

  // Render the appropriate layout based on the condition
  return !isDesktopPath ? (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  ) : userData ? (
    <>
      <MobileHeader />
      <div>{children}</div>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default Layout;
