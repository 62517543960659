import React, { useState } from "react";
import { Button, Col, Form, Card } from "react-bootstrap";
import AlertShow from "./AlertShow";
import { Demophonenumber } from "../api/dashboardApi";
import { getUser } from "../Session";
const RequestNumberCard = () => {
  const [phone, setPhone] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setPhone(value);
    }
  };
  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const submit = async () => {
    const userdetails = getUser();
    if (phone) {
      let result;
      if (phone.length === 10) {
        result = await Demophonenumber(userdetails.id, phone);
        console.log("OKOK", result);
      } else {
        var msg = "Enter valid mobile number";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
      }

      if (result === "record sucessfully inserted") {
        var msg = "Submitted successfully!! We will contact you soon";
        const error_color = "success";
        setPhone("");
        handleAlertMsg(error_color, msg);
      } else if (result === "Mobile number already exist in table") {
        var msg = "Mobile number already exist";
        const error_color = "error";
        setPhone("");
        handleAlertMsg(error_color, msg);
      } else {
        var msg = "something went wrong!!!";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
      }
    } else {
      var msg = "Please Enter mobile number";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
    }
  };
  return (
    <Card className="request-num-card">
      <Card.Body className="request-num-card-body">
        <div className="row p-2">
          <h6>
            Request for a free demo
            <br />
            My Phone Number
          </h6>
          <Form.Control
            type="number"
            placeholder="Phone Number"
            value={phone}
            onChange={handlePhoneChange}
            maxLength={10}
            minLength={10}
          />
          <Button className="btn-submit mt-4" onClick={() => submit()}>
            Submit
          </Button>
        </div>
        <AlertShow
          alertShowHide={showAlert}
          alertClose={handleCloseAlert}
          setalertMsg={alertMsg}
          severity={severity}
        />
      </Card.Body>
    </Card>
  );
};

export default RequestNumberCard;
