import React from "react";

const TopMoverRightSideBar = () => {
    return (
        <div className="col-lg-3 col-sm-12 right-side-bar">
          <div className="row deploye-data">
            <h5 className="title">Top Movers</h5>
            <div className="sub-text">
              <div className="row">
                <div className="col-2 text-primary">
                  <i className="fa fa-wifi"></i>
                </div>
                <div className="col-8">
                  <h6 className="fw-bold">Live Trade</h6>
                  <p className="text-muted">Not Deployed</p>
                </div>
                <div className="col-2 text-muted">
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
              {/*  */}
              <div className="col-12">
                <div className="sub-text">
                  <div className="row">
                    <div className="col-2 text-success">
                      <i className="fa fa-newspaper-o"></i>
                    </div>
                    <div className="col-8">
                      <h6 className="fw-bold">Paper Trade</h6>
                      <p className="text-muted ">Not Deployed</p>
                    </div>
                    <div className="col-2 text-muted">
                      <i className="fa fa-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="sub-text Scanner-div">
                  <div className="row">
                    <div className="col-2 text-success">
                      <i className="fa fa-newspaper-o"></i>
                    </div>
                    <div className="col-8">
                      <h6 className="fw-bold">Scanner</h6>
                      <p className="text-muted ">Not Deployed</p>
                    </div>
                    <div className="col-2 text-muted">
                      <i className="fa fa-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="title">Orderbook</h5>
          <div className=" portfolio-card">
            <div className=" order-data">
              <div className="row">
                <div className="col-3">
                  <i className="fa fa-cube"></i>
                </div>
                <div className="col-9">
                  <h1>0</h1>
                  <span className="text-muted">Open Orders</span>
                </div>
              </div>
    
              {/* </div> */}
              {/* <div className="col-12"> */}
              <div className="row">
                <div className="col-3">
                  <i className="fa fa-asterisk"></i>
                </div>
                <div className="col-9">
                  <h1>0</h1>
                  <span className="text-muted">
                    <i className="fa fa-check"></i> Exicuted Orders
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 pt-2">
            <b>Portfolio</b>
            <span>View all</span>
          </div>
        
          {/* <div className="row order-box"> */}
          {/* <h5 className="title">Order Book</h5> */}
          {/* <div className="col-lg-12 col-6 text-primary pt-5">
                  <div className="d-flex order-box-1">
                    <div className="o-b-sub-text align-self-center display-6">
                        0
                    </div>&nbsp;&nbsp;&nbsp;
                    <div>
                        <span className="fw-bold">Open Orders</span><br/>
                        <span>In Progress</span>
                    </div>
                    </div>
                </div>
                <div className="col-lg-12 col-6 text-primary pt-3 order-second">
                  <div className="d-flex order-box-2">
                    <div className="o-b-sub-text align-self-center ">
                       <i className="fa fa-check"></i>
                    </div>&nbsp;&nbsp;&nbsp;
                    <div>
                        <span className="fw-bold">Open Orders</span><br/>
                        <span>In Progress</span>
                    </div>
                    </div>
                </div> */}
          {/* </div> */}
          {/* daily use */}
          <div className="row pt-5">
            {/* <h5 className="title">Daily Usage</h5> */}
            <div>
            </div>
          </div>
        </div>
      );
}

export default TopMoverRightSideBar;