import React from "react";

const Disclaimer = () => {
  return (
    <section id="about-page">
      <div className="container container-lg">
        <div className="row">
          <div className="text-center">
            <h1 className="text-primary fw-bold">
              Cancellation & Refund Policy
            </h1>
          </div>
          <div className="fw-medium fs-5 py-4">
            <p>Last updated on Oct 5th, 2023.</p>
            <br />
            <p>
              Cancellation of subscription if done “NO” Refunds are entertained
              or will be processed.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Disclaimer;
