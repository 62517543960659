import moment from "moment";
import React from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";

const OrderDeployedModal = ({ show, setModalOrderShow, paperTradingData }) => {
  const handleClose = () => {
    setModalOrderShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} animation={false} centered>
        {/* <Modal.Header>
            BackTest Result Stock Transactions
        </Modal.Header> */}
        <Modal.Body
          id="create-strategy-modal"
          className="d-flex align-items-center justify-content-center table-view"
        >
          <Table>
            <thead>
              <tr>
                <th>Order Type</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              {paperTradingData &&
                paperTradingData?.map((item, i) => (
                  <tr key={i}>
                    <td>{item?.order_type == 1 ? "Buy" : "Sell"}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.price}</td>
                    <td>
                      {moment(item?.created_at).format("DD-MM-YYYY hh:mm:A")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleConfirm}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderDeployedModal;
