import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <section id="privacy-policy">
      <div className="container ">
        <div className="text-center">
          <h1 className="text-primary fw-bold">Privacy Policy</h1>
        </div>
        <br />
        <div className="content fw-medium fs-5">
          <p>Last updated on Oct 5th, 2023.</p><br/>
          <p>
            This privacy policy sets out how COALSTONE TECH PRIVATE LIMITED
            <Link to="https://www.dalroti.tech/" >(WWW.DALROTI.TECH)</Link> uses and protects any information that you give
            COALSTONE TECH PRIVATE LIMITED <Link to="https://www.dalroti.tech/" >(WWW.DALROTI.TECH)</Link> when you use this
            website.
          </p>
          <br />
          <p>
            COALSTONE TECH PRIVATE LIMITED <Link to="https://www.dalroti.tech/" >(WWW.DALROTI.TECH)</Link> is committed to
            ensuring that your privacy is protected. Should we ask you to
            provide certain information by which you can be identified when
            using this website, and then you can be assured that it will only be
            used in accordance with this privacy statement.
          </p>
          <br />
          <p>
            COALSTONE TECH PRIVATE LIMITED <Link to="https://www.dalroti.tech/" >(WWW.DALROTI.TECH)</Link> may change this
            policy from time to time by updating this page. You should check
            this page from time to time to ensure that you are happy with any
            changes.
          </p>
          <br />
          <p>We may collect the following information:</p>
          <br />
          <ul>
            <li>Name and job title</li>
            <li>Contact information including email address.</li>
            <li>
              Demographic information such as postcode, preferences and
              interests
            </li>
            <li>
              Other information relevant to customer surveys and/or offers.
            </li>
          </ul><br/>
          <p>What we do with the information we gather</p>
          <br />
          <p>
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
          </p>
          <br />
          <ul>
            <li>Internal record keeping.</li>
            <li>
              We may use the information to improve our products and services.
            </li>
            <li>
              We may periodically send promotional emails about new products,
              special offers or other information which we think you may find
              interesting using the email address which you have provided.{" "}
            </li>
            <li>
              From time to time, we may also use your information to contact you
              for market research purposes. We may contact you by email, phone,
              fax or mail. We may use the information to customise the website
              according to your interests.{" "}
            </li>
          </ul>
          <br />
          <p>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorised access or disclosure we have put in
            suitable measures.
          </p>
          <br />
          <p>How we use cookies</p>
          <br />
          <p>
            A cookie is a small file which asks permission to be placed on your
            computer's hard drive. Once you agree, the file is added, and the
            cookie helps analyses web traffic or lets you know when you visit a
            particular site. Cookies allow web applications to respond to you as
            an individual. The web application can tailor its operations to your
            needs, likes and dislikes by gathering and remembering information
            about your preferences.
          </p>
          <br />
          <p>
            We use traffic log cookies to identify which pages are being used.
            This helps us analyses data about webpage traffic and improve our
            website in order to tailor it to customer needs. We only use this
            information for statistical analysis purposes and then the data is
            removed from the system.
          </p>
          <br />
          <p>
            Overall, cookies help us provide you with a better website, by
            enabling us to monitor which pages you find useful and which you do
            not. A cookie in no way gives us access to your computer or any
            information about you, other than the data you choose to share with
            us.
          </p>
          <br />
          <p>
            You can choose to accept or decline cookies. Most web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. This may prevent
            you from taking full advantage of the website.
          </p>
          <br />
          <p>Controlling your personal information</p>
          <br />
          <p>
            You may choose to restrict the collection or use of your personal
            information in the following ways:
          </p>
          <br />
          <ul>
            <li>
              Whenever you are asked to fill in a form on the website, look for
              the box that you can click to indicate that you do not want the
              information to be used by anybody for direct marketing purposes.
            </li>
            <li>
              If you have previously agreed to us using your personal
              information for direct marketing purposes, you may change your
              mind at any time by writing to or emailing us at
              support@dalroti.tech
            </li>
          </ul>
          <p>
            We will not sell, distribute or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so. We may use your personal information to send you
            promotional information about third parties which we think you may
            find interesting if you tell us that you wish this to happen.
          </p>
          <br />
          <p>
            If you believe that any information, we are holding on you is
            incorrect or incomplete, please write to or email us as soon as
            possible, at the above address. We will promptly correct any
            information found to be incorrect.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
