import React from "react";
import { Col, Row } from "react-bootstrap";

const PartialCompleteSideBar = () => {
    return(
        <>
            <Row>
                <span className="fw-bold">Strategies</span>
            </Row>
            <Row className="align-items-center pt-3">
                <Col lg={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={9}>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                        <span className="main-text">My Strategies</span>
                        <span className="sub-text">Created by you</span>
                    </div>
                    <div >
                        <i className="fa fa-chevron-right"></i>
                    </div>
                    </div>
                </Col>

            </Row>
            <Row className="align-items-center pt-3">
                <Col lg={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={9}>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                        <span className="main-text">Discover</span>
                        <span className="sub-text">Strategies</span>
                    </div>
                    <div >
                        <i className="fa fa-chevron-right"></i>
                    </div>
                    </div>
                </Col>

            </Row>
            <Row className="align-items-center pt-3">
                <Col lg={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={9}>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                        <span className="main-text">Deployed Strategies</span>
                        <span className="sub-text">Live and Paper</span>
                    </div>
                    <div >
                        <i className="fa fa-chevron-right"></i>
                    </div>
                    </div>
                </Col>

            </Row>
            <Row className="pt-3">
                <span className="fw-bold">Scanner</span>
            </Row>
            <Row className="align-items-center pt-3">
                <Col lg={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={9}>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                        <span className="main-text">My Scanner</span>
                        <span className="sub-text">Created by you</span>
                    </div>
                    <div >
                        <i className="fa fa-chevron-right"></i>
                    </div>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center pt-3">
                <Col lg={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={9}>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                        <span className="main-text">Discover</span>
                        <span className="sub-text">Scanners</span>
                    </div>
                    <div >
                        <i className="fa fa-chevron-right"></i>
                    </div>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center pt-3">
                <Col lg={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={9}>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                        <span className="main-text">Live scans</span>
                        <span className="sub-text">Live</span>
                    </div>
                    <div >
                        <i className="fa fa-chevron-right"></i>
                    </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default PartialCompleteSideBar;