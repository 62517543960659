import React, { useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";

const Paisa5Margin = (props) => {
    const marginDetails = {
        NetAvailableMargin : -3695,
    }


  const memoizedFormGroup = useMemo(() => (
    <Form.Group as={Row} className="mb-3" controlId="formPlaintextMargin">
      <Form.Label column sm="2">
        Margin :
      </Form.Label>
      <Col sm="1" className="pt-2">
        <span className="fa fa-rupee"></span>
      </Col>
      <Col sm="9">
        <Form.Control plaintext readOnly defaultValue="-23456" />
      </Col>
    </Form.Group>
  ), [marginDetails.NetAvailableMargin]);

  return (
    <Form className="m-5">
      {memoizedFormGroup}
    </Form>
  );
};

export default Paisa5Margin;
