import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GetBlogs } from '../api/dashboardApi';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import parse from 'html-react-parser';
import { IconButton } from '@mui/material';
function BlogDetails() {
  const navigate = useNavigate();
  const b={
    paddingTop:"150px",
    paddingBottom:"50px"
}
    const {id}=useParams();
    const [blog,setBlogs]=useState([]);
   
    useEffect(()=>{
        gettingBlogs(); 
    },[id]);

    const gettingBlogs=async()=>{
        const result=await GetBlogs();
        console.log("first",result,id);
        const selected=result?.find(blog=>blog?.id===parseInt(id))
        console.log("}}}",selected);
        setBlogs(selected);
    }
const handleBack=()=>{
  navigate(-1);
}
    
  return (
           
          <div className="container" style={b}>
            <div className='container'>
              <div className='d-flex'></div>
            <IconButton onClick={handleBack} color="primary" aria-label="back">
                 <ArrowBackIcon />
             </IconButton>
          <h2 className="py-3 card-title">{blog?.title}</h2>
          <div className="row justify-content-center my-3">
            <div className="row px-0">
              <div className="card px-0 border-0">
                <img  
                  src={blog?.blog_img ? `http://192.168.1.30:8008/api/v1${blog.blog_img}` : '/defaultblogimg.jpg'}
                  className="card-img-top" alt='nt found'
                  // alt={blog.title}
                  style={{ height: '400px', objectFit: 'cover' }}
                />
                <div className="card-body">
                  {/* <h2 className="card-title">{blog?.title}</h2> */}
                  {blog?.description 
                   ?
                   <p className="card-text" style={{textAlign:"justify"}}>{parse(blog?.description)}</p> : <p>No Description Available...</p>
                  }
                 
                  {/* <button className="btn btn-primary" onClick={() => navigate('/blogs')}>Back to Blogs</button> */}
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
  

  )
}

export default BlogDetails