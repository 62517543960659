import React from "react";
import { Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";

const BuySymbolModal = ({ buyModal, setBuyModal }) => {
  const handleClose = () => {
    setBuyModal(false);
  };
  return (
    <>
      <Modal
        show={buyModal}
        onHide={handleClose}
        animation={false}
        centered
        id="buy-symbol-modal"
        dialogClassName="modal-responsive"
      >
        <Modal.Header>
          <div className="d-flex flex-column first-div ">
            <span>BUY INFY x 0 Qty</span>
            <span>At market on NSE</span>
          </div>
          <div className="second-div">
            <span>
              LTP &nbsp;<i className="fa fa-rupee"></i> 1662.50{" "}
            </span>
            <span className="amount-span">(+34567)</span>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Form className="d-flex">
            <div className="w-25 buy-content-first">
              <ListGroup horizontal className="p-0">
                <ListGroup.Item className="active">MIS</ListGroup.Item>
                <ListGroup.Item>CNC</ListGroup.Item>
              </ListGroup>
              <div className="d-flex p-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Qty.</Form.Label>
                  <Form.Control
                    value={1}
                    type="text"
                    className="w-75"
                    placeholder=""
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    value={0}
                    type="text"
                    placeholder=""
                    className="w-75"
                    disabled={true}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="w-75 buy-content-second">
              <ListGroup horizontal className="p-0">
                <ListGroup.Item className="active">MARKET</ListGroup.Item>
                <ListGroup.Item>LIMIT</ListGroup.Item>
                <ListGroup.Item>SL</ListGroup.Item>
                <ListGroup.Item>SL-M</ListGroup.Item>
              </ListGroup>
              <div className="d-flex p-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Trigger price</Form.Label>
                  <Form.Control
                    value={0}
                    type="text"
                    placeholder=""
                    className="w-50"
                    disabled={true}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Disclosed qty.</Form.Label>
                  <Form.Control
                    value={0}
                    type="text"
                    className="w-50"
                    placeholder=""
                    disabled={true}
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Form.Check
                inline
                name="regular"
                type="radio"
                id={`inline-radio-2`}
                checked
              />
              <Button variant="default" className="btn btn-regular">
                REGULAR
              </Button>
            </Col>
          </Row>
          <span>
            Order charges <i className="fa fa-rupee"></i>&nbsp; 23456
          </span>
          <Button
            className="border-0  pull-right"
            variant="default"
            size="sm"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-color pull-right "
            variant="primary"
            //   onClick={() => handleCreateList()}
            size="sm"
          >
            Buy
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuySymbolModal;
