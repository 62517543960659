import React from "react";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Signup from "./Pages/Signup";
import Contact from "./Pages/Contact";
import Otp from "./components/Otp";
import ThankYou from "./Pages/ThankYou";
import Layout from "./components/Layout";
import Payment from "./Pages/Payment";
import MobileHeader from "./mcomponents/MobileHeader";
import Dashboard from "./mcomponents/Dashbord";
import About from "./Pages/About";
import TermAndCondition from "./Pages/TermAndCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Disclaimer from "./Pages/Disclaimer";
import DiscoverDetails from "./mcomponents/DiscoverDetails";
import Profile from "./mcomponents/Profile";
import SubscribePlan from "./Pages/SubscribePlan";
import TradingAccount from "./components/TradingAccount";
import Technical from "./mcomponents/Technical";
import Strategy from "./mcomponents/Strategy";
import Scanners from "./mcomponents/Scanners";
import ImportContact from "./mcomponents/ImportContact";
import StrategyCreateComonent from "./components/strategy/StrategyCreateComponent";
import DeployedScreen from "./mcomponents/DeployedScreen";
import StrategyAll from "./components/strategy/StrategyAll";
import SingleStrategy from "./components/strategy/SingleStrategy";
import ScannerCreateComponent from "./components/scanner/ScannerCreateComponent";
import PartialComplete from "./mcomponents/PartialComplete";
import Trads from "./mcomponents/Trads";
import Discover from "./mcomponents/Discover";
import DiscoverStrategiesBox from "./components/DiscoverStrategiesBox";
import Symbol from "./components/technical/Symbol";
import PopularScanner from "./components/scanner/PopularScanner";
import Account from "./mcomponents/Account";
import NotificationM from "./mcomponents/NotificationM";
import SingleScanner from "./components/scanner/SingleScanner";
import SingleDiscover from "./components/strategy/SingleDiscover";
import BackTestResultStrategy from "./components/strategy/BacktestResultStrategy";
import CanvasBackTestResult from "./components/strategy/CanvasBackTestResult";
import ScannerResultPage from "./components/scanner/ScannerResultPage";
import ScannersAll from "./components/strategy/ScannersAll";
import SingleDiscoverScanner from "./components/scanner/SIngleDiscoverScanner";
import Contactus from "./Pages/Contactus";
import Blogs from "./Pages/Blogs";
import Pricing from "./Pages/Pricing";
import Shipping from "./Pages/Shipping";
import Help from "./Pages/Help";
import BlogDetails from "./mcomponents/BlogDetails";
function AppRoutes() {
  return (
    <div>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/otp" element={<Otp />}></Route>
            <Route path="/thankyou" element={<ThankYou />}></Route>
            <Route path="/payment" element={<Payment />}></Route>
            <Route path="/mheader" element={<MobileHeader />}>
              {" "}
            </Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            {/* <Route path="/orderdashboard" element={<DashboardOrder/>}></Route> */}
            <Route path="/strategies" element={<Strategy />}></Route>
            <Route path="/scanners" element={<Scanners />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route
              path="/terms-condition"
              element={<TermAndCondition />}
            ></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/disclaimer" element={<Disclaimer />}></Route>
            <Route path="/contactus" element={<Contactus />}></Route>
            <Route path="/Pricing" element={<Pricing />}></Route>
            <Route path="/blogs" element={<Blogs />}></Route>
            <Route path="/help" element={<Help />}></Route>
            <Route path="/shipping" element={<Shipping />}></Route>
            {/* <Route path="/Discover" element={<DiscoverDetails />}></Route> */}
            <Route path="/account" element={<Account />}></Route>
            <Route path="/subscribe" element={<SubscribePlan />}></Route>
            <Route path="/trading" element={<TradingAccount />}></Route>
            <Route path="/techincal" element={<Technical />} />
            <Route path="/importcontact" element={<ImportContact />} />
            <Route path="/strategies/viewall" element={<StrategyAll />} />
            <Route path="/scanners/viewall" element={<ScannersAll />} />
            <Route
              path="/createstrategies"
              element={<StrategyCreateComonent />}
            />
            <Route path="/deployed" element={<DeployedScreen />} />
            <Route path="/strategies/:id" element={<SingleStrategy />} />
            <Route path="/scanners/page" element={<ScannerCreateComponent />} />
            <Route path="/partial_complete" element={<PartialComplete />} />
            <Route path="/trades" element={<Trads />} />
            <Route path="/discover" element={<Discover />} />
            <Route
              path="/discover/explore"
              element={<DiscoverStrategiesBox />}
            />
            <Route path="/symbol" element={<Symbol />} />
            <Route path="/scans/popular" element={<PopularScanner />} />
            <Route path="/notification" element={<NotificationM />} />
            <Route path="/scanner/result/:scanid" element={<SingleScanner />} />
            <Route
              path="/discover_scanner/result/:scanid"
              element={<SingleDiscoverScanner />}
            />
            <Route path="/backtests" element={<SingleDiscover />} />
            <Route
              path="/backtestsresult/:id"
              element={<BackTestResultStrategy />}
            />
            {/* /blogdetails/${blog.id} */}
            <Route path="/blogdetails/:id" element={<BlogDetails/>}/>
            <Route path="/canvas" element={<CanvasBackTestResult />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default AppRoutes;
