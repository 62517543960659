import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button, Table } from "react-bootstrap";
import { getUser,getActiveAccountdata } from "../Session";
import {getMarginandFO} from "../api/zerodhaapi";
import {LiveScannerApi} from "../api/scannerapi";
import {getAllRunningDeployedStrategiesAPI} from "../api/strategyapi";
const DeployedBox = ({ mode, paperDeployed }) => {
  const navigate = useNavigate();
  const d = getActiveAccountdata();
  const [activetab, setActivetab] = useState("positions");
  const [position, setPosition] = useState(false);
  const [positiondata, setPositiondata] = useState([]);
  const [holdingsdata, setHoldingsdata] = useState([]);
  const [cnt,setCnt]=useState();
  const [deplypaperstrategy,setDeploypaperstrategy]=useState();
  useEffect(() => {
    // const paisatoken=get5paisaToken();
    if (d) {
      getPosition();
    }
  }, []);
  const getPosition = async () => {
    const positions_holdings = await getMarginandFO({
      api_key: d?.api_key,
      access_key: d?.access_token,
    });
  
    if(positions_holdings && positions_holdings.length > 0 && (positions_holdings !== 'undefined' || positions_holdings !== undefined))
      {
    const [, , result, holdingresult] = positions_holdings;

    if (result) {
      setPosition(true);
      setPositiondata(result);
    } else {
     console.log("something went wrong")
      setPosition(false);
    }

    if (holdingresult) {
      setPosition(true);
      setHoldingsdata(holdingresult);
    } else {
      setPosition(false);
    }
     }
     else
     {
      console.log("GETTING ERROR IN GETTING RESPONSE")
     }
  };
  const positionactive = (nm) => {
    if (nm === "positions") {
      setActivetab("positions");
    }
    if (nm === "holdings") {
      setActivetab("holdings");
    }
  };
useEffect(()=>{
    fetchscanner();
    getAllStrategies();
},[])
const fetchscanner=async()=>{
  const userId=getUser();
  const result = await LiveScannerApi({ user_id: userId.id });
  setCnt(result?.length);
}
const getAllStrategies = async () => {
  const userId = getUser();
  const getARDSResult = await getAllRunningDeployedStrategiesAPI({
    user_id: userId?.id,
    trading_type: 2,
  });
  if(getARDSResult)
    setDeploypaperstrategy(getARDSResult?.length)
  // if(getARDSResult.length>0)
      
}
  return (
    <div
      style={{
        // marginLeft: "80px",
        background: "white",
        padding: "12px",
        // borderRadius: "12px",
        borderLeft: "2px solid lightgray",
      }}
      id="deployed-side-bar" className="portfolio-card"
    >
      <div
        className="fw-bold"
        style={{ fontSize: "20px", paddingLeft: "10px" }}
      >
        Deployed
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "12px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/deployed?tab=live")}
      >
        <img
          src="/icon-images/livetrade.png"
          alt="Strategies Images"
          width={"45px"}
          height={"45px"}
          className="img-fluid"
        />
        <div style={{ marginLeft: "10px", width: "70%" }}>
          <div>Live Trades</div>
          
          <div style={{ color: "#9f9f9f" }}>{deplypaperstrategy>0 ? "Deployed":"Not Deployed"}</div>
        </div>
        <FontAwesomeIcon icon={faChevronRight} color="#9f9f9f" />
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "12px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/deployed?tab=paper")}
      >
        <img
          src="/icon-images/papertrade.png"
          alt="Strategies Images"
          width={"45px"}
          height={"45px"}
          className="img-fluid"
        />
        <div style={{ marginLeft: "10px", width: "70%" }}>
          <div>Paper Trades</div>

          <div style={{ color: "#9f9f9f" }}>
            {paperDeployed === 0 && "Not Deployed"}
          </div>
        </div>
        <FontAwesomeIcon icon={faChevronRight} color="#9f9f9f" />
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "12px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/deployed?tab=scanner")}
      >
        <img
          src="/icon-images/scanner.png"
          alt="Strategies Images"
          width={"40px"}
          height={"40px"}
          className="img-fluid"
        />
        <div style={{ marginLeft: "10px" }}>
          <div>Live Scanners</div>
          <div style={{ color: "#9f9f9f" }}>{cnt > 0 ? "Deployed" : "Not Deployed"}</div>
        </div>
      </div>

      <hr />
      <div style={{ marginTop: "20px", marginLeft: "10px" }}>
        <div className="fw-bold" style={{ fontSize: "20px" }}>
          Order Book
        </div>

        <div className="fs-2" style={{ color: "#2c308e" }}>
          0
        </div>

        <div
          className="pointer-cursor"
          style={{ color: "#9f9f9f" }}
          onClick={() => navigate("/trades?tab=pending")}
        >
          Open Orders
        </div>
        {/* <div
          style={{
            height: "1px",
            width: "90%",
            background: "lightgray",
            marginTop: "10px"
          }}
        ></div> */}
        <hr />
      </div>
      <div style={{ marginTop: "20px", marginLeft: "10px" }}>
        <div className="fs-2" style={{ color: "#2c308e" }}>
          0
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
          <div
            style={{ color: "#9f9f9f", marginLeft: "10px" }}
            className="pointer-cursor"
            onClick={() => navigate("/trades?tab=executed")}
          >
            Executed Orders
          </div>
        </div>
        {/* <div
          style={{
            height: "1px",
            width: "90%",
            background: "lightgray",
            marginTop: "10px"
          }}
        ></div> */}
        <hr />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          width: "100%",
          marginLeft: "10px",
        }}
      >
        <div className="fw-bold" style={{ fontSize: "20px" }}>
          Portfolio
        </div>
        <div
          className="pointer-cursor"
          onClick={() => navigate("/trades?tab=positions")}
        >
          View All
        </div>
      </div>

      <div className="row ">
        <div className="col-lg-6 col-md-6 p-0 px-1">
          <Button  variant="default"  
          onClick={() => positionactive("positions")}
                className={`w-100 border-0 ${
                  activetab === "positions" ? "active" : ""
                }`}>Positions</Button>&nbsp;&nbsp;
        </div>
        <div className="col-lg-6 col-md-6 p-0 px-1">
          <Button  variant="default" onClick={() => positionactive("holdings")}
                className={`w-100 border-0 ${
                  activetab === "holdings" ? "active" : ""
                }`}>Holdings</Button>
        </div>
        <Table className="p-0">
          <thead className="text-muted">
            <tr>
              <th>Instruments</th>
              <th>P&L</th>
            </tr>
          </thead>
          <tbody>
                      {(activetab === "positions" ? positiondata : holdingsdata)
                        .slice(0, 5)
                        .map((item, index) => (
                          <tr>
                            <td>{item.instrument}</td>
                            <td>{item.profit_loss}</td>
                          </tr>
                        ))}
                    </tbody>
          {/* <tbody className="text-center">
            <tr>
              <td>TATA MOTORS</td>
              <td>320000</td>
            </tr>
            <tr>
              <td>TATA MOTORS</td>
              <td>320000</td>
            </tr>
          </tbody> */}
        </Table>
      </div>
      {/* <div
          style={{
            height: "40px",
            padding: "8px",
            background: "#e1f2fa",
            borderRadius: "8px",
            textAlign: "center",
            flex: 1,
            marginRight: "10px"
          }}
        >
          Positions
        </div>
        <div
          style={{
            height: "40px",
            flex: 1,
            padding: "8px",
            background: "#f5f5f5",
            borderRadius: "8px",
            textAlign: "center"
          }}
        >
          F&O
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default DeployedBox;
