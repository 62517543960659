import React, { useLayoutEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChessKnight, faHouse } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import MobileUser from "./MobileUser";

const MobileHeader = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const isTabScreen = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };
  const pathName = useLocation().pathname;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);

  return (
    <>
      {!isTabScreen ? (
        <MobileUser />
      ) : (
        <div>
          <header className="mob-header fixed-bottom ">
            <nav className="mob-navbar">
              <ul className="mob-nav-links">
                <li>
                  <Link
                    to="/dashboard"
                    className={`text-muted my-text ${
                      pathName == "/dashboard" ? "active" : null
                    }`}
                  >
                    {/* <FontAwesomeIcon icon={faHouse} size="2x" /> */}
                    <i className="fa fa-home fa-1x" aria-hidden="true"></i>
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/strategies"
                    className={`text-muted ${
                      pathName == "/strategies" ? "active" : null
                    }`}
                  >
                    {/* <FontAwesomeIcon icon={faChessKnight}  size="2x"/> */}
                    <i className="fa fa-user-o fa-1x" aria-hidden="true"></i>
                    Strategy......
                  </Link>
                </li>
                <li>
                  <Link
                    to="/techincal"
                    className={`text-muted ${
                      pathName == "/techincal" ? "active" : null
                    }`}
                  >
                    <i className="fa fa-bar-chart fa-1x" aria-hidden="true"></i>
                    Technicals
                  </Link>
                </li>
                <li>
                  <Link
                    to="/scanners"
                    className={`text-muted ${
                      pathName == "/scanners" ? "active" : null
                    }`}
                  >
                    <i className="fa fa-search fa-1x" aria-hidden="true"></i>
                    Scanner
                  </Link>
                </li>
                <li>
                  <Link
                    to="/profile"
                    className={`text-muted ${
                      pathName == "/profile" ? "active" : null
                    }`}
                  >
                    <i className="fa fa-user-o fa-1x" aria-hidden="true"></i>
                    Profile
                  </Link>
                </li>
              </ul>
            </nav>
          </header>
        </div>
      )}
    </>
  );
};

export default MobileHeader;
