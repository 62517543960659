import React from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import moment from "moment";
const StrategyViewModal = ({ transactionData, viewOpen, setViewOpen }) => {
  console.log(transactionData, "transactionData");
  const handleClose = () => {
    setViewOpen(false);
  };

  return (
    <>
      <Modal
        show={viewOpen}
        onHide={handleClose}
        animation={false}
        centered
        size="lg"
      >
        {/* <Modal.Header>
            BackTest Result Stock Transactions
        </Modal.Header> */}
        <Modal.Body
          id="create-strategy-modal"
          className="d-flex align-items-center justify-content-center table-view"
        >
          <div
            className="instrumenttable w-100 h-50"
            style={{ overflowY: "scroll" }}
          >
            <Table className="">
              <thead className="">
                <tr>
                  <th>Date & time</th>
                  <th>price</th>
                  <th>p&L</th>
                  <th>Buy/Sell</th>
                  <th>volume</th>
                  <th>Quantity</th>
                  <th>trigger type</th>
                </tr>
              </thead>
              <tbody>
                {transactionData &&
                  transactionData?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {moment(item?.date).format("DD-MMM-YYYY")}&nbsp;
                        <span
                          className="text-secondary"
                          style={{ fontSize: "12px" }}
                        >
                          {moment.utc(item?.date).format("hh:mm:ss A")}
                        </span>
                      </td>
                      <td>{item?.close}</td>
                      <td
                        className={
                          item?.trigger_type !== "entry"
                            ? item?.pnl > 0
                              ? "text-success"
                              : "text-danger"
                            : null
                        }
                      >
                        {" "}
                        {item?.pnl !== undefined
                          ? Math.round(item?.pnl).toFixed(2)
                          : ""}
                      </td>
                      <td>{item?.status}</td>
                      <td>{item?.volume}</td>
                      <td>{item?.quantity}</td>
                      <td>{item?.trigger_type}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleConfirm}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StrategyViewModal;
