import React from "react";
import { Button } from "react-bootstrap";
import { useMenuContextScanner } from "./ScannerCreateComponent";
import { useNavigate } from "react-router-dom";

const ScannerCreateBarComponent = () => {
  const { menu, setMenu } = useMenuContextScanner();
  const navigate = useNavigate();

  const handleNextBtn = () => {
    if (menu === "condition") {
      setMenu("review");
    } else if (menu === "parameter") {
      setMenu("condition");
    }
  };

  return (
    <>
      <div className="row">
        <div className="d-flex justify-content-between my-4">
          <div>
            <i
              className="fa fa-chevron-left chev"
              onClick={() => navigate(-1)}
            ></i>
            <h5 className="ps-2 fw-bolder d-inline">ABC Scanner</h5>
          </div>
          <div>
            <div className="d-flex align-items-center">
              {/* <div className="d-flex justify-content-center align-items-center">
                        <div className="icon"><i className="fa-solid fa-file"></i></div>
                        <div className="multipgbtn ps-2">Multipage mode</div>
                    </div> */}

              {/* <div className="d-flex justify-content-center align-items-center mx-2">
                        <div className="icon"><i className="fa-solid fa-gear"></i></div>
                        <div className="multipgbtn ps-2">Advance mode</div>
                    </div> */}
              {/* <div className="d-flex align-items-end flex-column ">
                        <div className="ml-auto p-2">
                            <Button className="btn-color" onClick={handleNextBtn} size="sm">
                            &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Button>
                        </div>
                    </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="hline">
            <div
              className={`circle one ${menu === "parameter" ? "active" : ""}`}
              onClick={() => setMenu("parameter")}
            >
              1
            </div>
            <div className={`position ${menu === "parameter" ? "active" : ""}`}>
              Parameter
            </div>
            <div
              className={`circle two-scanner ${
                menu === "condition" ? "active" : ""
              }`}
              onClick={() => setMenu("condition")}
            >
              2
            </div>
            <div
              className={`condition-scanner ${
                menu === "condition" ? "active" : ""
              }`}
            >
              Condition
            </div>
            <div
              className={`circle four ${menu === "review" ? "active" : ""}`}
              onClick={() => setMenu("review")}
            >
              3
            </div>
            <div className={`Review ${menu === "review" ? "active" : ""}`}>
              Review
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScannerCreateBarComponent;
