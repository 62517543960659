import axios from "axios";
import { get5paisaToken, save5paisaToken } from "../Session";
// const API_BASE_URL = "http://3.111.147.17:8008/api/v1";
// const API_BASE_URL = "http://192.168.1.5:8008/api/v1";
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";

const token = get5paisaToken();
export const paisa5Totplogin = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaTOTPLogin`,
      data
    );
    // console.log("response");
    if (response.data.success) {
      const token = response.data.res;
      save5paisaToken(token);
      return true;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};

export const getMarginApi = async () => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisagetMargins`,
      {
        HEADERS: {
          authorization: "Bearer " + token,
        },
      }
    );
    if (response.data.success) {
      console.log("@", response.data);
      return response.data;
    } else {
      console.log("@", response.data);

      return false;
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

export const getHoldingsApi = async (token) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisagetHoldings`,
      {
        HEADERS: {
          authorization: "Bearer " + token,
        },
      }
    );

    if (response.data.success) {
      return response.data.holdings; // Assuming 'holdings' is the property containing the data
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

export const getPositionsApi = async () => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaFetchPositions`,
      {
        HEADERS: {
          authorization: "Bearer " + token,
        },
      }
    );
    console.log("response.data.success", response.data.success);
    if (response.data.success) {
      return response.data.positions;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};

export const get5PaisaGetMarketLiveStreamAPI = async (data) => {
  console.log("params of 5PaisaGetMarketLiveStream", data);
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaGetMarketLiveStream`,
      data
    );
    console.log("response of 5PaisaGetMarketLiveStream @@", response);
    if (response.success) {
      return response.data;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};
