import React, { useState, useEffect, memo } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import DeployedBox from "./DeployedBox";
import {
  LivePlaceOrderAPI,
  getAllRunningDeployedStrategiesAPI,
  getPaperTradingTransactionsapi,
  paperTradingPlaceOrderAPI,
  stopDeployedStrategyAPI,
} from "../api/strategyapi";
import {
  get5paisaToken,
  getActiveAccount,
  getActiveAccountdata,
  getUser,
  getZerodhaToken,
  save5paisaToken,
} from "../Session";
import { get5PaisaGetMarketLiveStreamAPI } from "../api/paisa5api";
import OrderDeployedModal from "../components/deployed/OrderDeployedModal";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button2 from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { getStockLTPDataAPI } from "../api/api";
import { LiveScannerApi } from "../api/scannerapi";
import { Table } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
let flag = 0;
const DeployedScreen = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activetab, setActivetab] = useState();
  const tab = searchParams.get("tab");

  const [mytab, setTab] = useState(tab ? tab : "");

  useEffect(() => {
    setActivetab(tab);
    setTab(mytab);
  }, [tab, mytab]);


  const settabactive = (mytab) => {
    setTab(mytab);
  };

  // setActivetab(tab);
  console.log("param", tab);
  const user = getUser();
  const userId = user.id;

  const [selectedButton, setSelectedButton] = useState("Button 1");
  const [selectedButton2, setSelectedButton2] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);

  const [waiting, setWaiting] = useState(12);
  const [entered, setEntered] = useState(4);
  const [stopped, setStopped] = useState(1);

  const [strategiesData, setStrategiesData] = useState([]);
  const [ltpData, setLtpData] = useState([]);
  const [selectedTrade, setSelectedTrade] = useState({});
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [buySell, setBuySell] = useState("");
  const [tradeCategory, setTradeCategory] = useState("");
  const [modalOrderShow, setModalOrderShow] = useState(false);
  const [paperTradingData, setPaperTradingData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [stockItem, setStockItem] = useState();
  const [stockItemId, setStockItemId] = useState();
  const [liveScanner, setLiveScanner] = useState([]);
  const handleCloseModal = () => setShowDetails(false);
  const handleShowModal = () => setShowDetails(true);

  const MINUTE_MS = 5000;

  useEffect(() => {
    console.log("stockItem>", stockItem);
  }, [stockItem]);

  useEffect(() => {
    getAllRunningDeployedStrategies(selectedButton2);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenOptionsDialog = (event, item) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptionsDialog = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (item, option) => {
    console.log("Clicked item>", item);
    if (option === "Stop") stopDeployedStrategy();
    if (option === "View Details") handleShowModal();

    handleCloseOptionsDialog();
  };

  const getAllRunningDeployedStrategies = async (tradeid) => {
    const getARDSResult = await getAllRunningDeployedStrategiesAPI({
      user_id: userId,
      trading_type: tradeid ? tradeid : 1,
    });
    // console.log("getARDSResult", getARDSResult);
    setStrategiesData(getARDSResult);
    // setLtpData(getARDSResult);

    let tokens =
      getARDSResult?.length > 0 &&
      getARDSResult?.map((i) => parseInt(i?.script_code));
    console.log("tokens>IMP", tokens, getARDSResult);
    if (tokens?.length > 0 && getARDSResult)
      getStocksData(tokens, getARDSResult);
  };

  const stopDeployedStrategy = async (Id) => {
    console.log("Id of stopped", stockItemId);
    const result = await stopDeployedStrategyAPI({
      strategy_id: stockItemId,
    });
    if (result) alert("Stopped");
    getAllRunningDeployedStrategies(selectedButton2);
  };

  useEffect(() => {
    console.log("paperTreading", tab);
    if (tab === "paper") {
      setSelectedButton2(2);
      const interval=setInterval(()=>{
        getAllRunningDeployedStrategies(2);
      },2000)
      return () => {
        clearInterval(interval); // Clear interval when component is unmounted
      };
      // getAllRunningDeployedStrategies(2);
    } else {
      setSelectedButton2(1);
      getAllRunningDeployedStrategies(1);
    }
  }, [tab]);

  const getLtpPnlData = async (Exch, ExchType, script_code) => {
    const token = await get5paisaToken();
    if (token) {
      const method = "POST";
      return new Promise((resolve, reject) => {
        if (method === "POST") {
          const subscribeMessage = {
            Method: "MarketFeedV3",
            Operation: "Subscribe",
            ClientCode: "59489811",
            MarketFeedData: [
              { Exch: Exch, ExchType: ExchType, ScripCode: script_code },
            ],
          };
          const accessToken = token;
          const clientCode = "59489811";

          const url = `wss://openfeed.5paisa.com/Feeds/api/chat?Value1=${accessToken}|${clientCode}`;
          const ws = new WebSocket(url);

          ws.onopen = () => {
            ws.send(JSON.stringify(subscribeMessage));
          };

          ws.onmessage = (event) => {
            const marketFeedData = JSON.parse(event.data);
            resolve(marketFeedData);
          };

          ws.onclose = () => {
            reject(new Error("WebSocket connection closed"));
          };

          ws.onerror = (error) => {
            reject(error);
          };
        }
      });
    } else {
      // console.log("Token not found");
    }
  };

  const fetchLtpData = async (data) => {
    try {
      const updatedStrategiesData = [];

      for (const item of data) {
        let lastRate = 0;
        let openRate = 0;
        if (item.exch && item.exchType && item.script_code) {
          const ltp = await getLtpPnlData(
            item.exch,
            item.exchType,
            item.script_code
          );
          lastRate = ltp ? ltp[0].LastRate : null;
          openRate = ltp ? ltp[0].OpenRate : null;
        }

        updatedStrategiesData.push({ ...item, lastRate, openRate });
      }
      console.log("checkdata--", updatedStrategiesData);
      setLtpData(updatedStrategiesData); // Set updated data using setLtpData
    } catch (error) {
      //   console.error("Error fetching LTP data:", error);
    }
  };

  const handleInputNumber = (event) => {
    const { id, value } = event.target;
    const numericValue = value.replace(/[^\d]/g, "");

    switch (id) {
      case "input1":
        setInput1(numericValue);
        break;
      case "input2":
        setInput2(numericValue);
        break;
      case "input3":
        setInput3(numericValue);
        break;
      case "input4":
        setInput4(numericValue);
        break;
      default:
        break;
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
  };
  const handleOptionChange2 = (event) => {
    setSelectedOption2(event.target.id);
    setInput2(selectedTrade.lastRate);
  };
  const handleOptionChange3 = (event) => {
    setSelectedOption3(event.target.id);
  };

  const handleShow = (item) => {
    console.log("Inside click", item);
    if (item.status === "waiting") {
      console.log("Inside waiting");
      setTradeCategory("waiting");
      if (item.entry_trigger === 1) {
        if (item.position_id === 1) {
          console.log("set buy");
          setBuySell("buy");
        } else if (item.position_id === 2) {
          console.log("set sell");
          setBuySell("sell");
        }
      }
    } else if (item.status === "entered") {
      console.log("Inside entered");
      setTradeCategory("entered");
      if (item.exit_trigger === 1) {
        if (item.position_id == 1) {
          console.log("set sell");
          setBuySell("sell");
        } else if (item.position_id == 2) {
          console.log("set buy");
          setBuySell("buy");
        }
      }
    }

    setShowModal(true);
    setSelectedTrade(item);
    setInput1(item?.quantity);
    setInput2(item?.lastRate);
    setSelectedOption2("option3");
    setSelectedOption(item?.holding_type == "MIS" ? "option1" : "option2");
    setSelectedOption(item?.holding_type == "CNC" ? "option2" : "option1");
    setSelectedOption3("option3");
    setInput3(item.stop_loss);
    setInput4(item?.quantity);
  };
  const handleClose = () => setShowModal(false);

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
  };

  const handleButtonClick2 = (buttonText) => {
    console.log("IDDDD", buttonText);
    setSelectedButton2(buttonText);
    getAllRunningDeployedStrategies(buttonText);
  };

  const onBuySellLiveClick = async () => {
    console.log("Inside buy/sell click, live");
    let posType;
    if (tradeCategory == "waiting") {
      posType = buySell == "buy" ? "BUY" : buySell == "sell" ? "SELL" : null;
    } else if (tradeCategory == "entered") {
      if (selectedTrade.position_id == 1) {
        posType = buySell == "buy" ? "SELL" : buySell == "sell" ? "BUY" : null;
      }
    }

    let posId;
    if (tradeCategory == "waiting") {
      posId = selectedTrade.position_id;
    } else if (tradeCategory == "entered") {
      if (selectedTrade.position_id == 1) {
        posId = 2;
      } else if (selectedTrade.position_id == 2) {
        posId = 1;
      }
    }

    const accountInfo = await getActiveAccountdata();
    console.log("Demat info", accountInfo);

    const resultPlaceOrderRes = await LivePlaceOrderAPI({
      RemoteOrderID: moment.unix(),
      orderType: posType,
      qty: selectedTrade.quantity,
      exchange: selectedTrade.exch,
      scripCode: selectedTrade.script_code,
      exchangeSegment: selectedTrade.exchType,
      atMarket: false,
      isStopLossOrder: false,
      stopLossPrice: 0,
      isVTD: false,
      isIOCOrder: false,
      isIntraday: false,
      ahPlaced: "N",
      IOCOrder: false,
      price: selectedOption2 == "option3" ? selectedTrade.lastRate : input2,
      positionId: posId,
      userId: userId,
      dematAccountId: accountInfo.id,
    });
    console.log("Response of live trade ", resultPlaceOrderRes);
    if (resultPlaceOrderRes.RMSResponseCode < 0) {
      alert(resultPlaceOrderRes.Message);
    } else {
    }
  };

  const onBuySellPaperClick = async () => {
    console.log("Inside buy/sell click, paper", selectedTrade);
    let posId;
    if (tradeCategory == "waiting") {
      posId = selectedTrade.position_id;
    } else if (tradeCategory == "entered") {
      if (selectedTrade.position_id == 1) {
        posId = 2;
      } else if (selectedTrade.position_id == 2) {
        posId = 1;
      }
    }
    console.log("pos id", posId);
    if (tradeCategory) {
      const resultPlaceOrderRes = await paperTradingPlaceOrderAPI({
        scripCode: selectedTrade.script_code,
        price: selectedOption2 == "option3" ? selectedTrade.lastRate : input2,
        exch: selectedTrade.exch,
        exchType: selectedTrade.exchType,
        userId: userId,
        positionId: posId,
        quantity: selectedTrade.quantity,
        strategyId: selectedTrade.id,
        status:
          tradeCategory == "waiting"
            ? "entered"
            : tradeCategory == "entered"
            ? "stopped"
            : null,
      });
      console.log("Result of buy sell", resultPlaceOrderRes);
      if (resultPlaceOrderRes == true) {
        console.log("Order placed successfully");
        setShowModal(false);
        setSelectedTrade({});
        getAllRunningDeployedStrategies();
      } else {
        console.log("Something went wrong with your order");
      }
    } else {
      console.log("status not found");
    }
  };

  const handleShowOrder = async (id, code) => {
    setModalOrderShow(true);
    getPaperTreadeData(id, code);
  };

  const getPaperTreadeData = async (id, code) => {
    const result = await getPaperTradingTransactionsapi({
      id: id,
      scriptCode: code,
    });
    console.log("Data@!!!", result);
    setPaperTradingData(result?.data);
  };

  const updateStocksData = (stocks, data) => {
    stocks.forEach((stock) => {
      const dataItem = data.find(
        (item) => item.instrument_token == stock.script_code
      );
      if (dataItem) {
        stock.ltp = dataItem.last_price;
        stock.openPrice = dataItem.ohlc.open;
      }
    });

    // After updating all stocks, you may want to call setLtpData once
    // to update all changes to the stocks array.
    console.log("stocks>", stocks);
    setLtpData(stocks);
  };

  const getStocksData = async (tokens, stocks) => {
    const token = getZerodhaToken();
    const apiKey = "3wxj01wpd2rnnn9c";

    console.log("token--", token);
    console.log("stockTokensSent", tokens);

    const queryParams = tokens?.map((token) => `i=${token}`).join("&");
    console.log("queryParams??", queryParams);

    const response = await getStockLTPDataAPI({
      apiKey: apiKey,
      token: token,
      queryParams: queryParams,
    });
    if (response) {
      if (response?.data) updateStocksDataNew(stocks, response?.data);
      // flag = 1;
      // console.log("stocksLTP>", stocks, data?.data);
      // // console.log("stocksLTP2>", stocks);
      // updateStocksData(stocks, data?.data);
    }

    // if (tokens?.length > 0) {
    //   try {
    //     const response = await axios.get(
    //       `https://api.kite.trade/quote/ohlc?${queryParams}`,
    //       {
    //         headers: {
    //           "X-Kite-Version": "3",
    //           Authorization: `token ${apiKey}:${token}`,
    //         },
    //       }
    //     );
    //     console.log("data>>>>", response.data?.data);
    //     if (response.data?.data)
    //       updateStocksDataNew(stocks, response.data?.data);
    //   } catch (error) {
    //     console.error("Error fetching LTP:", error);
    //   }
    // }
  };

  const updateStocksDataNew = (stocks, data) => {
    console.log("Data1&^^", stocks);
    console.log("Data2&^^", data);

    // Iterate through each stock in the stocks array
    stocks.forEach((stock) => {
      // Check if the current stock's script_code exists in the data object
      const instrumentToken = stock.script_code;
      const newData = data[instrumentToken];

      // If newData is found, update the stock properties
      if (newData) {
        stock.ltp = newData.last_price;
        stock.closePrice = newData.ohlc.close;
        // Add any other properties you need to update here
      }
    });

    console.log("stocks===", stocks);

    // Assuming setLtpData and onFormChange are functions to update state or perform some action
    setLtpData(stocks);
  };

  const buttonStyle = {
    borderRadius: "6px", // Set border radius for the buttons
    marginRight: "15px", // Set margin between buttons
    backgroundColor: "white", // Set background color to white
    color: "black",
    width: "100%",
    border: "1px solid lightgray",
    fontSize: "12px",
    fontWeight: "500",
    paddingLeft: "20px",
    paddingRight: "20px",
  };

  const textStyle = {
    fontSize: "12px",
    fontWeight: "500",
    marginRight: "8px",
  };
  const textStyle2 = {
    fontSize: "12px",
    fontWeight: "600",
    color: "#b4b8c4",
  };

  const buttonStyle2 = {
    borderRadius: "6px", // Set border radius for the buttons
    backgroundColor: "white", // Set background color to white
    color: "black",
    border: "1px solid lightgray",
    fontSize: "12px",
    fontWeight: "500",
    marginRight: "8px",
  };

  const selectedButtonStyle = {
    ...buttonStyle,
    color: "blue", // Set text color for the selected button
    backgroundColor: "white", // Set background color to white
    fontWeight: "bold",
    borderBottom: "3px solid blue",
    fontSize: "14px",
  };
  const selectedButtonStyle2 = {
    ...buttonStyle2,
    color: "blue", // Set text color for the selected button
    backgroundColor: "white", // Set background color to white
    fontWeight: "bold",
    borderBottom: "3px solid blue",
    fontSize: "12px",
  };
  const customDialogStyle = {
    maxWidth: "700px", // Set your desired width
    margin: "1.75rem auto", // Center the modal horizontally
    marginTop: "50vh", // Center the modal vertically
    transform: "translateY(-50%)", // Adjust for vertical centering
    boxShadow: "0 0 10px 3px #A9A9A9",
    borderRadius: "8px",
  };

  const inputLabel = {
    color: "#afafb0",
    fontWeight: "600",
  };
  const modalStyle = {
    display: showModal ? "block" : "none",
  };
  useEffect(() => {
    ScannerApi();
  }, []);

  const ScannerApi = async () => {
    const result = await LiveScannerApi({ user_id: userId });
    setLiveScanner(result);
    console.log("firstRR", result);
  };
  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer">
          <div className="row">
            <div className="col-lg-9 col-mg-9 col-sm-12">
              <div className="d-flex justify-content-between">
                <div>
                  <h3 style={{ color: "#2c308e" }}>
                    <i
                      className="fa fa-chevron-left chev fs-5 pointer-cursor"
                      onClick={() => navigate(-1)}
                    ></i>
                    <b className="ps-3">{["strategy", "live", "paper"].includes(tab)? "Deployed Strategies":"Live Scanners" }</b>
                    &nbsp;&nbsp;
                  </h3>
                </div>
                <div>
                  <ListGroup horizontal>
                    <ListGroup.Item
                      onClick={() => {
                        settabactive("strategy");
                        navigate("/deployed?tab=live");
                      }}
                      className={`me-2 ${
                        ["strategy", "live", "paper"].includes(tab)
                          ? "clrportfolio"
                          : "portfoliotab"
                      }`}
                    >
                      Strategy
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={() => {
                        settabactive("scanner");
                        navigate("/deployed?tab=scanner");
                      }}
                      className={`me-2 ${
                        tab === "scanner"  ? "clrportfolio" : "portfoliotab"
                      }`}
                    >
                      Scanner
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>

              {tab === "scanner" ? (
                <>
                  {" "}
                  {/* <div className="fw-bolder">Live Scanner</div> */}
                  <Table className="holding-table mt-2">
                    <thead className="">
                      <tr>
                        <th className="text-justify">Scanner Name</th>
                        <th className="text-center">Deploy Date and time</th>
                        <th className="text-center">Scan on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveScanner?.map((item, index) => {
                        const dateTime = new Date(
                          item.updated_at || item.created_at
                        );
                        const date = dateTime.toLocaleDateString();
                        const time = dateTime.toLocaleTimeString();
                        return (
                          <tr key={index}>
                            <td className="text-justify">
                              {item.scanner_name}
                            </td>
                            <td className="text-center">
                              {date} <small>{time}</small>
                            </td>
                            <td className="text-center">
                              {item.scan_on ? item.scan_on : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "72vw",
                    }}
                  >
                    <div className="col-lg-4 mt-4">
                      <div className="container">
                        <div className="btn-group">
                          <button
                            type="button"
                            className={`btn btn-primary ${
                              selectedButton === "Button 1" ? "active" : ""
                            }`}
                            onClick={() => handleButtonClick("Button 1")}
                            style={
                              selectedButton === "Button 1"
                                ? selectedButtonStyle
                                : buttonStyle
                            }
                          >
                            <span>WAITING</span>
                            {waiting && (
                              <span>{`(${
                                ltpData?.filter(
                                  (item) => item.status === "waiting"
                                ).length
                              })`}</span>
                            )}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-primary ${
                              selectedButton === "Button 2" ? "active" : ""
                            }`}
                            onClick={() => handleButtonClick("Button 2")}
                            style={
                              selectedButton === "Button 2"
                                ? selectedButtonStyle
                                : buttonStyle
                            }
                          >
                            <span>ENTERED</span>
                            {entered && (
                              <span>{`(${
                                ltpData?.filter(
                                  (item) => item.status === "entered"
                                ).length
                              })`}</span>
                            )}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-primary ${
                              selectedButton === "Button 3" ? "active" : ""
                            }`}
                            onClick={() => handleButtonClick("Button 3")}
                            style={
                              selectedButton === "Button 3"
                                ? selectedButtonStyle
                                : buttonStyle
                            }
                          >
                            <span>STOPPED</span>
                            {stopped && (
                              <span>{`(${
                                ltpData.filter(
                                  (item) => item.status === "stopped"
                                ).length
                              })`}</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="btn-group">
                        <button
                          className={`btn btn-primary ${
                            selectedButton2 === "1" ? "active" : ""
                          }`}
                          onClick={() => handleButtonClick2(1)}
                          style={
                            selectedButton2 === 1
                              ? selectedButtonStyle2
                              : buttonStyle2
                          }
                        >
                          <i className="fa-solid fa-wifi"></i>
                          {selectedButton2 === 1 && <span> Live Trading</span>}
                        </button>
                        <button
                          type="button"
                          className={`btn btn-primary ${
                            selectedButton2 === 2 ? "active" : ""
                          }`}
                          onClick={() => handleButtonClick2(2)}
                          style={
                            selectedButton2 === 2
                              ? selectedButtonStyle2
                              : buttonStyle2
                          }
                        >
                          <i
                            className="fa-solid fa-file-lines"
                            style={{
                              fontSize: "22px",
                              color: "lightgray",
                            }}
                          ></i>
                          {selectedButton2 === 2 && (
                            <span style={{ marginLeft: "12px" }}>
                              {" "}
                              Paper Trading
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row py-5">
                    <div
                      className="col-lg-12 col-sm-12"
                      style={{
                        background: "white",
                        width: "100vw",
                        marginRight: "20px",
                        marginTop: -10,
                      }}
                    >
                      <table
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                        }}
                      >
                        <thead>
                          <tr
                            style={{
                              borderBottom: "1px solid lightgray",
                              width: "100%",
                            }}
                          >
                            <th
                              style={{
                                width: "25%",
                                textAlign: "center",
                                padding: "10px",
                                background: "white",
                                color: "#b5c1d8",
                              }}
                            >
                              Instrument 
                            </th>
                            <th
                              style={{
                                width: "25%",
                                textAlign: "center",
                                padding: "10px",
                                background: "white",

                                color: "#b5c1d8",
                              }}
                            >
                              Strategy
                            </th>
                            <th
                              style={{
                                width: "25%",
                                textAlign: "center",
                                padding: "10px",
                                background: "white",

                                color: "#b5c1d8",
                              }}
                            >
                              Positions & Target
                            </th>
                            <th
                              style={{
                                width: "25%",
                                textAlign: "center",
                                padding: "10px",
                                background: "white",

                                color: "#b5c1d8",
                              }}
                            >
                              P&L
                            </th>
                            {selectedButton === "Button 3" && (
                              <th
                                style={{
                                  width: "25%",
                                  textAlign: "center",
                                  padding: "10px",
                                  background: "white",
                                  color: "#b5c1d8",
                                }}
                              >
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        {selectedButton === "Button 1" ? (
                          <>
                            {ltpData &&
                            ltpData.filter((item) => item.status === "waiting")
                              .length > 0 ? (
                              <tbody>
                                {ltpData
                                  .filter((item) => item.status === "waiting")
                                  ?.map((item, index) => (
                                    <tr
                                      key={index}
                                      style={{
                                        border: "0.5px solid lightgray",
                                      }}
                                    >
                                      <td style={{}}>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            <img
                                              alt="nt found"
                                              src="graph.png"
                                              style={{
                                                height: "40px",
                                              }}
                                            />
                                          </div>
                                          <div style={textStyle}>
                                            {item.stock_name}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            ...textStyle,
                                            fontWeight: "500",
                                            color:
                                              item.ltp !== 0 &&
                                              item?.openPrice !== 0
                                                ? item.ltp > item.openPrice
                                                  ? "#008000"
                                                  : "#BA0F04"
                                                : "#060606",
                                            marginLeft: "60px",
                                            display: "flex",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          {item.ltp}
                                          <span style={{ marginLeft: "10px" }}>
                                            {item?.ltp && item?.closePrice && (
                                              <div>
                                                {(
                                                  ((item?.ltp -
                                                    item?.closePrice) /
                                                    item?.closePrice) *
                                                  100
                                                ).toFixed(3)}
                                                %
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        onClick={() =>
                                          item
                                            ? item.entry_trigger == 1 &&
                                              selectedButton2 !== 2
                                              ? handleShow(item)
                                              : null
                                            : null
                                        }
                                        style={{
                                          textAlign: "center",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "black",
                                          fontSize: "12px",
                                          cursor: "pointer",
                                          marginLeft: "30px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <i
                                          className="fa-solid fa-wifi"
                                          style={{ marginRight: "14px" }}
                                        ></i>
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {item.strategy_name}
                                        </div>
                                        {item.entry_trigger == 1 &&
                                        selectedButton2 !== 2 ? (
                                          <div className="d-flex align-items-center">
                                            <div
                                              style={{
                                                height: "10px",
                                                width: "10px",
                                                background:
                                                  item.position_id === 1
                                                    ? "blue"
                                                    : "orange",
                                                borderRadius: "50%",
                                                animation:
                                                  "blink-animation 1s infinite alternate", // Apply the animation
                                              }}
                                            ></div>
                                            <Button
                                              className="ms-2 fw-bolder d-flex justify-content-center align-items-center"
                                              style={{
                                                width: "40px",
                                                height: "20px",
                                                fontSize: "12px",
                                              }}
                                            >
                                              Entry
                                            </Button>
                                          </div>
                                        ) : null}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          fontSize: "12px",
                                        }}
                                      >
                                        N/A
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          fontSize: "12px",
                                        }}
                                      >
                                        N/A
                                      </td>
                                      <td>
                                        <Button2
                                          onClick={(event) => {
                                            console.log("ITEM>>", item, index);
                                            setStockItem(
                                              JSON.parse(item?.entire_object)
                                            );
                                            setStockItemId(item?.id);
                                            handleOpenOptionsDialog(
                                              event,
                                              item
                                            );
                                          }}
                                        >
                                          <i className="fa fa-ellipsis-v"></i>
                                        </Button2>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          onClose={handleCloseOptionsDialog}
                                          PaperProps={{
                                            sx: {
                                              boxShadow:
                                                "0px 1px 5px rgba(0, 0, 0, 0.05)", // Applying shadow to the entire menu
                                            },
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              console.log("itemIMP", item);
                                              handleOptionClick(
                                                item,
                                                "View Details"
                                              );
                                            }}
                                            sx={{
                                              fontWeight: "500",
                                            }}
                                          >
                                            View Strategy Details
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              handleOptionClick(item, "Stop")
                                            }
                                            sx={{ color: "red" }}
                                          >
                                            Stop
                                          </MenuItem>
                                        </Menu>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="4"
                                    style={{ textAlign: "center" }}
                                  >
                                    No waiting list found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                            {ltpData &&
                            ltpData.filter((item) => item.status === "waiting")
                              .length > 0 ? (
                              <tr style={{ border: "0.5px solid lightgray" }}>
                                <td colSpan="4">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        textAlign: "right",
                                        padding: "10px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      TOTAL PROFIT/LOSS
                                    </div>
                                    <i
                                      className="fa fa-rupee"
                                      style={{ color: "#83c166" }}
                                    ></i>
                                    <div
                                      style={{
                                        textAlign: "right",
                                        padding: "10px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        paddingRight: "80px",
                                        color: "#83c166",
                                      }}
                                    >
                                      N/A
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ) : selectedButton === "Button 2" ? (
                          <>
                            {ltpData &&
                            ltpData.filter((item) => item.status === "entered")
                              .length > 0 ? (
                              <tbody>
                                {ltpData
                                  .filter((item) => item.status === "entered")
                                  ?.map((item, index) => (
                                    <tr
                                      key={index}
                                      style={{
                                        border: "0.5px solid lightgray",
                                      }}
                                    >
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            <img
                                              alt="nt found"
                                              src="graph.png"
                                              style={{
                                                height: "40px",
                                              }}
                                            />
                                          </div>
                                          <div style={textStyle}>
                                            {item.stock_name}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            ...textStyle,
                                            fontWeight: "500",
                                            marginLeft: "12px",
                                            color:
                                              item.ltp !== 0 &&
                                              item?.openPrice !== 0
                                                ? item.ltp > item.openPrice
                                                  ? "#008000"
                                                  : "#BA0F04"
                                                : "#060606",
                                            marginLeft: "60px",
                                            display: "flex",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          {item.ltp}
                                          <span style={{ marginLeft: "10px" }}>
                                            {item?.ltp && item?.closePrice && (
                                              <div>
                                                {(
                                                  ((item?.ltp -
                                                    item?.closePrice) /
                                                    item?.closePrice) *
                                                  100
                                                ).toFixed(3)}
                                                %
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        onClick={() =>
                                          item
                                            ? item.exit_trigger == 1 &&
                                              selectedButton2 !== 2
                                              ? handleShow(item)
                                              : null
                                            : null
                                        }
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "black",
                                          fontSize: "12px",
                                          cursor: "pointer",
                                          marginLeft: "30px",
                                        }}
                                      >
                                        <i
                                          className="fa-solid fa-wifi"
                                          style={{ marginRight: "14px" }}
                                        ></i>
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            marginRight: "12px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {item.strategy_name}
                                        </div>
                                        {item.exit_trigger == 1 &&
                                        selectedButton2 !== 2 ? (
                                          <div className="d-flex align-items-center">
                                            <div
                                              style={{
                                                height: "9px",
                                                width: "9px",
                                                background:
                                                  item.position_id == 1
                                                    ? "orange"
                                                    : "blue",
                                                borderRadius: "50%",
                                                animation:
                                                  "blink-animation 1s infinite alternate", // Apply the animation
                                              }}
                                            ></div>
                                            <Button
                                              className="ms-2 fw-bolder d-flex justify-content-center align-items-center"
                                              style={{
                                                width: "40px",
                                                height: "20px",
                                                fontSize: "12px",
                                              }}
                                            >
                                              Exit
                                            </Button>
                                          </div>
                                        ) : null}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        N/A
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "black",
                                          fontSize: "12px",
                                          marginLeft: "80px",
                                        }}
                                      >
                                        {item.lastRate != null ? (
                                          <i
                                            className="fa fa-rupee"
                                            style={{ marginRight: "14px" }}
                                          ></i>
                                        ) : null}
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            marginRight: "14px",
                                            color: "#060606",
                                          }}
                                        >
                                          {item?.lastRate}
                                          {item?.lastRate !== 0 ||
                                            (item.lastRate != null &&
                                              item?.openRate !== 0 && (
                                                <>
                                                  {"   "}
                                                  {(
                                                    ((item.lastRate -
                                                      item.openRate) /
                                                      item.openRate) *
                                                    100
                                                  ).toFixed(1)}
                                                  %
                                                </>
                                              ))}
                                        </div>
                                      </td>
                                      <td>
                                        <Button2
                                          onClick={(event) => {
                                            console.log("ITEM>>", item, index);
                                            setStockItem(
                                              JSON.parse(item?.entire_object)
                                            );
                                            setStockItemId(item?.id);
                                            handleOpenOptionsDialog(
                                              event,
                                              item
                                            );
                                          }}
                                        >
                                          <i className="fa fa-ellipsis-v"></i>
                                        </Button2>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          onClose={handleCloseOptionsDialog}
                                          PaperProps={{
                                            sx: {
                                              boxShadow:
                                                "0px 1px 5px rgba(0, 0, 0, 0.05)", // Applying shadow to the entire menu
                                            },
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() =>
                                              handleOptionClick(
                                                item,
                                                "View Details"
                                              )
                                            }
                                            sx={{ fontWeight: "500" }}
                                          >
                                            View Strategy Details
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              handleOptionClick(item, "Stop")
                                            }
                                            sx={{ color: "red" }}
                                          >
                                            Stop
                                          </MenuItem>
                                        </Menu>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="4"
                                    style={{ textAlign: "center" }}
                                  >
                                    No entered list found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                            {ltpData &&
                            ltpData.filter((item) => item.status === "entered")
                              .length > 0 ? (
                              <tr>
                                <td colSpan="4">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        textAlign: "right",
                                        padding: "10px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      TOTAL PROFIT/LOSS
                                    </div>
                                    <i
                                      className="fa fa-rupee"
                                      style={{ color: "#83c166" }}
                                    ></i>
                                    <div
                                      style={{
                                        textAlign: "right",
                                        padding: "10px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        paddingRight: "80px",
                                        color: "#83c166",
                                      }}
                                    >
                                      00.00
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {ltpData &&
                            ltpData.filter((item) => item.status === "stopped")
                              .length > 0 ? (
                              <tbody>
                                {ltpData
                                  .filter((item) => item.status === "stopped")
                                  ?.map((item, index) => (
                                    <tr
                                      key={index}
                                      style={{
                                        border: "0.5px solid lightgray",
                                      }}
                                    >
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            <img
                                              src="graph.png"
                                              alt="nt found"
                                              style={{
                                                height: "40px",
                                              }}
                                            />
                                          </div>
                                          <div style={textStyle}>
                                            {item.stock_name}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            ...textStyle,
                                            fontWeight: "500",
                                            marginLeft: "12px",
                                            color:
                                              item.ltp !== 0 &&
                                              item?.openPrice !== 0
                                                ? item.ltp > item.openPrice
                                                  ? "#008000"
                                                  : "#BA0F04"
                                                : "#060606",
                                            marginLeft: "60px",
                                            display: "flex",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          {item.ltp}
                                          <span style={{ marginLeft: "10px" }}>
                                            {item?.ltp && item?.closePrice && (
                                              <div>
                                                {(
                                                  ((item?.ltp -
                                                    item?.closePrice) /
                                                    item?.closePrice) *
                                                  100
                                                ).toFixed(3)}
                                                %
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "black",
                                          fontSize: "12px",
                                          cursor: "pointer",
                                          marginLeft: "30px",
                                        }}
                                      >
                                        <i
                                          className="fa-solid fa-wifi"
                                          style={{ marginRight: "14px" }}
                                        ></i>
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            marginRight: "12px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {item.strategy_name}
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        N/A
                                      </td>
                                      <td
                                        style={{
                                          marginLeft: "80px",
                                          textAlign: "center",
                                          padding: "10px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "black",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item.lastRate != null ? (
                                          <i
                                            className="fa fa-rupee"
                                            style={{ marginRight: "14px" }}
                                          ></i>
                                        ) : null}
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            marginRight: "14px",
                                            color: "#060606",
                                          }}
                                        >
                                          {item?.lastRate}
                                          {item?.lastRate !== 0 ||
                                            (item.lastRate != null &&
                                              item?.openRate !== 0 && (
                                                <>
                                                  {"   "}
                                                  {(
                                                    ((item.lastRate -
                                                      item.openRate) /
                                                      item.openRate) *
                                                    100
                                                  ).toFixed(1)}
                                                  %
                                                </>
                                              ))}
                                        </div>
                                      </td>
                                      <td>
                                        <Button2
                                          onClick={(event) => {
                                            console.log("ITEM>>", item, index);
                                            setStockItem(
                                              JSON.parse(item?.entire_object)
                                            );
                                            setStockItemId(item?.id);
                                            handleOpenOptionsDialog(
                                              event,
                                              item
                                            );
                                          }}
                                        >
                                          <i className="fa fa-ellipsis-v"></i>
                                        </Button2>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          onClose={handleCloseOptionsDialog}
                                          PaperProps={{
                                            sx: {
                                              boxShadow:
                                                "0px 1px 5px rgba(0, 0, 0, 0.05)", // Applying shadow to the entire menu
                                            },
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() =>
                                              handleOptionClick(
                                                item,
                                                "View Details"
                                              )
                                            }
                                            sx={{ fontWeight: "500" }}
                                          >
                                            View Strategy Details
                                          </MenuItem>
                                        </Menu>
                                      </td>
                                      <td
                                        onClick={() =>
                                          handleShowOrder(
                                            item.id,
                                            item.script_code
                                          )
                                        }
                                        style={{ paddingRight: "10px" }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="4"
                                    style={{ textAlign: "center" }}
                                  >
                                    No stopped list found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                            {ltpData &&
                            ltpData.filter((item) => item.status === "stopped")
                              .length > 0 ? (
                              <tr>
                                <td colSpan="4">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        textAlign: "right",
                                        padding: "10px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      TOTAL PROFIT/LOSS
                                    </div>
                                    <i
                                      className="fa fa-rupee"
                                      style={{ color: "#83c166" }}
                                    ></i>
                                    <div
                                      style={{
                                        textAlign: "right",
                                        padding: "10px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        paddingRight: "80px",
                                        color: "#83c166",
                                      }}
                                    >
                                      00.00
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                          </>
                        )}
                      </table>
                    </div>
                  </div>{" "}
                </>
              )}
            </div>
            <div className="col-lg-3 col-mg-3 col-sm-12">
              <DeployedBox mode={1} />
            </div>
          </div>
        </div>

        <div className="modal" style={modalStyle} tabIndex="-1" role="dialog">
          <div
            className="modal-dialog"
            style={customDialogStyle}
            role="document"
          >
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ background: "#0e6efd", height: "100px" }}
              >
                <div>
                  <h5 className="modal-title" style={{ color: "white" }}>
                    {buySell == "buy"
                      ? "BUY"
                      : buySell == "sell"
                      ? "SELL"
                      : null}
                  </h5>

                  <h5 className="modal-title" style={{ color: "white" }}>
                    AT MARKET ON
                  </h5>
                </div>

                <button
                  type="button"
                  className="close"
                  onClick={handleClose}
                  style={{
                    fontSize: "2.6rem",
                    color: "blue",
                    padding: "5px",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group" style={{ marginBottom: "30px" }}>
                  <div className="form-group" style={{ marginBottom: "30px" }}>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="optionsGroup1"
                        id="option1"
                        checked={selectedOption === "option1"}
                        onChange={handleOptionChange}
                      />
                      <label className="form-check-label" htmlFor="option1">
                        MIS
                      </label>
                    </div>

                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "25%" }}
                    >
                      <input
                        type="radio"
                        className="form-check-input"
                        name="optionsGroup1"
                        id="option2"
                        checked={selectedOption === "option2"}
                        onChange={handleOptionChange}
                      />
                      <label className="form-check-label" htmlFor="option2">
                        CNC
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="optionsGroup2"
                        id="option3"
                        checked={selectedOption2 === "option3"}
                        onChange={handleOptionChange2}
                      />
                      <label className="form-check-label" htmlFor="option3">
                        MARKET
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "6%" }}
                    >
                      <input
                        type="radio"
                        className="form-check-input"
                        name="optionsGroup2"
                        id="option4"
                        checked={selectedOption2 === "option4"}
                        onChange={handleOptionChange2}
                      />
                      <label className="form-check-label" htmlFor="option4">
                        LIMIT
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="optionsGroup3"
                        id="option3"
                        checked={selectedOption3 === "option3"}
                        onChange={handleOptionChange3}
                      />
                      <label className="form-check-label" htmlFor="option3">
                        SL
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="optionsGroup3"
                        id="option4"
                        checked={selectedOption3 === "option4"}
                        onChange={handleOptionChange3}
                      />
                      <label className="form-check-label" htmlFor="option4">
                        SL-M
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className="form-group row"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="col">
                    <label htmlFor="input1">Qty.</label>
                    <input
                      style={{ marginTop: "12px" }}
                      type="number"
                      className="form-control"
                      id="input1"
                      value={input1}
                      onInput={handleInputNumber}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="input2">Price</label>
                    <input
                      style={{ marginTop: "12px" }}
                      type="number"
                      className="form-control"
                      id="input2"
                      value={input2}
                      onInput={handleInputNumber}
                      disabled={selectedOption2 == "option3" ? true : false}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="input3">Trigger Price</label>
                    <input
                      style={{ marginTop: "12px" }}
                      type="number"
                      className="form-control"
                      id="input3"
                      value={input3}
                      onInput={handleInputNumber}
                      disabled={true}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="input4">Disclosed Qty.</label>
                    <input
                      style={{ marginTop: "12px" }}
                      type="number"
                      className="form-control"
                      id="input4"
                      value={input4}
                      onInput={handleInputNumber}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div
                className="modal-footer"
                style={{
                  border: "none",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    selectedButton2 == 1
                      ? onBuySellLiveClick()
                      : onBuySellPaperClick()
                  }
                >
                  {buySell == "buy" ? "BUY" : "SELL"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <OrderDeployedModal
          show={modalOrderShow}
          setModalOrderShow={setModalOrderShow}
          paperTradingData={paperTradingData}
        />

        <Modal
          centered
          show={showDetails}
          onHide={handleCloseModal}
          dialogClassName="formula-modal"
        >
          <Modal.Header className="pt-4 border-0">
            <Modal.Title className="fs-5 fw-bold px-2">
              {stockItem?.strategy_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">
              <h6 className="mb-3">Entry Condition</h6>
              <div className="discribtion-tab">
                {stockItem && stockItem?.functionText1 ? (
                  // stockItem?.functionText1
                  <span style={{ fontSize: "15px" }}>
                    {stockItem?.position_id === 1 ? "BUY" : "Sell"}{" "}
                    {stockItem?.quantity > 0 && stockItem?.quantity} share
                    when&nbsp;&nbsp;
                    <span className="function-text">
                      {stockItem?.functionText1}
                    </span>
                    at {stockItem?.candle_interval_id} candle interval using
                    candlestick chart.
                    <br />
                    {/* <span style={{ color: "#0173ab", fontWeight: "500" }}>
                    Enter trade between {stockItem?.start_time} to{" "}
                    {stockItem?.end_time} and{" "}
                    {stockItem?.position_id === 1 ? "Sell" : "Buy"}{" "}
                    {stockItem?.quantity} share at stop loss % of{" "}
                    {stockItem?.stop_loss} or target profit % of{" "}
                    {stockItem?.target_profit} at{" "}
                    {stockItem?.candle_interval_id} candle interval using
                    candlestick chart.
                  </span> */}
                  </span>
                ) : (
                  <span className="text-muted">No Entry Condition</span>
                )}
              </div>
              <hr className="my-4" />
            </div>
            <div>
              <h6 className="mb-3">Exit Condition</h6>
              <div className="discribtion-tab">
                {/* {stockItem &&
                (stockItem?.functionText2 ? (
                  stockItem?.functionText2
                ) : (
                  <span className="text-muted">No Exit Condition</span>
                ))} */}
                {stockItem && stockItem?.functionText2 ? (
                  // stockItem?.functionText1
                  <span style={{ fontSize: "15px" }}>
                    {/* {stockItem?.position_id === 1 ? "BUY" : "Sell"}{" "}
                  {stockItem?.quantity} share when&nbsp;&nbsp;
                  <span className="function-text">
                    {stockItem?.functionText2}
                  </span>
                  at {stockItem?.candle_interval_id} candle interval using
                  candlestick chart.
                  <br /> */}
                    <span>
                      Exit trade between {stockItem?.start_time} to{" "}
                      {stockItem?.end_time} and{" "}
                      {stockItem?.position_id === 1 ? "Sell" : "Buy"}{" "}
                      {stockItem?.quantity > 0 && stockItem?.quantity} share at
                      stop loss % of {stockItem?.stop_loss} or target profit %
                      of {stockItem?.target_profit}
                      {stockItem?.functionText2 && (
                        <>
                          &nbsp;
                          <span>or </span>&nbsp;
                          <span className="function-text">
                            {stockItem?.functionText2}
                          </span>
                        </>
                      )}
                      &nbsp; at {stockItem?.candle_interval_id} candle interval
                      using candlestick chart.
                    </span>
                  </span>
                ) : (
                  <span className="text-muted">No Entry Condition</span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  );
};

export default DeployedScreen;
