import React, { useEffect, useState } from "react";
import CustomModal from "./CustomModal";

const DematAccounts = (props) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedInputs, setSelectedInputs] = useState([]);
  const [showCreatePopup, setShowCreatePopup] = useState(false);

  console.log("myprops", props.demataccounts);
  const { demataccounts: accdetails } = props;

  useEffect(() => {
    if (accdetails) {
      displayicons(accdetails);
    }
  }, [accdetails]);

  const handleClickStrategyModal = () => {
    setShowCreatePopup(true);
  };

  const displayicons = async (accdetails) => {
    if (!accdetails) return;

    console.log("WWW", accdetails);
  };

  return (
    <div id="dematAccountsContainer" className="h-100">
      {accdetails &&
      accdetails.FivePaisa?.length === 0 &&
      accdetails.Zerodha?.length === 0 ? (
        <>
          <div className="py-2">
            <h6 className="px-3 pt-1">No linked Demat Account.</h6>
            <p className="px-3">
              To add Demat Account, please{" "}
              <a href="/trading" className="fw-bolder">
                click here
              </a>
            </p>
          </div>
        </>
      ) : (
        <div className="icons-container py-1 mx-3">
          {accdetails.FivePaisa?.length > 0 && (
            <img
              src="/5Paisa.png"
              alt="FivePaisa Icon"
              className="icon-class mx-2"
            />
          )}
          {accdetails.Zerodha?.length > 0 && (
            <img
              src="icon-trading/Zerodha.png"
              alt="Zerodha Icon"
              className="icon-class zerodha-icon mx-2"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DematAccounts;
