import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import NumberInput from "../NumberInput";

const SMAModal2 = ({
  showSMA,
  handleSMAClickedPage,
  setIsShow,
  type,
  typeName,
  mainData,
}) => {
  const [formData, setFormData] = useState(mainData);

  const { field2, periods2, offset2 } = formData;

  const handleClose = () => {
    setIsShow(false);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDone = () => {
    handleSMAClickedPage(formData);
  };

  return (
    <>
      <Modal show={showSMA} onHide={handleClose} animation={false} centered>
        <Modal.Body
          id="create-strategy-modal"
          className="d-flex align-items-center justify-content-center"
        >
          <Form>
            <h5>{typeName}</h5>
            <br />
            {type === "SMA (Simple Moving Average)" && (
              <Row>
                <Col sm="6">
                  <Form.Label>Field</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="field2"
                    value={formData.field2}
                    onChange={handelChange}
                  >
                    <option>Open this select menu</option>
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="high">high</option>
                    <option value="low">low</option>
                    <option value="close">close</option>
                    <option value="volume">volume</option>
                  </Form.Select>
                </Col>
                <Col sm="6">
                  <Form.Label>Period</Form.Label>
                  <NumberInput
                    type="number"
                    value={formData.periods2}
                    name="periods2"
                    onChange={handelChange}
                  />
                </Col>
                <Col sm="6" style={{ marginTop: "20px" }}>
                  <Form.Label>Offset</Form.Label>
                  <NumberInput
                    type="number"
                    value={formData.offset2}
                    name="offset2"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )}

            {type === "Low price" && (
              <Row>
                <Col sm="6">
                  <Form.Label>Offset</Form.Label>
                  <NumberInput
                    type="number"
                    value={offset2}
                    name="offset"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )}

            {type === "Exit" && (
              <Row>
                <Col sm="6">
                  <Form.Label>Field</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="exit_field2"
                    value={formData.exit_field2}
                    onChange={handelChange}
                  >
                    <option>Open this select menu</option>
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="high">high</option>
                    <option value="low">low</option>
                    <option value="close">close</option>
                    <option value="volume">volume</option>
                  </Form.Select>
                </Col>
                <Col sm="6">
                  <Form.Label>Period</Form.Label>
                  <NumberInput
                    type="number"
                    value={formData?.exit_period2}
                    name="exit_period2"
                    onChange={handelChange}
                  />
                </Col>
                <Col sm="6" style={{ marginTop: "20px" }}>
                  <Form.Label>Offset</Form.Label>
                  <NumberInput
                    type="number"
                    value={formData.exit_offset2}
                    name="exit_offset2"
                    onChange={handelChange}
                  />
                </Col>
              </Row>
            )}
            <br />
            <Button variant="primary" onClick={handleDone}>
              Done
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SMAModal2;
