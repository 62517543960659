import React from "react";
import { Card } from "react-bootstrap";

const DailyUsageCard = () => {
    return(
        <Card className="daily-usage-card border-0 ">
            <Card.Body className="daily-usage-card-body rounded">
                <div className="row">
                    <div className="col-10">
                    <Card.Title>
                    <span className="text-primary-active fs-3">50 </span> <span className="text-black-50 off-amount"> / 50 </span>
                        </Card.Title>
                        <Card.Subtitle>Backtests Per Day</Card.Subtitle>
                    </div>
                </div>

                <div className="row">
                    <div className="col-10">
                    <Card.Title className="pt-4">
                        <span className="text-primary-active fs-3">5 </span> <span className="text-black-50 off-amount">/ 5 </span>
                        </Card.Title>
                        <Card.Subtitle  >Concurrent Deployment</Card.Subtitle>
                    </div>
                </div>


                <div className="row ">
                    <div className="col-10">
                    <Card.Title className="pt-4">
                        <span className="text-primary-active fs-3">50 </span><span className="text-black-50 off-amount"> / 50 </span>
                        </Card.Title>
                        <Card.Subtitle>Scans Per Day</Card.Subtitle>
                    </div>

                </div>


               
              
                
            </Card.Body>
        </Card>
    )
}

export default DailyUsageCard;
