// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-list li:hover {
  background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dropdown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,sBAAsB;EACtB,wCAAwC;EACxC,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".dropdown-container {\n  position: relative;\n  display: inline-block;\n}\n\n.dropdown-header {\n  padding: 10px;\n  border: 1px solid #ccc;\n  cursor: pointer;\n}\n\n.dropdown-list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  background-color: #fff;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  border: 1px solid #ccc;\n}\n\n.dropdown-list li {\n  padding: 10px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.dropdown-list li:hover {\n  background-color: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
