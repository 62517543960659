import React from "react";
import { Form } from "react-bootstrap";

const NumberInput = ({
  name,
  value,
  onChange,
  placeholder,
  readOnly,
  disabled,
}) => {
  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    // Only allow numeric characters
    if (
      (charCode < 48 || charCode > 57) && // not a number
      charCode !== 43 && // not a '+'
      charCode !== 45 // not a '-'
    ) {
      event.preventDefault();
    }
  };

  return (
    <Form.Control
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      onKeyPress={handleKeyPress}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

export default NumberInput;
