import React from "react";

const About = () => {
  return (
    <section id="about-page">
      <div className="container container-lg">
        <div className="row">
            <div className="text-center">
                <h1 className="text-primary fw-bold">About Us</h1>
            </div>
        <div className="fw-medium fs-5 py-4">
          <p>
            We are a FINTECH startup based in Pune, India. We are a young and
            dynamic group of talented individuals who are passionate about
            technology and its potential to revolutionize the world of finance.
            Our core focus is on developing an Algorithm Trading Platform that
            is designed to help investors effectively navigate the complex world
            of financial markets.
          </p><br/>
          <p>
            What sets us apart is our commitment to innovation and uniqueness.
            We understand that effective algorithmic trading requires algorithms
            that go beyond conventional strategies. Therefore, we invest
            significant time and effort in developing cutting-edge algorithms
            that are capable of analyzing market data in real-time and making
            accurate predictions.
          </p><br/>
          <p>
            At Coalstone Tech, we understand the importance of agility and
            adaptability in the finance industry. Hence, our Algorithm Trading
            Platform is designed to be highly flexible and customizable. It
            provides our users with the ability to tailor the algorithms to
            their specific investment strategies and risk management parameters.
          </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
