import axios from "axios";

const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "http://192.168.1.7:8008/api/v1";
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";

export const getIndices = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/technical/get_indices`,
      data
    ); 
    // console.log("geting indices", response.data.success )
    if (response.data.success) {
      return response.data.Indices;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const gettechnicalindicator = async (data) => {
  try {
    // console.log("tectDta", data);
    const response = await axios.get(
      `${API_BASE_URL}/technical-indicator`,
      data
    ); 
    if (response.data.success) {
      // console.log("technicalIndicator", response.data)
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const addwatchlist = async (data) => {
  // console.log("addwatchlist@#", data);
  try {
    const response = await axios.post(
      `${API_BASE_URL}/technical/add_watchlist`,
      data
    );
    if (response.data.success) {
      return response.data.success;
    } else {
      return false;
    }
  } catch (error) {
    console.log("getIndices ERROR :", error);
  }
};
// https://dalrotibackend.shrikanttapkeer.com/api/v1/technical/update_watchlist
export const updatewatchlist = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/technical/update_watchlist`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("getIndices ERROR :", error);
    return false;
  }
};
// https://dalrotibackend.shrikanttapkeer.com/api/v1/technical/get_Topmovers
export const getTopMovers = async (data) => {
  try {     
    // console.log("nifty50TopGainers>>", data)
    const response = await axios.post(
      `${API_BASE_URL}/technical/get-nifty50TopGainers`,
      data
    );
   
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error in getting all demat acc", error);
  }
};

export const getMovingAverages = async ({
  api_key,
  access_key,
  scripCode,
  interval,
}) => {
  try {
    // console.log("Calling getMovingAverages with data:", {api_key, access_key,scripCode, interval});
    const response = await axios.post(
      `${API_BASE_URL}/technical/technical-indicator`,
      {
        api_key: api_key,
        access_key: access_key,
        scripCode: scripCode,
        interval: interval,
      }
    );
    // console.log("77", response);
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
    // console.log("moving Average data", response.data)
  } catch (error) {
    console.error("Error in getting moving averages", error);
  }
};

export const getTechnicalChartData = async (data) => {
  try {
    // console.log("calling...", data)
    const response = await axios.post(
      `${API_BASE_URL}/technical/technical-summary`,
      data
    );
    console.log("responsesummary", response);
    if (response.data) {
      console.log("tech-summary", response.data);
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error in getting moving averages", error);
  }
};

export const getPercentageChange = async (data) => {
  try {
    // console.log("calling... percentage", data);
    const response = await axios.post(
      `${API_BASE_URL}/technical/get-percentageChange`,
       data
    );
    console.log("get-percentageCange", response);
    if (response.data.success == true) {
      // console.log("get-percentageCange", response.data.data);
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error in getting percentage change", error);
  }
};
