import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

const ImportContact = () =>{
    return(
        <>
        <section>
        <div className="container-fluid ">
        <div className="py-5">
            <div className="row py-5">
                <div className="col-lg-7 import-contact-h1">
                    <h1 className="h1-header-color align-middle ">
                        Import contacts <br/> from your network
                    </h1>
                </div>
                <div className="col-lg-5 import-conatct-page">
                    <Row className="select-all-radio-btn">
                        <Col sm={10}>
                            <Form.Check // prettier-ignore
                            reverse
                            type="radio"
                            label="Select All"
                            id={`default-radio`}/>
                        </Col>
                    </Row>
                    <Card>
                        <Card.Body>
                            <Form>
                            <div className="row">
                                <div className="col-lg-3">
                                    <img src="/icon-images/profile.png" alt="useImg" />
                                </div>  
                                <div className="col-lg-7 contact-card align-middle">
                                    <Form.Check // prettier-ignore
                                        reverse
                                        label="Cool Name"
                                        id={`default-radio`}
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <img src="/icon-images/profile.png" alt="useImg" />
                                </div>  
                                <div className="col-lg-7 contact-card align-middle ">
                                <Form.Check // prettier-ignore
                                        reverse
                                        label="Cool Name"
                                            
                                            id={`default-radio`}
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <img src="/icon-images/profile.png" alt="useImg" />
                                </div>  
                                <div className="col-lg-7 contact-card align-middle  ">
                                <Form.Check // prettier-ignore
                                        reverse
                                        label="Cool Name"
                                            
                                            id={`default-radio`}
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <img src="/icon-images/profile.png" alt="useImg" />
                                </div>  
                                <div className="col-lg-7 contact-card ">
                                <Form.Check // prettier-ignore
                                        reverse
                                        label="Cool Name"
                                            
                                            id={`default-radio`}
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <img src="/icon-images/profile.png" alt="useImg" />
                                </div>  
                                <div className="col-lg-7 contact-card align-middle ">
                                <Form.Check // prettier-ignore
                                        reverse
                                        label="Cool Name"
                                            
                                            id={`default-radio`}
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <img src="/icon-images/profile.png" alt="useImg" />
                                </div>  
                                <div className="col-lg-7 contact-card align-middle">
                                <Form.Check // prettier-ignore
                                        reverse
                                        label="Cool Name"
                                            
                                            id={`default-radio`}
                                        />
                                </div>
                            </div>  
                            </Form>
                        </Card.Body>
                    </Card>
                    <br />
                    <div>
                         <Button className="btn-color"> Next </Button>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </section>
        </>
    )
}

export default ImportContact;