const calculateAdjustedIndex = (keysBeforeIndex) => {
  const counts = {
    periodCount: 0,
    minCount: 0,
    maxCount: 0,
    symbolCount: 0,
    floorCount: 0,
    ceilCount: 0,
    absCount: 0,
  };
  keysBeforeIndex.forEach((key) => {
    if (/Period/.test(key)) counts.periodCount++;
    if (/Min/.test(key)) counts.minCount++;
    if (/Max/.test(key)) counts.maxCount++;
    if (/Symbol/.test(key)) counts.symbolCount++;
    if (/Floor/.test(key)) counts.floorCount++;
    if (/Ceil/.test(key)) counts.ceilCount++;
    if (/Abs/.test(key)) counts.absCount++;
  });
  let adjustedIndex =
    counts.periodCount * 1 +
    (counts.minCount + counts.maxCount) * 2 +
    counts.symbolCount * 1 +
    counts.floorCount * 1 +
    counts.ceilCount * 1 +
    counts.absCount * 1;
  return adjustedIndex;
};

const filterFunctionsBox777 = (
  formData,
  indexToRemove,
  adjustedIndex,
  instanceType
) => {
  console.log("adjustedIndex", indexToRemove,
    adjustedIndex,
    instanceType )
  let adjustedIndexOffset = instanceType === "body2" ? 2 : instanceType === "body1" ? 1 : 1 ;
  console.log("adjustedIndex", indexToRemove + adjustedIndex + adjustedIndexOffset)
  let result = formData?.functionsBox777?.filter(
    (item, index) =>
      index !== indexToRemove + adjustedIndex + adjustedIndexOffset
  );
  return result;
};
const filterFunctionsBox888 = (
  formData,
  indexToRemove,
  adjustedIndex,
  instanceType
) => {
  let adjustedIndexOffset = instanceType === "body2" ? 2 : 1;
  let result = formData?.functionsBox888?.filter(
    (item, index) =>
      index !== indexToRemove + adjustedIndex + adjustedIndexOffset
  );
  return result;
};
export { calculateAdjustedIndex, filterFunctionsBox777, filterFunctionsBox888 };
