import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { getScannerResultApi } from "../../api/scannerapi";
import { useParams } from "react-router-dom";
import { getUser, getZerodhaToken } from "../../Session";

const ScannerResultPage = () => {
    const { scanid } = useParams();
    const [scannerData, setScannerData] = useState([]);

    const fetchScannerResult = async () => {
        const user = getUser();
        const token = getZerodhaToken();
        const result = await getScannerResultApi({
            id: scanid,
            user_id: user?.id,
            api_key: "3wxj01wpd2rnnn9c",
            access_token: token
        });
        setScannerData(result?.data);
    }

    useEffect(() => { fetchScannerResult() }, []);

    return (
        <>
            <section className="mobile-view">
                <div className="container web-cotainer">
                    <div className="row">
                        <div className="col-lg-12 col-mg-12 col-sm-12">
                            <h3 style={{ color: "#2c308e" }}>
                                <b>Scanners</b>
                            </h3>
                        </div>
                    </div>
                    <br />
                    <Table id="scanner-result-table" responsive="sm">
                        <thead>

                            <tr >
                                <th style={{ width: "30%" }} className="pr-2">Symbol</th>
                                <th style={{ width: "20%" }}>LTP</th>
                                <th style={{ width: "20%" }}>
                                    Volume
                                </th>
                                <th style={{ width: "30%" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {scannerData?.map((item, i) => (
                                <tr className="align-middle" key={i}>
                                    <td >
                                        <span>{item?.stock_name}</span>
                                        <span>{item?.holding_type}</span>
                                    </td>
                                    <td>
                                      
                                        -
                                    </td>
                                    <td>
                                        {/* 5665 */}-
                                    </td>
                                    <td className="text-end">
                                        <Button variant="outline-primary" className="hover-btn"><i className="fa  fa-sliders"></i></Button>&ensp;&ensp;
                                        <Button variant="outline-primary" className="hover-btn"><i className="fa fa-line-chart"></i></Button>&ensp;&ensp;
                                        <Button variant="outline-primary" className="hover-btn">B</Button>&ensp;&ensp;
                                        <Button variant="outline-danger" >S</Button>&ensp;&ensp;
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </section>
        </>
    );
};

export default ScannerResultPage;
