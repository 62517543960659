import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { submitfeedback } from "../../api/profileapi";
import { getUser } from "../../Session";
const SubmitFeedbackModal = ({ showSubmitModal, setShowSubmitModal }) => {
  const handleClose = () => {
    setShowSubmitModal(false);
  };

  return (
    <>
      <Modal
        show={showSubmitModal}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Body
          id="create-strategy-modal"
          className="d-flex align-items-center justify-content-center"
        >
          <Form className="w-75">
            <Form.Group className="mb-3 " controlId="formBasicEmail">
              <Form.Label onClick={() => submitfeedback()}>
                Submit Feedback=
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter your suggestion here..."
              />
              {/* {nameError && (
                <span className="text-error">Name is already exist </span>
              )} */}
            </Form.Group>
            <Button
              className="btn-color"
              variant="primary"
              //   onClick={() => handelClickCreateStrategy(strategiesName)}
              size="sm"
            >
              Send
            </Button>
            <br />
            <Button variant="default" onClick={handleClose} size="sm">
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubmitFeedbackModal;
