import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DiscoverStrategiesChart = ({ item }) => {
  const navigate = useNavigate();
  console.log("item", item)
  const [hoverStrategyCard, setHoverStrategyCard] = useState(0);

  const [series, setSeries] = useState([
    {
      name: "Desktops",
      data: [30, 40, 45, 50, 10, 20, 70, 91, 30, 40, 45, 50],
    },
  ]);
  // let entireData = JSON.parse(item?.strategy_details?.entire_object);
  const [options, setOptions] = useState({
    chart: {
      stroke: {
        curve: "smooth",
      },
      // id: "basic-bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: item?.strategy_name,
      align: "left",
      style: {
        fontWeight: "500",
        fontSize: "16px",
        padding: 0,
        margin: 0,
      },
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
  });

  function getTimeframe(intervalId) {
    const timeframeMap = {
      "1m": "1 Minute",
      "3m": "3 Minutes",
      "5m": "5 Minutes",
      "10m": "10 Minutes",
      "15m": "15 Minutes",
      "30m": "30 Minutes",
      "60m": "60 Minutes",
      "1day": "1 Day",
    };

    return timeframeMap[intervalId] || "";
  }

  return (
    <Card
      className="discover-Scanner pointer-cursor"
      onMouseEnter={() => setHoverStrategyCard(item.id)}
      onMouseLeave={() => setHoverStrategyCard("")}
      style={{
        width: "200px",marginRight:"8px",marginLeft:"0px"
      }}
      onClick={() =>
        navigate(`/strategies/${item?.strategy_id}`, { state: true })
      }
    >
      <Card.Body
        className="fs-6"
        style={{
          backgroundColor: hoverStrategyCard === item.id ? "#FCFCFC":"#f5f5f5",
          overflow: "hidden",
          borderRadius: "6px",
          // margin:"0px 10px"
        }}
      >
        <Card.Title className="text-muted mb-4 fw-bolder" style={{fontSize:"14px",height:"22px"}}>
          {item?.strategy_name?.length>38?`${item?.strategy_name?.slice(0,38)}...`:item?.strategy_name}
        </Card.Title>
        <div className="d-flex justify-content-between">
          <p className="mb-0" style={{fontSize:"11px"}}>{item?.stock_count>1?'stocks:':'stock:'} {item?.stock_count}</p>
            <div className="d-flex justify-content-center align-items-center" 
            style={{backgroundColor:"#27419F",color:"white",width:"70px",height:"20px"}}> 
               <p className="mb-0" style={{fontSize:"11px"}}>{item?.strategy_details?.entire_object &&
                getTimeframe(
                  JSON.parse(item?.strategy_details?.entire_object)
                    ?.candle_interval_id
                  )}</p></div>
          </div>
      </Card.Body>
    </Card>
  );
};

export default DiscoverStrategiesChart;
