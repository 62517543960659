import React from "react";

const Note = () => {
  return (
    <>
      <div className="row" style={{ marginTop: "-12px" }}>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex justify-content-between align-items-center strategy-view-all-warning">
            <span className="fs-4">
              <i
                className="fa fa-info-circle text-danger"
                aria-hidden="true"
              ></i>
            </span>
            &nbsp;&nbsp;
            <span className="text-danger sub-text text-justify p-3">
              Please note, discover strategies are meant for educational
              purposes and are not to be construed as recommendation. Backtest
              results are hypothetical results generated based on the conditions
              used on historical data, and don't represent actual returns or
              future returns.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Note;
