import React from "react";

const ThankYou = () => {
  return (
    <section>
      <div className="container-fluid text-center py-5">
        <div className="row">
          <div className="col-12">
            <h1 className="pt-5 pb-3" style={{ color: "#2d2e8d" }}>
              Nice to meet you!
            </h1>
            </div>
            <div className="col-12">
            <p>
              DalRoti lets you plan and manage your trades without coding.
              <br></br>
              on the go. We have made systematic trading. accessible and
              <br></br>
              affordable at all.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
