import React from "react";

function Contactus() {
  return (
    <div className="container">
      <div>Contactus</div>
    </div>
  );
}

export default Contactus;
