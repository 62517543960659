import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const LogBarChart = ({ data, val, graph }) => {
  const timestamps = data?.map((item) =>
    new Date(item.time_interval).getTime()
  );
  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
        },
        events: {
          dataPointSelection: function (event, chartContext, opts) {
            val(opts.w.globals.labels[opts.dataPointIndex]);
          },
        },
      },

      xaxis: {
        type: "category",
        scrollable: {
          enabled: true,
          offsetX: 0,
          scrollbarHeight: 20,
        },
        labels: {
          datetimeUTC: false,
          formatter: function (value, timestamp) {
            const date = new Date(timestamp);
            return date.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          },
          tickAmount: 4,
        },
      },
      tooltip: {
        x: {
          formatter: function (value) {
            return new Date(value).toLocaleString();
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: 20 + 60 / (1 + 30 * Math.exp(-data.length / 3)) + "%",
          distributed: true,
          endingShape: "flat",
        },
      },
      legend: { show: false },
      dataLabels: {
        enabled: false,
      },
    },
    annotations: {
      points: [
        {
          x: 0,
          y: 0,
          marker: {
            size: 8,
            fillColor: "#fff",
            strokeColor: "#000",
            strokeWidth: 2,
          },
          label: {
            borderColor: "#FF4560",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#FF4560",
            },
            text: " No data found",
          },
        },
      ],
    },
    series: [
      {
        name: "Result Count",
        data: data?.map((item) => ({
          x: item.time_interval,
          y: item.result_count,
        })),
      },
    ],
  });

  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      options: {
        ...prevOptions.options,
        plotOptions: {
          ...prevOptions.options.plotOptions,
          bar: {
            ...prevOptions.options.plotOptions.bar,
          },
        },
      },
      series: [
        {
          name: "Result Count",
          data: data?.map((item) => ({
            x: item.time_interval,
            y: item.result_count,
          })),
        },
      ],
    }));
  }, [data]);
  const nodata = {
    height: "200px",
  };
  return (
    <div className="position-relative">
      {data.length > 0 ? (
        <Chart
          options={chartOptions?.options}
          series={chartOptions?.series}
          type="bar"
          height={350}
        />
      ) : (
        <>
          <div className="text-center pt-3 fw-bolder" style={nodata}>
            No data found
          </div>
        </>
      )}
    </div>
  );
};

export default LogBarChart;
