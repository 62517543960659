import React from "react";

const CreateStockBox = ({ setShowList,setTransparentBackground }) => {
  const handleSelectShowList = () => {
    setShowList(true);
    setTransparentBackground(true)
  }
  return (
    <div
      style={{
        height: "150px",
        width: "150px",
        borderRadius: "10px",
        border: "1px dashed #4a56d9",
        background: "white",
        padding: "17px",
        marginRight: "20px",
        borderStyle: "dashed",
        color : "#4a56d9",
        cursor : "pointer"
      }}
      onClick={handleSelectShowList}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <div>
            <i className="fa fa-plus fs-2" ></i>
        </div>
        <div className="fs-3">
          Add
        </div>
        <div>
            Stock eg,SBIN
        </div>
      </div>

      
    </div>
  );
};
export default CreateStockBox;
