import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const DiscoverStrategiesBox = () => {
  const navigate = useNavigate();
  const exploreCardData = [
    {
      id: 1,
      icon: "/icons/Group.png",
      subHeading: "Make the trend your friend.",
      heading: "Trend Following",
      backgroudColor: "#E7F4FC",
      subHeadingColor: "#3e788f",
      filter: "trend",
      customClass: "img-size",
    },
    {
      id: 2,
      icon: "/icons/price-action.png",
      subHeading: "Price action is king.",
      heading: "Price Action",
      backgroudColor: "#FEECFA",
      subHeadingColor: "#1eff06",
      filter: "price_action",
      customClass: "img-size",
    },
    {
      id: 3,
      icon: "/icons/Mean-Reversion.png",
      subHeading: "The price returns back to the mean.",
      heading: "Mean Reversion",
      backgroudColor: "#FFFFFB",
      subHeadingColor: "#09b8c5",
      filter: "meanreversion",
      customClass: "bg-color-grey",
    },
    {
      id: 4,
      icon: "/icons/Mumentum.png",
      subHeading: "The rate at which price changes.",
      heading: "Momentum",
      backgroudColor: "#DDE8FE",
      subHeadingColor: "#ff7531",
      filter: "momentum",
      customClass: "",
    },
    {
      id: 5,
      icon: "/icons/miscellaneous.png",
      subHeading: "Miscellaneous",
      heading: "Miscellaneous",
      backgroudColor: "#e8e8ff",
      subHeadingColor: "#ff4035",
      filter: "miscellaneous",
      customClass: "",
    },
    {
      id: 6,
      icon: "/icons/pivot-point.png",
      subHeading: "Pivot points",
      heading: "Pivot points",
      backgroudColor: "#ddfff4",
      subHeadingColor: "#01855a",
      filter: "pivot_point",
      customClass: "",
    },
    {
      id: 7,
      icon: "/icons/overlay.png",
      subHeading: "overlays",
      heading: "Overlays",
      backgroudColor: "#DDE8FE",

      // backgroudColor : "#e8e8ff",
      subHeadingColor: "#3838e5",
      filter: "overlays",
      customClass: "",
    },
    {
      id: 8,
      icon: "/icons/options-stratergy.png",
      subHeading: "The versatile ones.",
      heading: "Options Strategies",
      backgroudColor: "#e8e8ff",
      subHeadingColor: "#3838e5",
      filter: "options_strategies",
      customClass: "",
    },
    {
      id: 9,
      icon: "/icons/intraday.png",
      subHeading: "A life lived on the edge.",
      heading: "Intraday",
      backgroudColor: "#ffeeed",
      subHeadingColor: "#ed392e",
      filter: "intraday",
      customClass: "img-size",
    },
    {
      id: 10,
      icon: "/icons/swing-trading.png",
      subHeading: "For the patient ones.",
      heading: "Swing Trading",
      backgroudColor: "#ddfff4",
      subHeadingColor: "#88c297",
      filter: "swing_trading",
      customClass: "",
    },
    {
      id: 11,
      icon: "/icons/Position-trading.png",
      subHeading: "The longest term trading of all",
      heading: "Position Trading",
      backgroudColor: "#FFFFFB",
      subHeadingColor: "#17c704",
      filter: "position_trading",
      customClass: "bg-color-grey",
    },
    {
      id: 12,
      icon: "/icons/Multi-time-frame.png",
      subHeading: "Same but diffrent",
      heading: "Multi Time Frames Analysis",
      backgroudColor: "#E7F4FC",
      subHeadingColor: "#17c704",
      filter: "multi_time_frame_analysis",
      customClass: "",
    },
  ];

  return (
    <>
      <section className="mobile-view discover-strategies">
        <Container className="container web-cotainer ">
          <Row className="title-section">
            <Col lg sm md xs>
              <span>Discover Strategies</span>
            </Col>
          </Row>
          <Row>
            <Col lg md sm xs>
              <span className="fs-5 text-muted"> Explore by Category </span>
              <Link to="/discover" className="pull-right text-decoration-none">
                {" "}
                View All
              </Link>
            </Col>
          </Row>
          <Row className="discover-strategies-cards pt-3">
            {exploreCardData.map((item) => (
              <Col
                key={item.id}
                lg={2}
                md={3}
                xs
                sm={6}
                style={{ marginBottom: "15px" }}
                onClick={() => {
                  navigate(`/discover?filter=${item.filter}`);
                }}
              >
                <Card
                  className="discover-card pointer-cursor"
                  style={{ background: item.backgroudColor }}
                >
                  <Card.Body className="d-flex flex-column p-0">
                    <span>
                      {/* <i className={item.icon}></i> */}
                      <img
                        src={item.icon}
                        alt="img"
                        className={item.customClass}
                      />
                    </span>
                    <span
                      className="sub-text px-3"
                      style={{ color: item.subHeadingColor }}
                    >
                      {item.subHeading}
                    </span>
                    <span className="main-text">{item.heading}</span>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DiscoverStrategiesBox;
