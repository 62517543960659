import React, { useState, useEffect, useMemo } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useMenuContext } from "./StrategyCreateComponent";
import StocksList from "../../mcomponents/StocksListBox";
import "moment/locale/de";
import { get5paisaToken, getZerodhaToken } from "../../Session";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { addNewStrategy, getStockLTPDataAPI } from "../../api/api";
import { useNavigate } from "react-router-dom";
import NumberInput from "../NumberInput";
import AlertShow from "../../mcomponents/AlertShow";
import { checkConditionValid } from "../../Utils/checkConditionValid";
import CustomAlert from "../componentCommon/CustomAlert";

const StrategyReviewComponent = ({
  formData,
  onFormChange,
  stocks,
  setStocks,
  handleDates,
  handleSave,
  handleSaveFalse,
  setCreateStratgyPage,
}) => {
  console.log("DateCheck", formData);
  const { menu, setMenu } = useMenuContext();
  const [showList, setShowList] = useState(false);
  const [initialCapitalError, setInitialCapitalError] = useState(false);
  const [ltpData, setLtpData] = useState();
  const [quantityReadOnly, setQuantityReadOnly] = useState(false);
  const [maxAllocationReadOnly, setMaxAllocationReadOnly] = useState(false);
  const [stockTokens, setStockTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    if (formData.stocks?.length > 0) {
      // setInterval(() => {
      console.log("e_stockTokens???", formData.stocks);
      // getStocksData();
      // }, 2000);
    }
  }, [formData.stocks]);
  useEffect(() => {
    console.log("existingStrategy-->", formData?.strategy_id);
    if (formData.position_sizing_type === "risk based") {
      formData.maxSlPerTrade = formData.max_allocation;
      onFormChange(formData);
    }
  }, []);

  useEffect(() => {
    if (formData.candle_interval_id === "1d")
      onFormChange({
        ...formData,
        holding_type: "CNC/NRML",
      });
  }, [formData.candle_interval_id]);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const addNewStrategey = async () => {
    console.log("formDataCheck>>==", formData);
    try {
      const dataSources = [
        { data: formData?.indicators, source: "entry" },
        { data: formData?.exit_indicators, source: "exit" },
      ].filter(({ data }) => data);
      let isMathFnValid = checkConditionValid(dataSources);
      if (isMathFnValid.length > 0) {
        setLoading(false);
        // alert(isMathFnValid.join("\n"));
        let message = isMathFnValid.join("\n");
        setAlertMessage({ message, type: "alert-primary" });
        return;
      }
      setLoading(true);

      let isHigherArray = [];
      let isHigherValue;
      for (let i = 0; i < formData?.stocks?.length; i++) {
        let value = formData?.stocks[i];
        isHigherValue =
          parseInt(value?.lot_size) *
          parseInt(value?.ltp) *
          parseInt(formData?.quantity);
        if (isHigherValue > parseInt(formData?.initial_capital)) {
          isHigherArray.push(value?.stock_name);
        }
      }
      let flag = 0;
      if (formData.initial_capital === "") {
        flag = 1;
        setInitialCapitalError(true);
      }
      if (flag === 0) {
        const isEmpty = isEmptyObject(formData?.indicators);
        if (isEmpty) {
          setAlertMessage({
            message: "Please add entry condition",
            type: "alert-info",
          });
          setLoading(false);
          return;
        }
        if (stocks?.length === 0 && formData.stocks?.length === 0) {
          setLoading(false);
          setAlertMessage({
            message: "Please select stock",
            type: "alert-danger",
          });
          return;
        }
 
          if(formData?.quantity===0 || formData?.quantity==="")
          {
              if(formData?.position_sizing_type==='capital based' && (formData?.max_allocation === 0 || formData?.max_allocation === ""))
              {
                setAlertMessage({
                  message: "Please select quantity",
                  type: "alert-info",
                });
                setLoading(false);
                return;
              }
              if(formData?.position_sizing_type==='risk based' && (formData?.maxSlPerTrade === 0 || formData?.maxSlPerTrade === ""))
              {
                setAlertMessage({
                  message: "Please select quantity",
                  type: "alert-info",
                });
                setLoading(false);
                return;  
              }
               if((formData?.position_sizing_type==='' || formData.position_sizing_type==='-') 
                && (formData?.max_allocation === 0 || formData?.max_allocation === ""))
               {
                setAlertMessage({
                            message: "Please select quantity",
                            type: "alert-info",
                          });
                          setLoading(false);
                          return;
               }
          }
        const addNew = await addNewStrategy(formData);
        if (addNew) setLoading(false);
        if (
          addNew.strategy_id === undefined ||
          addNew.strategy_id === "undefined"
        ) {
          setAlertMessage({
            message: "Strategy not created successfully, Please try again",
            type: "alert-info",
          }); 
          setCreateStratgyPage(false);
          navigate("/strategies");
        } else {
          navigate("/strategies/" + `${addNew?.strategy_id}`);
          handleSaveFalse();
        }
      }
    } catch (error) {
      console.error("Error fetching:", error);
    }

    setTimeout(() => {
      setLoading(false);
    }, 10000);
  };
  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    if (name === "max_allocation") {
      onFormChange({
        ...formData,
        quantity: 0,
        [name]: parseInt(value) || "",
      });
      value > 0 || value !== ""
        ? setQuantityReadOnly(true)
        : setQuantityReadOnly(false);
    } else if (name === "quantity") {
      onFormChange({
        ...formData,
        max_allocation: 0,
        [name]: parseInt(value) || "",
      });
      value > 0 || value !== ""
        ? setMaxAllocationReadOnly(true)
        : setMaxAllocationReadOnly(false);
    } else {
      onFormChange({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleReviewEnddate = (newValue) => {
    if (newValue) {
      // Format the selected date using Moment.js format
      const formattedDate = moment(newValue).format("YYYY-MM-DD");
      // Update the start_date property in the form data
      onFormChange((prevFormData) => ({
        ...prevFormData,
        end_date: formattedDate,
      }));
      /////////////CALCULATE START DATE////////////////////////
      const startDate = calculateStartDate(formattedDate);
      // formData.end_date = endDate;
      // onFormChange(formData);

      onFormChange((prevFormData) => ({
        ...prevFormData,
        start_date: startDate,
      }));
    }
  };

  const handleReviewStartdate = (newValue) => {
    if (newValue) {
      // Format the selected date using Moment.js format
      const formattedDate = moment(newValue).format("YYYY-MM-DD");
      // Update the end_date property in the form data

      onFormChange((prevFormData) => ({
        ...prevFormData,
        start_date: formattedDate,
      }));
      const endDate = calculateEndDate(formattedDate);
      onFormChange((prevFormData) => ({
        ...prevFormData,
        end_date: endDate,
      }));
    }
  };

  function calculateStartDate(endDate) {
    const end = moment(endDate);
    const currentDate = moment();
    const currentStartDate = moment(formData.start_date);

    if (formData?.candle_interval_id === "1m") {
      const oneMonthEarlier = end.clone().subtract(1, "month");
      // Check if the difference is less than 1 month
      if (end.diff(currentStartDate, "days") < 30) {
        return currentStartDate.format("YYYY-MM-DD");
      }
      // Check if one month earlier is before the current date
      if (oneMonthEarlier.isBefore(currentDate.clone().subtract(1, "month"))) {
        return oneMonthEarlier.format("YYYY-MM-DD");
      } else {
        return currentDate.clone().subtract(1, "month").format("YYYY-MM-DD");
      }
    }

    if (
      formData?.candle_interval_id === "3m" ||
      formData?.candle_interval_id === "5m" ||
      formData?.candle_interval_id === "10m" ||
      formData?.candle_interval_id === "15m" ||
      formData?.candle_interval_id === "30m" 
      // ||
      // (formData?.candle_interval_id === "60m" &&
      //   formData.monthOrWeekAdded === false) ||
      // (formData?.candle_interval_id === "1d" &&
      //   formData.monthOrWeekAdded === false)
    ) {
      const threeMonthsEarlier = end.clone().subtract(3, "month");
      // Check if the difference is less than 3 months
      if (end.diff(currentStartDate, "days") < 90) {
        return currentStartDate.format("YYYY-MM-DD");
      }
      // Check if three months earlier is before the current date
      if (
        threeMonthsEarlier.isBefore(currentDate.clone().subtract(3, "month"))
      ) {
        return threeMonthsEarlier.format("YYYY-MM-DD");
      } else {
        return currentDate.clone().subtract(3, "month").format("YYYY-MM-DD");
      }
    }

    if (
      formData?.candle_interval_id === "60m" 
      // && formData.monthOrWeekAdded === true
    ) {
      const oneYearEarlier = end.clone().subtract(1, "year");
      // Check if the difference is less than 1 year
      if (end.diff(currentStartDate, "days") < 365) {
        return currentStartDate.format("YYYY-MM-DD");
      }
      // Check if one year earlier is before the current date
      if (oneYearEarlier.isBefore(currentDate.clone().subtract(1, "year"))) {
        return oneYearEarlier.format("YYYY-MM-DD");
      } else {
        return currentDate.clone().subtract(1, "year").format("YYYY-MM-DD");
      }
    }

    if (
      formData?.candle_interval_id === "1d" 
      // && formData.monthOrWeekAdded === true
    ) {
      const fiveYearsEarlier = end.clone().subtract(5, "year");
      // Check if the difference is less than 5 years
      if (end.diff(currentStartDate, "days") < 1825) {
        return currentStartDate.format("YYYY-MM-DD");
      }
      // Check if five years earlier is before the current date
      if (fiveYearsEarlier.isBefore(currentDate.clone().subtract(5, "year"))) {
        return fiveYearsEarlier.format("YYYY-MM-DD");
      } else {
        return currentDate.clone().subtract(5, "year").format("YYYY-MM-DD");
      }
    }

    return endDate; // Fallback in case none of the conditions match
  }

  function calculateEndDate(startDate) {
    if (formData?.candle_interval_id === "1m") {
      const oneMonthLater = moment(startDate).add(1, "month");
      const currentDate = moment();
      // Check if one month later exceeds current date
      if (oneMonthLater.isAfter(currentDate)) {
        console.log("in isAfter");
        return currentDate.format("YYYY-MM-DD");
      } else {
        console.log("in isAfter2");

        return oneMonthLater.format("YYYY-MM-DD");
      }
    }
    if (
      formData?.candle_interval_id === "3m" ||
      formData?.candle_interval_id === "5m" ||
      formData?.candle_interval_id === "10m" ||
      formData?.candle_interval_id === "15m" ||
      formData?.candle_interval_id === "30m"
      //  ||
      // (formData?.candle_interval_id === "60m" &&
      //   formData.monthOrWeekAdded === false) ||
      // (formData?.candle_interval_id === "1d" &&
      //   formData.monthOrWeekAdded === false)
    ) {
      const oneMonthLater = moment(startDate).add(3, "month");
      const currentDate = moment();
      // Check if one month later exceeds current date

      if (oneMonthLater.isAfter(currentDate)) {
        return currentDate.format("YYYY-MM-DD");
      } else {
        return oneMonthLater.format("YYYY-MM-DD");
      }
    }
    if (
      formData?.candle_interval_id === "60m" 
      // && formData.monthOrWeekAdded === true
    ) {
      const oneYearLater = moment(startDate).add(1, "year");
      const currentDate = moment();
      // Check if one year later exceeds current date
      if (oneYearLater.isAfter(currentDate)) {
        return currentDate.format("YYYY-MM-DD");
      } else {
        return oneYearLater.format("YYYY-MM-DD");
      }
    }

    if (
      formData?.candle_interval_id === "1d" 
      // && formData.monthOrWeekAdded === true
    ) {
      const fiveYearsLater = moment(startDate).add(5, "year");
      const currentDate = moment();
      // Check if five years later exceeds current date
      if (fiveYearsLater.isAfter(currentDate)) {
        return currentDate.format("YYYY-MM-DD");
      } else {
        return fiveYearsLater.format("YYYY-MM-DD");
      }
    }
  }

  const getSelectedItems = (items) => {
    console.log("items>", items);
    const itemArray = [];
    items.map((i) => {
      itemArray.push(i.token);
    });
    setStocks(items);

    let tokens = items?.map((i) => parseInt(i?.instrument_token));
    console.log("tokens>", tokens);
    setStockTokens(tokens);

    console.log("itemsUpdated2>", items);
    formData.stocks = items;
    onFormChange(formData);
    console.log("JJJJ2", items[0]?.name);
  };
  // const MemoizedSelStockBox = useMemo(() => React.memo(SelStockBox), [stocks]);
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    if (alertMessage) {
      let wpm = 225; // average words reading per minute
      let words = alertMessage?.message?.split(/\s+/).length;
      let timeout = Math.ceil(words / wpm) * 15000;
      setTimeout(() => {
        setAlertMessage(null);
      }, timeout);
    }
  }, [alertMessage]);
  return (
    <>
      {alertMessage && (
        <CustomAlert
          message={alertMessage.message}
          type={alertMessage.type}
          setAlertMessage={setAlertMessage}
        />
      )}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(1.5px)",
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="row" id="uplad-first-stock-div">
        <div className="col-lg-12 backet-parameters-card pt-4 p-0">
          <Card>
            <Card.Body>
              <h6 className="fs-5 fw-6">Backtest Parameters</h6>
              <Form>
                <Row>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">Initial Capital</Form.Label>
                      <NumberInput
                        type="number"
                        placeholder="for eg. 100000"
                        value={formData.initial_capital}
                        name="initial_capital"
                        onChange={handleChangeValues}
                      />
                      {initialCapitalError && (
                        <span className="text-error">
                          {" "}
                          Please enter initial capital
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">
                        Daily strategy cycle
                      </Form.Label>
                      <Form.Select
                        onClick={() => {
                          setAlertMsg(
                            "Cannot select daily strategy cycle for holding type CNC/NRML"
                          );
                          if (formData.holding_type === "CNC/NRML") {
                            setShowAlert(true);
                            setSeverity("error");
                            setTimeout(() => {
                              setShowAlert(false);
                            }, 2000);
                          }
                        }}
                        aria-label="Default select example"
                        value={formData.daily_strategy_cycle}
                        name="daily_strategy_cycle"
                        onChange={
                          formData.holding_type === "CNC/NRML"
                            ? null
                            : handleChangeValues
                        }
                      >
                        <option value="-">-</option>
                        {[...Array(20)].map((_, index) => (
                          <option key={index} value={(index + 1) * 5}>
                            {(index + 1) * 5}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg="6">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="fw-6">
                        Backtesting period
                      </Form.Label>
                      <div className="d-flex pt-1 align-items-center">
                        <div>Start date</div>&nbsp;
                        <input
                          style={{ minWidth: "150px" }}
                          type="date"
                          className="form-control w-25"
                          value={formData?.start_date}
                          onChange={(e) =>
                            handleReviewStartdate(e.target.value)
                          }
                          max={formData?.end_date}
                          onKeyDown={(e) => e.preventDefault()}
                          // onFocus={(e) => e.currentTarget.showPicker()}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <div>End date</div>&nbsp;
                        <input
                          style={{ minWidth: "150px" }}
                          type="date"
                          className="form-control w-25"
                          value={formData?.end_date}
                          onChange={(e) => handleReviewEnddate(e.target.value)}
                          max={moment().format("YYYY-MM-DD")}
                          min={formData?.start_date}
                          onKeyDown={(e) => e.preventDefault()}
                          // onFocus={(e) => e.currentTarget.showPicker()}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label className="fw-6">
                        Strategy Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={formData.description}
                        onChange={handleChangeValues}
                        rows={4}
                        placeholder="Add your strategy description here"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex align-items-end flex-column ">
          <div className="ml-auto p-2">
            <Button
              onClick={() => {
                handleSave(true);
                setTimeout(() => {
                  addNewStrategey();
                }, 2500);
              }}
              className="btn-color"
            >
              Save and Backtest
            </Button>
          </div>
        </div>
      </div>
      <div>
        {showList && (
          <StocksList
            storeSelectedItems={getSelectedItems}
            setShowList={setShowList}
            selectedStock={stocks}
            formData={formData}
          />
        )}
      </div>

      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </>
  );
};

export default StrategyReviewComponent;
