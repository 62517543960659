import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {Gettingcontactdetails} from "../api/profileapi";
function Contact() {
  const navigate = useNavigate();
 const [formdata,setFormdata]=useState({
  name:'',
  email:'',
  company:'',
  aprx_budget:'',
  optional:'',
 })
 const validateFormData = () => {
  const { name, email, company, aprx_budget } = formdata;
  if (!name || !email || !company || !aprx_budget) {
      return false;
  }
  return true;
};

  const para = {
    color: "gray"
  };
  const para1 = {
    color: "gray",
    fontWeight: "600"
  };
  const input = {
    width: "45%",
    height: "40px",
    marginTop: "10px",
    backgroundColor: "#f5f5f5",
    border: "1px solid rgb(187 177 177)",
    display: "flex",
    alignItems: "center",
    borderRadius: "7px",
    paddingLeft: "20px"
  };
  const signupbtn = {
    height: "40px",
    width: "300px",
    marginTop: "50px",
    backgroundColor: "#1a60b5",
    border: "none",
    borderRadius: "6px",
    color: "white",
    fontSize: "18px",
    margin: "0px auto"
  };
  const box = {
    height: "230px"
  };
  const [form, setForm] = useState(false);
 
  const handleChange=(e)=>{
    const {name,value}=e.target;
    setFormdata({
      ...formdata,[name]:value
    });
  }
  const thankyoupg = async() => {
    if (!validateFormData()) {
          alert("Please fill in all the details")
  }
  else
  {
    const result=await Gettingcontactdetails(formdata);
    if(result===true)
        alert("Contact sent successfully...");
        navigate("/thankyou");
  };
}
  return (
    <div>
      <section>
        <>
          <div className="container py-3">
            <div className="text-center py-5">
              <h1
                className="fw-bolder pt-5 mt-5"
                style={{ color: "#2d2f93", fontSize: "40px" }}
              >
                Get in touch
              </h1>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div>
                    <h2
                      className="fw-bolder"
                      style={{ color: "#2d2f93", fontSize: "35px" }}
                    >
                      What will <br></br> be next step?
                    </h2>
                    <p style={para} className="pt-2">
                      You are one step closer to build<br></br> your perfect
                      product
                    </p>
                    {/* <img src="vline.png"></img> */}
                    <div className="d-flex">
                      <img src="vline.png" style={{ height: "280px" }} alt="nt found"></img>
                      <div>
                        <h6 className="mt-1 ps-2">
                          1. We'll prepare a proposal
                        </h6>
                        <p style={para}>
                          You are one step closer<br></br> to build your
                          perfect.
                        </p>

                        <h6 className="mt-5 ps-2">
                          2. We'll prepare a proposal
                        </h6>
                        <p style={para}>
                          You are one step closer<br></br> to build your
                          perfect.
                        </p>

                        <h6 className="mt-5 ps-2">
                          3. We'll prepare a proposal
                        </h6>
                        <p style={para}>
                          You are one step closer<br></br> to build your
                          perfect.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <div
                    className="ps-4 pt-4"
                    style={{
                      padding: "6px",
                      width: "90%",
                      margin: "0px auto",
                      // border: "1px solid red",
                      boxShadow: "4px 4px 15px -8px"
                    }}
                  >
                    <p style={para1}>
                      Write us a few words about your project <br></br> and we
                      will prepare a proposal
                    </p>
                    <div className="d-flex">
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={formdata.name}
                        className="pt-0" onChange={handleChange}
                        style={input}
                      ></input>
                      <input onChange={handleChange}
                        className="ms-2 pt-0"
                        type="text"
                        name="email"
                        value={formdata.value}
                        placeholder="Email"
                        style={input}
                      ></input>
                    </div>

                    <div className="d-flex">
                      <input onChange={handleChange}
                        type="text"
                        placeholder="Company"
                        name="company"
                        value={formdata.company}
                        className="pt-0"
                        style={input}
                      ></input>
                      <input onChange={handleChange}
                        className="ms-2 pt-0"
                        type="text"
                        name="aprx_budget"
                        value={formdata.aprx_budget}
                        placeholder="Aprx Budget"
                        style={input}
                      ></input>
                    </div>
                    {/* {/ <label>Optional</label> /} */}
                    <h6 className="mt-2">Optional</h6>
                    <textarea name="optional"
                        value={formdata.optional} 
                        onChange={handleChange}
                      rows={10}
                      style={{
                        height: "200px",
                        width: "90%",
                        backgroundColor: "#f5f5f5",
                        orderRadius: "5px",
                        borderColor: "#bec1c0"
                      }}
                    ></textarea>
                    <div className="text-center mt-3 mb-2">
                      <button
                        className="btn btn-primary btn-color"
                        onClick={thankyoupg}
                      >
                        &emsp; &emsp; &emsp;&emsp;Send&emsp;&emsp; &emsp; &emsp;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="contact-box">
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-lg-4 box-1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div
                    className="box"
                    style={{ backgroundColor: "#BCE6FF", borderRadius: 5 }}
                  >
                    <img
                      src="knowledge.png"
                      alt="Description of the image"
                      width="40"
                      height="40"
                    />

                    <h3>
                      <i className="bx bx-bulb"></i>
                    </h3>
                    <h4>
                      <span style={{ fontWeight: "bold" }}>Knowledge Base</span>
                    </h4>
                    <p style={{ color: "#7D828C" }}>
                      You are one step closer to build your perfect
                    </p>
                    <a
                      href="#"
                      className="buy-btn"
                      style={{ fontWeight: "bold", color: "#6F8EB9" }}
                    >
                      Read More
                    </a>
                  </div>
                </div>

                <div
                  className="col-lg-4 mt-4 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div
                    className="box featured"
                    style={{ background: "#d8c1f7", borderRadius: 5 }}
                  >
                    <img
                      src="knowledge.png"
                      alt="Description of the image"
                      width="40"
                      height="40"
                    />
                    <h3>
                      <i className="bx bx-bulb"></i>
                    </h3>
                    <h4>
                      <span style={{ fontWeight: "bold" }}>Knowledge Base</span>
                    </h4>
                    <p style={{ color: "#7D828C" }}>
                      You are one step closer to build your perfect
                    </p>
                    <a
                      href="#"
                      className="buy-btn"
                      style={{ fontWeight: "bold", color: "#6F8EB9" }}
                    >
                      Read More
                    </a>
                  </div>
                </div>

                <div
                  className="col-lg-4 mt-4 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div
                    className="box"
                    style={{ background: "#BBD5F4", borderRadius: 5 }}
                  >
                    <img
                      src="knowledge.png"
                      alt="Description of the image"
                      width="40"
                      height="40"
                    />
                    <h3>
                      <i className="bx bx-bulb"></i>
                    </h3>
                    <h4>
                      <span style={{ fontWeight: "bold" }}>Knowledge Base</span>
                    </h4>
                    <p style={{ color: "#7D828C" }}>
                      You are one step closer to build your perfect
                    </p>
                    <a
                      href="#"
                      className="buy-btn"
                      style={{ fontWeight: "bold", color: "#6F8EB9" }}
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="ps-5">
            <div className="ps-5">
          <h3 className="fw-bolder py-3" style={{ color: "#2d2f93", fontSize: "40px" }}>Contact Us:</h3>
<p style={{ color: "#7D828C" }}>Last updated on Oct 5th, 2023.</p>
<br></br>
<p style={{ color: "#7D828C" }}>You may contact us using the information below:</p>
<br></br>
<p style={{ color: "#7D828C" }}>Merchant Legal entity name: COALSTONE TECH PRIVATE LIMITED</p>
<br></br>
<p style={{ color: "#7D828C" }}>E-Mail ID: support@dalroti.tech</p>
</div>
                        
          </div>
        </>
      </section>
    </div>
  );
}

export default Contact;
