import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { zerodhaDeleteAccount } from "../../api/zerodhaapi";

const ZerodhaDetails = (props) => {
    const {zerodhaData , setIsAccountAdd ,setShow, setConfirmationMsg, isDeleteAccount ,setIsDeleteAccount ,featchAccountDetails} = props;
    const handelDeleteAccountConfirmation = async () => {
        setConfirmationMsg("Are you sure you want to delete this account ?");
        setShow(true);
    }

    const handelDeleteAccount = async () => {
        try{
            await zerodhaDeleteAccount({id : zerodhaData.id});
            setShow(true);
            setIsAccountAdd('newAccount');
            featchAccountDetails();
        }catch(error){
            console.log("Error : ", error);
            }
        setIsDeleteAccount(false);
        setShow(false);
        }

    useEffect(() => {
        console.log()
        if(isDeleteAccount){
            handelDeleteAccount();
        }
        console.log("Check the data...",);
    }, [isDeleteAccount]);


    return(
    <>
    <div className="col-lg-9">
        <div className="info-div">
            <h2 className="mobile-title">Zerodha Registartion</h2>
            <div className="card mb-4">
                <div className="card-body">
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextAppName">
                    <Form.Label column sm="3">
                    App Key
                    </Form.Label>
                    <Col sm="9">
                    <Form.Control plaintext readOnly value={zerodhaData?.api_key} />
                    </Col>
                </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                    App Secrete key
                    </Form.Label>
                    <Col sm="9">
                    <Form.Control plaintext readOnly value={zerodhaData?.api_secret} />
                    </Col>
                </Form.Group>
                <Row className="justify-content-md-center">
                <Col xs lg="2">
                    <Button variant="primary" onClick={() => setIsAccountAdd("signupform")}>Update</Button>
                </Col>
                <Col xs lg="2">
                    <Button variant="danger" onClick={handelDeleteAccountConfirmation}>Delete</Button>
                </Col>
                </Row>
                </Form>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default ZerodhaDetails;