import React, { useState } from "react";
import { Card } from "react-bootstrap";
import PartialCompleteSideBar from "../components/PartialCompleteSideBar";

const PartialComplete = () => {
  const [visibleTab , setVisibleTab] = useState("");
  
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer">
            
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-12">
                <div className="row">
                    <div className="col-12">
                        <h3 className="fw-bold">
                          <i className="fa fa-chevron-left chev fs-5 pointer-cursor"></i> &nbsp;&nbsp;
                            Pick up where you left off
                        </h3>
                    </div>
                </div>
                <div className="row mt-3" id="partial-completed-progress">
                  <div className="col-4 d-flex" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <Card>
                      <Card.Body className="p-0">
                        <div className="d-flex">
                            <div className="d-flex flex-column w-75 p-3 ">
                                <span className="fs-5 ">stategy first</span>
                                <span className="sub-text">Scanner Completed </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-center w-25 success-div p-3">
                                <span className="fs-4">
                                  75%
                                </span>
                            </div>
                        </div>
                      </Card.Body>
                    </Card>
                    {isHovered && 
                    <div className="d-flex align-items-center px-3 delete-tab">
                        <i className="fa fa-trash text-danger fs-4"></i>
                    </div>
                    }
                  </div>


                  <div className="col-4 d-flex" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <Card>
                      <Card.Body className="p-0">
                        <div className="d-flex">
                            <div className="d-flex flex-column w-75 p-3 ">
                                <span className="fs-5 ">stategy first</span>
                                <span className="sub-text">Scanner Completed </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-center w-25 pending-div p-3">
                                <span className="fs-4">
                                  25%
                                </span>
                            </div>
                        </div>
                      </Card.Body>
                    </Card>
                    {isHovered && 
                    <div className="d-flex align-items-center px-3 delete-tab">
                        <i className="fa fa-trash text-danger fs-4"></i>
                    </div>  
                      }
                  </div>


                  <div className="col-4 d-flex" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <Card>
                      <Card.Body className="p-0">
                        <div className="d-flex">
                            <div className="d-flex flex-column w-75 p-3 ">
                                <span className="fs-5 ">stategy first</span>
                                <span className="sub-text">Scanner Completed </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-center w-25 partial-div p-3">
                                <span className="fs-4">
                                  55%
                                </span>
                            </div>
                        </div>
                      </Card.Body>
                    </Card>
                    {isHovered && 
                    <div className="d-flex align-items-center px-3 delete-tab">
                        <i className="fa fa-trash text-danger fs-4"></i>
                    </div> }
                  </div>


                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12" id="partial-complete-side-bar">
                  <PartialCompleteSideBar />
              </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default PartialComplete;
