import { calculateAdjustedIndex, filterFunctionsBox777 } from "../indextoRemoveMath";

const handleRemoveCondition = (
    formData,
    onFormChange,
    instanceToRemove,
    indexToRemove,
    mInstance,
    setMathIndexRemove,
    setRemovedInsideMath,
    isIncludeMathFunction
) => {
  const validFunctions = [
    "Period min1",
    "Period max1",
    "Symbol1",
    "Floor1",
    "Ceil1",
    "Abs1",
  ];
  const additionalValidFunctions = ["Max1", "Min1"];
  if (validFunctions?.includes(instanceToRemove)) {
    console.log(
      "instanceToRemove??",
      instanceToRemove,
      indexToRemove,
      formData?.indicators[instanceToRemove]?.body[indexToRemove]
    );

    const updatedIndicators = { ...formData?.indicators };

    updatedIndicators[instanceToRemove]?.body.splice(indexToRemove, 1);

    let filteredBox = formData?.functionsBox?.filter(
      (item, index) => index !== indexToRemove
    );
    let keyToFind = instanceToRemove;
    const keys = Object.keys(formData?.indicators);
    const indexRemove = keys.indexOf(keyToFind);
    console.log("keyToFind", indexRemove);
    const keysBeforeIndex = keys.slice(0, indexRemove );
    let adjustedIndex = calculateAdjustedIndex(keysBeforeIndex);
    console.log("adjustedIndex", adjustedIndex, keysBeforeIndex )
    let filteredBox2 = filterFunctionsBox777(
      formData,
      indexRemove,
      adjustedIndex,
      "body"
    );
    console.log("adjustedIndex", filteredBox2)
    setMathIndexRemove(indexRemove + adjustedIndex + 1);
    formData.functionsBox = filteredBox;
    formData.functionsBox777 = filteredBox2;
    console.log("testremove>", formData);
    setRemovedInsideMath(true);
    onFormChange(formData);
    onFormChange((prevState) => ({
      ...prevState,
      indicators: updatedIndicators,
    }));
  } else if (additionalValidFunctions?.includes(instanceToRemove)) {
    console.log(
      "instanceToRemovemax",
      instanceToRemove,
      indexToRemove,
      formData?.indicators[instanceToRemove],
      mInstance
    );
    const updatedIndicators = { ...formData?.indicators };

    updatedIndicators[instanceToRemove][mInstance]?.splice(indexToRemove, 1);

    let filteredBox = formData?.functionsBox?.filter(
      (item, index) => index !== indexToRemove
    );
    let keyToFind = instanceToRemove;
    const keys = Object.keys(formData?.indicators);
    const indexRemove = keys.indexOf(keyToFind);
    const keysBeforeIndex = keys.slice(0, indexRemove );
    let adjustedIndex = calculateAdjustedIndex(keysBeforeIndex);
    let filteredBox2 = filterFunctionsBox777(
      formData,
      indexRemove,
      adjustedIndex,
      mInstance
    );
    setMathIndexRemove(
      indexRemove + adjustedIndex + (mInstance === "body2" ? 2 : 1)
    );
    formData.functionsBox = filteredBox;
    formData.functionsBox777 = filteredBox2;
    console.log("testremove>", formData, filteredBox2);
    onFormChange(formData);
    setRemovedInsideMath(true);
    onFormChange((prevState) => ({
      ...prevState,
      indicators: updatedIndicators,
    }));
  } else {
    // Make a copy of the formData?.indicators object to avoid directly modifying the state
    const updatedIndicators = { ...formData?.indicators };

    // Check if the instanceToRemove is an "and" instance
    if (updatedIndicators.hasOwnProperty(instanceToRemove)) {
      // Remove the "and" instance
      delete updatedIndicators[instanceToRemove];
    } else {
      // Remove the regular instance
      Object.keys(updatedIndicators).forEach((key) => {
        if (updatedIndicators[key].hasOwnProperty(instanceToRemove)) {
          delete updatedIndicators[key][instanceToRemove];
        }
      });
    }
    if (isIncludeMathFunction.current) {
      let filteredBox = formData?.functionsBox?.filter(
        (item, index) => index !== indexToRemove + 1
      );
      let filteredBox2 = formData?.functionsBox777?.filter(
        (item, index) => index !== indexToRemove + 1
      );

      formData.functionsBox = filteredBox;
      formData.functionsBox777 = filteredBox2;
    } else {
      let filteredBox = formData?.functionsBox?.filter(
        (item, index) => index !== indexToRemove
      );
      let filteredBox2 = formData?.functionsBox777?.filter(
        (item, index) => index !== indexToRemove
      );
      formData.functionsBox = filteredBox;
      formData.functionsBox777 = filteredBox2;
    }

    onFormChange(formData);
    // Update the state with the new indicators object
    onFormChange((prevState) => ({
      ...prevState,
      indicators: updatedIndicators,
    }));

    console.log("formData.functionsBox777?", formData.functionsBox777);
  }
};

export default handleRemoveCondition;
