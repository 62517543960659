import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Paisa5login from "./Paisa5login";
// import Paisa5login from "../components/dashboardComponent/Paisa5login";


const Paisa5LoginModal = ({
  showCreatePopup,
  handelClickCreateStrategy,
  setShowCreatePopup,
  paisa5data,
  connectBtn,
  handleClose,
  handleChangeConnect,
  setActiveaccount,
  setMyacc,
  setActiveUser,state
}) => {


  return (
    <>
      <Modal
        show={showCreatePopup}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Body
        //   id="create-strategy-modal"
        //   className="d-flex align-items-center justify-content-center"
        >
          <Paisa5login
            setShowCreatePopup={setShowCreatePopup}
            handleClose={handleClose}
            showCreatePopup={showCreatePopup}
            paisa5data={paisa5data}
            connectBtn={connectBtn}
            handleChangeConnect={handleChangeConnect} 
            setActiveaccount={setActiveaccount}
            setActiveUser={setActiveUser} 
            setMyacc={setMyacc}
            state={state}/>
            
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Paisa5LoginModal;