import React, { useCallback, useState } from "react";
import BuyCard from "../mcomponents/BuyCard";
import { Col, Row } from "react-bootstrap";
import { getUser, savePlanSession } from "../Session";
import {
  freeSubscribeApi,
  paymenHandler,
  requestPaymentApi,
  getActivePlanApi,
} from "../api/dashboardApi";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SubscribePlan = () => {
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(1000);

  const handelSubscribeFreeBtn = useCallback(async () => {
    const userData = getUser();
    console.log("EEE", userData);
    const responce = await freeSubscribeApi({ user_id: userData?.id });
    if (responce) {
      console.log("Free subscribe", responce);
      const d = await getActivePlanApi({ user_id: userData?.id });
      console.log("ACTIVEPLAN", d[0]);
      savePlanSession(d[0]);
      navigate("/dashboard");
    }
  }, [getUser, freeSubscribeApi]);

  const handelSubscribePrimeBtn = useCallback(async () => {
    const userData = getUser();
    console.log("userData", userData);
    const data = {
      amount: "1000",
      user_id: userData?.id,
      plan_id: 2,
    };
    const order = await requestPaymentApi(data);

    var options = {
      key: order.key,
      name: "DaalRoti",
      description: "",
      image: "/logo.png",
      order_id: order.orderId,
      handler: async function (response) {
        const userPaymentData = {
          paymentResponse: response,
          plan_id: 2,
          user_id: userData?.id,
          amount: "1000",
        };
        const res = await paymenHandler(userPaymentData);
        console.log("Myresult", res?.result);
        if (res.success) {
          savePlanSession(res.result?.getPlanDetails);
          navigate("/dashboard");
        } else {
          alert("Some error occurred. Please contact support");
        }
        // axios({
        //     url: 'https://dalrotibackend.shrikanttapkeer.com/api/v1/sales/confirm',
        //     method: 'POST',
        //     dataType: 'json',
        //     data: {
        //         paymentResponse: response,
        //         plan_id: 2,
        //         user_id : userData?.id,
        //         amount : "1000",
        //     },
        // }).then(function (responsedata){
        //     console.log("response",responsedata);
        //     if(responsedata.data?.success){
        //         navigate('/dashboard');
        //     }else{
        //         alert('Some error occurred. Please contact support');
        //     }
        // })
      },
      prefill: {
        name: userData?.fname + " " + userData?.lname,
        email: userData?.email,
        contact: userData?.phone,
      },
    };
    var rzp = new Razorpay(options);
    rzp.open();
  }, [Razorpay]);

  return (
    <>
      <section className="mobile-view" id="subscribe">
        <div className="container web-cotainer">
          <div className="subscribe-title">
            <center>
              <h1>Subscription Plan</h1>
            </center>
          </div>
          <Row>
            <Col lg={6} md={6} xs={12} sm={12}>
              <BuyCard
                planName="Free"
                className="free-subscribe"
                price="0"
                handelSubscribeBtn={handelSubscribeFreeBtn}
              />
            </Col>
            <Col lg={6} md={6} xs={12} sm={12}>
              <BuyCard
                planName="Premium"
                className="prime-subscribe"
                price={amount}
                handelSubscribeBtn={handelSubscribePrimeBtn}
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default SubscribePlan;
