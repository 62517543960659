import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
export default function SwipeableTemporaryDrawer({
  anchor,
  open,
  toggleDrawer,
  mycontent,
  nm,
}) {
  const navigate = useNavigate();
  const icon = {
    height: "30px",
    width: "30px",
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFiltercontent] = useState([]);
  const clr = nm === "topGainers" ? "text-success" : "text-danger";
  const p =
    nm === "topGainers" ? <i className="fa fa-plus text-success"></i> : null;
  const formatNumber = (value, fixed) => {
    const number = typeof value === "number" ? value : parseFloat(value);
    return isNaN(number) ? "N/A" : number.toFixed(fixed);
  };
  const handleClickOutside = (event) => {
    if (event.target.tagName !== "INPUT") {
      toggleDrawer(false)(event);
    }
  };
  const handleStockClick = (stock) => {
    navigate(
      `/symbol/?id=${stock?.instrument_token}&itemName=${stock?.stock_name}`
    );
    // alert("P");
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    e.stopPropagation();
  };
  useEffect(() => {
    const filtered = mycontent?.filter((item) =>
      item.stock_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFiltercontent(filtered);
  }, [mycontent,searchTerm]);
  const content = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 340 }}
      role="presentation"
      onClick={handleClickOutside}
      //   onKeyDown={toggleDrawer(false)}
    >
      <div className="position-sticky top-0 bg-white zIndex-9999">
        <h4 className="pt-3 text-center">
          {nm === "topGainers" ? "Top Gainers" : "Top Losers"}
        </h4>
        {/* <label>Search</label>   */}
        <div className="w-100 text-center">
          <input
            className=" my-2 "
            type="text"
            placeholder="Enter Stock name here..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              borderRadius: "12px",
              border: "1px solid lightgray",
              padding: "8px",
              paddingLeft: "10px", // Adjust to accommodate the icon width and spacin
              height: "30px",
              width: "90%",
            }}
            // onChange={(event) => }
          />
        </div>
      </div>
      <Typography variant="h6" sx={{ p: 2 }}>
        <div className="ps-1 pt-2">
          {filter?.map((item) => {
            const lastPriceFormatted = formatNumber(item?.last_price, 3);
            const diffFormatted = formatNumber(item?.percentageChange, 3);

            return (
              <>
                {/* <hr></hr> */}
                <div
                  className="py-1 d-flex justify-content-between"
                  onClick={() => handleStockClick(item)}
                >
                  <div className="d-flex">
                    <img
                      src="stockPlaceholder.png"
                      style={icon}
                      alt="nt found"
                    ></img>
                    <div className="ps-2" style={{ fontSize: "15px" }}>
                      {item.stock_name}
                    </div>
                  </div>
                  <div className={`ps-2 ${clr} text-start`}>
                    <div className="mb-0 " style={{ fontSize: "13px" }}>
                      <i className="fa fa-rupee pe-1 "></i>
                      {lastPriceFormatted}
                    </div>
                    <div>
                      <div className="d-inline" style={{ fontSize: "10px" }}>
                        {diffFormatted}%
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        {/* {mycontent.map((item)=>{
        <div>{item.stock_name}</div>
       })} */}
      </Typography>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      {content(anchor)}
      <div>{mycontent?.stock_name}</div>
    </SwipeableDrawer>
  );
}
