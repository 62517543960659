import React, { useEffect, useMemo, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Paisa5Margin from "./Paisa5Margin";
import Paisa5Holdings from "./Paisa5Holdings";
import Paisa5Position from "./Paisa5Position";
import { getHoldingsApi, getMarginApi, getPositionsApi } from "../../api/paisa5api";
import { Button, Card, CardBody, ListGroup, Table } from "react-bootstrap";

const Paisa5HMPDetails = () => {
    const [key, setKey] = useState('holding');
    const [margin , setMargin ] = useState('');
    const [position , setPosition ] = useState('');
    const [holdings , setHoldings] = useState('');  
   
    const featchAccountHMP = async () => {
        const marginData = await getMarginApi();
        const positionData = await getPositionsApi();
        const holdingsData = await getHoldingsApi();
        setMargin(marginData);
        setPosition(positionData);
        setHoldings(holdingsData);
    }

    useEffect(()=>{
        // featchAccountHMP();
    },[]);

    const memoizedHoldingsComponent = useMemo(() => <Paisa5Holdings holdingData={holdings} />, [holdings]);
    const memoizedPositionComponent = useMemo(() => <Paisa5Position positionData={position} />, [position]);

  return (
    <>
      <Button variant="light" size="sm">Positions</Button>
      <Button variant="light" size="sm">Holdings</Button>
      <br />

      <Table>
        <thead className="holding-thead">
          <th>
            Instruments
          </th>
          <th>
            Qty
          </th>
          <th>LTP</th>
          <th>P&L</th>
        </thead>
        {/* <tbody>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
        </tbody> */}
      </Table>

      <Card className="holding-card">
        <Card.Body>
        <Card.Title className="protfolio-card-title"><i className="fa fa-folder-open-o"></i></Card.Title>
        <Card.Subtitle className="mb-2 ">Nothing to see here</Card.Subtitle>
        <Card.Text className="text-muted">
            Take a live position to see your position here
        </Card.Text>
        <Card.Link className="link" href="#">Go to marketwatch </Card.Link>&nbsp;&nbsp; <i className="fa fa-chevron-right"></i>
        </Card.Body>
      </Card>
      {/* <Card style={{ width: '18rem' }}>
        <CardBody className="card-margin-body">
        <span className="margin-amount">Avilabale Margin &nbsp;&nbsp;
           -3965 &nbsp;&nbsp;<span className="fa fa-rupee"></span></span>
        </CardBody>
      </Card>
      <br/>
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        id="fill-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="holding" title="Holdings">
          {memoizedHoldingsComponent}
        </Tab>
        <Tab eventKey="position" title="Position">
          {memoizedPositionComponent}
        </Tab>
      </Tabs> */}
    </>
  );
};

export default Paisa5HMPDetails;
