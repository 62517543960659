import React, { useEffect, useState } from 'react'
import { GetBlogs } from '../api/dashboardApi';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
function Blogs() {
  const navigate=useNavigate();
  const [myblogs,setBlogs]=useState([])
    const blogs={
        paddingTop:"150px",
        paddingBottom:"50px"
    }
    // ${blog.title.substring(0,60)}...
    useEffect(()=>{
      const fetchblog=async()=>{
        const result=await GetBlogs();
        setBlogs(result);
        console.log("first",result)
      }
        fetchblog();
    },[])
  return (
    <div className='container' style={blogs}>
    <div className="container">
      <h2 className='py-3'>Blogs</h2>
      <div className="row">
        {myblogs ? (myblogs?.map((blog, index) => (
          // const parseddescription=parse(blog.description);
          <div className="col-md-4 mb-4 pointer-cursor" key={index}>
            <div className="card"  onClick={() => navigate(`/blogdetails/${blog?.id}`)}>
              <img
                src={blog.blog_img ? `https://dalrotibackend.shrikanttapkeer.com/${blog.blog_img}` : '/defaultblogimg.jpg'}
                className="card-img-top"
                alt={blog.title}
                style={{ height: '200px', objectFit: 'cover' }}
              />
              <div className="card-body mb-2" style={{height:"220px"}}>
                <h5 className="card-title" style={{height:"80px"}}>
                  {blog.title?.length>60 ? 
                  (<>{`${blog.title.substring(0,60)}...`}<span style={{cursor:"pointer",color:"black",fontSize:"12px"}}>Read More</span></>):(blog.title)}
                  </h5>
                <p className="card-text" onClick={() => navigate(`/blogdetails/${blog.id}`)}>
                {blog.description.length>150 ?
                (<>{parse(`${blog.description.substring(0,110)}...`)}
                {/* <span style={{cursor:"pointer",fontSize:"10px"}}>Read More</span> */}
                  </>):(blog.description)}
             </p>
             {/* {parse(blog?.description)} */}
              </div>
            </div>
          </div>
        ))):(<div>No Blogs to display</div>) }
        {/* {} */}
      </div>
    </div>
</div>
  )
}

export default Blogs