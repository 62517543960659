import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const DiscoverSideBar = () => {
  const navigate = useNavigate();

  const navigaeToLocation = (filter_name) => {
    const url = `/discover?filter=${filter_name}`;
    navigate(url);
  };

  return (
    <>
      <Row className="mb-2">
        <Col xs>
          <span className="fw-bold">Discover</span>
          <Link
            to="/discover/explore"
            className="pull-right text-decoration-none"
          >
            {" "}
            View all
          </Link>
        </Col>
      </Row>
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(1);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/Group.png" alt="trend-following" />
          {/* <i className="fa fa-plus"></i> */}
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Trend following</span>
          <span className="sub-title">Make the trend your friend</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(2);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/price-action.png" alt="Price-Action" />

          {/* <i className="fa fa-plus"></i> */}
        </Col>
        <Col lg={10} md={3} className="d-flex flex-column">
          <span className="title">Price Action</span>
          <span className="sub-title">Price action is king.</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(3);
        }}
      >
        <Col lg={2} md={2}>
          <img
            src="/icons/Mean-Reversion.png"
            alt="Price-Action"
            className="bg-color-grey"
          />

          {/* <i className="fa fa-plus"></i> */}
        </Col>
        <Col lg={10} md={3} className="d-flex flex-column">
          <span className="title">Mean Reversion</span>
          <span className="sub-title">The price return back to the mean.</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(4);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/Mumentum.png" alt="Momentum" />
          {/* <i className="fa fa-plus"></i> */}
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Momentum</span>
          <span className="sub-title">The rate at which price changes.</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(5);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/miscellaneous.png" alt="Momentum" />
          {/* <i className="fa fa-plus"></i> */}
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Miscellaneous</span>
          <span className="sub-title">Miscellaneous</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(6);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/pivot-point.png" alt="Momentum" />

          {/* <i className="fa fa-plus"></i> */}
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Pivot Points</span>
          <span className="sub-title">Pivot Points</span>
        </Col>
      </Row>
      <hr />
      {/* <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation("overlays");
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/overlay.png" alt="Momentum" />
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Overlays</span>
          <span className="sub-title">overlays</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation("options_strategies");
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/options-stratergy.png" alt="Momentum" />
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Options Strategies</span>
          <span className="sub-title">The versatile ones.</span>
        </Col>
      </Row> */}
    </>
  );
};

export default DiscoverSideBar;
