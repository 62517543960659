import React from "react";
import "../../styles/CustomAlert.css";

const CustomAlert = ({ message, type, setAlertMessage }) => {
  console.log("message", message, type);
  const onClose = () => {
    setAlertMessage(false);
  };
  return (
    <>
      <div className={`custom-alert`}>
        <div className="innerDiv">
          <div className={`alert ${type}`} role="alert">
            <span className="alert-close" onClick={onClose}>
              &times;
            </span>
            <div style={{ display: "flex" }}>
              {type === "alert-success" ? (
                <i className="fa-solid fa-circle-check alert-icon"></i>
              ) : (
                <i class="fa-solid fa-circle-info alert-icon"></i>
              )}
              {message?.split(".").map((part, index) => (
                <p key={index}>{part.trim()}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomAlert;
