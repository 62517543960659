import React from "react";
import { Button, Card, ListGroup } from "react-bootstrap";

const BuyCard = (props) => {
  const { planName, className, price, handelSubscribeBtn } = props;

  return (
    <Card className={className}>
      <Card.Body>
        <Card.Title className="buy-card-title">
          <span>{planName}</span>
          <span>
            <i className="fa fa-inr" aria-hidden="true"></i> {price}
          </span>
        </Card.Title>
        <Card.Text>
          <ListGroup>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; 1000 Backtests per day
            </ListGroup.Item>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; 100 Live strategies at a time
            </ListGroup.Item>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; Unlimited Scan per day
            </ListGroup.Item>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; Heikin-Ashi
            </ListGroup.Item>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; 10 entry and exit conditions
            </ListGroup.Item>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; Trialling stoploss
            </ListGroup.Item>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; Renko(in basic strategy)
            </ListGroup.Item>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; MCX (Commodities)
            </ListGroup.Item>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; Options (NEO-OPT)
            </ListGroup.Item>
            <ListGroup.Item>
              <img
                src="icon-images/checklist.png"
                className="img-fluid"
                heigth="10"
                alt="checklist"
                width="30"
              />{" "}
              &nbsp; MCX (Commodities)
            </ListGroup.Item>
          </ListGroup>
        </Card.Text>
        <Card.Footer className="text-center">
          <Button variant="primary" onClick={handelSubscribeBtn}>
            &emsp;&emsp;Buy Now &emsp;&emsp;
          </Button>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export default BuyCard;
