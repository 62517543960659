import React, { useEffect, useState } from "react";
import Header from "../Pages/Header";
import { useNavigate } from "react-router-dom";

const Otp = (props) => {
  // console.log("otpPage",props);
  const {
    otpDigits,
    onOtpDigitsChange,
    handleVerfiyOtp,
    handleExipreOtp,
    singUpBtn,
    setBtnShow,
    expireOtpMsg,
    setExpireOtpMsg,
    onClickResendOtp,
  } = props;
  const [timer, setTimer] = useState(180);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        // console.log("prevTimer",prevTimer);
        if (prevTimer === 0) {
          clearInterval(intervalId);
          handleExipreOtp();
          setBtnShow(false);
          onOtpDigitsChange(otpDigits, 0);
          return 0; // Clear the interval when the timer reaches 0
          // You can perform additional actions when the timer expires
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [timer]); // Empty dependency array ensures that the effect runs only once on mount

  const handleChangeOtpInput = (e, index) => {
    const value = e.target.value;
    console.log("Otp", value);

    if (!isNaN(value) && value.length <= 1) {
      // Update the corresponding digit in the OTP array
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index] = value;
      onOtpDigitsChange(newOtpDigits, "", timer);

      // Move to the next input field if a digit is entered
      if (value.length === 1 && index < 5) {
        document.getElementsByName(`otpInput${index + 1}`)[0].focus();
      }
    }
  };

  return (
    <>
      {/* <Header/> */}
      <section className="w-100 py-2">
        {/* <div className="row py-2"> */}
        {/* <div className="text-center p-5 fs-4"> */}
        <div className="d-flex justify-content-center w-100 otp-input">
          {otpDigits.map((digit, index) => (
            <input
              key={index}
              type="number"
              className="rounded m-1"
              maxLength={1}
              name={`otpInput${index}`}
              value={digit}
              onChange={(e) => handleChangeOtpInput(e, index)}
            />
          ))}
        </div>
        <div className="text-center">
          <span className="text-error">{expireOtpMsg}</span>
        </div>
        <div className="d-flex justify-content-center py-3">
          <p className="pe-5">
            Didn't receive OTP?{" "}
            <span
              style={{ color: "#2D2E8D", cursor: "pointer" }}
              onClick={() => {
                onClickResendOtp(); // Assuming onClickResendOtp is a function
                timer || setTimer(180);
              }}
            >
              Resend
            </span>
          </p>
          <p className="ps-3">
            {Math.floor(timer / 60)}:{timer % 60 < 10 ? "0" : ""}
            {timer % 60}
          </p>
        </div>
        {singUpBtn && (
          <button
            className="btn btn-primary btn-otp-log w-25"
            onClick={handleVerfiyOtp}
          >
            Verify
          </button>
        )}
        {/* </div> */}
        {/* </div> */}
      </section>
    </>
  );
};

export default Otp;
