// helper Function For plane indicators

const helperModalSwitchFn = (formData, clickedFunction, itemIndex, data) => {
  console.log("CHECK", formData, clickedFunction, itemIndex, data);
  switch (formData.selectedOption1) {
    case "Ulcer":
      formData.indicators[clickedFunction].ulcerField = data?.ulcerField;
      formData.indicators[clickedFunction].ulcerPeriod = data?.ulcerPeriod;
      formData.indicators[clickedFunction].ulcerOffset = data?.ulcerOffset;
      break;
    case "TriMA (Triangular Moving Average)":
      formData.indicators[clickedFunction].tmaField = data?.tmaField;
      formData.indicators[clickedFunction].tmaPeriod = data?.tmaPeriod;
      formData.indicators[clickedFunction].tmaOffset = data?.tmaOffset;
      break;
    case "VWMA (Volume Weighted Moving Average)":
      formData.indicators[clickedFunction].vwmaField = data?.vwmaField;
      formData.indicators[clickedFunction].vwmaPeriod = data?.vwmaPeriod;
      formData.indicators[clickedFunction].vwmaOffset = data?.vwmaOffset;
      break;
    case "DEMA (Double Exponential Moving Average)":
      formData.indicators[clickedFunction].field = data?.field;
      formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;
    case "TEMA (Triple Exponential Moving Average)":
      formData.indicators[clickedFunction].temaField = data?.temaField;
      formData.indicators[clickedFunction].temaPeriod = data?.temaPeriod;
      formData.indicators[clickedFunction].temaOffset = data?.temaOffset;
      break;
    case "Open price":
      // formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;
    case "Low price":
      formData.indicators[clickedFunction].lowOffset = data?.lowOffset;
      break;
    case "High price":
      formData.indicators[clickedFunction].highOffset = data?.highOffset;
      break;
    case "Close":
      formData.indicators[clickedFunction].closeOffset = data?.closeOffset;
      break;
    case "SMA (Simple Moving Average)":
      formData.indicators[clickedFunction].field = data?.field;
      formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;
    case "EMA (Exponential Moving Average)":
      formData.indicators[clickedFunction].field = data?.field;
      formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;
    case "Money Flow Index":
      formData.indicators[clickedFunction].mfiPeriod = data?.mfiPeriod;
      formData.indicators[clickedFunction].mfiOffset = data?.mfiOffset;
      break;
    case "Williams %R":
      formData.indicators[clickedFunction].wrOffset = data?.wrOffset;
      formData.indicators[clickedFunction].wrPeriod = data?.wrPeriod;
      break;

    case "Moving Average Deviation (MA Dev)":
      formData.indicators[clickedFunction].madField = data?.madField;
      formData.indicators[clickedFunction].madPeriod = data?.madPeriod;
      formData.indicators[clickedFunction].madType = data?.madType;
      formData.indicators[clickedFunction].madOffset = data?.madOffset;
      formData.indicators[clickedFunction].madPop = data?.madPop;
      break;

    case "MACD (Moving Average Convergence/Divergence)":
      formData.indicators[clickedFunction].macd_fastMaPeriod =
        data?.macd_fastMaPeriod;
      formData.indicators[clickedFunction].macd_slowMaPeriod =
        data?.macd_slowMaPeriod;
      formData.indicators[clickedFunction].macd_signalPeriod =
        data?.macd_signalPeriod;
      formData.indicators[clickedFunction].macdOffset = data?.macdOffset;
      break;

    case "OBV (On Balance Volume)":
      formData.indicators[clickedFunction].obvOffset = data?.obvOffset;
      break;

    case "Aroon-Down":
      formData.indicators[clickedFunction].aroonDownPeriod =
        data?.aroonDownPeriod;
      formData.indicators[clickedFunction].aroonDownOffset =
        data?.aroonDownOffset;
      break;

    case "Aroon-Up":
      formData.indicators[clickedFunction].aroonUpPeriod = data?.aroonUpPeriod;
      formData.indicators[clickedFunction].aroonUpOffset = data?.aroonUpOffset;
      break;

    case "Awesome Oscillator":
      formData.indicators[clickedFunction].aOscilatorOffset =
        data?.aOscilatorOffset;
      break;

    case "VWAP (Volume Weighted Average Price)":
      formData.indicators[clickedFunction].vwapOffset = data?.vwapOffset;
      break;

    case "Parabolic SAR":
      formData.indicators[clickedFunction].psarMinimumAF = data?.psarMinimumAF;
      formData.indicators[clickedFunction].psarMaximumAF = data?.psarMaximumAF;
      formData.indicators[clickedFunction].psarOffset = data?.psarOffset;
      break;

    case "Vortex Indicator":
      formData.indicators[clickedFunction].vortexIPeriod = data?.vortexIPeriod;
      formData.indicators[clickedFunction].vortexISignal = data?.vortexISignal;
      formData.indicators[clickedFunction].vortexIOffset = data?.vortexIOffset;
      break;

    case "Number":
      formData.indicators[clickedFunction].period = data?.period;
      break;

    case "Volume of a candle":
      formData.indicators[clickedFunction].volume = data?.volume;
      break;

    case "Donchian Channel":
      formData.indicators[clickedFunction].donchianChannel =
        data?.donchianChannel;
      formData.indicators[clickedFunction].donchianHighPeriod =
        data?.donchianHighPeriod;
      formData.indicators[clickedFunction].donchianLowPeriod =
        data?.donchianLowPeriod;
      formData.indicators[clickedFunction].donchianOffset =
        data?.donchianOffset;
      break;

    case "Stochastic":
      formData.indicators[clickedFunction].stochasticPeriod =
        data?.stochasticPeriod;
      formData.indicators[clickedFunction].stochasticType =
        data?.stochasticType;
      formData.indicators[clickedFunction].stochasticSmooth =
        data?.stochasticSmooth;
      formData.indicators[clickedFunction].stochasticOffset =
        data?.stochasticOffset;

      break;

    case "Chaikin Money Flow":
      formData.indicators[clickedFunction].chaikinPeriod = data?.chaikinPeriod;
      formData.indicators[clickedFunction].chaikinOffset = data?.chaikinOffset;
      break;

    case "MACD-histogram":
    case "MACD-signal":
      formData.indicators[clickedFunction].macdsFastMaPeriod =
        data?.macdsFastMaPeriod;
      formData.indicators[clickedFunction].macdsSlowMaPeriod =
        data?.macdsSlowMaPeriod;
      formData.indicators[clickedFunction].macdsSignalPeriod =
        data?.macdsSignalPeriod;
      formData.indicators[clickedFunction].macdsOffset = data?.macdsOffset;

      break;

    case "Commodity Channel Index":
      formData.indicators[clickedFunction].cciPeriod = data?.cciPeriod;
      formData.indicators[clickedFunction].cciOffset = data?.cciOffset;
      break;

    case "Center Of Gravity" : 
    case "Price Rate of Change":
      formData.indicators[clickedFunction].rocField = data?.rocField;
      formData.indicators[clickedFunction].rocPeriod = data?.rocPeriod;
      formData.indicators[clickedFunction].rocOffset = data?.rocOffset;
      break;

    case "RSI (Relative Strength Index)":
      formData.indicators[clickedFunction].rsiPeriod = data?.rsiPeriod;
      formData.indicators[clickedFunction].rsiOffset = data?.rsiOffset;
      break;

    case "RSI Moving Average":
      formData.indicators[clickedFunction].rsimaPeriod = data?.rsimaPeriod;
      formData.indicators[clickedFunction].rsimaType = data?.rsimaType;
      formData.indicators[clickedFunction].rsimaMaPeriod = data?.rsimaMaPeriod;
      formData.indicators[clickedFunction].rsimaOffset = data?.rsimaOffset;
      break;

    case "Supertrend":
      formData.indicators[clickedFunction].superTPeriod = data?.superTPeriod;
      formData.indicators[clickedFunction].superTMultiply =
        data?.superTMultiply;
      formData.indicators[clickedFunction].superTOffset = data?.superTOffset;
      break;

    case "TR (True Range)":
      formData.indicators[clickedFunction].trOffset = data?.trOffset;
      break;

    case "ATR (Average True Range)":
      formData.indicators[clickedFunction].atrPeriod = data?.atrPeriod;
      formData.indicators[clickedFunction].atrOffset = data?.atrOffset;
      break;

    case "NATR (Normalized Average True Range)":
      formData.indicators[clickedFunction].natrPeriod = data?.natrPeriod;
      formData.indicators[clickedFunction].natrOffset = data?.natrOffset;
      break;

    case "Bollinger %B":
      formData.indicators[clickedFunction].bolingerBField =
        data?.bolingerBField;
      formData.indicators[clickedFunction].bolingerBPeriod =
        data?.bolingerBPeriod;
      formData.indicators[clickedFunction].bolingerBDev = data?.bolingerBDev;
      formData.indicators[clickedFunction].bolingerBType = data?.bolingerBType;
      formData.indicators[clickedFunction].bolingerBOffset =
        data?.bolingerBOffset;
      break;

    case "MFI Moving Average (Money Flow Index Moving Average)":
      formData.indicators[clickedFunction].mfiMAPeriod = data?.mfiMAPeriod;
      formData.indicators[clickedFunction].mfiMAMAPeriod = data?.mfiMAMAPeriod;
      formData.indicators[clickedFunction].mfiMAType = data?.mfiMAType;
      formData.indicators[clickedFunction].mfiMAOffset = data?.mfiMAOffset;
      break;

    case "Know Sure Thing":
      formData.indicators[clickedFunction].kstField = data?.kstField;
      formData.indicators[clickedFunction].kstLTRoc = data?.kstLTRoc;
      formData.indicators[clickedFunction].kstLTSma = data?.kstLTSma;
      formData.indicators[clickedFunction].kstRoc = data?.kstRoc;
      formData.indicators[clickedFunction].kstLSma = data?.kstLSma;
      formData.indicators[clickedFunction].kstHRoc = data?.kstHRoc;
      formData.indicators[clickedFunction].kstHSma = data?.kstHSma;
      formData.indicators[clickedFunction].kstHeRoc = data?.kstHeRoc;
      formData.indicators[clickedFunction].kstHeSma = data?.kstHeSma;
      formData.indicators[clickedFunction].kstSPeriod = data?.kstSPeriod;
      formData.indicators[clickedFunction].kstType = data?.kstType;
      formData.indicators[clickedFunction].kstOffset = data?.kstOffset;
      break;

    case "Ichimoku":
      formData.indicators[clickedFunction].ichiConversion =
        data?.ichiConversion;
      formData.indicators[clickedFunction].ichiBase = data?.ichiBase;
      formData.indicators[clickedFunction].leadingSpanB = data?.leadingSpanB;
      formData.indicators[clickedFunction].laggingSpan = data?.laggingSpan;
      formData.indicators[clickedFunction].ichiLine = data?.ichiLine;
      formData.indicators[clickedFunction].cloudShift = data?.cloudShift;
      formData.indicators[clickedFunction].ichiOffset = data?.ichiOffset;

      break;

    case "WMA (Weighted Moving Average)":
      formData.indicators[clickedFunction].wmaField = data?.wmaField;
      formData.indicators[clickedFunction].wmaPeriod = data?.wmaPeriod;
      formData.indicators[clickedFunction].wmaOffset = data?.wmaOffset;
      break;
    case "Elder Force Index" :
    case "MOM (Momentum Indicator)":
      formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;

    case "Chande Momentum Oscillator":
      formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;

    case "Standard Deviation":
      formData.indicators[clickedFunction].stdPeriod = data?.stdPeriod;
      formData.indicators[clickedFunction].stdField = data?.stdField;
      formData.indicators[clickedFunction].stdDev = data?.stdDev;
      formData.indicators[clickedFunction].maType = data?.maType;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;

    case "Ultimate Oscillator":
      formData.indicators[clickedFunction].length1 = data?.length1;
      formData.indicators[clickedFunction].length2 = data?.length2;
      formData.indicators[clickedFunction].length3 = data?.length3;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;

    case "Nth Candle":
      formData.indicators[clickedFunction].field = data?.field;
      formData.indicators[clickedFunction].range = data?.range;
      formData.indicators[clickedFunction].num = data?.num;
      break;
    case "Choppiness Index" :
    case "Median Price":
    case "-DI (Minus Directional Index)" :
      formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;

    case "ATR Trailing Stoploss" : 
      formData.indicators[clickedFunction].multiplier = data?.multiplier;
      formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;

    case "Camarilla Pivot Points" :
    case "Central Pivot Range" :
      formData.indicators[clickedFunction].signal = data?.signal;
      break;
    case "Pivot Points":
      formData.indicators[clickedFunction].signal = data?.signal;
      formData.indicators[clickedFunction].type = data?.type;
      formData.indicators[clickedFunction].continous = data?.continous;
      break;
    case "Elder Ray Index" :
      formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].power = data?.power;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;
    case "UBB (Upper Bollinger Band)":
    case "MBB (Middle Bollinger Band)":
    case "LBB (Lower Bollinger Band)":
    case "Bollinger Bandwidth" :
      formData.indicators[clickedFunction].field = data?.field;
      formData.indicators[clickedFunction].period = data?.period;
      formData.indicators[clickedFunction].dev = data?.dev;
      formData.indicators[clickedFunction].type = data?.type;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;
    case "Phase Power Moving Average" :
      formData.indicators[clickedFunction].field = data?.field;
      formData.indicators[clickedFunction].powerLength = data?.powerLength;
      formData.indicators[clickedFunction].phase = data?.phase;
      formData.indicators[clickedFunction].power = data?.power;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;
    case "TSI (True Strength Indicator)" : 
      formData.indicators[clickedFunction].field = data?.field;
      formData.indicators[clickedFunction].type = data?.type;
      formData.indicators[clickedFunction].long = data?.long;
      formData.indicators[clickedFunction].short = data?.short;
      formData.indicators[clickedFunction].signal = data?.signal;
      formData.indicators[clickedFunction].offset = data?.offset;
      break;
    default:
      break;
  }
};

const helperModalSwitchForMath = (
  formData,
  clickedFunction,
  itemIndex,
  data,
  instance
) => {
  console.log("FIND", formData,
    clickedFunction,
    itemIndex,
    data,
    instance, formData.selectedOption1)
  switch (formData.selectedOption1) {
    case "Ulcer":
      formData.indicators[clickedFunction][instance][itemIndex].ulcerField =
        data?.ulcerField;
      formData.indicators[clickedFunction][instance][itemIndex].ulcerPeriod =
        parseInt(data?.ulcerPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].ulcerOffset =
        parseInt(data?.ulcerOffset);
      break;
    case "TriMA (Triangular Moving Average)":
      formData.indicators[clickedFunction][instance][itemIndex].tmaField =
        data?.tmaField;
      formData.indicators[clickedFunction][instance][itemIndex].tmaPeriod =
        parseInt(data?.tmaPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].tmaOffset =
        parseInt(data?.tmaOffset);
      break;
    case "VWMA (Volume Weighted Moving Average)":
      formData.indicators[clickedFunction][instance][itemIndex].vwmaField =
        data?.vwmaField;
      formData.indicators[clickedFunction][instance][itemIndex].vwmaPeriod =
        parseInt(data?.vwmaPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].vwmaOffset =
        parseInt(data?.vwmaOffset);
      break;
    case "DEMA (Double Exponential Moving Average)":
      formData.indicators[clickedFunction][instance][itemIndex].field =
        data?.field;
      formData.indicators[clickedFunction][instance][itemIndex].period =
        parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].offset =
        parseInt(data?.offset);
      break;
    case "TEMA (Triple Exponential Moving Average)":
      formData.indicators[clickedFunction][instance][itemIndex].temaField =
        data?.temaField;
      formData.indicators[clickedFunction][instance][itemIndex].temaPeriod =
        parseInt(data?.temaPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].temaOffset =
        parseInt(data?.temaOffset);
      break;
    case "Open price":
      // formData.indicators[clickedFunction][instance][itemIndex].period =
      //   parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].offset =
        parseInt(data?.offset);
      break;
    case "Low price":
      formData.indicators[clickedFunction][instance][itemIndex].lowOffset =
        parseInt(data?.lowOffset);
      break;
    case "High price":
      formData.indicators[clickedFunction][instance][itemIndex].highOffset =
        parseInt(data?.highOffset);
      break;
    case "Close":
      formData.indicators[clickedFunction][instance][itemIndex].closeOffset =
        parseInt(data?.closeOffset);
      break;
    case "SMA (Simple Moving Average)":
      formData.indicators[clickedFunction][instance][itemIndex].field =
        data?.field;
      formData.indicators[clickedFunction][instance][itemIndex].period =
        parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].offset =
        parseInt(data?.offset);
      break;
    case "EMA (Exponential Moving Average)":
      formData.indicators[clickedFunction][instance][itemIndex].field =
        data?.field;
      formData.indicators[clickedFunction][instance][itemIndex].period =
        parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].offset =
        parseInt(data?.offset);
      break;
    case "Money Flow Index":
      formData.indicators[clickedFunction][instance][itemIndex].mfiPeriod =
        parseInt(data?.mfiPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].mfiOffset =
        parseInt(data?.mfiOffset);
      break;
    case "Williams %R":
      formData.indicators[clickedFunction][instance][itemIndex].wrPeriod =
        parseInt(data?.wrPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].wrOffset =
        parseInt(data?.wrOffset);
      break;
    case "Moving Average Deviation (MA Dev)":
      formData.indicators[clickedFunction][instance][itemIndex].madField =
        data?.madField;
      formData.indicators[clickedFunction][instance][itemIndex].madPeriod =
        parseInt(data?.madPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].madType =
        data?.madType;
      formData.indicators[clickedFunction][instance][itemIndex].madOffset =
        parseInt(data?.madOffset);
      formData.indicators[clickedFunction][instance][itemIndex].madPop =
        data?.madPop;
      break;
    case "MACD (Moving Average Convergence/Divergence)":
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].macd_fastMaPeriod = parseInt(data?.macd_fastMaPeriod);
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].macd_slowMaPeriod = parseInt(data?.macd_slowMaPeriod);
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].macd_signalPeriod = parseInt(data?.macd_signalPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].macdOffset =
        parseInt(data?.macdOffset);
      break;
    case "OBV (On Balance Volume)":
      formData.indicators[clickedFunction][instance][itemIndex].obvOffset =
        parseInt(data?.obvOffset);
      break;
    case "Aroon-Down":
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].aroonDownPeriod = parseInt(data?.aroonDownPeriod);
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].aroonDownOffset = parseInt(data?.aroonDownOffset);
      break;
    case "Aroon-Up":
      formData.indicators[clickedFunction][instance][itemIndex].aroonUpPeriod =
        parseInt(data?.aroonUpPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].aroonUpOffset =
        parseInt(data?.aroonUpOffset);
      break;
    case "Awesome Oscillator":
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].aOscilatorOffset = parseInt(data?.aOscilatorOffset);
      break;
    case "VWAP (Volume Weighted Average Price)":
      formData.indicators[clickedFunction][instance][itemIndex].vwapOffset =
        parseInt(data?.vwapOffset);
      break;
    case "Parabolic SAR":
      formData.indicators[clickedFunction][instance][itemIndex].psarMinimumAF =
        parseFloat(data?.psarMinimumAF);
      formData.indicators[clickedFunction][instance][itemIndex].psarMaximumAF =
        parseFloat(data?.psarMaximumAF);
      formData.indicators[clickedFunction][instance][itemIndex].psarOffset =
        parseInt(data?.psarOffset);
      break;
    case "Vortex Indicator":
      formData.indicators[clickedFunction][instance][itemIndex].vortexIPeriod =
        parseInt(data?.vortexIPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].vortexISignal =
        data?.vortexISignal;
      formData.indicators[clickedFunction][instance][itemIndex].vortexIOffset =
        parseInt(data?.vortexIOffset);
      break;
    case "Number":
      formData.indicators[clickedFunction][instance][itemIndex].period =
        parseFloat(data?.period);
      break;
    case "Volume of a candle":
      formData.indicators[clickedFunction][instance][itemIndex].volume =
        parseInt(data?.volume);
      break;
    case "Donchian Channel":
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].donchianChannel = data?.donchianChannel;
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].donchianHighPeriod = parseInt(data?.donchianHighPeriod);
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].donchianLowPeriod = parseInt(data?.donchianLowPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].donchianOffset =
        parseInt(data?.donchianOffset);
      break;
    case "Stochastic":
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].stochasticPeriod = parseInt(data?.stochasticPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].stochasticType =
        data?.stochasticType;
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].stochasticSmooth = data?.stochasticSmooth;
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].stochasticOffset = parseInt(data?.stochasticOffset);
      break;
    case "Chaikin Money Flow":
      formData.indicators[clickedFunction][instance][itemIndex].chaikinPeriod =
        parseInt(data?.chaikinPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].chaikinOffset =
        parseInt(data?.chaikinOffset);
      break;
    case "MACD-histogram":
    case "MACD-signal":
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].macdsFastMaPeriod = parseInt(data?.macdsFastMaPeriod);
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].macdsSlowMaPeriod = parseInt(data?.macdsSlowMaPeriod);
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].macdsSignalPeriod = parseInt(data?.macdsSignalPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].macdsOffset =
        parseInt(data?.macdsOffset);
      break;
    case "Commodity Channel Index":
      formData.indicators[clickedFunction][instance][itemIndex].cciPeriod =
        parseInt(data?.cciPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].cciOffset =
        parseInt(data?.cciOffset);
      break;
    case "Center Of Gravity" :
    case "Price Rate of Change":
      formData.indicators[clickedFunction][instance][itemIndex].rocField =
        data?.rocField;
      formData.indicators[clickedFunction][instance][itemIndex].rocPeriod =
        parseInt(data?.rocPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].rocOffset =
        parseInt(data?.rocOffset);
      break;
    case "RSI (Relative Strength Index)":
      formData.indicators[clickedFunction][instance][itemIndex].rsiPeriod =
        parseInt(data?.rsiPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].rsiOffset =
        parseInt(data?.rsiOffset);
      break;
    case "RSI Moving Average":
      formData.indicators[clickedFunction][instance][itemIndex].rsimaPeriod =
        parseInt(data?.rsimaPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].rsimaType =
        data?.rsimaType;
      formData.indicators[clickedFunction][instance][itemIndex].rsimaMaPeriod =
        parseInt(data?.rsimaMaPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].rsimaOffset =
        parseInt(data?.rsimaOffset);
      break;
    case "Supertrend":
      formData.indicators[clickedFunction][instance][itemIndex].superTPeriod =
        parseInt(data?.superTPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].superTMultiply =
        parseFloat(data?.superTMultiply);
      formData.indicators[clickedFunction][instance][itemIndex].superTOffset =
        parseInt(data?.superTOffset);
      break;
    case "TR (True Range)":
      formData.indicators[clickedFunction][instance][itemIndex].trOffset =
        parseInt(data?.trOffset);
      break;
    case "ATR (Average True Range)":
      formData.indicators[clickedFunction][instance][itemIndex].atrPeriod =
        parseInt(data?.atrPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].atrOffset =
        parseInt(data?.atrOffset);
      break;
    case "NATR (Normalized Average True Range)":
      formData.indicators[clickedFunction][instance][itemIndex].natrPeriod =
        parseInt(data?.natrPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].natrOffset =
        parseInt(data?.natrOffset);
      break;
    case "Bollinger %B":
      formData.indicators[clickedFunction][instance][itemIndex].bolingerBField =
        data?.bolingerBField;
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].bolingerBPeriod = parseInt(data?.bolingerBPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].bolingerBDev =
        parseInt(data?.bolingerBDev);
      formData.indicators[clickedFunction][instance][itemIndex].bolingerBType =
        data?.bolingerBType;
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].bolingerBOffset = parseInt(data?.bolingerBOffset);
      break;
    case "MFI Moving Average (Money Flow Index Moving Average)":
      formData.indicators[clickedFunction][instance][itemIndex].mfiMAPeriod =
        parseInt(data?.mfiMAPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].mfiMAMAPeriod =
        parseInt(data?.mfiMAMAPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].mfiMAType =
        data?.mfiMAType;
      formData.indicators[clickedFunction][instance][itemIndex].mfiMAOffset =
        parseInt(data?.mfiMAOffset);
      break;
    case "Know Sure Thing":
      formData.indicators[clickedFunction][instance][itemIndex].kstField =
        data?.kstField;
      formData.indicators[clickedFunction][instance][itemIndex].kstLTRoc =
        data?.kstLTRoc;
      formData.indicators[clickedFunction][instance][itemIndex].kstLTSma =
        data?.kstLTSma;
      formData.indicators[clickedFunction][instance][itemIndex].kstRoc =
        data?.kstRoc;
      formData.indicators[clickedFunction][instance][itemIndex].kstLSma =
        data?.kstLSma;
      formData.indicators[clickedFunction][instance][itemIndex].kstHRoc =
        data?.kstHRoc;
      formData.indicators[clickedFunction][instance][itemIndex].kstHSma =
        data?.kstHSma;
      formData.indicators[clickedFunction][instance][itemIndex].kstHeRoc =
        data?.kstHeRoc;
      formData.indicators[clickedFunction][instance][itemIndex].kstHeSma =
        data?.kstHeSma;
      formData.indicators[clickedFunction][instance][itemIndex].kstSPeriod =
        parseInt(data?.kstSPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].kstType =
        data?.kstType;
      formData.indicators[clickedFunction][instance][itemIndex].kstOffset =
        parseInt(data?.kstOffset);
      break;
    case "Ichimoku":
      formData.indicators[clickedFunction][instance][itemIndex].ichiConversion =
        data?.ichiConversion;
      formData.indicators[clickedFunction][instance][itemIndex].ichiBase =
        data?.ichiBase;
      formData.indicators[clickedFunction][instance][itemIndex].leadingSpanB =
        data?.leadingSpanB;
      formData.indicators[clickedFunction][instance][itemIndex].laggingSpan =
        data?.laggingSpan;
      formData.indicators[clickedFunction][instance][itemIndex].ichiLine =
        data?.ichiLine;
      formData.indicators[clickedFunction][instance][itemIndex].cloudShift =
        data?.cloudShift;
      formData.indicators[clickedFunction][instance][itemIndex].ichiOffset =
        parseInt(data?.ichiOffset);
      break;
    case "WMA (Weighted Moving Average)":
      formData.indicators[clickedFunction][instance][itemIndex].wmaField =
        data?.wmaField;
      formData.indicators[clickedFunction][instance][itemIndex].wmaPeriod =
        parseInt(data?.wmaPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].wmaOffset =
        parseInt(data?.wmaOffset);
      break;
    case "Elder Force Index" :
    case "MOM (Momentum Indicator)":
      formData.indicators[clickedFunction][instance][itemIndex].period =
        parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].offset =
        parseInt(data?.offset);
      break;

    case "CHANDE MOMENTUM OSCILLATOR":
      formData.indicators[clickedFunction][instance][itemIndex].period =
        parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].offset =
        parseInt(data?.offset);
      break;

    case "Standard Deviation":
      formData.indicators[clickedFunction][instance][itemIndex].stdPeriod =
        parseInt(data?.stdPeriod);
      formData.indicators[clickedFunction][instance][itemIndex].stdField =
        data?.stdField;
      formData.indicators[clickedFunction][instance][itemIndex].stdDev =
        parseInt(data?.stdDev);
      formData.indicators[clickedFunction][instance][itemIndex].maType =
        data?.maType;
      formData.indicators[clickedFunction][instance][itemIndex].offset =
        parseInt(data?.offset);
      break;

    case "Ultimate Oscillator":
      formData.indicators[clickedFunction][instance][itemIndex].length1 =
        parseInt(data?.length1);
      formData.indicators[clickedFunction][instance][itemIndex].length2 =
        parseInt(data?.length2);
      formData.indicators[clickedFunction][instance][itemIndex].length3 =
        parseInt(data?.length3);
      formData.indicators[clickedFunction][instance][itemIndex].offset =
        parseInt(data?.offset);
      break;

    case "Nth Candle":
      formData.indicators[clickedFunction][instance][itemIndex].field = data?.field;
      formData.indicators[clickedFunction][instance][itemIndex].range = data?.range;
      formData.indicators[clickedFunction][instance][itemIndex].num = parseInt(data?.num);
      break;

    case "Chande Momentum Oscillator":
    case "Median Price":
    case "Choppiness Index" :
    case "-DI (Minus Directional Index)" :
      formData.indicators[clickedFunction][instance][itemIndex].period = parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].offset = parseInt(data?.offset);
      break;
    case "ATR Trailing Stoploss": 
      formData.indicators[clickedFunction][instance][itemIndex].multiplier = parseInt(data?.multiplier);
      formData.indicators[clickedFunction][instance][itemIndex].period = parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].offset = parseInt(data?.offset);
      break;

    case "Camarilla Pivot Points" :
    case "Central Pivot Range":
      formData.indicators[clickedFunction][instance][itemIndex].signal = data?.signal;
      break;
    case "Pivot Points":
      formData.indicators[clickedFunction][instance][itemIndex].signal = data?.signal;
      formData.indicators[clickedFunction][instance][itemIndex].type = data?.type;
      formData.indicators[clickedFunction][instance][itemIndex].continous = data?.continous;
      break;
    case "Elder Ray Index":
      formData.indicators[clickedFunction][instance][itemIndex].period = parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].power = data?.power;
      formData.indicators[clickedFunction][instance][itemIndex].offset = parseInt(data?.offset);
      break;
    case "UBB (Upper Bollinger Band)":
    case "MBB (Middle Bollinger Band)":
    case "LBB (Lower Bollinger Band)":
      formData.indicators[clickedFunction][instance][itemIndex].field =
        data?.field;
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].period = parseInt(data?.period);
      formData.indicators[clickedFunction][instance][itemIndex].dev =
        parseInt(data?.dev);
      formData.indicators[clickedFunction][instance][itemIndex].type =
        data?.type;
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].offset = parseInt(data?.offset);
      break;
    case "Phase Power Moving Average":
      formData.indicators[clickedFunction][instance][itemIndex].field =
        data?.field;
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].powerLength = parseInt(data?.powerLength);
      formData.indicators[clickedFunction][instance][itemIndex].phase =
        parseInt(data?.phase);
      formData.indicators[clickedFunction][instance][itemIndex].power =
        parseInt(data?.power);
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].offset = parseInt(data?.offset);
      break;
    case "TSI (True Strength Indicator)": 
      formData.indicators[clickedFunction][instance][itemIndex].field =
        data?.field;
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].type = data?.type;
      formData.indicators[clickedFunction][instance][itemIndex].long =
        parseInt(data?.long);
      formData.indicators[clickedFunction][instance][itemIndex].short =
        parseInt(data?.short);
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].signal = parseInt(data?.signal);
      formData.indicators[clickedFunction][instance][
        itemIndex
      ].offset = parseInt(data?.offset);
      break;
    default:
      break;
  }
};

const helperModalReplaceFn777 = (objOf, replaceObje, item) => {
  let validName;
  console.log("@@@", objOf, replaceObje, item);
  if (item) {
    validName = item?.name;
  } else {
    validName = objOf?.name;
  }
  switch (validName) {
    case "Ulcer":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.ulcerField;
      replaceObje.period = parseInt(objOf?.ulcerPeriod);
      replaceObje.offset = parseInt(objOf?.ulcerOffset);
      break;
    case "TriMA (Triangular Moving Average)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.tmaField;
      replaceObje.period = parseInt(objOf?.tmaPeriod);
      replaceObje.offset = parseInt(objOf?.tmaOffset);
      break;
    case "VWMA (Volume Weighted Moving Average)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.Field = objOf?.vwmaField;
      replaceObje.Period = parseInt(objOf?.vwmaPeriod);
      replaceObje.Offset = parseInt(objOf?.vwmaOffset);
      break;
    case "DEMA (Double Exponential Moving Average)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.field;
      replaceObje.period = parseInt(objOf?.period);
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "TEMA (Triple Exponential Moving Average)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.Field = objOf?.temaField;
      replaceObje.Period = parseInt(objOf?.temaPeriod);
      replaceObje.Offset = parseInt(objOf?.temaOffset);
      break;
    case "Open price":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      // replaceObje.period = parseInt(objOf?.period);
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "Low price":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.offset = parseInt(objOf?.lowOffset);
      break;
    case "High price":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.highOffset = parseInt(objOf?.highOffset);
      break;
    case "Close":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.closeOffset = parseInt(objOf?.closeOffset);
      break;
    case "SMA (Simple Moving Average)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.field;
      replaceObje.period = parseInt(objOf?.period);
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "WMA (Weighted Moving Average)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.wmaField;
      replaceObje.period = parseInt(objOf?.wmaPeriod);
      replaceObje.offset = parseInt(objOf?.wmaOffset);
      break;
    case "EMA (Exponential Moving Average)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.field;
      replaceObje.period = parseInt(objOf?.period);
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "Money Flow Index":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.Period = parseInt(objOf?.mfiPeriod);
      replaceObje.Offset = parseInt(objOf?.mfiOffset);
      break;
    case "Williams %R":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.Offset = parseInt(objOf?.wrOffset);
      replaceObje.Period = parseInt(objOf?.wrPeriod);
      break;
    case "Moving Average Deviation (MA Dev)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.Field = objOf?.madField;
      replaceObje.Period = parseInt(objOf?.madPeriod);
      replaceObje.Type = objOf?.madType;
      replaceObje.Offset = parseInt(objOf?.madOffset);
      replaceObje.Pop = objOf?.madPop;
      break;
    case "MACD (Moving Average Convergence/Divergence)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.MaPeriod = parseInt(objOf?.macd_fastMaPeriod);
      replaceObje.macd_slowMaPeriod = parseInt(objOf?.macd_slowMaPeriod);
      replaceObje.macd_signalPeriod = parseInt(objOf?.macd_signalPeriod);
      replaceObje.macdOffset = parseInt(objOf?.macdOffset);
      break;
    case "OBV (On Balance Volume)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.offset = parseInt(objOf?.obvOffset);
      break;
    case "Aroon-Down":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.aroonDownPeriod);
      replaceObje.offset = parseInt(objOf?.aroonDownOffset);
      break;
    case "Aroon-Up":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.aroonUpPeriod);
      replaceObje.offset = parseInt(objOf?.aroonUpOffset);
      break;
    case "Awesome Oscillator":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.offset = parseInt(objOf?.aOscilatorOffset);
      break;
    case "VWAP (Volume Weighted Average Price)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.offset = parseInt(objOf?.vwapOffset);
      break;
    case "Parabolic SAR":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.minAF = parseInt(objOf?.psarMinimumAF);
      replaceObje.maxAF = parseInt(objOf?.psarMaximumAF);
      replaceObje.offset = parseInt(objOf?.psarOffset);
      break;
    case "Vortex Indicator":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.vortexIPeriod);
      replaceObje.signal = objOf?.vortexISignal;
      replaceObje.offset = parseInt(objOf?.vortexIOffset);
      break;
    case "Number":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.period);
      break;
    case "Volume of a candle":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.volume = objOf?.volume;
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "Donchian Channel":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.channel = objOf?.donchianChannel;
      replaceObje.period = parseInt(objOf?.donchianHighPeriod);
      replaceObje.period1 = parseInt(objOf?.donchianLowPeriod);
      replaceObje.offset = parseInt(objOf?.donchianOffset);
      break;
    case "Stochastic":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.stochasticPeriod);
      replaceObje.type = objOf?.stochasticType;
      replaceObje.smooth = objOf?.stochasticSmooth;
      replaceObje.offset = parseInt(objOf?.stochasticOffset);
      break;
    case "Chaikin Money Flow":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.chaikinPeriod);
      replaceObje.offset = parseInt(objOf?.chaikinOffset);
      break;
    case "MACD-histogram":
    case "MACD-signal":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period1 = parseInt(objOf?.macdsFastMaPeriod);
      replaceObje.period2 = parseInt(objOf?.macdsSlowMaPeriod);
      replaceObje.period3 = parseInt(objOf?.macdsSignalPeriod);
      replaceObje.offset = parseInt(objOf?.macdsOffset);
      break;
    case "Commodity Channel Index":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.cciPeriod);
      replaceObje.offset = parseInt(objOf?.cciOffset);
      break;
    case "Center Of Gravity" :
    case "Price Rate of Change":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.rocField;
      replaceObje.period = parseInt(objOf?.rocPeriod);
      replaceObje.offset = parseInt(objOf?.rocOffset);
      break;
    case "RSI (Relative Strength Index)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.rsiPeriod);
      replaceObje.offset = parseInt(objOf?.rsiOffset);
      break;
    case "RSI Moving Average":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.rsimaPeriod);
      replaceObje.maType = objOf?.rsimaType;
      replaceObje.maPeriod = parseInt(objOf?.rsimaMaPeriod);
      replaceObje.offset = parseInt(objOf?.rsimaOffset);
      break;
    case "Supertrend":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.superTPeriod);
      replaceObje.multiply = parseInt(objOf?.superTMultiply);
      replaceObje.offset = parseInt(objOf?.superTOffset);
      break;
    case "TR (True Range)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.offset = parseInt(objOf?.trOffset);
      break;
    case "ATR (Average True Range)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.atrPeriod);
      replaceObje.offset = parseInt(objOf?.atrOffset);
      break;
    case "NATR (Normalized Average True Range)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.natrPeriod);
      replaceObje.offset = parseInt(objOf?.natrOffset);
      break;
    case "Bollinger %B":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.bolingerBField;
      replaceObje.period = parseInt(objOf?.bolingerBPeriod);
      replaceObje.deviation = objOf?.bolingerBDev;
      replaceObje.type = objOf?.bolingerBType;
      replaceObje.offset = parseInt(objOf?.bolingerBOffset);
      break;
    case "MFI Moving Average (Money Flow Index Moving Average)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.mfiMAPeriod);
      replaceObje.maPeriod = parseInt(objOf?.mfiMAMAPeriod);
      replaceObje.maType = objOf?.mfiMAType;
      replaceObje.offset = parseInt(objOf?.mfiMAOffset);
      break;
    case "Know Sure Thing":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.kstField;
      replaceObje.LTRoc = objOf?.kstLTRoc;
      replaceObje.LTSma = objOf?.kstLTSma;
      replaceObje.Roc = objOf?.kstRoc;
      replaceObje.LSma = objOf?.kstLSma;
      replaceObje.HRoc = objOf?.kstHRoc;
      replaceObje.HSma = objOf?.kstHSma;
      replaceObje.HeRoc = objOf?.kstHeRoc;
      replaceObje.HeSma = objOf?.kstHeSma;
      replaceObje.SPeriod = parseInt(objOf?.kstSPeriod);
      replaceObje.Type = objOf?.kstType;
      replaceObje.offset = parseInt(objOf?.kstOffset);
      break;
    case "Ichimoku":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.Conversion = objOf?.ichiConversion;
      replaceObje.Base = objOf?.ichiBase;
      replaceObje.leadingSpanB = objOf?.leadingSpanB;
      replaceObje.laggingSpan = objOf?.laggingSpan;
      replaceObje.Line = objOf?.ichiLine;
      replaceObje.cloudShift = objOf?.cloudShift;
      replaceObje.offset = parseInt(objOf?.ichiOffset);
      break;
    case "Elder Force Index" :
    case "MOM (Momentum Indicator)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.period);
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "Standard Deviation":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.stdPeriod);
      replaceObje.field = objOf?.stdField;
      replaceObje.stdDev = parseInt(objOf?.stdDev);
      replaceObje.maType = objOf?.maType;
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "Ultimate Oscillator":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.length1 = parseInt(objOf.length1);
      replaceObje.length2 = parseInt(objOf.length2);
      replaceObje.length3 = parseInt(objOf.length3);
      replaceObje.offset = parseInt(objOf.offset);
      break;
    case "Nth Candle":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf.field;
      replaceObje.range = objOf.range;
      replaceObje.num = parseInt(objOf.num);
      break;
    case "Chande Momentum Oscillator":
    case "Median Price":
    case "Choppiness Index" :
    case "-DI (Minus Directional Index)" :
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf.period);
      replaceObje.offset = parseInt(objOf.offset);
      break;
    case "ATR Trailing Stoploss" :
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name ;
      replaceObje.multiplier = parseInt(objOf?.multiplier);
      replaceObje.period = parseInt(objOf?.period);
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "Camarilla Pivot Points" :
    case "Central Pivot Range":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.signal = objOf?.signal;
      break ;
    case "Pivot Points":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.signal = objOf?.signal;
      replaceObje.type = objOf?.type;
      replaceObje.continous = objOf?.continous;
      break;
    case "Elder Ray Index":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = objOf?.period;
      replaceObje.power = objOf?.power;
      replaceObje.offset = objOf?.offset;
      break;
    case "UBB (Upper Bollinger Band)":
    case "MBB (Middle Bollinger Band)":
    case "LBB (Lower Bollinger Band)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = objOf?.period;
      replaceObje.field = objOf?.field;
      replaceObje.offset = objOf?.offset;
      replaceObje.dev = objOf?.dev;
      replaceObje.type = objOf?.type;
      break;
    case "Phase Power Moving Average":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.field;
      replaceObje.powerLength = objOf?.powerLength;
      replaceObje.phase = objOf?.phase;
      replaceObje.power = objOf?.power;
      replaceObje.offset = objOf?.offset ;
      break;
    case "TSI (True Strength Indicator)" : 
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.field;
      replaceObje.type = objOf?.type;
      replaceObje.long = objOf?.long;
      replaceObje.short = objOf?.short;
      replaceObje.signal = objOf?.signal;
      replaceObje.offset = objOf?.offset;
      break;
    default:
      break;
  }
};
// exit_indicators //
const helperModalReplaceFn888 = (objOf, replaceObje, item) => {
  console.log("form888", objOf, replaceObje, item)
  let validName;
  if (item) {
    validName = item?.name;
  } else {
    validName = objOf?.name;
  }
  switch (validName) {
    case "Ulcer":
      replaceObje.field = objOf?.e_ulcerField;
      replaceObje.period = parseInt(objOf?.e_ulcerPeriod);
      replaceObje.offset = parseInt(objOf?.e_ulcerOffset);
      break;
    case "TriMA (Triangular Moving Average)":
      replaceObje.field = objOf?.e_tmaField;
      replaceObje.period = parseInt(objOf?.e_tmaPeriod);
      replaceObje.offset = parseInt(objOf?.e_tmaOffset);
      break;
    case "VWMA (Volume Weighted Moving Average)":
      replaceObje.Field = objOf?.e_vwmaField;
      replaceObje.Period = parseInt(objOf?.e_vwmaPeriod);
      replaceObje.Offset = parseInt(objOf?.e_vwmaOffset);
      break;
    case "DEMA (Double Exponential Moving Average)":
      replaceObje.field = objOf?.e_field;
      replaceObje.period = parseInt(objOf?.e_period);
      replaceObje.offset = parseInt(objOf?.e_offset);
      break;
    case "TEMA (Triple Exponential Moving Average)":
      replaceObje.Field = objOf?.e_temaField;
      replaceObje.Period = parseInt(objOf?.e_temaPeriod);
      replaceObje.Offset = parseInt(objOf?.e_temaOffset);
      break;
    case "Open price":
      // replaceObje.period = parseInt(objOf?.e_period);
      replaceObje.offset = parseInt(objOf?.e_offset);
      break;
    case "Low price":
      replaceObje.offset = parseInt(objOf?.e_lowOffset);
      break;
    case "High price":
      replaceObje.offset = parseInt(objOf?.e_highOffset);
      break;
    case "Close":
      replaceObje.closeOffset = parseInt(objOf?.e_closeOffset);
      break;
    case "SMA (Simple Moving Average)":
      replaceObje.field = objOf?.e_field;
      replaceObje.period = parseInt(objOf?.e_period);
      replaceObje.offset = parseInt(objOf?.e_offset);
      break;
    case "WMA (Weighted Moving Average)":
      replaceObje.field = objOf?.e_wmaField;
      replaceObje.period = parseInt(objOf?.e_wmaPeriod);
      replaceObje.offset = parseInt(objOf?.e_wmaOffset);
      break;
    case "EMA (Exponential Moving Average)":
      replaceObje.field = objOf?.e_eField;
      replaceObje.period = parseInt(objOf?.e_ePeriod);
      replaceObje.offset = parseInt(objOf?.e_eOffset);
      break;
    case "Money Flow Index":
      replaceObje.Period = parseInt(objOf?.e_mfiPeriod);
      replaceObje.Offset = parseInt(objOf?.e_mfiOffset);
      break;
    case "Williams %R":
      replaceObje.Offset = parseInt(objOf?.e_wrOffset);
      replaceObje.Period = parseInt(objOf?.e_wrPeriod);
      break;
    case "Moving Average Deviation (MA Dev)":
      replaceObje.Field = objOf?.e_madField;
      replaceObje.Period = parseInt(objOf?.e_madPeriod);
      replaceObje.Type = objOf?.e_madType;
      replaceObje.Offset = parseInt(objOf?.e_madOffset);
      replaceObje.Pop = objOf?.e_madPop;
      break;
    case "MACD (Moving Average Convergence/Divergence)":
      replaceObje.MaPeriod = parseInt(objOf?.e_macd_fastMaPeriod);
      replaceObje.macd_slowMaPeriod = parseInt(objOf?.e_macd_slowMaPeriod);
      replaceObje.macd_signalPeriod = parseInt(objOf?.e_macd_signalPeriod);
      replaceObje.macdOffset = parseInt(objOf?.e_macdOffset);
      break;
    case "OBV (On Balance Volume)":
      replaceObje.offset = parseInt(objOf?.e_obvOffset);
      break;
    case "Aroon-Down":
      replaceObje.period = parseInt(objOf?.e_aroonDownPeriod);
      replaceObje.offset = parseInt(objOf?.e_aroonDownOffset);
      break;
    case "Aroon-Up":
      replaceObje.period = parseInt(objOf?.e_aroonUpPeriod);
      replaceObje.offset = parseInt(objOf?.e_aroonUpOffset);
      break;
    case "Awesome Oscillator":
      replaceObje.offset = parseInt(objOf?.e_aOscilatorOffset);
      break;
    case "VWAP (Volume Weighted Average Price)":
      replaceObje.offset = parseInt(objOf?.e_vwapOffset);
      break;
    case "Parabolic SAR":
      replaceObje.minAF = parseInt(objOf?.e_psarMinimumAF);
      replaceObje.maxAF = parseInt(objOf?.e_psarMaximumAF);
      replaceObje.offset = parseInt(objOf?.e_psarOffset);
      break;
    case "Vortex Indicator":
      replaceObje.period = parseInt(objOf?.e_vortexIPeriod);
      replaceObje.signal = objOf?.e_vortexISignal;
      replaceObje.offset = parseInt(objOf?.e_vortexIOffset);
      break;
    case "Number":
      replaceObje.period = parseInt(objOf?.e_period);
      break;
    case "Volume of a candle":
      replaceObje.volume = objOf?.e_volume;
      replaceObje.offset = parseInt(objOf?.e_offset);
      break;
    case "Donchian Channel":
      replaceObje.channel = objOf?.e_donchianChannel;
      replaceObje.period = parseInt(objOf?.e_donchianHighPeriod);
      replaceObje.period1 = parseInt(objOf?.e_donchianLowPeriod);
      replaceObje.offset = parseInt(objOf?.e_donchianOffset);
      break;
    case "Stochastic":
      replaceObje.period = parseInt(objOf?.e_stochasticPeriod);
      replaceObje.type = objOf?.e_stochasticType;
      replaceObje.smooth = objOf?.e_stochasticSmooth;
      replaceObje.offset = parseInt(objOf?.e_stochasticOffset);
      break;
    case "Chaikin Money Flow":
      replaceObje.period = parseInt(objOf?.e_chaikinPeriod);
      replaceObje.offset = parseInt(objOf?.e_chaikinOffset);
      break;
    case "MACD-histogram":
    case "MACD-signal":
      replaceObje.period1 = parseInt(objOf?.e_macdsFastMaPeriod);
      replaceObje.period2 = parseInt(objOf?.e_macdsSlowMaPeriod);
      replaceObje.period3 = parseInt(objOf?.e_macdsSignalPeriod);
      replaceObje.offset = parseInt(objOf?.e_macdsOffset);
      break;
    case "Commodity Channel Index":
      replaceObje.period = parseInt(objOf?.e_cciPeriod);
      replaceObje.offset = parseInt(objOf?.e_cciOffset);
      break;
    case "Center Of Gravity" :
    case "Price Rate of Change":
      replaceObje.field = objOf?.e_rocField;
      replaceObje.period = parseInt(objOf?.e_rocPeriod);
      replaceObje.offset = parseInt(objOf?.e_rocOffset);
      break;
    case "RSI (Relative Strength Index)":
      replaceObje.period = parseInt(objOf?.e_rsiPeriod);
      replaceObje.offset = parseInt(objOf?.e_rsiOffset);
      break;
    case "RSI Moving Average":
      replaceObje.period = parseInt(objOf?.e_rsimaPeriod);
      replaceObje.maType = objOf?.e_rsimaType;
      replaceObje.maPeriod = parseInt(objOf?.e_rsimaMaPeriod);
      replaceObje.offset = parseInt(objOf?.e_rsimaOffset);
      break;
    case "Supertrend":
      replaceObje.period = parseInt(objOf?.e_superTPeriod);
      replaceObje.multiply = parseInt(objOf?.e_superTMultiply);
      replaceObje.offset = parseInt(objOf?.e_superTOffset);
      break;
    case "TR (True Range)":
      replaceObje.offset = parseInt(objOf?.e_trOffset);
      break;
    case "ATR (Average True Range)":
      replaceObje.period = parseInt(objOf?.e_atrPeriod);
      replaceObje.offset = parseInt(objOf?.e_atrOffset);
      break;
    case "NATR (Normalized Average True Range)":
      replaceObje.period = parseInt(objOf?.e_natrPeriod);
      replaceObje.offset = parseInt(objOf?.e_natrOffset);
      break;
    case "Bollinger %B":
      replaceObje.field = objOf?.e_bolingerBField;
      replaceObje.period = parseInt(objOf?.e_bolingerBPeriod);
      replaceObje.deviation = objOf?.e_bolingerBDev;
      replaceObje.type = objOf?.e_bolingerBType;
      replaceObje.offset = parseInt(objOf?.e_bolingerBOffset);
      break;
    case "MFI Moving Average (Money Flow Index Moving Average)":
      replaceObje.period = parseInt(objOf?.e_mfiMAPeriod);
      replaceObje.maPeriod = parseInt(objOf?.e_mfiMAMAPeriod);
      replaceObje.maType = objOf?.e_mfiMAType;
      replaceObje.offset = parseInt(objOf?.e_mfiMAOffset);
      break;
    case "Know Sure Thing":
      replaceObje.field = objOf?.e_kstField;
      replaceObje.LTRoc = objOf?.e_kstLTRoc;
      replaceObje.LTSma = objOf?.e_kstLTSma;
      replaceObje.Roc = objOf?.e_kstRoc;
      replaceObje.LSma = objOf?.e_kstLSma;
      replaceObje.HRoc = objOf?.e_kstHRoc;
      replaceObje.HSma = objOf?.e_kstHSma;
      replaceObje.HeRoc = objOf?.e_kstHeRoc;
      replaceObje.HeSma = objOf?.e_kstHeSma;
      replaceObje.SPeriod = parseInt(objOf?.e_kstSPeriod);
      replaceObje.Type = objOf?.e_kstType;
      replaceObje.offset = parseInt(objOf?.e_kstOffset);
      break;
    case "Ichimoku":
      replaceObje.Conversion = objOf?.e_ichiConversion;
      replaceObje.Base = objOf?.e_ichiBase;
      replaceObje.leadingSpanB = objOf?.e_leadingSpanB;
      replaceObje.laggingSpan = objOf?.e_laggingSpan;
      replaceObje.Line = objOf?.e_ichiLine;
      replaceObje.cloudShift = objOf?.e_cloudShift;
      replaceObje.offset = parseInt(objOf?.e_ichiOffset);
      break;
    case "Elder Force Index" :
    case "MOM (Momentum Indicator)":
      replaceObje.period = parseInt(objOf?.period);
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "Chande Momentum Oscillator":
      replaceObje.period = parseInt(objOf?.period);
      replaceObje.offset = parseInt(objOf?.offset);
      break;
    case "Standard Deviation":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.e_stdPeriod);
      replaceObje.field = objOf?.e_stdField;
      replaceObje.stdDev = parseInt(objOf?.e_stdDev);
      replaceObje.maType = objOf?.e_maType;
      replaceObje.offset = parseInt(objOf?.e_offset);
      break;

    case "Ultimate Oscillator":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.length1 = parseInt(objOf?.e_length1);
      replaceObje.length2 = parseInt(objOf?.e_length2);
      replaceObje.length3 = parseInt(objOf?.e_length3);
      replaceObje.offset = parseInt(objOf.e_offset);
      break;

    case "Nth Candle":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.e_field;
      replaceObje.range = objOf?.e_range;
      replaceObje.num = parseInt(objOf?.e_num);
      break;

    case "Chande Momentum Oscillator" :
    case "Median Price":
    case "Choppiness Index" :
    case "-DI (Minus Directional Index)" :
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = parseInt(objOf?.e_period);
      replaceObje.offset = parseInt(objOf?.e_offset);
      break;
    case "ATR Trailing Stoploss" :
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.multiplier = parseInt(objOf?.e_multiplier)
      replaceObje.period = parseInt(objOf?.e_period);
      replaceObje.offset = parseInt(objOf?.e_offset);
      break;
    case "Camarilla Pivot Points" :
    case "Central Pivot Range" :
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.signal = objOf?.e_signal;
      break;
    case "Pivot Points":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.signal = objOf?.e_signal;
      replaceObje.type = objOf?.e_type;
      replaceObje.continous = objOf?.e_continous;
      break;
    case "Elder Ray Index":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = objOf?.e_period;
      replaceObje.power = objOf?.e_power;
      replaceObje.offset = objOf?.e_offset;
      break;
    case "UBB (Upper Bollinger Band)":
    case "MBB (Middle Bollinger Band)":
    case "LBB (Lower Bollinger Band)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.period = objOf?.e_period;
      replaceObje.field = objOf?.e_field;
      replaceObje.offset = objOf?.e_offset;
      replaceObje.dev = objOf?.e_dev;
      replaceObje.type = objOf?.e_type;
      break;
    case "Phase Power Moving Average":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.e_field;
      replaceObje.powerLength = objOf?.e_powerLength;
      replaceObje.phase = objOf?.e_phase;
      replaceObje.power = objOf?.e_power;
      replaceObje.offset = objOf?.e_offset;
      break;
    case "TSI (True Strength Indicator)":
      replaceObje.function_id = objOf?.function_id;
      replaceObje.name = objOf?.name;
      replaceObje.field = objOf?.e_field;
      replaceObje.type = objOf?.e_type;
      replaceObje.long = objOf?.e_long;
      replaceObje.short = objOf?.e_short;
      replaceObje.signal = objOf?.e_signal;
      replaceObje.offset = objOf?.e_offset;
      break;
    default:
      break;
  }
};

export {
  helperModalSwitchFn,
  helperModalSwitchForMath,
  helperModalReplaceFn777,
  helperModalReplaceFn888,
};
