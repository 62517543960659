import { ListItem } from "@mui/material";
import React, { useState } from "react";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";

const CreateDeployPopup = ({
  openDeployModal,
  setOpentDeployModal,
  strategy,
  termCondition,
  settermCondition,
  handleDeployed,
  trading_type,
  set_trading_Type,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleClose = () => {
    setOpentDeployModal(false);
  };

  const handleClickTearm = () => {
    setIsChecked(true);
    settermCondition(false);
  };

  const handleTradeType = (type) => {
    set_trading_Type(type);
  };

  return (
    <Modal
      show={openDeployModal}
      onHide={handleClose}
      animation={false}
      centered
      size="lg"
    >
      <Modal.Body
      // id="create-strategy-modal"
      //   className="d-flex align-items-center justify-content-center"
      >
        <div className="p-3">
          <h5 className="text-start fw-bolder">Deploy</h5>
          <br />
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <div className="reddot"></div>
                    <h6 className="ps-2">Instruments</h6>
                  </div>
                  <div>1</div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>{strategy?.stock_name}</div>
                  <div className="d-flex flex-column text-center">
                    <div className="text-end">Quantity</div>
                    <div
                      className="text-center w-50 ml-auto"
                      style={{
                        textAlign: "-webkit-center",
                        marginLeft: "auto",
                      }}
                    >
                      <Form.Control
                        centered
                        type="text"
                        className="w-100 ml-auto"
                        size="sm"
                        readOnly
                        value={strategy?.quantity}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex">
                  <div className="greendot"></div>
                  <h6 className="ps-2">Deployment Type</h6>
                </div>
                <div className="d-flex flex-row">
                  <ListGroup horizontal>
                    <ListGroup.Item
                      onClick={() => handleTradeType(1)}
                      className={`${trading_type === 1 ? "active" : ""}`}
                    >
                      <i className="fa fa-newspaper-o"></i> &nbsp;
                      {trading_type === 1 ? "Live trade" : ""}
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={() => handleTradeType(2)}
                      className={`${trading_type === 2 ? "active" : ""}`}
                    >
                      <i className="fa fa-wifi"></i>&nbsp;{" "}
                      {trading_type === 2 ? "Paper trade" : ""}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                <div className="d-flex pt-3">
                  <span className="fw-bold"> Option </span>
                </div>
                <div className="d-flex flex-column">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formGroupEmail"
                  >
                    <Form.Label className="ml-auto">Strategy Cycle</Form.Label>
                    {/* <span></span> */}
                    <Form.Select
                      disabled
                      aria-label="Default select example"
                      value="1"
                    >
                      <option>Select option </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="d-flex flex-column">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>Holding Type</Form.Label>
                    <Form.Select
                      disabled
                      aria-label="Default select example"
                      value={strategy?.holding_type}
                    >
                      <option value="CNS/NRML">CNS/NRML</option>
                      <option value="MIS">MIS</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="d-flex flex-column">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>Order Type</Form.Label>
                    <Form.Select
                      disabled
                      value="1"
                      aria-label="Default select example"
                    >
                      <option value="1">Market</option>
                      <option value="2">Limit</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="d-flex pt-3">
                  <Form.Check // prettier-ignore
                    type="checkbox"
                    onChange={handleClickTearm}
                  />
                  &nbsp;&nbsp; I accept the &nbsp;
                  <span className="text-primary"> trading terms</span>
                </div>
                {termCondition && (
                  <div className="d-flex">
                    <span className="text-error">
                      Please select trading terms
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="btn-color"
          onClick={() => handleDeployed(isChecked)}
        >
          {trading_type === 1 ? "Live Trade" : "Paper Trade"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CreateDeployPopup;
