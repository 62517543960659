import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const OrderCard = () => {
  return (
    <>
      <Card className="portfolio-card">
        <Card.Body className="portfolio-card-body order-data p-0">
          <br />
          <div className="row lh-lg">
            <div className="col-12 ">
              <h1>0</h1>
              <Link to="/trades?tab=pending" className="text-muted py-2 text-decoration-none"> Open Orders</Link>
              <br />
              <Link to="/techincal" className="text-muted text-decoration-none py-2"> Go to Technicals</Link>
            </div>
             <hr className=" " />
          </div>
          <br />
          <div className="row lh-lg">
            <div className="col-12">
              <h1>0</h1>
              <span className="text-muted">
                <i className="fa fa-check"></i> 
                 <Link to="/trades?tab=executed" className="text-muted text-decoration-none pt-2"> Executed Orders</Link>
              </span>
              <br />
              <Link to="/techincal" className="text-muted text-decoration-none pt-2"> Go to Technicals</Link>
            </div>
            <hr />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default OrderCard;
