import React from "react";
import CandleInterval from "./CandleInterval";
const InstanceDisplay = (props) => {
  const {
    instanceData,
    formData,
    instanceName,
    index,
    setShowClose,
    setShowOpen,
    setShowHighPrice,
    setShowLowPrice,
    setShowRSI,
    setShowUlcer,
    setShowDEMA,
    setShowTEMA,
    setShowTMA,
    setShowVWMA,
    setShowSMA,
    setShowEMA,
    setShowWMA,
    setShowNumber,
    setShowMoneyFlowIndex,
    setShowWilliams,
    setShowMad,
    setShowMacd,
    setShowObv,
    setShowAroonDown,
    setShowAroonUp,
    setShowOscilator,
    setShowVWAP,
    setShowpsar,
    setShowVortexI,
    setShowVolume,
    setShowDonchian,
    setShowStochastic,
    setShowChaikin,
    setShowMacdSignal,
    setShowCCI,
    setShowRoc,
    setShowRSIMA,
    setShowTR,
    setShowATR,
    setShowNATR,
    setShowSuperT,
    setShowBB,
    setShowMfiMa,
    setShowIchimoku,
    setShowKST,
    setShowMOM,
    setisStdDeviation,
    setShowUltimateOscillator,
    setShowNthCandle,
    isShowingAddButton,
    handleAdd,
    handleEdit,
    handleRemoveButtonClick,
    setShowCandleModal,
    getIndex,
    buttonStyle,
    addButton,
    handleAddButtonClick,
    setShowDropdown,
    setInnerAdd,
    setOuterAdd,
    isMathFunction,
    instance,
    isExit,
    setMedianPrice,
    setShowChande,
    setShowATRTrailModal,
    setShowChoppiness,
    setCentralRange,
    setCamrillaPivot,
    setShowPivotPoint,
    setShowElderRay,
    setBollingUML,
    setPowerMoving,
    setShowDI,
    setShowTsi,
  } = props;
  console.log("instanceData", instanceData?.name);

  const renderButtonContent = () => {
    if (!instanceData?.name) return "";
   const bollingerFields = isExit
     ? ["e_field", "e_period", "e_dev", "e_type", "e_offset"]
     : ["field", "period", "dev", "type", "offset"];
     
    const fieldMappings = {
      Close: isExit ? ["e_closeOffset"] : ["closeOffset"],
      "Open price": isExit ? ["e_offset"] : ["offset"],
      "High price": isExit ? ["e_highOffset"] : ["highOffset"],
      "Low price": isExit ? ["e_lowOffset"] : ["lowOffset"],
      "RSI (Relative Strength Index)": isExit
        ? ["e_rsiPeriod", "e_rsiOffset"]
        : ["rsiPeriod", "rsiOffset"],
      Ulcer: isExit
        ? ["e_ulcerField", "e_ulcerPeriod", "e_ulcerOffset"]
        : ["ulcerField", "ulcerPeriod", "ulcerOffset"],
      "DEMA (Double Exponential Moving Average)": isExit
        ? ["e_field", "e_period", "e_offset"]
        : ["field", "period", "offset"],
      "TEMA (Triple Exponential Moving Average)": isExit
        ? ["e_temaField", "e_temaPeriod", "e_temaOffset"]
        : ["temaField", "temaPeriod", "temaOffset"],
      "TriMA (Triangular Moving Average)": isExit
        ? ["e_tmaField", "e_tmaPeriod", "e_tmaOffset"]
        : ["tmaField", "tmaPeriod", "tmaOffset"],
      "VWMA (Volume Weighted Moving Average)": isExit
        ? ["e_vwmaField", "e_vwmaPeriod", "e_vwmaOffset"]
        : ["vwmaField", "vwmaPeriod", "vwmaOffset"],
      "SMA (Simple Moving Average)": isExit
        ? ["e_field", "e_period", "e_offset"]
        : ["field", "period", "offset"],
      "EMA (Exponential Moving Average)": isExit
        ? ["e_field", "e_period", "e_offset"]
        : ["field", "period", "offset"],
      "WMA (Weighted Moving Average)": isExit
        ? ["e_wmaField", "e_wmaPeriod", "e_wmaOffset"]
        : ["wmaField", "wmaPeriod", "wmaOffset"],
      Number: isExit ? ["e_period"] : ["period"],
      "Money Flow Index": isExit
        ? ["e_mfiPeriod", "e_mfiOffset"]
        : ["mfiPeriod", "mfiOffset"],
      "Williams %R": isExit ? ["e_wrOffset"] : ["wrOffset"],
      "Moving Average Deviation (MA Dev)": isExit
        ? [
            "e_madField",
            "e_madPeriod",
            "e_madType",
            "e_madOffset1",
            "e_madOffset2",
          ]
        : ["madField", "madPeriod", "madType", "madOffset", "madPop"],
      "MACD (Moving Average Convergence/Divergence)": isExit
        ? [
            "e_macd_fastMaPeriod",
            "e_macd_slowMaPeriod",
            "e_macd_signalPeriod",
            "e_macdOffset",
          ]
        : [
            "macd_fastMaPeriod",
            "macd_slowMaPeriod",
            "macd_signalPeriod",
            "macdOffset",
          ],
      "OBV (On Balance Volume)": isExit ? ["e_obvOffset"] : ["obvOffset"],
      "Aroon-Down": isExit
        ? ["e_aroonDownPeriod", "e_aroonDownOffset"]
        : ["aroonDownPeriod", "aroonDownOffset"],
      "Aroon-Up": isExit
        ? ["e_aroonUpPeriod", "e_aroonUpOffset"]
        : ["aroonUpPeriod", "aroonUpOffset"],
      "Awesome Oscillator": isExit
        ? ["e_aOscilatorOffset"]
        : ["aOscilatorOffset"],
      "VWAP (Volume Weighted Average Price)": isExit
        ? ["e_vwapOffset"]
        : ["vwapOffset"],
      "Parabolic SAR": isExit
        ? ["e_psarMinimumAF", "e_psarMaximumAF", "e_psarOffset"]
        : ["psarMinimumAF", "psarMaximumAF", "psarOffset"],
      "Vortex Indicator": isExit
        ? ["e_vortexIPeriod", "e_vortexISignal", "e_vortexIOffset"]
        : ["vortexIPeriod", "vortexISignal", "vortexIOffset"],
      "Volume of a candle": isExit ? ["e_volume"] : ["volume"],
      "Donchian Channel": isExit
        ? [
            "e_donchianChannel",
            "e_donchianHighPeriod",
            "e_donchianLowPeriod",
            "e_donchianOffset",
          ]
        : [
            "donchianChannel",
            "donchianHighPeriod",
            "donchianLowPeriod",
            "donchianOffset",
          ],
      Stochastic: isExit
        ? [
            "e_stochasticPeriod",
            "e_stochasticType",
            "e_stochasticSmooth",
            "e_stochasticOffset",
          ]
        : [
            "stochasticPeriod",
            "stochasticType",
            "stochasticSmooth",
            "stochasticOffset",
          ],
      "Chaikin Money Flow": isExit
        ? ["e_chaikinPeriod", "e_chaikinOffset"]
        : ["chaikinPeriod", "chaikinOffset"],
      "MACD-signal": isExit
        ? [
            "e_macdsFastMaPeriod",
            "e_macdsSlowMaPeriod",
            "e_macdsSignalPeriod",
            "e_macdsOffset",
          ]
        : [
            "macdsFastMaPeriod",
            "macdsSlowMaPeriod",
            "macdsSignalPeriod",
            "macdsOffset",
          ],
      "Commodity Channel Index": isExit
        ? ["e_cciPeriod", "e_cciOffset"]
        : ["cciPeriod", "cciOffset"],
      "Price Rate of Change": isExit
        ? ["e_rocField", "e_rocPeriod", "e_rocOffset"]
        : ["rocField", "rocPeriod", "rocOffset"],
      "&&": "and",
      "||": "or",
      "RSI Moving Average": isExit
        ? ["e_rsimaPeriod", "e_rsimaType", "e_rsimaMaPeriod", "e_rsimaOffset"]
        : ["rsimaPeriod", "rsimaType", "rsimaMaPeriod", "rsimaOffset"],
      "TR (True Range)": isExit ? ["e_trOffset"] : ["trOffset"],
      "ATR (Average True Range)": isExit
        ? ["e_atrPeriod", "e_atrOffset"]
        : ["atrPeriod", "atrOffset"],
      "NATR (Normalized Average True Range)": isExit
        ? ["e_natrPeriod", "e_natrOffset"]
        : ["natrPeriod", "natrOffset"],
      Supertrend: isExit
        ? ["e_superTPeriod", "e_superTMultiply", "e_superTOffset"]
        : ["superTPeriod", "superTMultiply", "superTOffset"],
      "Bollinger %B": isExit
        ? [
            "e_bolingerBField",
            "e_bolingerBPeriod",
            "e_bolingerBDev",
            "e_bolingerBType",
            "e_bolingerBOffset",
          ]
        : [
            "bolingerBField",
            "bolingerBPeriod",
            "bolingerBDev",
            "bolingerBType",
            "bolingerBOffset",
          ],
      "MFI Moving Average (Money Flow Index Moving Average)": isExit
        ? ["e_mfiMAPeriod", "e_mfiMAType", "e_mfiMAMAPeriod", "e_mfiMAOffset"]
        : ["mfiMAPeriod", "mfiMAType", "mfiMAMAPeriod", "mfiMAOffset"],
      Ichimoku: isExit
        ? [
            "e_ichiConversion",
            "e_ichiBase",
            "e_leadingSpanB",
            "e_laggingSpan",
            "e_ichiLine",
            "e_cloudShift",
            "e_ichiOffset",
          ]
        : [
            "ichiConversion",
            "ichiBase",
            "leadingSpanB",
            "laggingSpan",
            "ichiLine",
            "cloudShift",
            "ichiOffset",
          ],
      "Know Sure Thing": isExit
        ? [
            "e_kstField",
            "e_kstLTRoc",
            "e_kstLTSma",
            "e_kstRoc",
            "e_kstLSma",
            "e_kstHRoc",
            "e_kstHSma",
            "e_kstHeRoc",
            "e_kstHeSma",
            "e_kstSPeriod",
            "e_kstType",
            "e_kstOffset",
          ]
        : [
            "kstField",
            "kstLTRoc",
            "kstLTSma",
            "kstRoc",
            "kstLSma",
            "kstHRoc",
            "kstHSma",
            "kstHeRoc",
            "kstHeSma",
            "kstSPeriod",
            "kstType",
            "kstOffset",
          ],
      "MOM (Momentum Indicator)": isExit
        ? ["period", "offset"]
        : ["period", "offset"],
      "Standard Deviation": isExit
        ? ["e_stdPeriod", "e_stdField", "e_stdDev", "e_maType", "e_offset"]
        : ["stdPeriod", "stdField", "stdDev", "maType", "offset"],
      "Ultimate Oscillator": isExit
        ? ["e_length1", "e_length2", "e_length3", "e_offset"]
        : ["length1", "length2", "length3", "offset"],
      "Nth Candle": isExit
        ? ["e_field", "e_range", "e_num"]
        : ["field", "range", "num"],
      "MACD-histogram": isExit
        ? [
            "e_macdsFastMaPeriod",
            "e_macdsSlowMaPeriod",
            "e_macdsSignalPeriod",
            "e_macdsOffset",
          ]
        : [
            "macdsFastMaPeriod",
            "macdsSlowMaPeriod",
            "macdsSignalPeriod",
            "macdsOffset",
          ],
      "Median Price": isExit ? ["e_period", "e_offset"] : ["period", "offset"],
      "Chande Momentum Oscillator": isExit
        ? ["e_period", "e_offset"]
        : ["period", "offset"],
      "ATR Trailing Stoploss": isExit
        ? ["e_multiplier", "e_period", "e_offset"]
        : ["multiplier", "period", "offset"],
      "Choppiness Index": isExit
        ? ["e_period", "e_offset"]
        : ["period", "offset"],
      "Central Pivot Range": isExit ? ["e_signal"] : ["signal"],
      "Camarilla Pivot Points": isExit ? ["e_signal"] : ["signal"],
      "Pivot Points": isExit
        ? ["e_signal", "e_type", "e_continous"]
        : ["signal", "type", "continous"],
      "Center Of Gravity": isExit
        ? ["e_rocField", "e_rocPeriod", "e_rocOffset"]
        : ["rocField", "rocPeriod", "rocOffset"],
      "Elder Ray Index": isExit
        ? ["e_power", "e_period", "e_offset"]
        : ["power", "period", "offset"],
      "Elder Force Index": isExit ? ["period", "offset"] : ["period", "offset"],
      "UBB (Upper Bollinger Band)": bollingerFields,
      "MBB (Middle Bollinger Band)": bollingerFields,
      "LBB (Lower Bollinger Band)": bollingerFields,
      "Bollinger Bandwidth": bollingerFields,
      "Phase Power Moving Average": isExit
        ? ["e_field", "e_powerLength", "e_phase", "e_power", "e_offset"]
        : ["field", "powerLength", "phase", "power", "offset"],
      "-DI (Minus Directional Index)": isExit
        ? ["e_period", "e_offset"]
        : ["period", "offset"],
      "TSI (True Strength Indicator)": isExit
        ? ["e_field", "e_type", "e_long", "e_short", "e_signal", "e_offset"]
        : ["field", "type", "long", "short", "signal", "offset"],
      default: instanceData?.name,
    };

    const fields = fieldMappings[instanceData?.name] || [];
        console.log("fieldValues", fields);

    let fieldValues;
    if (Array.isArray(fields)) {
      fieldValues = fields
        .map((field) => {
          const value = instanceData[field];
          return value !== undefined && value !== null ? value : ""; // Ensure it is included
        })
        .join(",");
    } else {
      fieldValues = instanceData[fields] || "";
    }
    if (fields === "and" || fields === "or") return fields;
    console.log("checkData", instanceData?.name);

    if (fieldValues) return `${instanceData.name} (${fieldValues})`;
    
    return `${instanceData.name}`;
  };
  const handleButtonClick = () => {
    if (formData) {
      formData[isExit ? "selectedOption2" : "selectedOption1"] =
        instanceData?.name;
      formData.clickedFunction = instanceName;
      if (isMathFunction) {
        getIndex(index, instance);
      }
    }

    switch (instanceData.name) {
      case "Close":
        setShowClose(true);
        break;
      case "Open price":
        setShowOpen(true);
        break;
      case "High price":
        setShowHighPrice(true);
        break;
      case "Low price":
        setShowLowPrice(true);
        break;
      case "RSI (Relative Strength Index)":
        setShowRSI(true);
        break;
      case "Ulcer":
        setShowUlcer(true);
        break;
      case "DEMA (Double Exponential Moving Average)":
        setShowDEMA(true);
        break;
      case "TEMA (Triple Exponential Moving Average)":
        setShowTEMA(true);
        break;
      case "TriMA (Triangular Moving Average)":
        setShowTMA(true);
        break;
      case "VWMA (Volume Weighted Moving Average)":
        setShowVWMA(true);
        break;
      case "SMA (Simple Moving Average)":
        setShowSMA(true);
        break;
      case "EMA (Exponential Moving Average)":
        setShowEMA(true);
        break;
      case "WMA (Weighted Moving Average)":
        setShowWMA(true);
        break;
      case "Number":
        setShowNumber(true);
        break;
      case "Money Flow Index":
        setShowMoneyFlowIndex(true);
        break;
      case "Williams %R":
        setShowWilliams(true);
        break;
      case "Moving Average Deviation (MA Dev)":
        setShowMad(true);
        break;
      case "MACD (Moving Average Convergence/Divergence)":
        setShowMacd(true);
        break;
      case "OBV (On Balance Volume)":
        setShowObv(true);
        break;
      case "Aroon-Down":
        setShowAroonDown(true);
        break;
      case "Aroon-Up":
        setShowAroonUp(true);
        break;
      case "Awesome Oscillator":
        setShowOscilator(true);
        break;
      case "VWAP (Volume Weighted Average Price)":
        setShowVWAP(true);
        break;
      case "Parabolic SAR":
        setShowpsar(true);
        break;
      case "Vortex Indicator":
        setShowVortexI(true);
        break;
      case "Volume of a candle":
        setShowVolume(true);
        break;
      case "Donchian Channel":
        setShowDonchian(true);
        break;
      case "Stochastic":
        setShowStochastic(true);
        break;
      case "Chaikin Money Flow":
        setShowChaikin(true);
        break;
      case "MACD-histogram":
      case "MACD-signal":
        setShowMacdSignal(true);
        break;
      case "Commodity Channel Index":
        setShowCCI(true);
        break;
      case "Center Of Gravity":
      case "Price Rate of Change":
        setShowRoc(true);
        break;
      case "RSI Moving Average":
        setShowRSIMA(true);
        break;
      case "TR (True Range)":
        setShowTR(true);
        break;
      case "ATR (Average True Range)":
        setShowATR(true);
        break;
      case "NATR (Normalized Average True Range)":
        setShowNATR(true);
        break;
      case "Supertrend":
        setShowSuperT(true);
        break;
      case "Bollinger %B":
        setShowBB(true);
        break;
      case "MFI Moving Average (Money Flow Index Moving Average)":
        setShowMfiMa(true);
        break;
      case "Ichimoku":
        setShowIchimoku(true);
        break;
      case "Know Sure Thing":
        setShowKST(true);
        break;
      case "Elder Force Index":
      case "MOM (Momentum Indicator)":
        setShowMOM(true);
        break;
      case "Standard Deviation":
        setisStdDeviation(true);
        break;
      case "Ultimate Oscillator":
        setShowUltimateOscillator(true);
        break;
      case "Nth Candle":
        setShowNthCandle(true);
        break;
      case "Chande Momentum Oscillator":
        setShowChande(true);
        break;
      case "Median Price":
        setMedianPrice(true);
        break;
      case "ATR Trailing Stoploss":
        setShowATRTrailModal(true);
        break;
      case "Choppiness Index":
        setShowChoppiness(true);
        break;
      case "Central Pivot Range":
        setCentralRange(true);
        break;
      case "Camarilla Pivot Points":
        setCamrillaPivot(true);
        break;
      case "Pivot Points":
        setShowPivotPoint(true);
        break;
      case "Elder Ray Index":
        setShowElderRay(true);
      case "UBB (Upper Bollinger Band)":
      case "MBB (Middle Bollinger Band)":
      case "LBB (Lower Bollinger Band)":
      case "Bollinger Bandwidth":
        setBollingUML(true);
        break;
      case "Phase Power Moving Average":
        setPowerMoving(true);
        break;
      case "-DI (Minus Directional Index)":
        setShowDI(true);
        break;
      case "TSI (True Strength Indicator)":
        setShowTsi(true);
        break;
      default:
        break;
    }
  };
  
  console.log("isIncludes", instanceName);
  return (
    <div className="d-flex">
      {instanceData?.add === true && (
        <button
          onClick={() => {
            setShowDropdown(true);
            setInnerAdd(true);
            setOuterAdd(false);
            handleAddButtonClick(instanceName);
          }}
          style={addButton}
        >
          {"Add"}
        </button>
      )}
      {/* // below code  is for indicators // */}
      {isMathFunction ? (
        <>
          {instanceData?.name !== "Number" &&
            instanceData?.name !== "&&" &&
            instanceData?.name !== "||" &&
            instanceData?.name !== "+" && 
            instanceData?.name !== "-" && 
            instanceData?.name !== "*" && 
            instanceData?.name !== "/" && 
            !instanceName.startsWith("comparator") &&
            !instanceName.startsWith("operators") && (
              <CandleInterval
                index={index}
                instanceType={instance}
                data={instanceData}
                formData={formData}
                instanceName={instanceName}
                setShowCandleModal={setShowCandleModal}
                getIndex={getIndex}
                isExit={isExit}
              />
            )}
          &ensp;
          {instanceData?.name && (
            <button
              onClick={handleButtonClick}
              style={{
                ...buttonStyle,
                color: "#0e6efd",
                fontWeight: "500",
              }}
            >
              {renderButtonContent()}
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveButtonClick(instanceName, index, instance);
                }}
                className={`fa-solid fa-xmark ${
                  isExit ? "iconExitContainer" : "iconContainer"
                }`}
                style={{
                  color: "#7AB1DD",
                }}
              ></i>
              &ensp;
            </button>
          )}
        </>
      ) : isShowingAddButton ? (
        <button
          className="stratergyAddBtn"
          onClick={() => {
            handleAdd(instanceData, index);
          }}
        >
          Add
        </button>
      ) : (
        <>
          {instanceData?.name !== "Number" &&
            instanceData?.name !== "Nth Candle" &&
            instanceData?.name !== "&&" &&
            instanceData?.name !== "||" &&
            !instanceName.startsWith("comparator") &&
            !instanceName.startsWith("operators") && (
              <CandleInterval
                index={index}
                instanceType=""
                data={instanceData}
                formData={formData}
                instanceName={instanceName}
                setShowCandleModal={setShowCandleModal}
                getIndex={getIndex}
              />
            )}
          &ensp;
          {instanceData?.name && (
            <button
              onClick={handleButtonClick}
              style={{
                ...buttonStyle,
                color: "#0e6efd",
                fontWeight: "500",
              }}
            >
              {/* // this is checking that formula is test or not */}
              {renderButtonContent()}
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(instanceData, index);
                }}
                className={`fa-solid fa-xmark ${
                  isExit ? "iconExitContainer" : "iconContainer"
                }`}
                style={{
                  color: "#7AB1DD",
                }}
              ></i>
              &ensp;
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default InstanceDisplay;
