import React from "react";

const Close_I = ({
  index,
  instanceData,
  instanceName,
  handleRemoveButtonClick,
  setShowClose,
  formData,
  item,
  setShowCandleModal,
  getIndex,
  instance,
  exit,
  isShowingAddButton,
  handleAdd ,
  handleEdit ,
}) => {
  let data = item
    ? item
    : instanceData?.body
    ? instanceData?.body[index]
    : instanceData;
  return (
    <div className="d-flex">
      {isShowingAddButton ? (
        <button
        className="stratergyAddBtn"
          onClick={() => {
            handleAdd(instanceData, index);
          }}
        >
          Add
        </button>
      ) : (
        <>
          <div
            className="hover-underline"
            style={{ cursor: "pointer" }}
            onClick={() => {
              console.log("showDrop", instanceData);
              formData.selectedOption1 = instanceData?.name;
              formData.clickedFunction = instanceName;
              setShowCandleModal(true);
              getIndex(index, instance);
            }}
          >
            {data?.candleIntervalId}
          </div>
          &ensp;
          <button
            onClick={() => {
              setShowClose(true);
              formData.selectedOption1 = data?.name;
              formData.clickedFunction = instanceName;
              getIndex(index, instance);
            }}
            className="buttonStyle"
          >
            {exit
              ? `${data?.name} (${data?.e_closeOffset})`
              : `${data?.name} (${data?.closeOffset})`}
            <i
              onClick={(e) => {
                e.stopPropagation();
                if(instance === "body1" || instance === "body2"){
                  handleRemoveButtonClick(instanceName, index, instance);
                }else {
                  handleEdit(instanceData, index);
                }
              }}
              className= {`fa-solid fa-xmark iconContainer`}
              style={{
                color : "#7AB1DD"
             }}
            ></i>
            &ensp;
          </button>
        </>
      )}
    </div>
  );
};

export default Close_I;
