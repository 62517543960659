import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ZerodhaLogin from "../zerodha/ZerodhaLogin";

const ZerodhaLoginModal = ({
    showCreatePopup,
    handelClickCreateStrategy,
    setShowCreatePopup,
    paisa5data,
    connectBtn,
    handleClose,
    handleChangeConnect,
    setActiveaccount,
    setMyacc,
    setActiveUser,state
}) => {
    return(
        <>
            <Modal 
                show={showCreatePopup}
                onHide={handleClose}
                animation={false}
                centered
            >
                <Modal.Header>
                    <span className="fw-bold fs-3"> Zerodha Login </span>
                </Modal.Header>
                <Modal.Body>
                    <ZerodhaLogin handleClose={handleClose} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ZerodhaLoginModal;