import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Help = () => {
  const c = {
    paddingTop: "20px",
    paddingBottom: "50px",
    width: "100%",
    margin: "0px auto",
  };
  return (
    <div className="help-container">
      <div style={c}>
        <iframe
          src="/mymanual.pdf"
          title="p"
          frameborder="0"
          height="800px"
          width="100%"
        ></iframe>
      </div>
    </div>
  );
};
export default Help;
