import React from "react";
import { Carousel } from "react-bootstrap";

const Slider = () =>{
    return(
        <Carousel controls={false}> 
            <Carousel.Item>
                <img src="tread-1.jpg" className="mx-auto d-block"/>
            </Carousel.Item>
            <Carousel.Item>
                <img src="tread-2.jpg" className="img-fluid mx-auto d-block"/>
            </Carousel.Item>
            <Carousel.Item>
                <img src="tread-3.jpg" className="img-fluid mx-auto d-block"/>
            </Carousel.Item>
        </Carousel>
    )


}

export default Slider;