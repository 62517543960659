import React from "react";
import { Card } from "react-bootstrap";

const DiscoverStrategiesCard = ({ color, textColor }) => {
  return (
    <Card className="discovery-strategies-card">
      <Card.Body className="p-3 discovery-strategies-card-body">
        <div>
          <i className="fa fa-bar-chart"></i>
        </div>
        <div className="d-s-text">
          <span> Make the trend your friend. </span>
        </div>
        <div className="d-s-text2">
          <span> Trend Following </span>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DiscoverStrategiesCard;
