import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import "./Dropdown.css";

const Dropdown = ({ options, onSelect, value }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Set initial value when the value prop changes
    setSelectedOption(options.find((option) => option.value === value) || null);
  }, [value, options]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <div
        style={{
          padding: "10px",
          border: "1px solid #ccc",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleToggle}
      >
        <span style={{ marginRight: "5px" }}>
          {selectedOption ? selectedOption.label : value || "Select an option"}
        </span>
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
      {isOpen && (
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            position: "absolute",
            top: "100%",
            left: 0,
            backgroundColor: "#fff",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            border: "1px solid #ccc",
          }}
        >
          {options.map((option) => (
            <li
              key={option.value}
              style={{
                padding: "10px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                transition: "background-color 0.3s",
              }}
              onClick={() => handleOptionClick(option)}
            >
              <span style={{ marginRight: "5px" }}>{option.label}</span>
              {option.icon && <FontAwesomeIcon icon={option.icon} />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
