import React from "react";
import { Form, Table } from "react-bootstrap";

const TechnicalTable = () => {
    return(
        <>
        <Table>
            <tbody>
                <tr>
                    <td>
                    <Form>
                        <Form.Check // prettier-ignore
                        type="checkbox"
                        id={`default-checkbox`}
                        // label={`default checkbox`}
                        />
                    </Form>
                    </td>
                    <td>
                        <span>ONGS</span>
                        <span>NSE</span>
                    </td>
                    <td>
                        Barchart
                    </td>
                    <td>
                        <span>
                            189.60
                        </span>
                        <span>
                            -3.78%
                        </span>
                    </td>
                    <td>
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </td>
                </tr>

                <tr>
                    <td>
                    <Form>
                        <Form.Check 
                        type="checkbox"
                        id={`default-checkbox`}
                        
                        />
                    </Form>
                    </td>
                    <td>
                        <span>ONGS</span>
                        <span>NSE</span>
                    </td>
                    <td>
                        Barchart
                    </td>
                    <td>
                        <span>
                            189.60
                        </span>
                        <span>
                            -3.78%
                        </span>
                    </td>
                    <td>
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </td>
                </tr>
            </tbody>
        </Table>
        </>
    )
}
export default TechnicalTable;