import React from "react";
import { Link } from "react-router-dom";

const TradingIcon = (props) => {
    const {imgSrc , alt ,title , handleClick ,page } = props;
    const titleLength = title?.length;
  return (
    <>
     <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-4">
      <div className="broker-btn">
        <Link to={`/account?tab=trading&acc=${page}`}>
          <img src={imgSrc} alt={alt} />
          <h5 className={titleLength > 10 ? 'broker-btn-title-responsive' : 'broker-btn-title' }>{title}</h5>
        </Link>
      </div>
      </div>
    </>
  );
};
export default TradingIcon;
