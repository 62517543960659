import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
// const firebaseConfig = {
//   apiKey: "AIzaSyDgrNFHy-pn7MVIeeKItTUghv2v6wXajEM",
//   authDomain: "dalrotireact.firebaseapp.com",
//   projectId: "dalrotireact",
//   storageBucket: "dalrotireact.appspot.com",
//   messagingSenderId: "557842664467",
//   appId: "1:557842664467:web:35c81420d12058713783e6"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDUogQtyygYraK-vkB7JvyfswzviARAHQ8",
  authDomain: "dalroti-firebase.firebaseapp.com",
  projectId: "dalroti-firebase",
  storageBucket: "dalroti-firebase.appspot.com",
  messagingSenderId: "569048518178",
  appId: "1:569048518178:web:10bb918567743aa290c47b"
};

  const app = initializeApp(firebaseConfig);
  export const auth=getAuth(app);  

  

 