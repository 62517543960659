const shouldFetchScannerResult = (mainObj, allDataLength) => {
  return (
    (mainObj?.scan_on === "NIFTY 50" && allDataLength < 50) ||
    (mainObj?.scan_on === "NIFTY 100" && allDataLength < 101) ||
    (mainObj?.scan_on === "Energy" && allDataLength < 30) ||
    (mainObj?.scan_on === "Consumer Discretionary" && allDataLength < 30) ||
    (mainObj?.scan_on === "Industrials" && allDataLength < 30) ||
    (mainObj?.scan_on === "Health Care" && allDataLength < 30) ||
    (mainObj?.scan_on === "Information Technology" && allDataLength < 30) ||
    (mainObj?.scan_on === "Utilities" && allDataLength < 26)
  );
};

const setupScannerInterval = (
  scanConditions,
  clickLive,
  fetchScannerResult,
  getLiveResult,
  intervalTime = 1000
) => {
  return setInterval(() => {
    if (!clickLive) {
      if (scanConditions()) {
        fetchScannerResult("function");
      }
    }
    getLiveResult();
  }, intervalTime);
};

export { shouldFetchScannerResult, setupScannerInterval };
