import moment from "moment";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DiscoverScanner = ({ item }) => {
  const navigate = useNavigate();

  const [hover, setHover] = useState("");
  function getTimeframe(intervalId) {
    const timeframeMap = {
      "1m": "1 Minute",
      "3m": "3 Minutes",
      "5m": "5 Minutes",
      "10m": "10 Minutes",
      "15m": "15 Minutes",
      "30m": "30 Minutes",
      "60m": "60 Minutes",
      "1day": "1 Day",
    };

    return timeframeMap[intervalId] || "";
  }
  return (
    <Card 
      onMouseEnter={() => setHover(item.id)}
      onMouseLeave={() => setHover("")}
      className="discover-Scanner"
      style={{
        width: "200px",
        cursor: "pointer",
        marginRight:"8px",marginLeft:"0px"
        // boxShadow:
        //   "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19)",
      }}
      onClick={() =>
        navigate("/discover_scanner/result/" + item?.scanner_id, {
          state: {
            isDiscover: true,
          },
        })
      }
    >
      <Card.Body
        className="fs-6"
        style={{
          backgroundColor: hover === item.id ?  "#FCFCFC":"#f5f5f5",
          overflow: "hidden",
          borderRadius: "6px",
        }}
      >
        <Card.Title className="mb-4 fw-bolder" 
        style={{fontSize:"14px",height:"22px"}}>
            {item?.scanner_name?.length>38?`${item?.scanner_name?.slice(0,38)}...`:item?.scanner_name}
        </Card.Title>
          <div className="d-flex justify-content-between">
          <p className="mb-0" style={{fontSize:"11px"}}>{item?.scanner_details?.scan_on}</p>
            <div className="d-flex justify-content-center align-items-center" 
            style={{backgroundColor:"white",width:"70px",height:"20px",backgroundColor:"#27419F",color:"white"}}> 
               <p className="mb-0" style={{fontSize:"11px"}}>
               {item?.scanner_details?.entire_object ?
                getTimeframe(
              JSON.parse(item?.scanner_details?.entire_object)
                ?.candle_interval_id
            ):(getTimeframe((item?.scanner_details?.candle_interval_id)))}</p>
            </div>
          </div>
      </Card.Body>
    </Card>
  );
};

export default DiscoverScanner;
