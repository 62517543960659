import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function ToastMsg({toastShow , setToastShow}) {

  return (
    <>
       <ToastContainer className="d-inline-block p-3" position='bottom-start' bg="danger">
        <Toast onClose={() => setToastShow(false)} show={toastShow} delay={4000} autohide>
            <Toast.Header closeButton={false}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Error</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body className='Danger'>Strategy is already created</Toast.Body>
          </Toast>
        </ToastContainer>
    </>
  );
}

export default ToastMsg;