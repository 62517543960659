import React from "react";
import "../../styles/SkeletonLoader.css" ;

const SkeletonLoader = ({
  width = "100%",
  height = "100%",
  borderRadius = "4px",
  marginTop = "2%"
}) => {
  return (
    <>
      <div
        className="skeleton-wrapper"
        style={{
          "--skeleton-width": width,
          "--skeleton-height": height,
          "--skeleton-radius": borderRadius,
          "--skeleton-marginTop" : marginTop
        }}
      >
        <div className="skeleton"></div>
      </div>
    </>
  );
};

export default SkeletonLoader;
