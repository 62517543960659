import React from "react";
import { Form } from "react-bootstrap";

const NumberInputWithDecimal = ({ name, value, onChange, placeholder }) => {
  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    // Only allow numeric characters
    if (
      (charCode < 48 || charCode > 57) && // not a number
      charCode !== 43 && // not a '+'
      charCode !== 45 && // not a '-'
      charCode !== 46 // not a '.'
    ) {
      event.preventDefault();
    }
  };

  return (
    <Form.Control
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      onKeyPress={handleKeyPress}
      placeholder={placeholder}
    />
  );
};

export default NumberInputWithDecimal;
