import { helperModalReplaceFn777, helperModalReplaceFn888 } from "../helperModalSwitchFn";

const generateMathOutput = (
  formData,
  onFormChange,
  indicators,
  isSelectMax,
  isSelectMin,
  isExist
) => {
  const outputData = [];
  const items = Object.values(indicators);

  let currentGroup = [];
  let encounteredAndOr = false;

  // Function to rename properties in the body array
  const renameBodyProperties = (bodyArray) => {
    return bodyArray.map((item) => {
      const newItem = { ...item };
      for (const key in newItem) {
        if (key.includes("Field")) {
          newItem.field = newItem[key];
        } else if (key.includes("Period")) {
          newItem.period = newItem[key];
        } else if (key.includes("MaPeriod")){
            newItem.maPeriod = newItem[key];
        }else if (key.includes("Offset")) {
          newItem.offset = newItem[key];
        } else if (key.includes("Type")) {
          newItem.type = newItem[key];
        }
      }
      return newItem;
    });
  };
  // Function to handle isSelectMax scenario
  const handleSelectMax = (item) => {
    console.log("MAXITEM", item);
    const body1 = item.body1 ? renameBodyProperties(item.body1) : [];
    const body2 = item.body2 ? renameBodyProperties(item.body2) : [];

    return {
      function_id: item.function_id,
      function_type_id: item?.function_type_id,
      candleIntervalId: item?.candleIntervalId,
      name: item?.name,
      period: parseInt(item.period),
      body: [body1, body2],
    };
  };
  console.log("items-----", items);
  items.forEach((item) => {
    if (item.name === "&&" || item.name === "||") {
      // Push the current group of items into outputData
      if (currentGroup.length > 0) {
        outputData.push(currentGroup);
        currentGroup = []; // Reset currentGroup for the next group
      }
      // Push the "&&" or "||" item into a new array
      outputData.push([item]);
      encounteredAndOr = true;
      console.log("LOGICAL", item, outputData);
    } else {
      // Add comparator property based on the item's name
      if (item?.function_type_id === 3) {
        let replaceObje = {
          candleIntervalId: item?.candleIntervalId,
          function_id: item?.function_id,
          function_type_id: item?.function_type_id,
          name : item?.name
        };
        if(isExist === "exist") {
            helperModalReplaceFn888(item, replaceObje);  
        }else {
            helperModalReplaceFn777(item, replaceObje);
        }
        item = replaceObje;
      } else if (item?.function_type_id === 5) {
        item = {
          ...item,
          name: item?.name,
          function: item?.name || null,
          field: null,
          period: null,
          offset: null,
          ...(item?.name === "crosses above"
            ? { isCrossover: 2 }
            : item?.name === "crosses below"
            ? { isCrossover: 1 }
            : {}),
          comparator: getComparatorValue(item?.name),
        };
      } else if (item.function_id === 2 || item.function_id === 3) {
        if (isSelectMax || isSelectMin) {
          item = handleSelectMax(item);
        }
      } else {
        // If the item has a body array, rename its properties
        if (item.body && Array.isArray(item.body)) {
          item.body = renameBodyProperties(item.body);
        }
      }

      // Add the current item to currentGroup
      currentGroup.push(item);
      console.log("LOGICAL", currentGroup);
    }
  });
  if (!encounteredAndOr) {
    outputData.push(currentGroup);
  } else {
    // If "&&" or "||" is encountered, add the remaining currentGroup to outputData
    if (currentGroup.length > 0) {
      outputData.push(currentGroup);
    }
  }
  console.log("OUTPUT", outputData);
  // Convert outputData to the required format
  const formattedOutputData = outputData.map((group) => {
    return group.map((item) => {
      if (item.comparator) {
        return {
          ...item,
          name: item?.name,
          function: item?.name || null,
          field: null,
          period: null,
          offset: null,
          ...(item?.name === "crosses above"
            ? { isCrossover: 2 }
            : item?.name === "crosses below"
            ? { isCrossover: 1 }
            : {}),
          comparator: getComparatorValue(item?.name),
        };
      } else if (item.body1 || item.body2) {
        return handleSelectMax(item);
      } else if (item.body) {
        if (item.scripCode) {
          return {
            function_id: item.function_id,
            function_type_id: item?.function_type_id,
            body: item.body,
            name: item?.name,
            scripCode: item.scripCode,
          };
        } else
          return {
            function_id: item.function_id,
            function_type_id: item?.function_type_id,
            period: parseInt(item.period, 10),
            body: item.body,
            name: item?.name,
          };
      } else {
        return item;
      }
    });
  });
  function getComparatorValue(name) {
    switch (name) {
      case "crosses above":
        return ">";
      case "crosses below":
        return "<";
      case "higher than":
        return ">";
      case "lower than":
        return "<";
      case "lower than equal to":
        return "<=";
      case "higher than equal to":
        return ">=";
      case "equal to":
        return "==";
      default:
        return null;
    }
  }
  
  if(isExist === "exist"){
    formData.exit_functions = formattedOutputData;
  }else {
    formData.functions = formattedOutputData;
  }
  console.log("MathIndicator>", formData);
  onFormChange(formData);
};

export default generateMathOutput;
