import React from "react";

const UserHeader = (props) => {

  return (
    <div className="top-header-section">
      <ul>
        <li>
          <span className="text-hello fs-2">Hello</span>
          <span className="text-user-name fs-2 text-capitalize ps-3">
            {props.user.user_name}
          </span></li>
      </ul>
    </div>
  );
};

export default UserHeader;
