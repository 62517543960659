import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

const StrategyDeploySuccessModal = ({
  deployedSuccess,
  setDeployedSuccess,
}) => {
  const handleClose = () => {
    setDeployedSuccess(false);
  };

  return (
    <Modal
      show={deployedSuccess}
      onHide={handleClose}
      animation={false}
      centered
    >
      <Modal.Body id="create-strategy-modal">Deployed</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StrategyDeploySuccessModal;
