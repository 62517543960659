import React ,{useState} from "react";
import { Button, Form, Modal } from "react-bootstrap";


const CopyScannerModal = ({
    copyScannerShow,
    handelClickCreateScanner,
    setCopyScannerShow,
    nameError,
    setError
  }) => {

    
    const [scannnerName, setScannnerName] = useState();
    const [isDisable , setIsDisable] = useState(false);
  
    const handleClose = () => {
        setCopyScannerShow(false);
    };
    const handelChange = (e) => {
      const value = e.target.value;
      setScannnerName(value);
      // setError(false);
    };

    return(
        <>
             <Modal
          show={copyScannerShow}
          onHide={handleClose}
          animation={false}
          centered
          dialogClassName="copy-scanner-modal"
        >
          <Modal.Body
            className="text-center"
          >
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="mb-4 fs-4">Copy Scanner As</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Scanner Name"
                  value={scannnerName}
                  onChange={handelChange}
                  autoComplete="off"
                />
                {nameError ? <span className="text-error">Name cannot be empty</span> :null}
              </Form.Group>
              <div className="d-flex justify-content-between">
              <Button
                className="btn-color mr-2"
                variant="primary"
                disabled={isDisable} 
                // onClick={() => handelClickCreateScanner(scannnerName)}
              >
                Copy
              </Button>
              <Button variant="outline-primary" className="" onClick={handleClose} id="cancel-outline-primary">
                Cancel
              </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        
        </>
    )
}

export default CopyScannerModal;