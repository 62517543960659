import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ScannerCategoriesSideBar = () => {
    const navigate = useNavigate();

    const redirect = (item) =>{
        const url = `/scans/popular?filter=${item}` ;
        navigate(url);
    }

    return(
        <div className="px-4">
            <Row>
                <Col className="fw-bold">
                    Categories
                </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center mt-3 scanner-menu" onClick={()=>{redirect(1)}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Chart Patterns</span>
                    <span className="sub-title">Chart Patterns scanners</span>
                </Col>
            </Row>
            <hr/>
            <Row className="d-flex justify-content-center align-items-center scanner-menu" onClick={()=>{redirect(2)}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Webinar Scanners</span>
                    <span  className="sub-title">Scanners as seen in webinars</span>
                </Col>
            </Row>
            <hr/>
            <Row className="d-flex justify-content-center align-items-center scanner-menu" onClick={()=>{redirect(3)}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Bullish</span>
                    <span  className="sub-title">Bullish scanners</span>
                </Col>
            </Row>
            <hr/>
            {/* <Row className="d-flex justify-content-center align-items-center scanner-menu" onClick={()=>{redirect("intraday_bullish")}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Intraday Bullish</span>
                    <span  className="sub-title">Intraday bullish scanners</span>
                </Col>
            </Row>
            <hr/> */}
            <Row className="d-flex justify-content-center align-items-center scanner-menu" onClick={()=>{redirect(4)}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Intraday Bearish</span>
                    <span  className="sub-title">Intraday Bearish scanners</span>
                </Col>
            </Row>
            <hr/>
            <Row className="d-flex justify-content-center align-items-center scanner-menu" onClick={()=>{redirect(5)}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Range Breakout</span>
                    <span  className="sub-title">Range breakout scanners</span>
                </Col>
            </Row>
            <hr/>
            <Row className="d-flex justify-content-center align-items-center scanner-menu" onClick={()=>{redirect(6)}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Top Gainers</span>
                    <span  className="sub-title">Top gainer scanner</span>
                </Col>
            </Row>
            <hr/>
            {/* <Row className="d-flex justify-content-center align-items-center scanner-menu" onClick={()=>{redirect("crossover")}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Crossover</span>
                    <span  className="sub-title">Crossover scanners</span>
                </Col>
            </Row>
            <hr/> */}
            {/* <Row className="d-flex justify-content-center align-items-center scanner-menu" onClick={()=>{redirect("top_losers")}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Top Losers</span>
                    <span  className="sub-title">Top loser scanners</span>
                </Col>
            </Row>
            <hr/> */}
            {/* <Row className="d-flex justify-content-center align-items-center scanner-menu" onClick={()=>{redirect("bearish")}}>
                <Col lg={2} md={2} sm={2}>
                    <i className="fa fa-plus"></i>
                </Col>
                <Col lg={10} md={10} sm={10} className="d-flex flex-column">
                    <span className="title">Bearish</span>
                    <span  className="sub-title">Bearish scanners</span>
                </Col>
            </Row> */}
            <hr/>
        </div>
    )
}

export default ScannerCategoriesSideBar;