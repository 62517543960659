import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Card from "../components/Card";
import DeployedBox from "./DeployedBox";
import ScannerModal from "../components/scanner/ScannerModal";
import { Link, useNavigate } from "react-router-dom";
import CopyScannerModal from "../components/scanner/CopyScannerModal";
import {
  getAllSavedScannersApi,
  getDiscoverScannersByIdApi,
  getScannerCategoriesApi,
} from "../api/scannerapi";
import { getUser, getActiveAccount } from "../Session";
import ScannerBox from "../components/scanner/ScannerBox";
import SkeletonLoader from "../components/componentCommon/SkeletonLoader";
const scannerCard = [
  {
    id: 1,
    icon: "startegy-icon/scanner-1.png",
    title: "Chart Pattern",
    subtext: "Make the trend your friend",
    class: "first-card",
    filter: "chart_pattern",
  },
  {
    id: 2,
    icon: "startegy-icon/Scanner-2.png",
    title: "Webinar Scanners",
    subtext: "Make the trend your friend",
    class: "second-card",
    filter: "webinar_scanner",
  },
  {
    id: 3,
    icon: "startegy-icon/Scanner-3.png",
    title: "Bulish",
    subtext: "Make the trend your friend",
    class: "first-card",
    filter: "bullish",
  },
  {
    id: 4,
    icon: "startegy-icon/Scanner-4.png",
    title: "Intraday Bearish",
    subtext: "Make the trend your friend",
    class: "second-card",
    filter: "intraday_bearish",
  },
  {
    id: 5,
    icon: "startegy-icon/Scanner-5.png",
    title: "Range Breakout",
    subtext: "Make the trend your friend",
    class: "first-card",
    filter: "range_breakout",
  },
  {
    id: 6,
    icon: "startegy-icon/Scanner-6.png",
    title: "Top Gainers",
    subtext: "Make the trend your friend",
    class: "second-card",
    filter: "top_gainers",
  },
];
const Scanners = () => {
  const navigate = useNavigate();
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [createScannerPage, setCreateScannerPage] = useState(false);
  const [scannerName, setScannerName] = useState("");
  const [strategiesNameError, setStrategiesNameError] = useState(false);
  const [copyScannerShow, setCopyScannerShow] = useState(false);
  const [scannerAllData, setScannerAllData] = useState([]);
  const [scanners, setScanners] = useState();
  const [scannerCategories, setScannerCategories] = useState();
  const [isScannerLoading, setIsScannerLoading] = useState(true);
  const [isScannerDiscover, setIsScannerDiscover] = useState(true);
  useEffect(() => {
    getScannerCategories();
  }, []);
  const getScannerCategories = async () => {
    const result = await getScannerCategoriesApi();
    console.log("getScannerCategories", result);
    // if (result) setStrategyCategories(result);
    const updatedResult = result?.map((info) => {
      const card = scannerCard.find((card) => card.id === info.id);
      return card
        ? {
            ...info,
            icon: card.icon,
            subtext: card.subtext,
            class: card.class,
            filter: card.filter,
          }
        : info;
    });
    console.log("updatedResult", updatedResult);
    setScannerCategories(updatedResult);
    setIsScannerDiscover(false);
  };

  const getScannersById = async (id) => {
    const result = await getDiscoverScannersByIdApi({
      category_id: id,
    });
    console.log("getScannerCategories", result);
    if (result) setScanners(result);
  };

  const handelClickCreateScanner = (name) => {
    const acc = getActiveAccount();
    if (acc != null) {
      if (name !== undefined) {
        setShowCreatePopup(false);
        // sessionStorage.removeItem("candleIntervalId")
        // sessionStorage.removeItem("candle_interval_id")
        navigate(`/scanners/page?scannid=1&scannername=${name}`);
      } else {
        setStrategiesNameError(true);
      }
    } else {
      alert(
        "To create scanner you need to activate broker account from dashboard"
      );
      navigate("/dashboard");
    }
  };

  const showCopyScanner = () => {
    setCopyScannerShow(true);
  };

  const handelClickOpenScannerModal = () => {
    setShowCreatePopup(true);
  };

  const fetchAllScanner = async () => {
    const user = getUser();
    const result = await getAllSavedScannersApi({ user_id: user.id });
    console.log("data----check", result);
    setScannerAllData(result?.data);
    setIsScannerLoading(false);
  };

  useEffect(() => {
    fetchAllScanner();
  }, []);

  const handleClickViewAllScanner = () => {
    navigate("/scanners/viewall");
  };

  const memoizedComponent = useMemo(() => {
    return (
      <section className="mobile-view">
        <div className="container web-cotainer">
          <div className="row">
            <div className="col-lg-9 col-mg-9 col-sm-12">
              <h3 style={{ color: "#2c308e" }}>
                <b>Scanners</b>
              </h3>
              <div className="row">
                <div className="d-flex justify-content-between">
                  <h5 className="fw-normal">My Scanners</h5>
                  <span
                    className="text-primary pointer-cursor"
                    onClick={handleClickViewAllScanner}
                  >
                    View all
                  </span>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="text-muted text-center py-3">
                    <img
                      src="icon-images/strategyIcon.png"
                      alt="Strategies Images"
                      width={"auto"}
                      height={60}
                      className="img-fluid"
                    />
                  </div>
                  <Button
                    variant="primary"
                    onClick={handelClickOpenScannerModal}
                    className="btn-color w-lg-50 w-100"
                  >
                    Create New Scanner
                  </Button>
                </div>

                <div className="col-lg-8">
                  <div
                    style={{
                      display: "flex",
                      height: "195px",
                      width: "100%",
                      overflowX: isScannerLoading ? "none" : "auto",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="container">
                      <div
                        className="row"
                        style={{
                          flexWrap: "nowrap",
                        }}
                      >
                        {isScannerLoading ? (
                          <SkeletonLoader
                            width="100%"
                            height="200px"
                            borderRadius="8px"
                          />
                        ) : (
                          scannerAllData?.map((item) => (
                            <div className="col-4">
                              <ScannerBox scanner={item} />
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-5">
                <div className="col-lg-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-12">
                      <h6 className="fw-bold">Discover Scanner</h6>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-6 col-4 ">
                        <h6 className="text-secondary text-start fw">
                          Explore by Category
                        </h6>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-4 ">
                        <Link
                          to="/scans/popular"
                          className="second-text text-end fw text-decoration-none pull-right"
                        >
                          View all
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="row" id="strategy-cards-tread">
                  {isScannerDiscover
                    ? Array.from({ length: 6 }).map((_, index) => (
                        <div
                          className="col-lg-4 col-md-4 col-sm-4 p-2 pointer-cursor"
                          key={index}
                        >
                          <SkeletonLoader
                            width="100%"
                            height="200px"
                            borderRadius="8px"
                          />
                        </div>
                      ))
                    : scannerCategories?.map((item, i) => {
                        return (
                          <Card
                            key={i}
                            itemData={item}
                            redirect="/scans/popular?filter="
                          />
                        );
                      })}
                </div>
              </div>
              <div className="row">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-8">
                    <h6 className="fw-bold">Popular Scanner</h6>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-4 ">
                    <Link
                      to="/scans/popular?filter=popular_scanner"
                      className="second-text text-end fw text-decoration-none pull-right"
                    >
                      View all
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-6 col-sm-6">
                  <h6>
                    Trianlge Pattern &ensp; <span>08 OCT 2023</span>
                  </h6>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 text-end">
                  <span className="btn-scanner-nifty">NIFTY 100</span>
                  &nbsp;&nbsp;&nbsp;
                  <span className="btn-scanner-1hour">1 hour</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-8">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi.
                  </span>
                  <br />
                  <span className="text-primary fw-bold">Read More</span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-mg-12 col-sm-12">
                  <Button className="sub-btn" onClick={showCopyScanner}>
                    {" "}
                    &nbsp;&nbsp;Take Live &nbsp; &nbsp;
                  </Button>{" "}
                  &nbsp;
                  <Button
                    onClick={() => {
                      navigate("/scanner/singel?id=1");
                    }}
                  >
                    &nbsp;&nbsp;Show Result&nbsp;&nbsp;
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-mg-3 col-sm-12">
              <DeployedBox />
            </div>
          </div>
        </div>
        <ScannerModal
          showCreatePopup={showCreatePopup}
          handelClickCreateScanner={handelClickCreateScanner}
          setShowCreatePopup={setShowCreatePopup}
          nameError={strategiesNameError}
          setError={setStrategiesNameError}
        />
        <CopyScannerModal
          copyScannerShow={copyScannerShow}
          setCopyScannerShow={setCopyScannerShow}
        />
        {/* <ToastMsg toastShow={toastShow} setToastShow={setToastShow} /> */}
      </section>
    );
  }, [createScannerPage, showCreatePopup, copyScannerShow, scannerAllData]);

  return <>{memoizedComponent}</>;
};

export default Scanners;
