import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DeployedBox from "../../mcomponents/DeployedBox";
import CopyScannerModal from "./CopyScannerModal";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { getUser, getZerodhaToken } from "../../Session";
import {
  addNewScannerAPI,
  deleteScannerAPI,
  deployScanner,
  getDiscoverScannerResultApi,
  getLiveScanLogAPi,
  getLiveScannerResultAPI,
  getScannerResultApi,
  stopLiveScanner,
} from "../../api/scannerapi";
import moment from "moment";
import TakeLiveModal from "./TakeLiveModal";
import LogBarChart from "./LogChart";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getStockLTPDataAPI } from "../../api/api";
import DeleteStrategyModal from "../strategy/DeleteStrategyModel";
import ScannerModal from "./ScannerModal";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const SingleDiscoverScanner = () => {
  let flag = 0;
  const navigate = useNavigate();
  const location = useLocation();
  const strategyId = location.state?.strategyId;
  console.log("fromRecentCreate", strategyId);

  const [copyScannerShow, setCopyScannerShow] = useState(false);
  const { scanid } = useParams();
  const [scannerData, setScannerData] = useState([]);
  const [data, setData] = useState([]);
  const [scannerNameError, setScannerNameError] = useState(false);
  const [scanCondition, setScanCondition] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [mainObj, setMainObj] = useState();
  const [candleId, setCandleId] = useState();
  const [isLive, setIsLive] = useState(false);
  const [logData, setLogData] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isHide, setHide] = useState(false);
  const [clickLive, setCLickLive] = useState(false);
  const [searchOn, setSearchOn] = useState(false);
  const [show, setShow] = useState(false);
  const [cnt,setCnt]=useState();
  const [showCreate, setShowCreate] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState();
  const [length, setLength] = useState(false);
  const [scannerName, setScannerName] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const [scandate, setDate] = useState();
  const [ltpData, setLtpData] = useState();
  const [graph, setGraph] = useState(false); 
  const [clickeddata, setClickeddata] = useState(null);
  const user = getUser();
  const isCreated = location.state?.isCreated;
  const [otherpage, setOtherpage] = useState(null);
  const [isCreatedClick, setIsCreatedClick] = useState(isCreated);
  const [onClickDisable, setonClickDisable] = useState(false);
  const token = getZerodhaToken();
 
  const [isDisabled, setIsDisabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const noscanedit = () => {
    alert(
      "scanning is still in progress, you can edit after scanning is completed"
    );
  };
  const handleEditMenu = () => {
   
    if (isLive) {
      alert("Please Stop the Live Scanner");
      return;
    }

    setShowCreate(true);
    handleClose();
  };
  const handleDelete = () => {
    setShow(true);
    handleClose();
  };
  const handleCloseModal = () => {
    setShow(false);
  };
  const handelClickCreateScanner = (NewScannerName) => {
    if(scannerData[0]?.entire_object)
      {
        let data = JSON.parse(scannerData[0]?.entire_object);
      }
    data.scanner_id = scannerData[0]?.scanner_id;
    data.access_token = token;
    data.user_id = user?.id;
    console.log("entire_object", scannerData[0]?.scanner_id, data);
    console.log("NewScannerName", NewScannerName);
    navigate(
      `/scanners/page?scannid=${scannerData[0]?.scanner_id}&scannername=${NewScannerName}`,
      {
        state: {
          editDiscover: true,
          data: data,
          scannerName: NewScannerName,
        },
      }
    );
  };
  const fetchScannerResult = async () => {
    try {
      console.log("isDiscover?", location.state?.isDiscover, scanid);
      
      const result = await getDiscoverScannerResultApi({
        id: scanid,
        user_id: user.id,
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
      });
      setAllData(result?.data);
      setAllDataLength(result?.data?.length);
      const resultData = result?.data;
      console.log("scannerResult>", resultData);
  
      if (resultData && resultData.length>0) {
        setScannerName(result?.data[0]?.scanner_name);
        setDate(
          result?.data[0]?.updated_at
            ? result?.data[0]?.updated_at
            : result?.data[0]?.created_at
        );
        const entireObject = resultData[0]?.entire_object;
        if (entireObject) {
          try
          {
            setMainObj(JSON.parse(entireObject));
            setScanCondition(JSON.parse(entireObject)?.functionText1);
          } catch (parseError) {
            console.error("Error parsing entire_object JSON:", parseError);
          }
         
        }
        console.log("resultData",resultData)
        const filteredData = resultData.filter(item => item.is_trigger === 1);
        setCnt(filteredData?.length);
        setLength(true);
        console.log("filteredData>", filteredData);

        const tokens = filteredData?.map(item => parseInt(item.script_code));
        if (flag === 0) {
          setData(filteredData);
          if (filteredData[0]?.candle_interval_id != "1d") {
            let intervalId = parseInt(
              filteredData?.[0]?.candle_interval_id.replace(/[^0-9]/g, ""),
              10
            );
            setIntervalId(intervalId);
          } else {
            setIntervalId(61);
          }
          flag = 1;
        }
        console.log("909090",tokens,filteredData);
        getStocksData(tokens, filteredData);

      } else {
        console.log("No data returned from API.");
      }
    } catch (error) {
      console.error("Error fetching scanner result:", error);
    }
  };
  const getStocksData = async (tokens, data) => {
    console.log("iscalling stock data", tokens, data);
    const apiKey = "3wxj01wpd2rnnn9c";
    const queryParams = tokens?.map((token) => `i=${token}`).join("&");
    if (tokens?.length > 0) {
      const response = await getStockLTPDataAPI({
        apiKey: apiKey,
        token: token,
        queryParams: queryParams,
      });
      if (response) {
        if (response.data) updateStocksDataNew(data, response?.data);
      }
    }
  };
  const updateStocksDataNew = (stocks, data) => {
    Object.keys(data).forEach((instrumentToken) => {
      const stockToUpdate = stocks.find(
        (stock) => stock.script_code == instrumentToken
      );

      if (stockToUpdate) {
        const newData = data[instrumentToken];
        stockToUpdate.ltp = newData.last_price;
        stockToUpdate.closePrice = newData.ohlc.close;
      }
    });

    if (stocks?.length > 0) setScannerData(stocks);
    console.log("====++", stocks);
  };

  // const getStocksData = async (tokens) => {
  //   console.log("token--", token);
  //   console.log("stockTokensSent", tokens);
  //   if (tokens?.length > 0) {
  //     const data = await getStockLTPDataAPI({
  //       api_key: "3wxj01wpd2rnnn9c",
  //       access_token: token,
  //       token: tokens,
  //     });
  //     if (data) {
  //       flag = 1;
  //       console.log("stocksLTP>", scannerData, data?.data);
  //       setLtpData(data?.data);
  //       updateStocksData(scannerData, data?.data);
  //     }
  //   }
  // };

  // const updateStocksData = (stocks, data) => {
  //   console.log("updateStocksData>", stocks, data);

  //   data?.forEach((dataItem) => {
  //     const stockToUpdate = stocks.find(
  //       (stock) => stock.script_code == dataItem.instrument_token
  //     );
  //     console.log("stockToUpdate", stockToUpdate);

  //     let percentage =
  //       ((dataItem.last_price - dataItem.ohlc.close) / dataItem.ohlc.close) *
  //       100;
  //     if (stockToUpdate) {
  //       stockToUpdate.ltp = dataItem.last_price;
  //       stockToUpdate.closePrice = dataItem.ohlc.close;
  //       stockToUpdate.percentage = percentage.toFixed(2);
  //       stockToUpdate.volume_traded = dataItem.volume_traded;
  //     }

  //     console.log("stocks===", stocks);
  //   });
  //   if (stocks?.length > 0) setScannerData(stocks);
  //   flag = 1;
  // };

  const fetchScanLiveLog = async () => {
    const result = await getLiveScanLogAPi({
      scanner_id: scanid,
      user_id: user.id,
      api_key: "3wxj01wpd2rnnn9c",
      access_token: token,
    });
    console.log("livelog>", result?.data);
    setLogData(result?.data);
  };

  useEffect(() => {
    console.log("IslIVE>", isLive);
  }, [isLive]);

  useEffect(() => {
    if (isLive) {
      const interval = setInterval(fetchScanLiveLog, 2000); // Call fetchScanLiveLog every 2 seconds

      // Clear the interval on component unmount to prevent memory leaks
      return () => clearInterval(interval);
    }
  }, []); 

  useEffect(() => {
    if (isLive) {
      const interval = setInterval(getLiveResult, 2000); 
      return () => clearInterval(interval);
    }
  }, [isLive]); 

  // useEffect(() => {
  //   if (!clickLive && !searchOn && !isLive) {
  //     if (!isLive) {
  //       const interval = setInterval(fetchScannerResult, 2000); // Call fetchScanLiveLog every 2 seconds

  //       // Clear the interval on component unmount to prevent memory leaks
  //       return () => clearInterval(interval);
  //     }
  //   }
  // }, []); // Empty dependency array ensures useEffect only runs once after initial render

  useEffect(() => {
    setIsloading(true);
    if (strategyId) {
      setIsloading(true);
      setTimeout(() => {
        if (!clickLive) fetchScannerResult();
        setIsloading(false);
        getLiveResult();
      }, 40000);
    } else {
      setIsloading(true);
      setTimeout(() => {
        if (!clickLive) fetchScannerResult();
        setIsloading(false);
        getLiveResult();
      }, 1000);
    }
  }, []);

  // useEffect(() => {
  //   const filteredData = data?.filter(
  //     (item) =>
  //       item.stock_name.toLowerCase().includes(searchInput.toLowerCase()) &&
  //       item.is_trigger == 1
  //   );
  //   setScannerData(filteredData);
  //   console.log("Search>>>", filteredData);
  //   const tokens = filteredData?.map((item) => parseInt(item.script_code));
  //   console.log("tokens??>", tokens);
  //   if (flag === 0) getStocksData(tokens);
  //   if (flag === 1) updateStocksData(scannerData, ltpData);
  // }, [searchInput]);

  const scanNow = async () => {
   alert("scanning");
        let data = { ...mainObj };
        data.access_token = token;
        data.scanner_id = scanid;
        setIsloading(true);
      console.log("SCAN",data);
    const result = await addNewScannerAPI(data);

    if (result) {
      setIsloading(true);
      setTimeout(() => {
        fetchScannerResult();
        setIsloading(false);
      }, 40000);
    }
    else
    {
      console.log("False response...")
    }
  };
  const takeLive = async () => {
    if (isLive) {
      setHide(true);
      setCLickLive(true);
      setIsloading(true);
      const result = await stopLiveScanner({
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
        scanner_id: scanid,
        candleIntervalId: candleId,
        user_id: user?.id,
      });
      if (result) {
        setTimeout(() => {
          fetchScannerResult();
          getLiveResult();
        }, 4000);

        // alert("Live Scanner Stopped!");
        setCopyScannerShow(false);
      }
    } else {
      setHide(false);
      setIsloading(true);
      const result = await deployScanner({
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
        scanner_id: scanid,
        candleIntervalId: candleId,
      });
      if (result) {
        setTimeout(() => {
          getLiveResult();
        }, 4000);
        setCopyScannerShow(false);
      }
    }
  };

  const getLiveResult = async () => {
    const result = await getLiveScannerResultAPI({
      api_key: "3wxj01wpd2rnnn9c",
      access_token: token,
      scanner_id: scanid,
      user_id: user.id,
    });
    if (result) {
      console.log("liveresult1>>", result?.data);
      if (result?.data?.length > 0) setIsLive(true);
      else setIsLive(false);
    }
    const filteredData = result?.data?.filter((item) => item.is_trigger === 1);
    console.log("liveResult2>>", filteredData);

    const tokens = filteredData?.map((item) => parseInt(item.script_code));
    console.log("livetokens??>", tokens);
    if (flag == 0) getStocksData(tokens);
    setTimeout(() => {
      setIsloading(false);
    }, 1000);

    fetchScanLiveLog();
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setSearchOn(true);
  };
  const handleEdit = (data) => {
    console.log("DATA,,", data);
    setCandleId(data);
  };

  function getTimeframe(intervalId) {
    const timeframeMap = {
      "1m": "1 Minute",
      "3m": "3 Minutes",
      "5m": "5 Minutes",
      "10m": "10 Minutes",
      "15m": "15 Minutes",
      "30m": "30 Minutes",
      "60m": "60 Minutes",
      "1day": "1 Day",
    };

    return timeframeMap[intervalId] || "";
  }

  const handleConfirm = async () => {
    handleClose();
    const data = await deleteScannerAPI({
      scanner_id: scanid,
      user_id: user.id,
    });
    if (data) {
      alert("Scanner Deleted!");
      handleCloseModal();
      navigate("/scanners");
    }
    handleClose();
  };
  const setValue = (prop) => {
    setClickeddata(prop);
    setOtherpage(prop);
  };
  return (
    <>
    <section className="mobile-view">
      <div className="container web-cotainer">
        <Row>
          <Col lg={9} md={9} sm={12}>
            <Row>
              <Container className="title-section p-0">
                <Col className="d-flex justify-content-between align-items-center">
                  <div>
                    <i
                      className="fa fa-chevron-left pointer-cursor "
                      onClick={() => navigate(-1)}
                    ></i>
                    <span>My Scanner</span>
                  </div>
                  <div>
                    <Button
                      className="primary na-cursor"
                      onClick={() => {
                        setCopyScannerShow(true);
                      }}
                      disabled={
                        (isDisabled && isLive) ||
                        onClickDisable ||
                        (isCreatedClick &&
                          localStorage.getItem("IsCreated") == null) ||
                        localStorage.getItem(`${scanid}`) == scanid
                      }
                    >
                      {isLive ? "Stop Live" : "Take Live"}
                    </Button>
                    &nbsp;&nbsp;
                    {!isLive && (
                      <Button
                        disabled={
                          onClickDisable ||
                          (isCreatedClick &&
                            localStorage.getItem("IsCreated") == null) ||
                          localStorage.getItem(`${scanid}`) == scanid
                        }
                        onClick={() => {
                          scanNow();
                        }}
                      >
                        Scan Now
                      </Button>
                    )}
                  </div>
                </Col>
              </Container>
            </Row>

            <Row className="mt-2 mb-2">
              <Col className="d-flex flex-row justify-content-between align-items-center">
                <div>
                  <span className="fs-4 fw-bold">{scannerName}</span>
                  &nbsp;
                  <i className="fa fa-star"></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    <Button
                      variant="outline-primary"
                      size="sm mb-2"
                      disabled={
                        (isCreatedClick &&
                          localStorage.getItem("IsCreated") == null) ||
                        onClickDisable ||
                        localStorage.getItem(`${scanid}`) === scanid
                      }
                      onClick={() => {
                        handleEditMenu();
                      }}
                    >
                      Edit Scanner
                    </Button>
                  </span>
                </div>

                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="fa fa-ellipsis-v text-muted dropdown-button"
                    aria-hidden="true"
                    onClick={handleClick}
                  ></i>
                  <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        if (
                          (mainObj?.scan_on === "NIFTY 50" &&
                            allDataLength < 50) ||
                          (mainObj?.scan_on === "NIFTY 100" &&
                            allDataLength < 101) ||
                          (mainObj?.scan_on === "Energy" &&
                            allDataLength < 30) ||
                          (mainObj?.scan_on === "Consumer Discretionary" &&
                            allDataLength < 30) ||
                          (mainObj?.scan_on === "Industrials" &&
                            allDataLength < 30) ||
                          (mainObj?.scan_on === "Health Care" &&
                            allDataLength < 30) ||
                          (mainObj?.scan_on === "Information Technology" &&
                            allDataLength < 30) ||
                          (mainObj?.scan_on === "Utilities" &&
                            allDataLength < 26)
                        ) {
                          noscanedit();
                        } else {
                          handleEditMenu();
                        }
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                  </Menu>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg md sm>
                <div className="d-flex align-items-center mb-2">
                  <div style={{ fontWeight: "500", width: "110px" }}>
                    Scan on&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                  </div>
                  &nbsp;&nbsp;
                  <div
                    style={{
                      fontWeight: "500",
                      color: "#293696",
                    }}
                  >
                    {data && data?.length > 0
                      ? data[0]?.scan_on
                      : allData && allData[0]?.scan_on}
                  </div>
                </div>
                <span className="d-flex align-items-center mb-2">
                  <div style={{ fontWeight: "500", width: "110px" }}>
                    Timeframe&nbsp;&nbsp; :
                  </div>
                  &nbsp;&nbsp;
                  <div style={{ color: "#293696", fontWeight: "600" }}>
                    {data && data[0]?.entire_object
                      ? getTimeframe(
                          JSON?.parse(data[0]?.entire_object)
                            ?.candle_interval_id
                        )
                      : allData &&
                        allData[0]?.entire_object &&
                        getTimeframe(
                          JSON?.parse(allData[0]?.entire_object)
                            ?.candle_interval_id
                        )}
                  </div>
                </span>

                <span style={{ fontWeight: "500" }}>
                  <div className="d-flex align-items-center">
                    <div style={{ fontWeight: "500", width: "110px" }}>
                      Condition &nbsp;&nbsp;
                    </div>
                    <span>:</span>
                    &nbsp;&nbsp;
                    <div style={{ fontWeight: "600", color: "#293696" }}>
                      {scanCondition}
                    </div>
                  </div>
                </span>
                <span
                  className="d-flex align-items-center"
                  style={{ marginTop: "6px" }}
                >
                  <div style={{ fontWeight: "500", width: "110px" }}>
                    Description &nbsp;:
                  </div>
                  &nbsp;&nbsp;
                  {data && data[0] && <div>{data[0]?.description}</div>}
                </span>
              </Col>
            </Row>
            {!isHide && graph && (
              <LogBarChart data={logData} val={setValue} graph={graph} />
            )}
            <>
              <Row className="mt-3 d-flex align-items-center justify-content-between">
                {
                  <Col lg={6} md={6} sm={12}>
                    <div>
                      <span className="fs-5" style={{ fontWeight: "600" }}>
                        {cnt}
                      </span>
                      &nbsp;&nbsp;
                      <span>Scan Result</span>&nbsp;&nbsp;
                      <span className="text-muted">
                        <span style={{ fontWeight: "500" }}>
                          Last ran at &nbsp;
                        </span>
                        {scannerData && scannerData[0] ? (
                          scannerData[0]?.updated_at ? (
                            moment
                              .utc(scannerData[0]?.updated_at)
                              .local()
                              .format("hh:mm A DD/MM/YYYY")
                          ) : (
                            moment
                              .utc(scannerData[0]?.created_at)
                              .local()
                              .format("hh:mm A DD/MM/YYYY")
                          )
                        ) : (
                          <span>
                            {scandate &&
                              moment
                                .utc(scandate)
                                .local()
                                .format("hh:mm A DD/MM/YYYY")}
                          </span>
                        )}
                      </span>
                    </div>
                  </Col>
                }
              </Row>
              <Table
                className="mt-3"
                id="scanner-table"
                style={{
                  border: "0.1px solid lightgray",
                }}
              >
                <thead>
                  <tr style={{ borderBottom: "1px solid #D3D3D3" }}>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Symbol
                    </th>
                    <th className="text-center" colspan="3">
                      LTP
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {scannerData?.map((item, i) => (
                    <tr style={{ position: "relative" }} key={i}>
                      <td
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src="/stockPlaceholder.png"
                            style={{ height: "18px", width: "18px" }}
                            alt="nt found"
                          ></img>
                          &nbsp;
                          <div className="ps-2">
                            <div style={{ fontWeight: "600" }}>
                              {item?.stock_name
                                ? item?.stock_name
                                : item?.script_name}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                              {item?.exch === "n" || item?.exch == ""
                                ? "NSE"
                                : item?.exch === "b"
                                ? "BSE"
                                : item?.exch}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        style={{
                          color:
                            item?.ltp !== 0 && item?.closePrice !== 0
                              ? item.ltp > item.closePrice
                                ? "#008000"
                                : "#BA0F04"
                              : "#060606",
                          textAlign: "end",
                        }}
                      >
                        {item?.ltp}
                      </td>
                      <td
                        style={{
                          color:
                            item?.ltp !== 0 && item?.closePrice !== 0
                              ? item.ltp > item.closePrice
                                ? "#008000"
                                : "#BA0F04"
                              : "#060606",
                          textAlign: "center",
                          width: "20px",
                        }}
                      >
                        {item?.ltp !== 0 && item?.closePrice !== 0 ? (
                          item.ltp > item.closePrice ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )
                        ) : null}
                      </td>
                      <td style={{ width: "120px" }}>
                        {item?.ltp && item?.closePrice && (
                          <div>
                            {(
                              ((item?.ltp - item?.closePrice) /
                                item?.closePrice) *
                              100
                            ).toFixed(3)}
                            %
                          </div>
                        )}
                        {item?.percentage ? "%" : ""}
                      </td>
                      <td> {item?.volume_traded}</td>

                      <td style={{ textAlign: "right" }}>
                        <Button size="sm" className="btn-color">
                          Buy
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                        <Button size="sm">Sell</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <DeployedBox />
          </Col>
        </Row>
      </div>
    </section>

    <TakeLiveModal
      copyScannerShow={copyScannerShow}
      setCopyScannerShow={setCopyScannerShow}
      setError={setScannerNameError}
      scannerName={scannerName}
      handleTakeLive={takeLive}
      mainData={mainObj}
      onFormChange={handleEdit}
      isLive={isLive}
      candle_interval_id={intervalId}
    />
    <DeleteStrategyModal
      show={show}
      handleClose={handleCloseModal}
      handleConfirm={handleConfirm}
      mode="Scanner"
    />
  </>
  );
};

export default SingleDiscoverScanner;
