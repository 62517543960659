import logo from "./logo.svg";
import "./App.css";
import AppRoutes from "./Routes";
import Layout from "./components/Layout";
// import { useTheme } from "./ThemeContext";
function App() {
  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;
