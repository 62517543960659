
const config={
    colors:{
        primary:"black",
        secondary:"white",
    },
    backgroundColors:{
        primary:"black",
        secondary:"white",
    }
}

export default config;