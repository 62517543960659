import React, { useState, useRef, useEffect } from "react";
import { useMenuContextScanner } from "./ScannerCreateComponent";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { getfunctionTypeWisefunction } from "../../api/strategyapi";
import FormulaTab from "../strategy/FormulaTab";

import CompartorModal from "../../components/strategy/ComparatorModal";
import Ulcer from "../modal/Ulcer";
import TriMA from "../modal/TriMA";
import Vwma from "../modal/Vwma";
import DEMA from "../modal/DEMA";
import TEMA from "../modal/TEMA";
import Open from "../modal/Open";
import Low from "../modal/Low";
import Close from "../modal/Close";
import HighPrice from "../modal/HighPrice";
import EMA from "../modal/EMA";
import SMA from "../modal/SMA";
import Number from "../modal/Number";
import MoneyFlowIndex from "../modal/MoneyFlowIndex";
import WilliamsR from "../modal/WilliamsR";
import MovingAverageDeviation from "../modal/MovingAverageDeviation";
import MACD from "../modal/MACD";
import OBV from "../modal/OBV";
import MOM from "../modal/MOM";
import AroonDown from "../modal/AroonDown";
import AroonUp from "../modal/AroonUp";
import ParabolicSAR from "../modal/ParabolicSAR";
import VortexIndicator from "../modal/VortexIndicator";
import DonchianChannel from "../modal/DonchianChannel";
import Stochastic from "../modal/Stochastic";
import ChaikinMoneyFlow from "../modal/ChaikinMoneyFlow";
import CCI from "../modal/CommodityChannelIndex";
import PriceRateofChange from "../modal/PriceRateofChange";
import MACDsignal from "../modal/MACDsignal";
import AwesomeOscillator from "../modal/AwesomeOscillator";
import VWAP from "../modal/VWAP";
import Volume from "../modal/Volume";
import moment from "moment";
import RSI from "../modal/RSI";
import RSIMovingAverage from "../modal/RSIMovingAverage";
import BollingerB from "../modal/BollingerB";
import TR from "../modal/TR";
import ATR from "../modal/ATR";
import NATR from "../modal/NATR";
import Supertrend from "../modal/Supertrend";
import MfiMovingAverage from "../modal/MfiMovingAverage";
import KnowSureThing from "../modal/KnowSureThing";
import Ichimoku from "../modal/Ichimoku";
import CandleInterval from "../modal/CandleInterval";
import WMA from "../modal/WMA";
import PeriodMaxModal from "../modal/PeriodMaxModal";
import PeriodMinModal from "../modal/PeriodMinModal";
import indicatorsSwitchCase from "../../Utils/indicatorsSwitchCase";
import {
  helperModalReplaceFn777,
  helperModalSwitchFn,
  helperModalSwitchForMath,
} from "../../Utils/helperModalSwitchFn";
import {
  calculateAdjustedIndex,
  filterFunctionsBox777,
} from "../../Utils/indextoRemoveMath";
import replaceNewData from "../../Utils/replaceNewData";
import formateFnObject from "../../Utils/formateFnObject";
import generateMathOutput from "../../Utils/CommonFunction/generateMathOutput";
import getCandleId from "../../Utils/CommonFunction/getCandleId";
import updateHandleModalData from "../../Utils/CommonFunction/handleModalData";
import GetFunctionofHandle from "../../Utils/CommonFunction/GetFunctionofHandle";
import handleRemoveCondition from "../../Utils/CommonFunction/handleRemoveCondition";
import FunctionList from "../componentCommon/FunctionList";
import mergeArrays from "../../Utils/CommonFunction/mergeArrays";
import VwapStandarddeviation from "../modal/VwapStandarddeviation";
import UltimateOscillator from "../modal/UltimateOscillator";
import Nthcandle from "../modal/Nthcandle";
import MedianPrice from "../modal/MedianPrice";
import ChandeMomentumOscillator from "../modal/ChandeMomentumOscillator";
import ATRTrailingModal from "../modal/ATRTrailingModal";
import ChoppinessIndex from "../modal/ChoppinessIndexMovingAverage";
import CentralPivotRange from "../modal/CentralPivotRange";
import CamarillaPivot from "../modal/CamarillaPivot";
import PivotPoints from "../modal/PivotPoints";
import ElderRayIndex from "../modal/ElderRayIndex";
import UBB from "../modal/UBB";
import MBB from "../modal/MBB";
import LBB from "../modal/LBB";
import PhasePowerMovingAverage from "../modal/PhasePowerMovingAverage";
import PeriodOffset from "../modal/PeriodOffset";
import Tsi from "../modal/Tsi";

const addButton = {
  backgroundColor: "transparent", // No background color
  color: "#0e6efd", // Text color
  fontWeight: "500",
  fontSize: "16px",
  height: "30px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px dashed #1579c9", // Dashed border
  borderRadius: "4px",
  cursor: "pointer",
  transition: "border-color 0.3s", // Only transition for border color
  marginRight: "5px",
};

let eflag = 0;

const ScannerConditionComponent = ({
  formData,
  onFormChange,
  allStocks,
  getText,
}) => {
  const { menu, setMenu, storeAllStocks } = useMenuContextScanner();
  const divRef = useRef(null);
  const conditionDropdownRef = useRef(null);
  const [flag, setFlag] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [functionType, setFunctionType] = useState([]);
  const [showSMA, setIsShow] = useState(false);
  const [showSMA2, setIsShow2] = useState(false);
  const [showSMA3, setIsShow3] = useState(false);
  const [conditionTab, setConditionTab] = useState(false);
  const [mode, setMode] = useState("");
  const [remove, setRemove] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");
  const [currentId, setCurrentId] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [innerAdd, setInnerAdd] = useState("");
  const [outerAdd, setOuterAdd] = useState("");
  //Modal state variables
  const [showUlcer, setShowUlcer] = useState(false);
  const [showTMA, setShowTMA] = useState(false);
  const [showVWMA, setShowVWMA] = useState(false);
  const [showDEMA, setShowDEMA] = useState(false);
  const [showTEMA, setShowTEMA] = useState(false);
  const [showOpen, setShowOpen] = useState(false);
  const [showLowPrice, setShowLowPrice] = useState(false);
  const [showHighPrice, setShowHighPrice] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [showSMA1, setShowSMA] = useState(false);
  const [showEMA, setShowEMA] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [showMoneyFlowIndex, setShowMoneyFlowIndex] = useState(false);
  const [showWilliams, setShowWilliams] = useState(false);
  const [showMad, setShowMad] = useState(false);
  const [showMacd, setShowMacd] = useState(false);
  const [showObv, setShowObv] = useState(false);
  const [showOscilator, setShowOscilator] = useState(false);
  const [showAroonUp, setShowAroonUp] = useState(false);
  const [showAroonDown, setShowAroonDown] = useState(false);
  const [showpsar, setShowpsar] = useState(false);
  const [showVortexI, setShowVortexI] = useState(false);
  const [showDonchian, setShowDonchian] = useState(false);
  const [showStochastic, setShowStochastic] = useState(false);
  const [showChaikin, setShowChaikin] = useState(false);
  const [showCCI, setShowCCI] = useState(false);
  const [showRoc, setShowRoc] = useState(false);
  const [showMacdSignal, setShowMacdSignal] = useState(false);
  const [showVWAP, setShowVWAP] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [showRSI, setShowRSI] = useState(false);
  const [showRSIMA, setShowRSIMA] = useState(false);
  const [showBB, setShowBB] = useState(false);
  const [showTR, setShowTR] = useState(false);
  const [showATR, setShowATR] = useState(false);
  const [showNATR, setShowNATR] = useState(false);
  const [showSuperT, setShowSuperT] = useState(false);
  const [showMfiMA, setShowMfiMa] = useState(false);
  const [showKST, setShowKST] = useState(false);
  const [showIchimoku, setShowIchimoku] = useState(false);
  const [showWMA, setShowWMA] = useState(false);
  const [showPeriodMaxModal, setPeriodMaxModal] = useState(false);
  const [showPeriodMinModal, setPeriodMinModal] = useState(false);
  const [showCandleModal, setShowCandleModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmRemove, setconfirmRemove] = useState(false);
  const [isSelectMax, setIsSelectMax] = useState(false);
  const [isSelectMin, setIsSelectMin] = useState(false);
  const [showStdDeviation, setisStdDeviation] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [instance, setInstance] = useState();
  const [removedInsideMath, setRemovedInsideMath] = useState(false);
  const [showMOM, setShowMOM] = useState(false);
  const isIncludeMathFunction = useRef(null);
  const [showUltimateOscillator, setShowUltimateOscillator] = useState(false);
  const [mathIndexRemove, setMathIndexRemove] = useState(-1);
  const [showNthCandle, setShowNthCandle] = useState(false);
  const [showMedianPrice, setMedianPrice] = useState(false);
  const [showChande, setShowChande] = useState(false);
  const [showATRTrailingModal, setShowATRTrailModal] = useState(false);
  const [showChoppiness, setShowChoppiness] = useState(false);
  const [showCentralRange, setCentralRange] = useState(false);
  const [showCamrillaPivot, setCamrillaPivot] = useState(false);
  const [showPivotPoint, setShowPivotPoint] = useState(false);
  const [showElderRay, setShowElderRay] = useState(false);
  const [bollingUML, setBollingUML] = useState(false);
  const [powerMoving, setPowerMoving] = useState(false);
  const [showDI, setShowDI] = useState(false);
  const [showTsi, setShowTsi] = useState(false);

  const selectedItemStyle = {
    backgroundColor: "#00aeee", // Customize the background color as needed
    fontWeight: "bold", // Add additional styles if needed
    border: "1px solid blue",
  };
  console.log("SCANNERDATA", formData, onFormChange);
  const [showInstead, setAddInstead] = useState({
    function_id: 0,
    function_type_id: 0,
    isOpen: false,
    index: -1,
  });
  const handleEdit = (instanceData, index) => {
    console.log("instanceData", instanceData, index);
    setAddInstead({
      function_id: instanceData?.function_id,
      function_type_id: instanceData?.function_type_id,
      isOpen: true,
      index: index,
    });

    // call dropdown function particular type id //
    handleClickAll(instanceData?.function_type_id?.toString());
  };

  const handleGetFunction1 = (item) => {
    console.log("click", item, formData);
    setSearchQuery("");
    setShowDropdown(false);
    let replaceData = replaceNewData(formData, item, showInstead);
    formData = replaceData;
    // console.log("REPLACE", formData )
    if (replaceData) {
      setAddInstead({
        function_id: 0,
        function_type_id: 0,
        isOpen: false,
        index: -1,
      });
    }
  };
  useEffect(() => {
    // console.log("33first",formData);
  }, [formData]);
  const handleSetInstead = () => {
    setAddInstead({
      function_id: 0,
      function_type_id: 0,
      isToggle: false,
    });
  };
  const handleRemiceInstead = () => {
    setAddInstead((prev) => ({ ...prev, isOpen: false }));
  };
  const handleGetFunction = (item) => {
    console.log("GETFUN", item);
    GetFunctionofHandle(
      item,
      formData,
      onFormChange,
      setInnerAdd,
      setOuterAdd,
      handleRemiceInstead,
      setCurrentId,
      setConditionTab,
      handleClickAll,
      setSelectedOption1,
      setMode,
      setIsSelectMax,
      setIsSelectMin,
      setSearchQuery,
      setShowDropdown,
      setRemovedInsideMath,
      removedInsideMath,
      mathIndexRemove,
      flag,
      setFlag,
      outerAdd,
      isIncludeMathFunction,
      innerAdd,
      instance
    );
  };

  const handleCandleModal = (data) => {
    console.log("IntervalData", data);
    if (data?.candleIntervalId && formData)
      formData.indicators[formData.clickedFunction].candleIntervalId =
        data?.candleIntervalId;

    formData.functionsBox777[itemIndex].candleIntervalId =
      data.candleIntervalId;

    if (formData?.indicators[formData?.clickedFunction]?.body)
      formData.indicators[formData?.clickedFunction].body[
        itemIndex
      ].candleIntervalId = data?.candleIntervalId;

    console.log("INTERVAL", formData);
    onFormChange(formData);
    handleNextEnteryBtn();
  };

  useEffect(() => {
    if (eflag === 1) {
      updateCandleIntervalId();
      updateCandleInterval();
    }
    handleClickAll("1");
  }, [formData?.candleIntervalId]);
  useEffect(() => {
    handleNextEnteryBtn();
  }, [getText]);
  const updateCandleInterval = () => {
    // Create a copy of the original data
    let updatedData = [];
    if (Array.isArray(formData?.functionsBox777)) {
      updatedData = [...formData.functionsBox777];
    } else {
      console.log("undefined formData.functionsBox777");
    }
    let candleId = getCandleId(formData);

    updatedData.forEach((item) => {
      item.candleIntervalId = candleId;
    });
    if (formData) formData.functionsBox777 = updatedData;
    onFormChange(formData);
  };

  const updateCandleIntervalId = () => {
    // const updatedIndicators;

    // if(formData?.indicators)
    const updatedIndicators = { ...formData?.indicators };

    let candleId = getCandleId(formData);
    for (const key in updatedIndicators) {
      if (updatedIndicators.hasOwnProperty(key)) {
        const indicator = updatedIndicators[key];
        // Check if candleIntervalId property exists
        if (indicator?.hasOwnProperty("candleIntervalId")) {
          // Update candleIntervalId property value

          indicator.candleIntervalId = candleId; // Replace 'newCandleIntervalValue' with your desired value
        }
        if (indicator?.body) {
          if (Array.isArray(indicator.body)) {
            indicator.body.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body1) {
          if (Array.isArray(indicator.body1)) {
            indicator.body1.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body2) {
          if (Array.isArray(indicator.body2)) {
            indicator.body2.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
      }
    }
    // if(indicators)
    if (formData) {
      formData.indicators = updatedIndicators;
    }

    onFormChange(formData);
  };

  const handleClickAll = async (type) => {
    setSelectedItem(type);
    const data = await getfunctionTypeWisefunction({ type_id: type });

    setFunctionType(data);
  };

  useEffect(() => {
    handleClickAll("1");
  }, []);

  useEffect(() => {
    console.log("storeAllStocks>>", storeAllStocks);
  }, [storeAllStocks]);

  useEffect(() => {
    setTimeout(() => {
      eflag = 1;
    }, 2000);
  }, []); // This effect runs once when the component mounts

  const getEntryText = async () => {
    // Get the element by its ID
    const divElement = document.getElementById("strategyBox");

    // Check if the div element exists
    if (divElement) {
      // Get the existing text content of the div
      let existingContent = divElement.innerText;

      // Replace {input1Placeholder} with formData?.input1 value
      existingContent = existingContent.replace(
        "{input1Placeholder}",
        formData?.input1
      );
      let text1 = handleReplacement(existingContent);

      if (formData) {
        formData.functionText1 = existingContent;
      }
      onFormChange(formData);
      // setGotText(!gotText);

      // Do something with the modified text content
    }
  };

  const toggleDropdown = () => {
    setRemove(false);
    setShowDropdown(true);
  };

  const handleModalData = (data) => {
    updateHandleModalData(
      data,
      formData,
      onFormChange,
      getEntryText,
      itemIndex,
      setIsShow,
      instance
    );
  };

  const handleSMAComparator = (data) => {
    setIsShow3(false);
    if (formData?.ctype === "c1") {
      onFormChange({
        ...formData,
        comparator: data?.field,
      });
    }
    if (formData?.ctype === "c2") {
      onFormChange({
        ...formData,
        comparator2: data?.comparator2,
      });
    }
    if (formData?.ctype === "c3") {
      onFormChange({
        ...formData,
        comparator3: data?.comparator3,
      });
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    toggleDropdown();
  };

  const handleReplacement = (text) => {
    console.log("checkText-", text);
    let modifiedText = text?.replace(/lower than or equal to/gi, "<=");
    modifiedText = modifiedText?.replace(/higher than or equal to/gi, ">=");
    modifiedText = modifiedText?.replace(/lower than/gi, "<");
    modifiedText = modifiedText?.replace(/higher than/gi, ">");
    modifiedText = modifiedText?.replace(/> equal to/gi, ">=");
    modifiedText = modifiedText?.replace(/< equal to/gi, "<=");
    modifiedText = modifiedText?.replace(/and/gi, "&&");
    modifiedText = modifiedText?.replace(/or/gi, "||");
    modifiedText = modifiedText?.replace(/equal to/gi, "===");
    modifiedText = modifiedText?.replace(/crosses above/gi, ">");
    modifiedText = modifiedText?.replace(/crosses below/gi, ">");

    // Add more replacements as needed

    return modifiedText;
  };

  const handleAddButtonClick = (instanceName, bodyInstance) => {
    if (formData) {
      formData.selectedOption3 = instanceName;
    }

    setInstance(bodyInstance);
  };

  const handleRemoveButtonClick = (
    instanceToRemove,
    indexToRemove,
    mInstance
  ) => {
    handleRemoveCondition(
      formData,
      onFormChange,
      instanceToRemove,
      indexToRemove,
      mInstance,
      setMathIndexRemove,
      setRemovedInsideMath,
      isIncludeMathFunction
    );
  };

  const handleButtonClick = (index) => {};

  const handleAdd = (instanceData, index) => {
    toggleDropdown();
  };

  const handleNextEnteryBtn = () => {
    getEntryText();
    let text1 = handleReplacement(formData?.functionText1);
    mergeArrays(
      formData?.functionsBox777,
      text1
        ?.split("\n")
        .map((line) => line.trim())
        .filter(
          (line) =>
            line !== "" &&
            !line?.startsWith("1minute") &&
            !line?.startsWith("3minute") &&
            !line?.startsWith("5minute") &&
            !line?.startsWith("10minute") &&
            !line?.startsWith("15minute") &&
            !line?.startsWith("30minute") &&
            !line?.startsWith("60minute") &&
            !line?.startsWith("1day") &&
            !line?.startsWith("week") &&
            !line?.startsWith("month") &&
            !line?.startsWith("2h") &&
            !line?.startsWith("3h") &&
            !line?.startsWith("4h")
        ),
      onFormChange,
      formData,
      isSelectMax,
      isSelectMin,
      isIncludeMathFunction
    );

    // setMenu("review");
  };

  const getIndex = (index, instance) => {
    setItemIndex(index);
    setInstance(instance);
  };
  const RemoveFormula = () => {
    let ans = window.confirm("Are you sure, you want to remove formula?");
    if (ans === true) {
      setFlag(0);
      setRemove(true);
      onFormChange((prevState) => ({
        ...prevState,
        functions: [],
        functionsBox: [],
        functionsBox2: [],
        functionsBox777: [],
        functionsMainBox: [],
        indicators: {},
        mode: "",
        selectedOption1: "",
        order: [],
        isIncludeMathFunction: false,
      }));
      handleSetInstead();
      isIncludeMathFunction.current = false;
    }
  };
  return (
    <div className="row" id="scanner-condition-tab">
      <div className=" mt-5 pb-2 d-flex align-items-center justify-content-between">
        <div>
          <h6 className="fw-bolder">Entry Condition</h6>
        </div>
        <div></div>
      </div>
      <div className="input-group mb-3" id="tab-function-type-dropdown">
        <div className="d-flex row" style={{ minWidth: "300px" }}>
          {/* Input box */}
          <div
            // ref={conditionDropdownRef}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Form.Control
              type="text"
              placeholder="What would you like to add?"
              className="strategy-entry-search"
              onClick={(event) => {
                event.stopPropagation();
                toggleDropdown();
              }}
              value={searchQuery}
              onChange={handleSearch}
            />
            <span
              className="close-icon"
              onClick={() => {
                setSearchQuery("");
                setShowDropdown(false);
              }}
              style={{ cursor: "pointer", marginLeft: "-25px" }}
            >
              X
            </span>
          </div>
          {/* Dropdown menu */}
          {showDropdown && (
            <FunctionList
              showInstead={showInstead}
              formData={formData}
              isSelectMax={isSelectMax}
              removedInsideMath={removedInsideMath}
              selectedItem={selectedItem}
              selectedItemStyle={selectedItemStyle}
              handleClickAll={handleClickAll}
              handleGetFunction1={handleGetFunction1}
              handleGetFunction={handleGetFunction}
              getEntryText={getEntryText}
              searchQuery={searchQuery}
              functionType={functionType}
              remove={remove}
              isIncludeMathFunction={isIncludeMathFunction}
              onFormChange={onFormChange}
            />
          )}
        </div>
      </div>
      {formData?.conditionTab ? (
        <div className="row" id="strategy-entry-condition-card">
          <div className="col-lg-12 p-0">
            <Card>
              <Card.Body>
                <div className="row">
                  <div
                    className="col-lg-12 p-3"
                    id="strategyBox"
                    style={{
                      display: "contents",
                    }}
                  >
                    <FormulaTab
                      formData={formData}
                      handleRemoveButtonClick={handleRemoveButtonClick}
                      setIsShow={setIsShow}
                      setIsShow3={setIsShow3}
                      onFormChange={onFormChange}
                      handleButtonClick={handleButtonClick}
                      setShowDropdown={setShowDropdown}
                      setInnerAdd={setInnerAdd}
                      setOuterAdd={setOuterAdd}
                      handleAddButtonClick={handleAddButtonClick}
                      outerAdd={outerAdd}
                      setShowClose={setShowClose}
                      setShowOpen={setShowOpen}
                      setShowLowPrice={setShowLowPrice}
                      setShowHighPrice={setShowHighPrice}
                      setShowUlcer={setShowUlcer}
                      setShowDEMA={setShowDEMA}
                      setShowTEMA={setShowTEMA}
                      setShowTMA={setShowTMA}
                      setShowVWMA={setShowVWMA}
                      setShowEMA={setShowEMA}
                      setShowSMA={setShowSMA}
                      setShowNumber={setShowNumber}
                      setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                      setShowWilliams={setShowWilliams}
                      setShowMad={setShowMad}
                      setShowMOM={setShowMOM}
                      setShowObv={setShowObv}
                      setShowAroonDown={setShowAroonDown}
                      setShowAroonUp={setShowAroonUp}
                      setShowOscilator={setShowOscilator}
                      setShowVWAP={setShowVWAP}
                      setShowpsar={setShowpsar}
                      setShowVortexI={setShowVortexI}
                      setShowVolume={setShowVolume}
                      setShowDonchian={setShowDonchian}
                      setShowStochastic={setShowStochastic}
                      setShowChaikin={setShowChaikin}
                      setShowMacdSignal={setShowMacdSignal}
                      setShowCCI={setShowCCI}
                      setShowRoc={setShowRoc}
                      setShowRSI={setShowRSI}
                      setShowRSIMA={setShowRSIMA}
                      setShowMacd={setShowMacd}
                      setShowBB={setShowBB}
                      setShowTR={setShowTR}
                      setShowATR={setShowATR}
                      setShowNATR={setShowNATR}
                      setShowSuperT={setShowSuperT}
                      setShowMfiMa={setShowMfiMa}
                      setShowKST={setShowKST}
                      setShowIchimoku={setShowIchimoku}
                      setShowCandleModal={setShowCandleModal}
                      getIndex={getIndex}
                      setShowWMA={setShowWMA}
                      allStocks={storeAllStocks}
                      setAddInstead={setAddInstead}
                      showInstead={showInstead}
                      handleEdit={handleEdit}
                      handleAdd={handleAdd}
                      setPeriodMaxModal={setPeriodMaxModal}
                      setPeriodMinModal={setPeriodMinModal}
                      setisStdDeviation={setisStdDeviation}
                      setShowUltimateOscillator={setShowUltimateOscillator}
                      setShowNthCandle={setShowNthCandle}
                      setMedianPrice={setMedianPrice}
                      setShowChande={setShowChande}
                      setShowATRTrailModal={setShowATRTrailModal}
                      setShowChoppiness={setShowChoppiness}
                      setCentralRange={setCentralRange}
                      setCamrillaPivot={setCamrillaPivot}
                      setShowPivotPoint={setShowPivotPoint}
                      setShowElderRay={setShowElderRay}
                      setBollingUML={setBollingUML}
                      setPowerMoving={setPowerMoving}
                      setShowDI={setShowDI}
                      setShowTsi={setShowTsi}
                    />
                    &nbsp; &nbsp;
                    {formData.isIncludeMathFunction && (
                      // <button
                      //   onClick={() => {
                      //     setShowDropdown(true);
                      //     setOuterAdd(true);
                      //   }}
                      //   style={addButton}
                      // >
                      //   {"Add"}
                      // </button>
                      <div
                        onClick={() => {
                          setShowDropdown(true);
                          setOuterAdd(true);
                        }}
                        className="pluseIcon"
                      >
                        <i className="fa-solid fa-plus"></i>
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div
              className="row"
              style={{ marginTop: "12px", marginBottom: "16px" }}
            >
              <div className="col-lg-12 d-flex flex-row-reverse">
                <span
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => RemoveFormula()}
                >
                  Remove
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="box my-3">
          <div className="text-center">
            <div className="filecircle mx-auto">
              <i className="fa-regular fa-file"></i>
            </div>
            <h5 className="fw-bolder text-dark pt-3 pb-1">Add a condition</h5>
            <p className="para">
              Add an entity condition by searching <br></br>for indicators and
              comparators
            </p>
          </div>
        </div>
      )}
      <CompartorModal
        showSMA={showSMA3}
        setIsShow={setIsShow3}
        handleSMAClickedPage={handleSMAComparator}
        type={formData?.ctype}
        typeName={formData?.selectedOption2}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Ulcer
        show={showUlcer}
        setIsShow={setShowUlcer}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <TriMA
        show={showTMA}
        setIsShow={setShowTMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Vwma
        show={showVWMA}
        setIsShow={setShowVWMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MOM
        show={showMOM}
        setIsShow={setShowMOM}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <DEMA
        show={showDEMA}
        setIsShow={setShowDEMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <TEMA
        show={showTEMA}
        setIsShow={setShowTEMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Open
        show={showOpen}
        setIsShow={setShowOpen}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Low
        show={showLowPrice}
        setIsShow={setShowLowPrice}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <HighPrice
        show={showHighPrice}
        setIsShow={setShowHighPrice}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Close
        show={showClose}
        setIsShow={setShowClose}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <EMA
        show={showEMA}
        setIsShow={setShowEMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        handleRemoveButtonClick={handleRemoveButtonClick}
        index={itemIndex}
        instance={instance}
      />
      <SMA
        show={showSMA1}
        setIsShow={setShowSMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <WMA
        show={showWMA}
        setIsShow={setShowWMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Number
        show={showNumber}
        setIsShow={setShowNumber}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <MoneyFlowIndex
        show={showMoneyFlowIndex}
        setIsShow={setShowMoneyFlowIndex}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <WilliamsR
        show={showWilliams}
        setIsShow={setShowWilliams}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MovingAverageDeviation
        show={showMad}
        setIsShow={setShowMad}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MACD
        show={showMacd}
        setIsShow={setShowMacd}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <OBV
        show={showObv}
        setIsShow={setShowObv}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <AroonDown
        show={showAroonDown}
        setIsShow={setShowAroonDown}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <AroonUp
        show={showAroonUp}
        setIsShow={setShowAroonUp}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <AwesomeOscillator
        show={showOscilator}
        setIsShow={setShowOscilator}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <VWAP
        show={showVWAP}
        setIsShow={setShowVWAP}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <ParabolicSAR
        show={showpsar}
        setIsShow={setShowpsar}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <VortexIndicator
        show={showVortexI}
        setIsShow={setShowVortexI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Volume
        show={showVolume}
        setIsShow={setShowVolume}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <DonchianChannel
        show={showDonchian}
        setIsShow={setShowDonchian}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Stochastic
        show={showStochastic}
        setIsShow={setShowStochastic}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <ChaikinMoneyFlow
        show={showChaikin}
        setIsShow={setShowChaikin}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <CCI
        show={showCCI}
        setIsShow={setShowCCI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PriceRateofChange
        show={showRoc}
        setIsShow={setShowRoc}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MACDsignal
        show={showMacdSignal}
        setIsShow={setShowMacdSignal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <RSI
        show={showRSI}
        setIsShow={setShowRSI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <RSIMovingAverage
        show={showRSIMA}
        setIsShow={setShowRSIMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <BollingerB
        show={showBB}
        setIsShow={setShowBB}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <TR
        show={showTR}
        setIsShow={setShowTR}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <ATR
        show={showATR}
        setIsShow={setShowATR}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <NATR
        show={showNATR}
        setIsShow={setShowNATR}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Supertrend
        show={showSuperT}
        setIsShow={setShowSuperT}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <MfiMovingAverage
        show={showMfiMA}
        setIsShow={setShowMfiMa}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <KnowSureThing
        show={showKST}
        setIsShow={setShowKST}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Ichimoku
        show={showIchimoku}
        setIsShow={setShowIchimoku}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <CandleInterval
        show={showCandleModal}
        setIsShow={setShowCandleModal}
        handleModalData={handleCandleModal}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        instance={instance}
      />
      <PeriodMaxModal
        setIsShow={setPeriodMaxModal}
        showPeriodMaxModal={showPeriodMaxModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PeriodMinModal
        setIsShow={setPeriodMinModal}
        showPeriodMinModal={showPeriodMinModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <VwapStandarddeviation
        show={showStdDeviation}
        setIsShow={setisStdDeviation}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <UltimateOscillator
        show={showUltimateOscillator}
        setIsShow={setShowUltimateOscillator}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <Nthcandle
        show={showNthCandle}
        setIsShow={setShowNthCandle}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <MedianPrice
        show={showMedianPrice}
        setIsShow={setMedianPrice}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <ChandeMomentumOscillator
        show={showChande}
        setIsShow={setShowChande}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <ATRTrailingModal
        show={showATRTrailingModal}
        setIsShow={setShowATRTrailModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <ChoppinessIndex
        show={showChoppiness}
        setIsShow={setShowChoppiness}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <CentralPivotRange
        show={showCentralRange}
        setIsShow={setCentralRange}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <CamarillaPivot
        show={showCamrillaPivot}
        setIsShow={setCamrillaPivot}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PivotPoints
        show={showPivotPoint}
        setIsShow={setShowPivotPoint}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <ElderRayIndex
        show={showElderRay}
        setIsShow={setShowElderRay}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      {/* <UBB
        show={bollingUML}
        setIsShow={setBollingUML}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <MBB
        show={bollingUML}
        setIsShow={setBollingUML}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      /> */}
      <LBB
        show={bollingUML}
        setIsShow={setBollingUML}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PhasePowerMovingAverage
        show={powerMoving}
        setIsShow={setPowerMoving}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PeriodOffset
        show={showDI}
        setIsShow={setShowDI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <Tsi
        show={showTsi}
        setIsShow={setShowTsi}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
    </div>
  );
};

export default ScannerConditionComponent;
