import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Card, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import SideBarSingleStrategy from "./SideBarSingelStrategy";


const SingleDiscover = () => {
    const [series, setSeries] = useState([
        {
          name: "Desktops",
          data: [59, 300, 59, 500, 100 ,200 ,300 , 100],
        },
      ]);
    
    const [options, setOptions] = useState({
        chart: {
          stroke: {
            show: true,
            // curve: 'stepline',
            lineCap: 'butt',
            colors: '#33b2df',
            width: 0.5,
            dashArray: 0, 
          },
          // id: "basic-bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          // sparkline: {
          //   enabled: true
          // }
        },
        stroke: {
            curve: 'stepline',
          },
        dataLabels: {
          enabled: false,
        },
        colors: ['#76C253'],
        title: {
          //   text: "Openeing Range Break",
          align: "left",
          style: {
            fontWeight: "500",
            fontSize: "16px",
            padding: 0,
            margin: 0,
          },
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          min: 0,
        max: 600,
        tickAmount: 6,
          show: true,
          labels: {
            show: true,
            align: 'left',
            minWidth: 0,
            maxWidth: 160,
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            // formatter: (value) => { return val },
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: false ,
          },
        },
        grid: {
          show: false,
        },
      });
    
    
    return(
        <>
        <section className="mobile-view">
            <div className="container web-cotainer">
                <Card id="single-discover-card">
                  <Card.Body className="d-flex align-items-center">
                      <div className="mr-auto"> {/* This will push the following content to the center */}
                          <i className="fa fa-chevron-left pointer-cursor"></i>
                      </div>
                      <div className="d-flex flex-column align-items-start px-4">
                          <div>
                              <span className="title">
                                  Discover
                              </span>
                          </div>
                          <div>
                              <span className="sub-title">Breakout and Bulish Trend</span>
                          </div>
                          <div>A long position in CE is taken when 30min opening range high of Bank Nifty is broken and RSI is showing strength.</div>
                      </div>
                  </Card.Body>
                </Card>
                <Row className="mt-3 stocks-details-card" id="stocks-details-card">
                    <Col lg={2}>
                        <div>
                            <Card className="active">
                                <Card.Body className="text-center p-0 active-card">
                                    <img src="/icons/Ellipse9.png" alt="images"/>
                                    <div className="d-flex flex-column">
                                    <span className="pt-3 main-title">BankBaroda</span>
                                    <span className="text-muted py-3 sub-title">279.87(+2.87%)</span>
                                    <span className="p-2 profit">+8.78%</span>
                                    </div>
                                    {/* <div id="pointer"></div> */}
                                </Card.Body>
                            </Card>
                            <Card className="mt-3">
                              <Card.Body className="text-center p-0">
                                      <img src="/icons/Ellipse9.png" alt="images"/>
                                      <div className="d-flex flex-column">
                                      <span className="pt-3 main-title">BankBaroda</span>
                                      <span className="text-muted py-3  sub-title">279.87(+2.87%)</span>
                                      <span className="p-2 profit">+8.78%</span>
                                      </div>
                                  </Card.Body>
                            </Card>  
                        </div>
                    </Col>
                    <Col lg={7}>
                        <Card id="discover-card-bar-details">
                            <Card.Header>
                                <div className="head-section-first">
                                    <img src="/icons/Ellipse9.png" alt="images" />
                                    &nbsp;&nbsp;<span>BAJAJFINSV</span>
                                </div>
                                <div className="head-section-second">
                                    <span>Backtest P&L </span>
                                    <span className="amount">1,07,440(+8.42 %)</span>
                                </div>
                                <div className="head-section-third">
                                    <Button variant="default">Brokerage</Button>
                                </div>

                                {/* <ReactApexChart options={candleOption} series={candleSeries} type="candlestick" height={350} /> */}

                            </Card.Header>
                            <Card.Body>
                            <Chart options={options} series={series} type="line" height="170" />
                            <hr/>
                            <div className="d-flex flex-wrap streak-data mt-3">
                                    <div className="details-item">
                                        <span className="item-number">
                                            8
                                        </span>
                                        <span className="sub-text">
                                            Total number of trades
                                        </span>
                                    </div>
                                    <div  className="details-item">
                                        <span  className="item-number">
                                            5
                                        </span>
                                        <span className="sub-text">
                                        Number of wins
                                        </span>
                                    </div>
                                    <div  className="details-item">
                                        <span  className="item-number">
                                            3
                                        </span>
                                        <span className="sub-text">
                                        Number of losses
                                        </span>
                                    </div>
                                    <div  className="details-item">
                                        <span  className="item-number">
                                            2
                                        </span>
                                        <span className="sub-text">
                                        Winning streak
                                        </span>
                                    </div>
                                    <div  className="details-item">
                                        <span  className="item-number">
                                            1
                                        </span>
                                        <span className="sub-text">
                                        Losing streak
                                        </span>
                                    </div>
                                    <div  className="details-item">
                                        <span  className="item-number">
                                            32.29
                                        </span>
                                        <span className="sub-text">Max gains</span>
                                    </div>
                                    <div  className="details-item">
                                        <span  className="item-number">-33.10</span>
                                        <span className="sub-text">Max loss</span>
                                    </div>
                                    <div  className="details-item">
                                        <span  className="item-number">23.31</span>
                                        <span className="sub-text">Avg gain/winning trade</span>
                                    </div>
                                    <div  className="details-item">
                                        <span  className="item-number">
                                        -19.85
                                        </span>
                                        <span className="sub-text">Avg loss/losing trade</span>
                                    </div>
                                    <div  className="details-item">
                                        <span  className="item-number">-0.5646567</span>
                                        <span className="sub-text">Max DD</span>
                                    </div>
                                    </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <SideBarSingleStrategy strategyDetails=""/>
                    </Col>
                </Row>

            </div>
        </section>
        </>
    )
}

export default SingleDiscover;