import React, { useMemo } from "react";
import Paisa5SignUp from "./Paisa5SignUp";
import Paisa5AccountDetails from "./Paisa5AccountDetails";
import Paisa5AddAccountMsg from "./Paisa5AddAccountMsg";

const Paisa5Page = (props) => {
  const {
    isAccountAdd,
    paisa5FormData,
    handleGetPaisaDetails,
    setIsAccountAdd,
    setShow,
    show,
    isDeleteAccount,
    setIsDeleteAccount,
    setConfirmationMsg,
  } = props;
  console.log("isAccountAdd =====", paisa5FormData);
  if (
    (paisa5FormData === undefined || paisa5FormData === "") &&
    isAccountAdd !== "signupform" &&
    isAccountAdd !== "detailsform"
  ) {
    setIsAccountAdd("newAccount");
  }

  const memoizedComponent = useMemo(() => {
    return isAccountAdd === "newAccount" ? (
      <Paisa5AddAccountMsg setIsAccountAdd={setIsAccountAdd} />
    ) : isAccountAdd === "signupform" ? (
      <Paisa5SignUp
        handleGetPaisaDetails={handleGetPaisaDetails}
        paisa5FormData={paisa5FormData}
        setIsAccountAdd={setIsAccountAdd}
      />
    ) : isAccountAdd === "detailsform" ? (
      <Paisa5AccountDetails
        paisa5FormData={paisa5FormData}
        setIsAccountAdd={setIsAccountAdd}
        handleGetPaisaDetails={handleGetPaisaDetails}
        setShow={setShow}
        show={show}
        isDeleteAccount={isDeleteAccount}
        setIsDeleteAccount={setIsDeleteAccount}
        setConfirmationMsg={setConfirmationMsg}
      />
    ) : null;
  }, [isAccountAdd, paisa5FormData, handleGetPaisaDetails]);

  return <>{memoizedComponent}</>;
};

export default Paisa5Page;
