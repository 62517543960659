import React, { useEffect, useMemo, useState } from "react";

import ZerodhaAddAccountMsg from "./zerodha/ZerodhaAddAccountMsg";
import ZerodhaSignUp from "./zerodha/ZerodhaSignUp";
import ZerodhaDetails from "./zerodha/ZerodhaDetails";

const ZerodhaPage = (props) => {

    const {setShow, setConfirmationMsg, isDeleteAccount ,setIsDeleteAccount ,isAccountAdd ,setIsAccountAdd ,featchAccountDetails ,zerodhaData} = props;
  
    const memoizedComponent =  useMemo(() => {
        return isAccountAdd === "newAccount" ? <ZerodhaAddAccountMsg setIsAccountAdd={setIsAccountAdd} /> :
        isAccountAdd === "signupform" ? <ZerodhaSignUp zerodhaData ={zerodhaData} setIsAccountAdd={setIsAccountAdd} featchAccountDetails={featchAccountDetails} /> :
        isAccountAdd === "detailsform" ? <ZerodhaDetails  zerodhaData ={zerodhaData} setIsAccountAdd={setIsAccountAdd} setShow={setShow} setConfirmationMsg={setConfirmationMsg} isDeleteAccount={isDeleteAccount}  setIsDeleteAccount={setIsDeleteAccount} featchAccountDetails={featchAccountDetails}/> : null
    } ,[isAccountAdd, setConfirmationMsg, isDeleteAccount,zerodhaData ,setShow ,setIsDeleteAccount]);

    return(
        <>
        {memoizedComponent}
        </>
    )
}

export default ZerodhaPage;
