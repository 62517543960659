import axios from "axios";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "http://3.111.147.17:8008/api/v1";
const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const paisaCreateAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaAddAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const paisaGetAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaGetAccount`,
      data
    );

    if (response.data.success) {
      return response.data.accountDetails;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const paisaDeleteAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaDeleteAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const paisaEditAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaEditAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getCountries = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/get_countires`);
    console.log(response.data.success);
    if (response.data.success) {
      console.log("state:::@@", response.data.countries);
      return response.data.countries;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};
export const getStates = async (country_id) => {
  try {
    console.log("??", country_id);
    const response = await axios.post(`${API_BASE_URL}/users/get_states`, {
      country_id: country_id,
    });
    console.log(response.data.success);
    if (response.data.success) {
      console.log("state:::", response.data.states);
      return response.data.states;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};
export const saveprofiledetails = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/users/edit-profile`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
export const deleteprofileimage = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/users/remove-image`,
      data
    );
    if (response.data.success) {
      console.log("imgprof1", response);
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
export const submitfeedback = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/users/add-feedback`,
      data
    );
    if (response.data.success) {
      return response.data.success;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
// users/getSingleUserDetails
export const getUserDetails = async (data) => {
  console.log("first+++", data);
  try {
    const response = await axios.post(
      `${API_BASE_URL}/users/getSingleUserDetails`,
      data
    );
    console.log("response", response);
    if (response.data.success) {
      return response.data.userDetails;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
export const Gettingcontactdetails = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/users/add-get-in-touch`,
      data
    );
    console.log("response", response);
    if (response.data.success) {
      return response.data.success;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
