import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { getUser } from "../../Session";
import { zerodhaAddAccount, zerodhaEditAccount } from "../../api/zerodhaapi";

const ZerodhaSignUp = (props) => {
    const { zerodhaData ,setIsAccountAdd ,featchAccountDetails} = props;

    const [formData,setFormData] = useState({
        apiKey: zerodhaData !== "" ? zerodhaData?.api_key :'',
        apiSecret: zerodhaData !== "" ? zerodhaData?.api_secret :'',
      });
    
      const [formDataError,setFormDataError] = useState({
        errorapiKey: '',
        errorapiSecret: '',
      });

      const { apiKey, apiSecret} = formData;
      const { errorapiKey, errorapiSecret} = formDataError;
    
      const handleChangeValue = (e) => {
        const {name , value } =e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    

      const handelSubmit = async (e) => {
        e.preventDefault();
        let flag = 0;
        if (apiKey === '') {
          setFormDataError((prevErrors) => ({
            ...prevErrors,
            errorapiKey: "Please enter app key",
          }));
          flag =1;
        }else{
          setFormDataError((prevErrors) => ({
            ...prevErrors,
            errorapiKey: '',
          }));
        }
    
        if (apiSecret === '') {
          setFormDataError((prevErrors) => ({
          ...prevErrors,
          errorapiSecret: "Please enter app secret key",
        }));
        flag =1;
    
        }else{
          setFormDataError((prevErrors) => ({
            ...prevErrors,
            errorapiSecret: '',
          }));
        }
    
       
        if(flag === 0 ){
          const user = getUser();
          console.log("user",user);
          const userId  = user.id;
         
            console.log("zerodhaData?.id ", zerodhaData?.id)
          if(zerodhaData?.id !== undefined){
            const updateData =  {
              ...formData,
              id : zerodhaData?.id
            }
            await zerodhaEditAccount(updateData);
          }else{
            const data = {
              ...formData,
              user_id : userId
            }
            await zerodhaAddAccount(data);
          }
          setIsAccountAdd('detailsform');
          featchAccountDetails();
        }
      }

    return(
        <div className="col-lg-9">
            <div className="info-div">
              <h2 className="mobile-title">Zerodha Registartion</h2>
              <div className="card mb-4">
                <div className="card-body">
                <Form onSubmit={handelSubmit}>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextAppName">
                    <Form.Label column sm="3">
                    App Key
                    </Form.Label>
                    <Col sm="9">
                    <Form.Control value={apiKey} name="apiKey"  type="text" placeholder="Enter app key" onChange={handleChangeValue} />
                    <span className="text-error">{errorapiKey}</span>
                    </Col>
                </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                    Secret key
                    </Form.Label>
                    <Col sm="9">
                    <Form.Control value={apiSecret} name="apiSecret"  type="text" placeholder="Enter app secret key"  onChange={handleChangeValue}/>
                    <span className="text-error">{errorapiSecret}</span>
                    </Col>
                </Form.Group>
                <Row className=" justify-content-md-center">
                <Col xs lg="2">
                    <Button variant="primary" type="submit">{zerodhaData?.id ? "Update" : "Submit"}</Button>
                </Col>
                </Row>
                </Form>
                </div>
              </div>
            </div>
          </div>
    )
}

export default ZerodhaSignUp;