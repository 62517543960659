import React from 'react'

function Shipping() {
    const p={
        paddingTop:"150px",
        paddingBottom:"50px",
    }
  return (
    <section id="about-page">
    <div className="container container-lg">
      <div className="row">
        <div className="text-center">
          <h1 className="text-primary fw-bold">
          Shipping & Delivery Policy
          </h1>
        </div>
        <div className="fw-medium fs-5 py-4">
          <p>Last updated on Oct 5th, 2023.</p>
          <br />
          <p>
          Shipping is not applicable for our business.
          </p>
        </div>
      </div>
    </div>
  </section>
    // <div className='container' style={p}>
    //     <h3>Shipping & Delivery Policy:</h3>
    //         <p>Last updated on Oct 5th, 2023.</p>
    //         <br></br>
    //         <p>Shipping is not applicable for our business.</p>                 
    // </div>
  )
}

export default Shipping