import React from "react";
import BuyCard from "../mcomponents/BuyCard";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
function Pricing() {
  const navigate = useNavigate();
  const handelSubscribeFreeBtn = () => {
    navigate("/signup");
  };
  const handelSubscribePrimeBtn = () => {
    navigate("/signup");
  };
  return (
    <section className="mobile-view" id="subscribe">
      <div className="container web-cotainer">
        <div className="subscribe-title pt-5">
          <center>
            <h1>Subscription Plan</h1>
          </center>
        </div>
        <Row>
          <Col lg={6} md={6} xs={12} sm={12}>
            <BuyCard
              planName="Free"
              className="free-subscribe"
              price="0"
              // onClick={handelSubscribeFreeBtn}
              handelSubscribeBtn={handelSubscribeFreeBtn}
            />
          </Col>
          <Col lg={6} md={6} xs={12} sm={12}>
            <BuyCard
              planName="Premium"
              className="prime-subscribe"
              price={1000}
              // onClick={handelSubscribePrimeBtn}
              handelSubscribeBtn={handelSubscribePrimeBtn}
            />
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Pricing;
