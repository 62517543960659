// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-alert {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color:#fff ; 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  padding: 5px 5px;
  max-width: 90%; 
  color: #fff;
}

.innerDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px; 
}

.alert {
  display: flex;
  align-items: center;
  width: 100%;
}

.alert-close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 25px;
  height: 25px;
  border: 1px solid #7ab1dd;
  text-align: center;
  line-height: 22px;
  font-size: 25px;
  cursor: pointer;
  color: #000;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

.alert-close:hover {
  color: #721c24;
  background-color: #7ab1dd;
}

.alert-icon {
  margin-right: 15px;
  font-size: 28px;
}

p {
  margin: 0;
}

.alert-success {
  background-color: #28a745; 
}

.alert-error {
  background-color: #dc3545; 
}

.alert-info {
  background-color: #17a2b8; 
}
`, "",{"version":3,"sources":["webpack://./src/styles/CustomAlert.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;EACxC,gBAAgB;EAChB,cAAc;EACd,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".custom-alert {\n  position: fixed;\n  top: 10%;\n  left: 50%;\n  transform: translateX(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n  background-color:#fff ; \n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); \n  padding: 5px 5px;\n  max-width: 90%; \n  color: #fff;\n}\n\n.innerDiv {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  max-width: 500px; \n}\n\n.alert {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.alert-close {\n  position: absolute;\n  top: -15px;\n  right: -15px;\n  width: 25px;\n  height: 25px;\n  border: 1px solid #7ab1dd;\n  text-align: center;\n  line-height: 22px;\n  font-size: 25px;\n  cursor: pointer;\n  color: #000;\n  border-radius: 50%;\n  background-color: white;\n  cursor: pointer;\n}\n\n.alert-close:hover {\n  color: #721c24;\n  background-color: #7ab1dd;\n}\n\n.alert-icon {\n  margin-right: 15px;\n  font-size: 28px;\n}\n\np {\n  margin: 0;\n}\n\n.alert-success {\n  background-color: #28a745; \n}\n\n.alert-error {\n  background-color: #dc3545; \n}\n\n.alert-info {\n  background-color: #17a2b8; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
