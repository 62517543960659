import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const TechnicalCreateModal = ({showCreateModal , setShowCreateModal ,handleCreateList}) => {
  const [show, setShow] = useState(true);
  const [strategiesName, setStrategiesName] = useState();
  const [isDisable, setIsDisable] = useState(true);

  const handleClose = () => {
    setShowCreateModal(false);
  };
const setName=(e)=>{
  setStrategiesName(e.target.value)
}
  return (
    <>
     <Modal
        show={showCreateModal}
        onHide={handleClose}
        animation={false}
        centered
        dialogClassName="technical-modal"
      >
        <Modal.Body
          className="text-center"
        >
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="mb-4 fs-4">Create New List</Form.Label>

              <Form.Control
                type="text"
                placeholder="List Name"
                value={strategiesName}
                className="w-100"
                autoComplete="off" onChange={setName}
              />
              {/* {nameError && (
                <span className="text-error">Name cannot be empty </span>
              )} */}
            </Form.Group>
            <Button
              className="btn-color w-100 "
              variant="primary"
              onClick={() => handleCreateList(strategiesName)}
              // size="sm"
              
            >
              Create
            </Button>
            <br />
            <br/>
            {/* <Button className="border-0" variant="default" onClick={handleClose} size="sm">
              Cancel
            </Button> */}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TechnicalCreateModal;
