import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DeleteStrategyModal({
  show,
  handleClose,
  handleConfirm,
  confirmationMsg,
  mode,
}) {
  return (
    <>
      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete &nbsp;
            {mode === "Scanner" ? "Scanner" : "Strategy"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure, you want to remove this&nbsp;
          {mode === "Scanner" ? "Scanner" : "Strategy"}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteStrategyModal;
