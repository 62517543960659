import React from "react";
import PdfViewer from "../components/pdfViewer";
import { Margin } from "@mui/icons-material";

function Help() {
  const c = {
    paddingTop: "20px",
    paddingBottom: "50px",
    width: "90%",
    margin: "0px auto",
  };
  return (
    <>
      <h1>help</h1>
      <div style={c}>
        <iframe
          src="/mymanual.pdf"
          title="p"
          frameborder="0"
          height="800px"
          width="100%"
        ></iframe>
      </div>
    </>
  );
}

export default Help;
