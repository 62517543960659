import React from "react";
import { Button, Modal } from "react-bootstrap";

const RemoveFormulaModal = ({
  show,
  hide,
  handleConfirm,
  confirmationMsg,
}) => {
    const handleClose = () => {
        hide(false);
    }

  return (
    <>
      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Strategy</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to remove this formula?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveFormulaModal;
