import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Paisa5login from "../components/dashboardComponent/Paisa5login";


const CustomModal=({
    showCreatePopup,
    handelClickCreateStrategy,
    setShowCreatePopup,
  }) => {

    const handleClose = () => {
        setShowCreatePopup(false);
      };

    return(

        <>
         <Modal
         show={showCreatePopup}
         onHide={handleClose}
         animation={false}
         centered
      >
        <Modal.Body
        //   id="create-strategy-modal"
        //   className="d-flex align-items-center justify-content-center"
        >
          <Paisa5login />
        </Modal.Body>
      </Modal>
        </>
    );
}
export default CustomModal;