import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { paisa5Totplogin } from "../../api/paisa5api";
import { get5paisaToken, getUser } from "../../Session";
import Paisa5HMPDetails from "./Paisa5HMPDetails";
import { useNavigate } from "react-router-dom";
import { saveActiveAccount } from "../../Session";
// import {saveActiveAccount} from "../Session";
const paisa5Token = get5paisaToken();

const Paisa5login = (props) => {
 
    const navigate=useNavigate();
    console.log("Paisa5login",props.paisa5data);
    console.log("paisa5Token",paisa5Token);
    const { handleChangeConnect , connectBtn ,paisa5data,handleClose,setMyacc ,setActiveUser,  showCreatePopup,setShowCreatePopup,setActiveaccount,state } = props;
    const [token , setToken ] = useState(() => {
        if (paisa5Token && paisa5Token.trim() !== "") {
          return true;
        } else {
          return false;
        }
      });

    const [formData , setFormData ] = useState({
        clientCode : paisa5data !== undefined ? paisa5data[0]?.client_id : '',
        totp : '',
        pin: '',
    });

    const [formDataError , setFormDataError ] = useState({
        clientCodeError : '',
        totpError : '',
        pinError : '',
    });

    const {clientCode , totp , pin } = formData ; 
    const {clientCodeError , totpError , pinError } = formDataError ; 

    const handleChangeValue = (event) => {
        console.log(event.target.value);
        const name = event.target.name;
        const value = event.target.value; 
        setFormData((preFormData) => ({...preFormData,[name] : value}));
    }

    const handleSubmit = async (e) => {
   
        try{
            console.log("hello handle change");
            let flag = 0 ;
            e.preventDefault();
            if(clientCode === ''){
                flag = 1;
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    clientCodeError: 'Please Enter User ID',
                }));
            }else{
                flag = 0 ;
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    clientCodeError: '',
                }));
            }

            if(totp === ''){
                flag = 1;
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    totpError: 'Please Enter TOTP',
                }));
            }else{
                flag = 0 ;
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    totpError: '',
                }));
            }

            if(pin === ''){
                flag = 1;
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    pinError: 'Please Enter MPIN',
                }));
            }else{
                flag = 0 ;
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    pinError: '',
                }));
            }
           
            if(!flag){
                console.log("formData",formData);
                const user = getUser();
                const data = {
                    client_code : clientCode,
                    totp :  totp,
                    pin : pin,
                    user_id : user.id,
                }
                const responce = await paisa5Totplogin(data);
                console.log("responce",responce);
                if(responce){
                 console.log("RRR");
                    setToken(true);
                    setActiveaccount(true)
                    if(state?.val==2)
                    {
                    setActiveUser(true);
                    setMyacc(true);
                    }
                   
                    saveActiveAccount('paisa');       
                    console.log("{active}",setActiveaccount);
                    handleClose();
                    window.location.reload();
                    if(state?.val==1)
                    {
                    navigate('/dashboard');
                    }
                   
                   
                }
                else{
                    alert("You have Entered incorrect TOTP, Please try again");
                }
            }

        }catch(error){
             console.log("Error : " , error);
        }

    }

    console.log("tokencheck...",token);
    useEffect(()=>{
     
        if(paisa5Token && paisa5Token.trim !== ""){
            setToken(true);
        }
        if(paisa5data && paisa5data.length > 0){
            setFormData((prevData) =>({
                ...prevData,
                clientCode : paisa5data[0]?.client_id
            })
           )
        }
    },[token,paisa5data ,showCreatePopup]);

const abc=()=>{
   
    handleClose();
   
}
    const loginFormContent = useMemo(() => {
        return (
            <>
            <Form onSubmit={handleSubmit}>
            <center>
            <Form.Group as={Row} className="justify-content-md-center pt-2" controlId="formPlaintextAppName">
                 <Col sm="9">
                 <Form.Control name="clientCode" value={clientCode} readOnly type="text" placeholder="Enter User ID" onChange={handleChangeValue} />
                 <span className="text-error">{clientCodeError}</span>
                 </Col>
             </Form.Group>
             <Form.Group as={Row}  className="justify-content-md-center pt-2" controlId="formPlaintextAppName">
                 <Col sm="9">
                 <Form.Control  name="pin" value={pin}  type="text"  placeholder="Enter MPIN" onChange={handleChangeValue} />
                 <span className="text-error">{pinError}</span>
                 </Col>
             </Form.Group>
             <Form.Group as={Row}  className="justify-content-md-center pt-2" controlId="formPlaintextAppName">
                 <Col sm="9">
                 <Form.Control  name="totp" value={totp}  type="text" placeholder="Enter TOTP" onChange={handleChangeValue} />
                 <span className="text-error">{totpError}</span>
                 </Col>
             </Form.Group>
            <Button type="submit" className="text-center mt-2" >Login</Button>
            <Button  className="text-center mt-2 ms-2" onClick={abc} >Close</Button>
             </center>
            
         </Form>
         
          </>
        );
      }, [clientCode, totp, pin, clientCodeError, totpError, pinError,showCreatePopup]);

    return(
        <>
        {/* <h2 className="mobile-title text-center">Welcome 5paisa page</h2> */}
        {/* { token ? */}
            {/* // <Paisa5HMPDetails /> */}
        {/* // : */}
        {/* //     !connectBtn ?  */}
        {/* //     <center> */}
        {/* //     <Button variant="primary"  */}
        {/* //     onClick={handleChangeConnect}> */}
        {/* //      Connect  */}
        {/* //     </Button> */}
        {/* //     </center>  */}
        {/* // :  */}
         {loginFormContent}
        {/* // } */}
    

        </>
    )
}


export default Paisa5login;