import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";

import { useMenuContext } from "./StrategyCreateComponent";
import { getfunctionTypeWisefunction } from "../../api/strategyapi";

import axios from "axios";

import SMAModal from "./SMAModal";

import SMAModal2 from "./SMAModal2";
import CompartorModal from "./ComparatorModal";
import "./Strategy.css";
import Ulcer from "../modal/Ulcer";
import TriMA from "../modal/TriMA";
import Vwma from "../modal/Vwma";
import DEMA from "../modal/DEMA";
import TEMA from "../modal/TEMA";
import Open from "../modal/Open";
import Low from "../modal/Low";
import Close from "../modal/Close";
import HighPrice from "../modal/HighPrice";
import EMA from "../modal/EMA";
import SMA from "../modal/SMA";
import FormulaTab from "./FormulaTab";
import Number from "../modal/Number";
import MoneyFlowIndex from "../modal/MoneyFlowIndex";
import WilliamsR from "../modal/WilliamsR";
import MovingAverageDeviation from "../modal/MovingAverageDeviation";
import MACD from "../modal/MACD";
import OBV from "../modal/OBV";
import AroonDown from "../modal/AroonDown";
import AroonUp from "../modal/AroonUp";
import ParabolicSAR from "../modal/ParabolicSAR";
import VortexIndicator from "../modal/VortexIndicator";
import DonchianChannel from "../modal/DonchianChannel";
import Stochastic from "../modal/Stochastic";
import ChaikinMoneyFlow from "../modal/ChaikinMoneyFlow";
import CCI from "../modal/CommodityChannelIndex";
import PriceRateofChange from "../modal/PriceRateofChange";
import MACDsignal from "../modal/MACDsignal";
import AwesomeOscillator from "../modal/AwesomeOscillator";
import VWAP from "../modal/VWAP";
import Volume from "../modal/Volume";
import moment from "moment";
import RSI from "../modal/RSI";
import RSIMovingAverage from "../modal/RSIMovingAverage";
import RemoveFormulaModal from "./RemoveFormulaModal";
import BollingerB from "../modal/BollingerB";
import TR from "../modal/TR";
import ATR from "../modal/ATR";
import NATR from "../modal/NATR";
import Supertrend from "../modal/Supertrend";
import MfiMovingAverage from "../modal/MfiMovingAverage";
import KnowSureThing from "../modal/KnowSureThing";
import Ichimoku from "../modal/Ichimoku";
import CandleInterval from "../modal/CandleInterval";
import WMA from "../modal/WMA";
import MOM from "../modal/MOM";
import ChandeMomentumOscillator from "../modal/ChandeMomentumOscillator";
import { getZerodhaToken } from "../../Session";
import PeriodMaxModal from "../modal/PeriodMaxModal";
import PeriodMinModal from "../modal/PeriodMinModal";
import replaceNewData from "../../Utils/replaceNewData";
import {
  helperModalReplaceFn777,
  helperModalSwitchFn,
  helperModalSwitchForMath,
} from "../../Utils/helperModalSwitchFn";
import indicatorsSwitchCase from "../../Utils/indicatorsSwitchCase";
import {
  calculateAdjustedIndex,
  filterFunctionsBox777,
} from "../../Utils/indextoRemoveMath";
import formateFnObject from "../../Utils/formateFnObject";
import generateMathOutput from "../../Utils/CommonFunction/generateMathOutput";
import getCandleId from "../../Utils/CommonFunction/getCandleId";
import updateHandleModalData from "../../Utils/CommonFunction/handleModalData";
import GetFunctionofHandle from "../../Utils/CommonFunction/GetFunctionofHandle";
import handleRemoveCondition from "../../Utils/CommonFunction/handleRemoveCondition";
import FunctionList from "../componentCommon/FunctionList";
import mergeArrays from "../../Utils/CommonFunction/mergeArrays";
import VwapStandarddeviation from "../modal/VwapStandarddeviation";
import UltimateOscillator from "../modal/UltimateOscillator";
import Nthcandle from "../modal/Nthcandle";

const addButton = {
  backgroundColor: "transparent", // No background color
  color: "#0e6efd", // Text color
  fontWeight: "500",
  fontSize: "16px",
  height: "30px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px dashed #1579c9", // Dashed border
  borderRadius: "4px",
  cursor: "pointer",
  transition: "border-color 0.3s", // Only transition for border color
  marginRight: "5px",
};

const selectedItemStyle = {
  backgroundColor: "#00aeee", // Customize the background color as needed
  fontWeight: "bold", // Add additional styles if needed
  border: "1px solid blue",
};

let eflag = 0;

const StrategyEntryComponent = ({
  formData,
  onFormChange,
  allStocks,
  save,
}) => {
  const { menu, setMenu, allStockStore } = useMenuContext();
  console.log("FORMDATA", formData, save);
  const divRef = useRef(null);
  const conditionDropdownRef = useRef(null);
  const [flag, setFlag] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [functionType, setFunctionType] = useState([]);
  const [showSMA, setIsShow] = useState(false);
  const [showSMA2, setIsShow2] = useState(false);
  const [showSMA3, setIsShow3] = useState(false);
  const [conditionTab, setConditionTab] = useState(false);
  const [mode, setMode] = useState("");
  const [remove, setRemove] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");
  const [currentId, setCurrentId] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [innerAdd, setInnerAdd] = useState("");
  const [outerAdd, setOuterAdd] = useState("");
  //Modal state variables
  const [showUlcer, setShowUlcer] = useState(false);
  const [showTMA, setShowTMA] = useState(false);
  const [showVWMA, setShowVWMA] = useState(false);
  const [showDEMA, setShowDEMA] = useState(false);
  const [showTEMA, setShowTEMA] = useState(false);
  const [showOpen, setShowOpen] = useState(false);
  const [showLowPrice, setShowLowPrice] = useState(false);
  const [showHighPrice, setShowHighPrice] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [showSMA1, setShowSMA] = useState(false);
  const [showEMA, setShowEMA] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [showMoneyFlowIndex, setShowMoneyFlowIndex] = useState(false);
  const [showWilliams, setShowWilliams] = useState(false);
  const [showMad, setShowMad] = useState(false);
  const [showMacd, setShowMacd] = useState(false);
  const [showObv, setShowObv] = useState(false);
  const [showOscilator, setShowOscilator] = useState(false);
  const [showAroonUp, setShowAroonUp] = useState(false);
  const [showAroonDown, setShowAroonDown] = useState(false);
  const [showpsar, setShowpsar] = useState(false);
  const [showVortexI, setShowVortexI] = useState(false);
  const [showDonchian, setShowDonchian] = useState(false);
  const [showStochastic, setShowStochastic] = useState(false);
  const [showChaikin, setShowChaikin] = useState(false);
  const [showCCI, setShowCCI] = useState(false);
  const [showRoc, setShowRoc] = useState(false);
  const [showMacdSignal, setShowMacdSignal] = useState(false);
  const [showVWAP, setShowVWAP] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [showRSI, setShowRSI] = useState(false);
  const [showRSIMA, setShowRSIMA] = useState(false);
  const [showBB, setShowBB] = useState(false);
  const [showTR, setShowTR] = useState(false);
  const [showATR, setShowATR] = useState(false);
  const [showNATR, setShowNATR] = useState(false);
  const [showSuperT, setShowSuperT] = useState(false);
  const [showMfiMA, setShowMfiMa] = useState(false);
  const [showKST, setShowKST] = useState(false);
  const [showIchimoku, setShowIchimoku] = useState(false);
  const [showWMA, setShowWMA] = useState(false);
  const [showMOM, setShowMOM] = useState(false);
  const [showChande, setShowChande] = useState(false);
  const [showPeriodMaxModal, setPeriodMaxModal] = useState(false);
  const [showPeriodMinModal, setPeriodMinModal] = useState(false);
  const [showCandleModal, setShowCandleModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmRemove, setconfirmRemove] = useState(false);
  const [instance, setInstance] = useState();
  const [isSelectMax, setIsSelectMax] = useState(false);
  const [isSelectMin, setIsSelectMin] = useState(false);
  const [showStdDeviation, setisStdDeviation] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [removedInsideMath, setRemovedInsideMath] = useState(false);
  const [mathIndexRemove, setMathIndexRemove] = useState(-1);
  const isIncludeMathFunction = useRef(null);
  const [showUltimateOscillator, setShowUltimateOscillator] = useState(false);
  const [showNthCandle, setShowNthCandle] = useState(false);
  const [showInstead, setAddInstead] = useState({
    function_id: 0,
    function_type_id: 0,
    isOpen: false,
    index: -1,
  });
  const handleEdit = (instanceData, index) => {
    console.log("MathFn", instanceData, index);
    if (instanceData?.function_type_id == 2) {
      setRemovedInsideMath(true);
    }
    setAddInstead({
      function_id: instanceData?.function_id,
      function_type_id: instanceData?.function_type_id,
      isOpen: true,
      index: index,
    });
    // call dropdown function particular type id //
    handleClickAll(instanceData?.function_type_id?.toString());
  };

  const handleGetFunction1 = (item) => {
    console.log("click", item, formData);
    setSearchQuery("");
    setShowDropdown(false);
    let replaceData = replaceNewData(formData, item, showInstead);
    formData = replaceData;
    // console.log("REPLACE", formData )
    if (replaceData) {
      setAddInstead({
        function_id: 0,
        function_type_id: 0,
        isOpen: false,
        index: -1,
      });
    }
  };
  useEffect(() => {
    eflag = 0;
  }, []);

  useEffect(() => {
    if (save) handleNextEnteryBtn();
  }, [save]);
  useEffect(() => {
    // console.log("functionsBox777>", formData.functionsBox777);
    if (eflag === 1) {
      updateCandleIntervalId();
      updateCandleInterval();
    }
    handleClickAll("1");
  }, [formData.candleIntervalId]);

  const updateCandleInterval = () => {
    // Create a copy of the original data
    // console.log("<><>",formData.functionsBox777);
    let updatedData = [];
    if (Array.isArray(formData?.functionsBox777)) {
      updatedData = [...formData.functionsBox777];
    }
    let candleId = getCandleId(formData);

    updatedData.forEach((item) => {
      item.candleIntervalId = candleId;
    });

    formData.functionsBox777 = updatedData;
    onFormChange(formData);
  };

  const updateCandleIntervalId = () => {
    const updatedIndicators = { ...formData.indicators };
    let candleId = getCandleId(formData);
    for (const key in updatedIndicators) {
      if (updatedIndicators.hasOwnProperty(key)) {
        const indicator = updatedIndicators[key];
        // Check if candleIntervalId property exists
        console.log("UPDATEINTERVAL", indicator);
        if (indicator.hasOwnProperty("candleIntervalId")) {
          indicator.candleIntervalId = candleId; // Replace 'newCandleIntervalValue' with your desired value
        }
        if (indicator?.body) {
          if (Array.isArray(indicator.body)) {
            indicator.body.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body1) {
          if (Array.isArray(indicator.body1)) {
            indicator.body1.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body2) {
          if (Array.isArray(indicator.body2)) {
            indicator.body2.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
      }
    }
    formData.indicators = updatedIndicators;
    onFormChange(formData);
  };

  const getEntryText = async () => {
    const divElement = document.getElementById("strategyBox");
    if (divElement) {
      const hasInputs = divElement.querySelector("input, textarea") !== null;
      let existingContent = divElement.innerText;
      existingContent = existingContent?.replace(
        "{input1Placeholder}",
        formData?.input1
      );
      console.log("CHECKING", existingContent);

      let text1 = handleReplacement(existingContent);
      formData.functionText1 = existingContent;
      onFormChange(formData);
    }
  };
  const toggleDropdown = () => {
    setRemove(false);
    setShowDropdown(true);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    toggleDropdown();
  };

  const handleChangeBuyCellBtn = (val) => {
    console.log("Came1-");
    onFormChange({
      ...formData,
      position_id: val,
    });
  };

  const handleSMAClickedPage = (data) => {
    console.log("ModalClosed-");
    setIsShow(false);

    console.log("formData------", formData);
    if (formData.selectedOption1 === "SMA (Simple Moving Average)") {
      console.log("data<<<<", data);

      formData.offset1 = data?.offset1;
      formData.field = data?.field;
      formData.periods = data?.periods;

      onFormChange(formData);
    }
    if (formData.selectedOption1 === "RSI (Relative Strength Index)") {
      formData.rsiOffset = data?.rsiOffset;
      formData.rsiPeriod = data?.rsiPeriod;
      onFormChange(formData);
    }

    if (formData.selectedOption1 === "EMA (Exponential Moving Average)") {
      formData.offset = data?.offset;
      formData.field = data?.field;
      formData.period = data?.period;
      onFormChange(formData);
    }

    if (formData.selectedOption1 === "Number") {
      formData.period = data?.period;
      onFormChange(formData);
    }
  };
  const handleSMAClickedPage2 = (data) => {
    console.log("Came3-");
    console.log("modalIndi data", data);
    setIsShow2(false);

    onFormChange({
      ...formData,

      offset2: data?.offset2,
      field2: data?.field2,
      periods2: data?.periods2,
    });
  };

  const handleModalData = (data) => {
    updateHandleModalData(
      data,
      formData,
      onFormChange,
      getEntryText,
      itemIndex,
      setIsShow,
      instance
    );
  };

  const handleCandleModal = (data) => {
    // if (data?.candleIntervalId === "month" || data?.candleIntervalId === "week")
    //   formData.monthOrWeekAdded = true;
    // else 
    // formData.monthOrWeekAdded = false;  

    if (data?.candleIntervalId)
      formData.indicators[formData.clickedFunction].candleIntervalId =
        data?.candleIntervalId;

    formData.functionsBox777[itemIndex].candleIntervalId =
      data?.candleIntervalId;
    if (formData.indicators[formData.clickedFunction]?.body)
      formData.indicators[formData.clickedFunction].body[
        itemIndex
      ].candleIntervalId = data?.candleIntervalId;

    if (formData.indicators[formData.clickedFunction][instance])
      formData.indicators[formData.clickedFunction][
        instance
      ][0].candleIntervalId = data?.candleIntervalId;
    onFormChange(formData);
    
    // let startDate = "";
    // let endDate = "";
    // if (formData.candleIntervalId === 1) {
    //   startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
    //   endDate = moment().subtract(29, "days").format("YYYY-MM-DD"); // Assign end date for 1min and 2min intervals
    // } else if (
    //   formData.candleIntervalId === 3 ||
    //   formData.candleIntervalId === 5 ||
    //   formData.candleIntervalId === 10 ||
    //   formData.candleIntervalId === 15 ||
    //   formData.candleIntervalId === 30
    // ) {
    //   startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
    //   endDate = moment().subtract(90, "days").format("YYYY-MM-DD"); // Assign
    // } else if (formData.candleIntervalId === 60) {
    //   if (formData.monthOrWeekAdded) {
    //     startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
    //     endDate = moment().subtract(1, "year").format("YYYY-MM-DD"); // Assign
    //   } else {
    //     startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
    //     // endDate = moment().subtract(1, "year").format("YYYY-MM-DD"); // Assign
    //     endDate = moment().subtract(90, "days").format("YYYY-MM-DD");
    //   }
    // } else {
    //   if (formData.monthOrWeekAdded) {
    //     startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
    //     endDate = moment().subtract(5, "year").format("YYYY-MM-DD"); // Assign
    //   } else {
    //     startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
    //     endDate = moment().subtract(90, "days").format("YYYY-MM-DD"); // Assign
    //   }
    // }

    onFormChange({
      ...formData,
      // start_date: endDate,
      // end_date: startDate,
    });
  };
  const handleRemiceInstead = () => {
    setAddInstead((prev) => ({ ...prev, isOpen: false }));
  };
  const handleSetInstead = () => {
    setAddInstead({
      function_id: 0,
      function_type_id: 0,
      isToggle: false,
    });
  };
  const handleGetFunction = (item) => {
    console.log("SELECT", item);
    GetFunctionofHandle(
      item,
      formData,
      onFormChange,
      setInnerAdd,
      setOuterAdd,
      handleRemiceInstead,
      setCurrentId,
      setConditionTab,
      handleClickAll,
      setSelectedOption1,
      setMode,
      setIsSelectMax,
      setIsSelectMin,
      setSearchQuery,
      setShowDropdown,
      setRemovedInsideMath,
      removedInsideMath,
      mathIndexRemove,
      flag,
      setFlag,
      outerAdd,
      isIncludeMathFunction,
      innerAdd,
      instance
    );
  };

  useEffect(() => {
    setTimeout(() => {
      eflag = 1;
    }, 2000);
  }, []);

  const handleStartTime = (newValue) => {
    var dateObject = moment(newValue, "HH:mm");

    if (dateObject.isValid()) {
      var selectedTime = dateObject.format("HH:mm");

      onFormChange((prevFormData) => ({
        ...prevFormData,
        start_time: selectedTime,
        starttime: newValue,
      }));
    } else {
      console.log("Invalid time");
    }
  };
  const handleEndTime = (newValue) => {
    var dateObject = moment(newValue, "HH:mm");

    if (dateObject.isValid()) {
      var selectedTime = dateObject.format("HH:mm");

      onFormChange((prevFormData) => ({
        ...prevFormData,
        end_time: selectedTime, // Assuming you have an 'end_time' field in formData
        endtime: newValue,
      }));
    } else {
      console.log("Invalid time");
    }
  };

  const handleButtonClick = (index) => {
    console.log("ITEM>", formData.functionsBox2[index]);
  };

  const handleClickAll = async (type) => {
    console.log("maxType", type);
    setSelectedItem(type);
    const data = await getfunctionTypeWisefunction({ type_id: type });
    setFunctionType(data);
  };

  const handleOpenModal = () => {
    setIsShow(true);
  };

  const handleConfirmRemove = () => {
    setconfirmRemove(true);
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleNextEnteryBtn = () => {
    getEntryText();
    let text1 = handleReplacement(formData.functionText1);
    console.log(
      "formula?",
      formData.functionsBox777,
      text1
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line !== "" && !line.startsWith("15 Minutes"))
    );

    mergeArrays(
      formData.functionsBox777,
      text1
        .split("\n")
        .map((line) => line.trim())
        .filter(
          (line) =>
            line !== "" &&
            !line.startsWith("1minute") &&
            !line.startsWith("3minute") &&
            !line.startsWith("5minute") &&
            !line.startsWith("10minute") &&
            !line.startsWith("15minute") &&
            !line.startsWith("30minute") &&
            !line.startsWith("60minute") &&
            !line.startsWith("1day") &&
            !line.startsWith("2h") &&
            !line.startsWith("3h") &&
            !line.startsWith("4h") &&
            !line.startsWith("week") &&
            !line.startsWith("month")
        ),
      onFormChange,
      formData,
      isSelectMax,
      isSelectMin,
      isIncludeMathFunction
    );

    setMenu("exist");
  };
  const getIndex = (index, instance) => {
    console.log("getIndex", instance, index, formData.functionsBox777);
    setItemIndex(index);
    setInstance(instance);
  };

  // handleAdd //
  const handleAdd = (instanceData, index) => {
    toggleDropdown();
  };

  const handleRemoveButtonClick = (
    instanceToRemove,
    indexToRemove,
    mInstance
  ) => {
    handleRemoveCondition(
      formData,
      onFormChange,
      instanceToRemove,
      indexToRemove,
      mInstance,
      setMathIndexRemove,
      setRemovedInsideMath,
      isIncludeMathFunction
    );
  };

  const handleAddButtonClick = (instanceName, bodyInstance) => {
    console.log(
      "ADDING",
      instanceName,
      bodyInstance,
      formData.indicators[instanceName]
    );
    formData.selectedOption3 = instanceName;
    setInstance(bodyInstance);
  };

  const handleReplacement = (text) => {
    console.log("checkText-", text);
    let modifiedText = text.replace(/lower than or equal to/gi, "<=");
    modifiedText = modifiedText.replace(/higher than or equal to/gi, ">=");
    modifiedText = modifiedText.replace(/lower than/gi, "<");
    modifiedText = modifiedText.replace(/higher than/gi, ">");
    modifiedText = modifiedText.replace(/> equal to/gi, ">=");
    modifiedText = modifiedText.replace(/< equal to/gi, "<=");
    modifiedText = modifiedText.replace(/and/gi, "&&");
    modifiedText = modifiedText.replace(/or/gi, "||");
    modifiedText = modifiedText.replace(/equal to/gi, "===");
    modifiedText = modifiedText.replace(/crosses above/gi, ">");
    modifiedText = modifiedText.replace(/crosses below/gi, ">");

    // Add more replacements as needed

    return modifiedText;
  };
  const RemoveFormula = () => {
    let ans = window.confirm("Are you sure you want to remove Formula?");
    if (ans === true) {
      setFlag(0);
      setRemove(true);
      onFormChange((prevState) => ({
        ...prevState,
        functions: [],
        functionsBox: [],
        functionsBox2: [],
        functionsBox777: [],
        functionsMainBox: [],
        indicators: {},
        mode: "",
        selectedOption1: "",
        order: [],
        isIncludeMathFunction: false,
      }));
      handleSetInstead();
      isIncludeMathFunction.current = false;
    }
  };
  return (
    <>
      <div className="row">
        <h6 className="fw-bolder mt-5">Position I would take</h6>
        <div
          className="col-lg-12 col-md-12 col-sm-12 col"
          id="entry-bye-cell-card"
        >
          <Card>
            <Card.Body>
              <ListGroup horizontal>
                <ListGroup.Item
                  action
                  variant="primary"
                  className={formData.position_id === 1 ? "active" : ""}
                  onClick={() => {
                    handleChangeBuyCellBtn(1);
                  }}
                >
                  &nbsp;&nbsp;&nbsp; Buy &nbsp;&nbsp;&nbsp;
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className={formData.position_id === 2 ? "sellactive" : ""}
                  variant="primary"
                  onClick={() => {
                    handleChangeBuyCellBtn(2);
                  }}
                  // onClick={()=>{handleChangeCharType(2)}}
                >
                  &nbsp;&nbsp;&nbsp; Sell &nbsp;&nbsp;&nbsp;
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
        <div className=" mt-5 pb-2 d-flex align-items-center justify-content-between">
          <div>
            <h6 className="fw-bolder">Entry Condition</h6>
          </div>
          <div></div>
        </div>
        <div className="input-group mb-3" id="tab-function-type-dropdown">
          <div className="d-flex row" style={{ minWidth: "300px" }}>
            <div
              // ref={conditionDropdownRef}
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Form.Control
                type="text"
                placeholder="What would you like to add?"
                className="strategy-entry-search"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleDropdown();
                }}
                value={searchQuery}
                onChange={handleSearch}
              />
              <span
                className="close-icon"
                onClick={() => {
                  setSearchQuery("");
                  setShowDropdown(false);
                }}
                style={{ cursor: "pointer", marginLeft: "-25px" }}
              >
                X{/* Replace 'X' with your close iconX */}
              </span>
            </div>

            {/* Dropdown menu */}
            {showDropdown && (
              <FunctionList
                showInstead={showInstead}
                formData={formData}
                isSelectMax={isSelectMax}
                removedInsideMath={removedInsideMath}
                selectedItem={selectedItem}
                selectedItemStyle={selectedItemStyle}
                handleClickAll={handleClickAll}
                handleGetFunction1={handleGetFunction1}
                handleGetFunction={handleGetFunction}
                getEntryText={getEntryText}
                searchQuery={searchQuery}
                functionType={functionType}
                remove={remove}
                isIncludeMathFunction={isIncludeMathFunction}
                onFormChange={onFormChange}
              />
            )}
          </div>
        </div>
        {conditionTab || formData?.conditionTab ? (
          <div className="row" id="strategy-entry-condition-card" ref={divRef}>
            <div className="col-lg-12 p-0">
              <Card>
                <Card.Body>
                  {/* bg-danger */}
                  <div className="row">
                    <div
                      className="col-lg-12 p-3"
                      id="strategyBox"
                      style={{
                        display: "contents",
                      }}
                    >
                      <FormulaTab
                        formData={formData}
                        handleRemoveButtonClick={handleRemoveButtonClick}
                        setIsShow={setIsShow}
                        setIsShow3={setIsShow3}
                        onFormChange={onFormChange}
                        handleButtonClick={handleButtonClick}
                        setShowDropdown={setShowDropdown}
                        setInnerAdd={setInnerAdd}
                        setOuterAdd={setOuterAdd}
                        handleAddButtonClick={handleAddButtonClick}
                        outerAdd={outerAdd}
                        setShowClose={setShowClose}
                        setShowOpen={setShowOpen}
                        setShowLowPrice={setShowLowPrice}
                        setShowHighPrice={setShowHighPrice}
                        setShowUlcer={setShowUlcer}
                        setShowDEMA={setShowDEMA}
                        setShowTEMA={setShowTEMA}
                        setShowTMA={setShowTMA}
                        setShowVWMA={setShowVWMA}
                        setShowEMA={setShowEMA}
                        setShowSMA={setShowSMA}
                        setShowNumber={setShowNumber}
                        setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                        setShowWilliams={setShowWilliams}
                        setShowMad={setShowMad}
                        setShowObv={setShowObv}
                        setShowAroonDown={setShowAroonDown}
                        setShowAroonUp={setShowAroonUp}
                        setShowOscilator={setShowOscilator}
                        setShowVWAP={setShowVWAP}
                        setShowpsar={setShowpsar}
                        setShowVortexI={setShowVortexI}
                        setShowVolume={setShowVolume}
                        setShowDonchian={setShowDonchian}
                        setShowStochastic={setShowStochastic}
                        setShowChaikin={setShowChaikin}
                        setShowMacdSignal={setShowMacdSignal}
                        setShowCCI={setShowCCI}
                        setShowRoc={setShowRoc}
                        setShowRSI={setShowRSI}
                        setShowRSIMA={setShowRSIMA}
                        setShowMacd={setShowMacd}
                        setShowBB={setShowBB}
                        setShowTR={setShowTR}
                        setShowATR={setShowATR}
                        setShowNATR={setShowNATR}
                        setShowSuperT={setShowSuperT}
                        setShowMfiMa={setShowMfiMa}
                        setShowKST={setShowKST}
                        allStocks={allStockStore}
                        setShowIchimoku={setShowIchimoku}
                        setShowCandleModal={setShowCandleModal}
                        getIndex={getIndex}
                        setShowWMA={setShowWMA}
                        setShowMOM={setShowMOM}
                        setAddInstead={setAddInstead}
                        showInstead={showInstead}
                        handleEdit={handleEdit}
                        handleAdd={handleAdd}
                        setPeriodMaxModal={setPeriodMaxModal}
                        setPeriodMinModal={setPeriodMinModal}
                        setisStdDeviation={setisStdDeviation}
                        setShowUltimateOscillator={setShowUltimateOscillator}
                        setShowNthCandle={setShowNthCandle}
                      />
                      &nbsp; &nbsp;
                      {formData.isIncludeMathFunction && (
                        <div
                          onClick={() => {
                            setShowDropdown(true);
                            setOuterAdd(true);
                          }}
                          className="pluseIcon"
                        >
                          <i className="fa-solid fa-plus"></i>
                        </div>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <div className="row">
                <div
                  // className="col-lg-12 d-flex flex-row-reverse"
                  className="col-lg-12 d-flex flex-row-reverse"
                  style={{ marginTop: "10px" }}
                >
                  <span
                    className="text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => RemoveFormula()}
                  >
                    Remove
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="box my-3">
            <div className="text-center">
              <div className="filecircle mx-auto">
                <i className="fa-regular fa-file"></i>
              </div>
              <h5 className="fw-bolder text-dark pt-3 pb-1">Add a condition</h5>
              <p className="para">
                Add an entity condition by searching <br></br>for indicators and
                comparators
              </p>
            </div>
          </div>
        )}
        <div className="pt-3">
          <h6 className="fw-bolder">Entry Time</h6>
        </div>
        <div id="entry-time-card">
          <Card>
            <Card.Body className="entry-time-card-body">
              <div
                className="d-flex justify-content-between my-3"
                style={{ width: "100%" }}
              >
                <div className="d-flex align-items-center">
                  <p className="mb-0">Start time </p>&nbsp;&nbsp;
                  <input
                    style={{ minWidth: "150px" }}
                    type="time"
                    value={formData.start_time || ""}
                    onChange={(e) => handleStartTime(e.target.value)}
                    className="w-25 form-control timer-form-control-sm"
                  />
                </div>
                <div className="d-flex align-items-center">
                  <p className="mb-0 ms-2">End time</p> &nbsp;&nbsp;
                  <input
                    style={{ minWidth: "150px" }}
                    type="time"
                    value={formData.end_time || ""}
                    onChange={(e) => handleEndTime(e.target.value)}
                    className="w-25 form-control timer-form-control-sm"
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <SMAModal
        showSMA={showSMA}
        setIsShow={setIsShow}
        handleSMAClickedPage={handleSMAClickedPage}
        type={formData.selectedOption1}
        typeName={formData.selectedOption1}
        mainData={formData}
      />
      <SMAModal2
        showSMA={showSMA2}
        setIsShow={setIsShow2}
        handleSMAClickedPage={handleSMAClickedPage2}
        type={formData.selectedOption1}
        typeName={formData.selectedOption1}
        mainData={formData}
      />
      <CompartorModal
        showSMA={showSMA3}
        setIsShow={setIsShow3}
        type={formData.ctype}
        typeName={formData.selectedOption2}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Ulcer
        show={showUlcer}
        setIsShow={setShowUlcer}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <TriMA
        show={showTMA}
        setIsShow={setShowTMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Vwma
        show={showVWMA}
        setIsShow={setShowVWMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <DEMA
        show={showDEMA}
        setIsShow={setShowDEMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <TEMA
        show={showTEMA}
        setIsShow={setShowTEMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Open
        show={showOpen}
        setIsShow={setShowOpen}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Low
        show={showLowPrice}
        setIsShow={setShowLowPrice}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <HighPrice
        show={showHighPrice}
        setIsShow={setShowHighPrice}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Close
        show={showClose}
        setIsShow={setShowClose}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <EMA
        show={showEMA}
        setIsShow={setShowEMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        handleRemoveButtonClick={handleRemoveButtonClick}
        index={itemIndex}
        instance={instance}
      />
      <SMA
        show={showSMA1}
        setIsShow={setShowSMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <WMA
        show={showWMA}
        setIsShow={setShowWMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Number
        show={showNumber}
        setIsShow={setShowNumber}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <MoneyFlowIndex
        show={showMoneyFlowIndex}
        setIsShow={setShowMoneyFlowIndex}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <WilliamsR
        show={showWilliams}
        setIsShow={setShowWilliams}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MovingAverageDeviation
        show={showMad}
        setIsShow={setShowMad}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MACD
        show={showMacd}
        setIsShow={setShowMacd}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <OBV
        show={showObv}
        setIsShow={setShowObv}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <AroonDown
        show={showAroonDown}
        setIsShow={setShowAroonDown}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <AroonUp
        show={showAroonUp}
        setIsShow={setShowAroonUp}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <AwesomeOscillator
        show={showOscilator}
        setIsShow={setShowOscilator}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <VWAP
        show={showVWAP}
        setIsShow={setShowVWAP}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <ParabolicSAR
        show={showpsar}
        setIsShow={setShowpsar}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <VortexIndicator
        show={showVortexI}
        setIsShow={setShowVortexI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Volume
        show={showVolume}
        setIsShow={setShowVolume}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <DonchianChannel
        show={showDonchian}
        setIsShow={setShowDonchian}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Stochastic
        show={showStochastic}
        setIsShow={setShowStochastic}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <ChaikinMoneyFlow
        show={showChaikin}
        setIsShow={setShowChaikin}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <CCI
        show={showCCI}
        setIsShow={setShowCCI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PriceRateofChange
        show={showRoc}
        setIsShow={setShowRoc}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <MACDsignal
        show={showMacdSignal}
        setIsShow={setShowMacdSignal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <RSI
        show={showRSI}
        setIsShow={setShowRSI}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <RSIMovingAverage
        show={showRSIMA}
        setIsShow={setShowRSIMA}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <RemoveFormulaModal
        show={showRemoveModal}
        hide={setShowRemoveModal}
        handleConfirm={handleConfirmRemove}
        index={itemIndex}
        instance={instance}
      />
      <BollingerB
        show={showBB}
        setIsShow={setShowBB}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <TR
        show={showTR}
        setIsShow={setShowTR}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <ATR
        show={showATR}
        setIsShow={setShowATR}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <NATR
        show={showNATR}
        setIsShow={setShowNATR}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Supertrend
        show={showSuperT}
        setIsShow={setShowSuperT}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <MfiMovingAverage
        show={showMfiMA}
        setIsShow={setShowMfiMa}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <KnowSureThing
        show={showKST}
        setIsShow={setShowKST}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <Ichimoku
        show={showIchimoku}
        setIsShow={setShowIchimoku}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <CandleInterval
        show={showCandleModal}
        setIsShow={setShowCandleModal}
        handleModalData={handleCandleModal}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <MOM
        show={showMOM}
        setIsShow={setShowMOM}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />

      <ChandeMomentumOscillator
        show={showChande}
        setIsShow={setShowChande}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PeriodMaxModal
        setIsShow={setPeriodMaxModal}
        showPeriodMaxModal={showPeriodMaxModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PeriodMinModal
        setIsShow={setPeriodMinModal}
        showPeriodMinModal={showPeriodMinModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <VwapStandarddeviation
        show={showStdDeviation}
        setIsShow={setisStdDeviation}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <UltimateOscillator
        show={showUltimateOscillator}
        setIsShow={setShowUltimateOscillator}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <Nthcandle
        show={showNthCandle}
        setIsShow={setShowNthCandle}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
    </>
  );
};
export default StrategyEntryComponent;
