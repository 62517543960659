import React, { useEffect, useState } from "react";
import { useMenuContextScanner } from "./ScannerCreateComponent";
import {
  getStocksUnderIndices,
  getStrategyListapi,
} from "../../api/scannerapi";

const container = {
  position: "fixed",
  top: 0,
  right: 0,
  width: "30vw",
  height: "100vh",
  background: "white",
  zIndex: 9999,
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  padding: "10px",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
};

const SideBarScanOn = ({ setShowList, handelScanItem, stocks }) => {
  const {
    allStocks,
    setAllStocks,
    selectedItems,
    setSelectedItems,
    storeAllStocks,
  } = useMenuContextScanner();
  console.log("scannerCheck", allStocks);

  const [searchTerm, setSearchTerm] = useState("");
  const [item, setitem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [stocksPerPage] = useState(30);

  // const handleAddToSelected = (id, itemData) => {
  //   if (!selectedItems?.some((item) => item.id === id)) {
  //     // If it doesn't exist, add it to the array
  //     // setSelectedItems(id);
  //     setSelectedItems([...selectedItems, itemData]);
  //   }
  // };
  const handleAddToSelected = (id, itemData) => {
    if (!selectedItems.some((item) => item.id === id)) {
      // If it doesn't exist, clear the selection and then add the new item
      setSelectedItems([itemData]);
    }
  };

  const handleDoneClick = () => {
    console.log("---itemchecksss", selectedItems);
    handelScanItem(selectedItems);
    setShowList(false);
  };
  const handleRemoveFromSelected = (index) => {
    const updatedSelectedItems = [...selectedItems];
    console.log("updatedSelectedItems", updatedSelectedItems);
    updatedSelectedItems.splice(index, 1);
    setSelectedItems(updatedSelectedItems);
  };
  const handleSearchChange = async (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    console.log("valuuueSearch", storeAllStocks);
    // const stockData = await getStrategyListapi();
    const filteredStocks = storeAllStocks?.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    console.log("valuuueSearch", filteredStocks);

    // Calculate pagination indexes
    const indexOfLastStock = currentPage * stocksPerPage;
    const indexOfFirstStock = indexOfLastStock - stocksPerPage;
    const slicedData = filteredStocks?.slice(
      indexOfFirstStock,
      indexOfLastStock
    );
    setAllStocks(slicedData);
  };

  useEffect(() => {}, [searchTerm]);
  return (
    <div style={container}>
      {/* Search Box */}
      <div
        style={{
          marginBottom: "10px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            width: "60%",
          }}
        >
          <i
            style={{
              position: "absolute",
              marginLeft: "10px",
              width: "20px",
              height: "20px",
            }}
            className="fa-solid fa-magnifying-glass"
          ></i>
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              borderRadius: "12px",
              border: "1px solid lightgray",
              padding: "8px",
              paddingLeft: "40px", // Adjust to accommodate the icon width and spacing
              width: "100%",
            }}
          />
        </div>
        <button
          className="btn-color"
          style={{
            padding: "8px",
            background: "#0312f5",
            color: "white",
            borderRadius: "8px",
            border: "none",
            width: "30%",
          }}
          onClick={handleDoneClick}
        >
          Done
        </button>
      </div>

      <div
        style={{
          justifyContent: "space-around",
          marginBottom: "10px",
          overflowX: "auto",
          width: "100%",
          display: "flex",
          flexWrap: "wrap", // Ensure items wrap to the next line when there's not enough space
          justifyContent: "flex-start",
        }}
      >
        {selectedItems?.map((selectedItem, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "4px",
              background: "lightgray",
              borderRadius: "18px",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "12px",
              fontSize: "12px",
            }}
            key={index}
          >
            <i
              className="fa-solid fa-circle-check"
              style={{ color: "#03C03C", marginLeft: "5px" }}
            ></i>
            <div style={{ marginLeft: "6px" }}>{selectedItem?.name}</div>

            <i
              style={{
                marginLeft: "10px",
                width: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleRemoveFromSelected(index)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
        ))}
      </div>

      {/* </div> */}

      {/* Wrapper for Stock List */}
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          width: "100%",
          marginBottom: "20px", // Adjust as needed
        }}
      >
        {stocks?.map((itemData, index) => (
          <div
            className="sidebar-scanner-item"
            key={index}
            onClick={() => handleAddToSelected(itemData.id, itemData)}
          >
            {/* Stock Details */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "40%",
              }}
            >
              <div style={{ width: "100%", marginLeft: "14px" }}>
                <div style={{ fontSize: "16px" }}> {itemData?.name}</div>
              </div>
            </div>
            {selectedItems?.some(
              (selectedItem) => selectedItem?.name === itemData?.name
            ) ? (
              <i
                className="fa fa-check"
                style={{ fontSize: "20px", cursor: "pointer", color: "green" }}
              ></i>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideBarScanOn;
