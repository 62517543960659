// Account.js
import React, { useMemo } from "react";
import ProfileCard from "./ProfileCard";
import { Link, useSearchParams } from "react-router-dom";
import Profile from "./Profile";
import TradingAccount from "../components/TradingAccount";
import Billing from "../components/account/Billing";
import { Card } from "react-bootstrap";
import Preferences from "../components/account/Preferences";
import Help from "../components/account/Help";
import SubscribePlan from "../Pages/SubscribePlan";
import Feedback from "../components/account/Feedback";

const Account = () => {
  const [searchParams] = useSearchParams();

  const memorizeComponent = useMemo(() => (
    <ProfileCard tab={searchParams.get("tab")} />
  ), [searchParams]);
  

  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer" id="profile-account">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              {memorizeComponent}
            </div>

            <div className="col-lg-8 col-md-8 col-12">
            <Card className="note">
                <Card.Body className="p-2">
                    <span>
                    &nbsp; <i className="fa fa-info"></i> &nbsp; 
                    contact support: <span  className="text-primary text-decoration-none fw-bold">support@dalroti.tech</span> for any queries, Contact no:  <span  className="text-primary text-decoration-none fw-bold">08069426942</span> 
                    </span>
                </Card.Body>
            </Card>
              {searchParams.get("tab") === "profile" ? <Profile /> : null}
              {searchParams.get("tab") === "trading" ? <TradingAccount /> : null}
              {searchParams.get("tab") === "billing" ? <Billing /> : null}
              {searchParams.get("tab") === "preferences" ? <Preferences /> : null}
              {searchParams.get("tab") === "help" ? <Help /> : null}
              {searchParams.get("tab") === "feedback" ? <Feedback /> : null}
              {searchParams.get("tab") === "pricing" ? <></> : null}
              {searchParams.get("tab") === "help" ? <Help/>:null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Account;
