import React, { useState, useEffect } from "react";
import { Card, Nav } from "react-bootstrap";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { clearSessionData } from "../Session";
import SubmitFeedbackModal from "../components/account/SubmitFeedbackModal";

const ProfileCard = (props) => {
  const { tab } = props;

  const [searchParams , setSearchParams] = useSearchParams();
  const [showSubmitModal , setShowSubmitModal] = useState(false);
  // const tab = searchParams.get("tab");
  const [activePage, setActivePage] = useState(tab);
  const navigate = useNavigate();
  const handleClickNavigate = (page) => {
    navigate(page);
    setActivePage(page);
  };
  console.log("tabdata" , tab);
  

  useEffect(() => {
    setActivePage(window.location.pathname);
    console.log("tradingname", window.location.pathname);
  }, []);

  useEffect(() => {
    if(tab !== ""){
      setActivePage(tab);
    
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scrolling
        });
    }
  },[tab,activePage]);

  const handleLogout = () => {
    const logout = clearSessionData();
    localStorage.removeItem('IsCreated');
    navigate("/login");
  };

  console.log("activePage",activePage);

  return (
    <>
      <Card className="profile-card">
        <Card.Body className="profile-card-body">
          <br />
          <Card.Link
            className={`pointer-cursor ${activePage === "profile" ? "active" : ""}`}
            onClick={() => handleClickNavigate("/account?tab=profile")}
          >
            <div className="d-flex">
              <i
                className="fa fa-user align-self-center"
                aria-hidden="true"
              ></i>
              &nbsp;&nbsp;&nbsp;
              <div>
                <h6>
                  <span className="text-body fw-bold"> My Profile</span>
                  <br />
                  <span className="text-black-50"> Profile Details </span>
                </h6>
              </div>
            </div>
          </Card.Link>
          <Card.Link
          
          className={`pointer-cursor ${activePage === "billing" ? "active" : ""}`}
            onClick={() => handleClickNavigate("/account?tab=billing")}
          >
            <div className="d-flex">
              <i
                className="fa fa-file-text-o align-self-center"
                aria-hidden="true"
              ></i>
              &nbsp;&nbsp;&nbsp;
              <div>
                <h6>
                  <span className="text-body fw-bold"> Billing</span>
                  <br />
                  <span className="text-black-50">
                    {" "}
                    Current Plan, Invoices{" "}
                  </span>
                </h6>
              </div>
            </div>
          </Card.Link>
          <Card.Link
             className={`pointer-cursor ${activePage === "pricing" ? "active" : ""}`}
             onClick={() => handleClickNavigate("/account?tab=pricing")}
          >
            <div className="d-flex">
              <i
                className="fa fa-rupee align-self-center"
                aria-hidden="true"
              ></i>
              &nbsp;&nbsp;&nbsp;
              <div>
                <h6>
                  <span className="text-body fw-bold"> Pricing</span>
                  <br />
                  <span className="text-black-50">
                    {" "}
                    See all different price available{" "}
                  </span>
                </h6>
              </div>
            </div>
          </Card.Link>
          <Card.Link
          className={`pointer-cursor ${activePage === "trading" ? "active" : ""}`}

            // className={activePage === "trading" ? "active" : ""}
            onClick={() => handleClickNavigate("/account?tab=trading")}
          >
            {/* <Link to="/trading" onClick={props.redirect}> */}
            <div className="d-flex">
              <i
                className="fa fa-user align-self-center "
                aria-hidden="true"
              ></i>
              &nbsp;&nbsp;&nbsp;
              <div>
                <h6>
                  <span className="text-body fw-bold">
                    {" "}
                    My Trading Accounts
                  </span>
                  <br />
                  <span className="text-black-50"> Add a broker account </span>
                </h6>
              </div>
            </div>
            {/* </Link> */}
          </Card.Link>
          {/* <Card.Link   className={`pointer-cursor ${activePage === "referal" ? "active" : ""}`}
             onClick={() => handleClickNavigate("/account?tab=referal")}>
            <div className="d-flex">
              <i
                className="fa fa-user align-self-center"
                aria-hidden="true"
              ></i>
              &nbsp;&nbsp;&nbsp;
              <div>
                <h6>
                  <span className="text-body fw-bold"> Referal & Rewards</span>
                  <br />
                  <span className="text-black-50">
                    {" "}
                    See all different price available{" "}
                  </span>
                </h6>
              </div>
            </div>
          </Card.Link> */}
          {/* <Card.Link
            className={`pointer-cursor ${activePage === "preferences" ? "active" : ""}`}
            onClick={() => handleClickNavigate("/account?tab=preferences")}
          >
            <div className="d-flex">
              <i
                className="fa fa-sliders align-self-center"
                aria-hidden="true"
              ></i>
              &nbsp;&nbsp;&nbsp;
              <div>
                <h6>
                  <span className="text-body fw-bold"> Preferences</span>
                  <br />
                  <span className="text-black-50">
                    {" "}
                    See all different price available
                  </span>
                </h6>
              </div>
            </div>
          </Card.Link> */}
          <Card.Link
            className={`pointer-cursor ${activePage === "help" ? "active" : ""}`}
            onClick={() => handleClickNavigate("/account?tab=help")}
          >
            <div className="d-flex">
              <i
                className="fa fa-info align-self-center"
                aria-hidden="true"
              ></i>
              &nbsp;&nbsp;&nbsp;
              <div>
                <h6>
                  <span className="text-body fw-bold"> Help</span>
                  <br />
                  <span className="text-black-50"> Read manual </span>
                </h6>
              </div>
            </div>
          </Card.Link>
          <Card.Link 
          className={`pointer-cursor ${activePage === "feedback" ? "active" : ""}`}
          onClick={() => handleClickNavigate("/account?tab=feedback")}
          // onClick={() => setShowSubmitModal(true)}
          >
            <div className="d-flex">
              <i
                className="fa fa-file-text-o align-self-center"
                aria-hidden="true"
              ></i>
              &nbsp;&nbsp;&nbsp;
              <div>
                <h6>
                  <span className="text-body fw-bold"> Feedback</span>
                  <br />
                  <span className="text-black-50"> Submit suggestions </span>
                </h6>
              </div>
            </div>
          </Card.Link>
          <Card.Link onClick={handleLogout} className="pointer-cursor">
            <div className="d-flex">
              <i
                className="fa fa-power-off align-self-center"
                aria-hidden="true"
              ></i>
              &nbsp;&nbsp;&nbsp;
              <div>
                <h6>
                  <span className="text-body fw-bold"> Logout</span>
                  <br />
                  <span className="text-black-50">
                    {" "}
                    Logout of current account{" "}
                  </span>
                </h6>
              </div>
            </div>
          </Card.Link>
        </Card.Body>
      </Card>
      <SubmitFeedbackModal  showSubmitModal={showSubmitModal} setShowSubmitModal={setShowSubmitModal} />
    </>
  );
};

export default ProfileCard;
