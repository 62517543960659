import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const MovingAverageChart = ({ maxPercentage }) => {
  // console.log("maxPercentage", maxPercentage);
  const [options, setOptions] = useState({
    chart: {
      id: "moving-average-chart",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "50%",
        barWidth: "50%",
        columnWidth: "15%",
        distributed: true,
        horizontal: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      colors: ["transparent"],
      width: 8,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      labels: {
        show: true,
        style: {
          colors: "#ffffff",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    annotations: {
      points: [
        {
          x: "0%",
          y: 400,
          marker: {
            size: 8,
            fillColor: "#fff",
            strokeColor: "#2698FF",
            radius: 4,
          },
          label: {
            borderColor: "#FF4560",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#FF4560",
            },
          },
        },
      ],
    },
    legend: {
      show: false,
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Moving Average",
      data: [
        { x: "-100%", y: 1250, fillColor: "#cf3d43" },
        { x: "-90%", y: 1200, fillColor: "#d14449" },
        { x: "-80%", y: 1100, fillColor: "#d34a4f" },
        { x: "-70%", y: 1000, fillColor: "#d45156" },
        { x: "-60%", y: 900, fillColor: "#d6575c" },
        { x: "-50%", y: 800, fillColor: "#d75e63" },
        { x: "-40%", y: 700, fillColor: "#d96469" },
        { x: "-30%", y: 600, fillColor: "#AB6B8B" },
        { x: "-20%", y: 500, fillColor: "#018DE7" },
        { x: "-10%", y: 400, fillColor: "#018DE7" },
        { x: "0%", y: 300, fillColor: "#018DE7" },
        { x: "10%", y: 400, fillColor: "#018DE7" },
        { x: "20%", y: 500, fillColor: "#88ADAB" },
        { x: "30%", y: 600, fillColor: "#80A9A4" },
        { x: "40%", y: 700, fillColor: "#26C62B" },
        { x: "50%", y: 800, fillColor: "#26C62B" },
        { x: "60%", y: 900, fillColor: "#26C62B" },
        { x: "70%", y: 1000, fillColor: "#26C62B" },
        { x: "80%", y: 1100, fillColor: "#26C62B" },
        { x: "90%", y: 1200, fillColor: "#26C62B" },
        { x: "100%", y: 1250, fillColor: "#26C62B" },
      ],
    },
  ]);

  useEffect(() => {
    if (!maxPercentage) return;

    let setXAnnotation;

    if (maxPercentage.type === "percentBearish") {
      setXAnnotation = -parseFloat(maxPercentage.val);
    } else if (maxPercentage.type === "percentBullish") {
      setXAnnotation = parseFloat(maxPercentage.val);
    } else {
      setXAnnotation = 100 - parseFloat(maxPercentage.val);
      if (maxPercentage.count == "percentBullish") {
        setXAnnotation = setXAnnotation;
      } else if (maxPercentage.count == "percentBearish") {
        setXAnnotation = -setXAnnotation;
      } else {
        setXAnnotation = 100 - parseFloat(maxPercentage.val) - 10;
      }
    }

    const closestDataPoint = series[0].data.reduce((prev, curr) =>
      Math.abs(curr.x.replace("%", "") - setXAnnotation) <
      Math.abs(prev.x.replace("%", "") - setXAnnotation)
        ? curr
        : prev
    );
    // console.log(closestDataPoint);
    setOptions({
      ...options,
      annotations: {
        points: [
          {
            x: `${closestDataPoint.x}`,
            y: 400,
            marker: {
              size: 8,
              fillColor: "#fff",
              strokeColor: "#2698FF",
              radius: 4,
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560",
              },
            },
          },
        ],
      },
    });
  }, [maxPercentage]);

  return (
    <Chart
      options={options}
      series={[
        {
          name: series[0].name,
          data: series[0].data.map((item) => ({
            x: item.x,
            y: item.y,
            fillColor: item.fillColor,
          })),
        },
      ]}
      type="bar"
      height="100%"
    />
  );
};

export default MovingAverageChart;
