import React, { useState } from "react";
import { Button, Card, Form, ListGroup } from "react-bootstrap";

const SideBarSingleStrategy = ({ strategyDetails }) => {
  const [showChart, setShowChart] = useState(false);
  const [showQuantity, setShowQuantity] = useState(false);
  const [showEntry, setShowEntry] = useState(false);
  const [showBacktest, setBacktest] = useState(false);
  const handleClickHoldingType = (value) => {
    // onFormChange({
    //   ...formData,
    //   holding_type: value,
    // });
  };

  const handleCharShow = () => {
    setShowEntry(false);
    setBacktest(false);
    setShowQuantity(false);
    setShowChart(!showChart);
  };
  const handleCharQuantity = () => {
    setShowChart(false);
    setBacktest(false);
    setShowEntry(false);
    setShowQuantity(!showQuantity);
  };
  const handleCharEntry = () => {
    setShowChart(false);
    setShowQuantity(false);
    setShowEntry(!showEntry);
    setBacktest(false);
  };
  const handelBacktest = () => {
    setShowChart(false);
    setShowQuantity(false);
    setShowEntry(false);
    setBacktest(!showBacktest);
  };
  const handleSelectChange = (event) => {
    // onFormChange((prevData) => ({
    //   ...prevData,
    //   position_id: event.target.value,
    // }));
  };

  return (
    <>
      <div className="row p-2" id="sidebar-singel-strategy">
        <div className="col-lg-12">
          <h4>Position I would take</h4>
          <div>
            <div id="strategy-chart-type-btn" className="pt-2">
              <ListGroup horizontal style={{ height: "60%" }} className="p-0">
                <ListGroup.Item
                  action
                  variant="primary"
                  className="active"
                  // onClick={()=>{handleChangeCharType(1)}}
                >
                  <i className="fa fa-sliders"></i>
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  disabled
                  variant="primary"
                  // onClick={()=>{handleChangeCharType(2)}}
                >
                  <i className="fa fa-sliders"></i>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <br />
            <div className="d-flex justify-content-between">
              <div>
                <h5>Chart</h5>
              </div>
              <div>
                {showChart ? (
                  <i
                    className="fa fa-angle-up pointer-cursor"
                    onClick={handleCharShow}
                  ></i>
                ) : (
                  <i
                    className="fa fa-angle-down pointer-cursor"
                    onClick={handleCharShow}
                  ></i>
                )}
              </div>
            </div>
            {showChart && (
              <Card style={{ border: "none" }}>
                <Card.Body>
                  <div className="row">
                    <div className="col-12">
                      <h6>Chart Type</h6>
                      <div id="strategy-chart-type-btn" className="pt-2">
                        <ListGroup
                          horizontal
                          style={{ height: "60%" }}
                          className="p-0"
                        >
                          <ListGroup.Item
                            action
                            variant="primary"
                            className="active"
                            // onClick={()=>{handleChangeCharType(1)}}
                          >
                            <i className="fa fa-sliders"></i>
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            disabled
                            variant="primary"
                            // onClick={()=>{handleChangeCharType(2)}}
                          >
                            <i className="fa fa-sliders"></i>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </div>
                    <div className="col-12 pt-3">
                      <h6>Interval</h6>
                      <ListGroup>
                        <ListGroup.Item className="custom-list-item active">
                          {strategyDetails.candle_interval_id === "1m"
                            ? "1 Minutes"
                            : "1m"}
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-list-item">
                          {strategyDetails.candle_interval_id === "3m"
                            ? "3 Minutes"
                            : "3m"}
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-list-item">
                          {strategyDetails.candle_interval_id === "5m"
                            ? "5 Minutes"
                            : "5m"}
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-list-item">
                          {strategyDetails.candle_interval_id === "10m"
                            ? "10 Minutes"
                            : "10m"}
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-list-item">
                          {strategyDetails.candle_interval_id === "15m"
                            ? "15 Minutes"
                            : "15m"}
                          15m
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-list-item">
                          {strategyDetails.candle_interval_id === "1h"
                            ? "1 Hours"
                            : "1h"}
                          1H
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-list-item">
                          {strategyDetails.candle_interval_id === "1d"
                            ? "1 Day"
                            : "1d"}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                    <div className="col-12 pt-3">
                      <h6>Holding Type</h6>
                      <ListGroup horizontal>
                        <ListGroup.Item
                          action
                          variant="primary"
                          className={`w-50 ${
                            strategyDetails.holding_type === "MIS"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            handleClickHoldingType("MIS");
                          }}
                        >
                          MIS
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          className={`w-50 ${
                            strategyDetails.holding_type === "CNC/NRML"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            handleClickHoldingType("CNC/NRML");
                          }}
                          variant="primary"
                        >
                          CNC/NRML
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            )}
            <div className="d-flex justify-content-between pt-2">
              <div>
                <h5>Quantity</h5>
              </div>
              <div>
                {showQuantity ? (
                  <i
                    className="fa fa-angle-up pointer-cursor"
                    onClick={handleCharQuantity}
                  ></i>
                ) : (
                  <i
                    className="fa fa-angle-down pointer-cursor"
                    onClick={handleCharQuantity}
                  ></i>
                )}
              </div>
            </div>
            {showQuantity && (
              <Card style={{ border: "none" }}>
                <Card.Body>
                  <div className="col-12 pt-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="quantity"
                        value={strategyDetails.quantity}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Max Allocation</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Amount per trade (Max Alloctaion)"
                        value={strategyDetails.quantity}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Max Quantity ( in lots)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Amount per trade (Max Alloctaion)"
                        value={strategyDetails.quantity}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Position sizing type</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleSelectChange}
                        value={strategyDetails.position_id}
                      >
                        <option>-</option>
                        <option value="1">Capital Bases</option>
                        <option value="2">Risk Based</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Card.Body>
              </Card>
            )}

            <div className="d-flex justify-content-between pt-2">
              <div>
                <h5>Entry</h5>
              </div>
              <div>
                {showEntry ? (
                  <i
                    className="fa fa-angle-up pointer-cursor"
                    onClick={handleCharEntry}
                  ></i>
                ) : (
                  <i
                    className="fa fa-angle-down pointer-cursor"
                    onClick={handleCharEntry}
                  ></i>
                )}
              </div>
            </div>
            {showEntry && (
              <Card style={{ border: "none" }}>
                <Card.Body>
                  <div className="col-12 pt-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Entry conditions</Form.Label>
                      <span>
                        BUY 1 share when Min( , ) at min candle interval using
                        candlestick chart. Enter trade between 09:00 to 23:59
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Start Time</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Amount per trade (Max Alloctaion)"
                        value={strategyDetails.start_time}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Stop Time</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Amount per trade (Max Alloctaion)"
                        value={strategyDetails.end_time}
                      />
                    </Form.Group>
                  </div>
                </Card.Body>
              </Card>
            )}

            <div className="d-flex justify-content-between pt-2">
              <div>
                <h5>Backtest Parameter</h5>
              </div>
              <div>
                {showBacktest ? (
                  <i
                    className="fa fa-angle-up pointer-cursor"
                    onClick={handelBacktest}
                  ></i>
                ) : (
                  <i
                    className="fa fa-angle-down pointer-cursor"
                    onClick={handelBacktest}
                  ></i>
                )}
              </div>
            </div>
            {showBacktest && (
              <Card style={{ border: "none" }}>
                <Card.Body>
                  <div className="col-12 pt-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Initial Capital</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="quantity"
                        value={strategyDetails.quantity}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Backtesting start date</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Amount per trade (Max Alloctaion)"
                        value={strategyDetails.quantity}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Backtesting stop date</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Amount per trade (Max Alloctaion)"
                        value={strategyDetails.quantity}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Daily strategy cycle</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleSelectChange}
                        value={strategyDetails.position_id}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option>-</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Card.Body>
              </Card>
            )}

            <div className="col-12 pt-3">
              <Button className="btn-color text-center w-100" disabled>
                Run Backtest
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarSingleStrategy;
