import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {LiveScannerApi} from "../api/scannerapi";
import { getUser } from "../Session";
import {getAllRunningDeployedStrategiesAPI} from "../api/strategyapi";

const DeployedCard = () => {
  const navigate = useNavigate();
  const [cnt,setCnt]=useState();
  const [deplypaperstrategy,setDeploypaperstrategy]=useState();
useEffect(()=>{
  getlivescanner();
  getAllStrategies();
},[])

const getlivescanner=async()=>{
  const userId=getUser();
  const result = await LiveScannerApi({ user_id: userId.id });
  setCnt(result?.length);
}
const getAllStrategies = async () => {
  const userId = getUser();
  const getARDSResult = await getAllRunningDeployedStrategiesAPI({
    user_id: userId?.id,
    trading_type: 2,
  });
  if(getARDSResult)
    setDeploypaperstrategy(getARDSResult?.length)
  // if(getARDSResult.length>0)
      
}
  return (
    <Card>
      <Card.Body>
        <div className="row deploye-data">
          <h5 className="fw-bold py-3 fs-6">
            Deployed <Link to="/deployed?tab=live" 
            className="view-all-btn text-decoration-none"> View all</Link>
          </h5>
          <div className="sub-text py-3">
            <div className="row">
              <div className="col-6 pointer-cursor" onClick={() => navigate("/deployed?tab=live")}>
                <h6 className="fw-bold" >
                  {" "}
                  <i className="fa fa-wifi"></i>&nbsp; Live Trade{" "}
                </h6>
              </div>
              <div className="col-6">
                <p className="text-muted font-italic">Not Deployed</p>
              </div>
            </div>
            {/*  */}
            <div className="col-12">
              <div className="sub-text">
                <div className="row">
                  <div className="col-6 pointer-cursor" onClick={() => navigate("/deployed?tab=paper")}>
                    <h6 className="fw-bold" >
                      {" "}
                      <i className="fa fa-newspaper-o"></i>&nbsp; Paper Trade{" "}
                    </h6>
                  </div>
                  <div className="col-6">
                    <p className="text-muted font-italic">{deplypaperstrategy > 0 ? "Deployed" : "Not Deployed"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="sub-text Scanner-div">
                <div className="row">
                  <div className="col-6 pointer-cursor" onClick={() => navigate("/deployed?tab=scanner")}>
                    <h6 className="fw-bold">
                      {" "}
                      <i className="fa fa-newspaper-o"></i>&ensp; Live Scanner{" "}
                    </h6>
                  </div>
                  <div className="col-6">
                    <p className="text-muted font-italic">{cnt > 0 ? "Deployed" : "Not Deployed"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DeployedCard;
