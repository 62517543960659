import React from "react";
import { Link } from "react-router-dom";

const TermAndCondition = () => {
  return (
    <section id="term-condition">
      <div className="container ">
        <div className="text-center">
          <h1 className="text-primary fw-bold">Terms & Conditions</h1>
        </div>
        <br />
        <div class="contentArea w-100">
          <div class="divTermsConditions">
            <div class="termPolicyHeaderStyle">
              <h4>TERMS OF USE:</h4>
            </div>
            <div class="center">
              <p>
                These terms of use (&quot;Terms of Use&quot;) mandate the terms
                on which users or visitors (the &quot;Users&quot;) accessing the
                platform dalroti.tech and any corresponding websites or mobile
                applications (the &quot;Platform&quot;), owned and operated by
                COALSTONE TECH PRIVATE LIMITED (&quot;DALROTI.TECH&quot;).
              </p>

              <p>
                The Platform may be used by Users to avail Services (defined
                below), through either brokers registered with the Platform
                (&quot;Tied-up brokers&quot;), or brokers not registered with
                the Platform (&quot;Non-tied up brokers&quot;; both Tied-up
                brokers and Non-tied up brokers shall hereinafter collectively
                be referred to as &quot;brokers&quot;, wherever the context so
                requires or permits) and users as defined below in the
                agreement.
              </p>

              <p>
                Users are requested to carefully read the Terms of Use before
                using the Platform or registering on the Platform or accessing
                any material or information through the Platform. Through the
                continued use of the Platform, or accepting these Terms of Use
                at the time of creating an Account, Users accept these Terms of
                Use and agree to be legally bound by the same. Notwithstanding
                the generality of the foregoing, the user accepts that he shall
                be responsible for being updated with the changes in the terms
                and conditions as posted on the platform, if any.
              </p>

              <p>
                Use of and access to the Platform is offered to Users upon the
                condition of acceptance of all the terms, conditions and notices
                contained in these Terms of Use, Risk Disclosure and Privacy
                Policy, along with any amendments made by
                &quot;DALROTI.TECH&quot; at its sole discretion and posted on
                the Platform from time to time and subject to the above,
                &quot;DALROTI.TECH&quot; grants Users a personal, limited,
                non-exclusive, non-sub licensable, revocable, non-transferrable
                license to access and limited privilege to use the Services
                subject to the Terms of this Agreement and it shall not be
                construed that any license rights are being transferred to the
                user other than the ones mentioned above and shall be limited
                only to the services provided under the specific subscription
                plan, if any.
              </p>

              <p>
                This user agreement or terms of use is an electronic record in
                the form of an electronic contract formed under the information
                technology act, 2000 and rules made thereunder and the amended
                provisions pertaining to electronic documents/records in various
                statutes as amended by the information technology act, 2000.
                This user agreement or terms of use do not require any physical,
                electronic or digital signatures. This user agreement is a
                legally binding document between the user and COALSTONE TECH
                PRIVATE LIMITED (both terms defined below). This user agreement
                shall govern the relationship between the user on the
                &quot;COALSTONE TECH PRIVATE LIMITED&quot; platform and mandate
                the terms on which the user avails COALSTONE TECH PRIVATE
                LIMITED services and accesses the platform.
              </p>

              <h6>INTERPRETATION</h6>

              <p>
                In this Agreement, unless the context otherwise requires, the
                following terms shall have the following meanings:
              </p>

              <p>
                The headings are for convenience only and shall not affect its
                interpretation;
              </p>

              <p>
                Where a word or phrase is defined, other parts of speech and
                grammatical forms of that word or phrase shall have
                corresponding meanings;
              </p>

              <p>
                The references to Recitals, Clauses and Annexures shall be
                references to the recitals, clauses and Annexures of this
                agreement;
              </p>

              <p>
                The references to statutes shall be a reference to the statutory
                enactments, rules and regulations (as modified, amended or
                re-enacted as of the appropriate date) in force;
              </p>

              <p>
                Any reference to &quot;writing&quot; includes printing, typing,
                lithography, fax and other means of reproducing words in
                permanent visible form including e-mails, where the e-mail has a
                return acknowledgement from all the addressees but excluding any
                other electronic forms like SMS or similar form of electronic
                message;
              </p>

              <p>
                Words of the masculine gender include the feminine and neutral
                genders and words denoting natural persons include corporations
                and firms and all such words shall be construed interchangeably
                in that manner;
              </p>

              <p>
                Words importing the singular meaning, where the context admits,
                include the plural meaning and vice versa;
              </p>

              <p>
                The terms &quot;include&quot; and &quot;including&quot; shall
                mean, &quot;include without limitation;
              </p>

              <p>
                Words denoting an obligation on a Party to do any act, matter or
                things include an obligation to procure that it be done and
                words placing a Party under a restriction include an obligation
                not to permit or allow infringement of the restriction.
              </p>

              <p>
                Any consent or approval required under this Agreement shall be
                required or obtained before the act or event to which it applies
                is carried out or done and shall be effective only when the
                consent or approval is given.
              </p>

              <p>DEFINITIONS</p>

              <p>
                &quot;AGREEMENT&quot; means this user agreement including all
                Schedules and Annexure attached hereto, and any subsequent
                written modifications, amendments, changes, alterations as maybe
                executed from time to time by &quot;COALSTONE TECH PRIVATE
                LIMITED&quot;.
              </p>

              <p>
                &quot;PERSON&quot; means any individual, sole proprietorship,
                unincorporated association, body corporate, corporation,
                company, partnership, limited liability company, joint venture,
                Governmental Authority, Association of Person, Hindu Undivided
                Family (HUF), Foreign Company, Liaison Office, Branch office,
                trust or any other entity or organization;
              </p>

              <p>
                &quot;USER&quot; means any person as defined above who shall
                access or agrees to access the platform and avail or agrees to
                avail services through the platform using any device capable of
                using and sending electronic communication and includes his
                employees, agents, authorized persons, nominee etc. and also
                includes a visitor on the platform.
              </p>

              <p>
                {" "}
                &quot;PLATFORM&quot; includes the dashboard, website,
                application etc. which enables the user or a third party to
                access the offers, terms and conditions, register himself on the
                platform etc. as a whole to avail any services provided by
                COALSTONE TECH PRIVATE LIMITED.
              </p>

              <p>
                &quot;CONFIDENTIAL INFORMATION&quot; means all information,
                data, know-how, intellectual property whether it is technical in
                nature or not which is disclosed by the Party to the second
                part.
              </p>

              <p>
                &quot;ORDER&quot; means an order placed on the platform by a
                user availing our services.
              </p>

              <p>
                &quot;CONTENT&quot; includes but is not limited to reviews,
                images, photos, audio, videos, location data, product
                information, details, instructions, strategies, terms and
                conditions which is uploaded, transmitted, shared in connection
                with the services provided through the platform.
              </p>

              <p>
                &quot;GOVERNING LAW&quot; refers to act, rules, regulations as
                construed and enforced in accordance to legislation and law in
                India.
              </p>

              <h6>TERMS OF THIS USER AGREEMENT</h6>

              <h6>ELIGIBILITY:</h6>

              <p>
                The Platform, and by extension, the &quot;DALROTI.TECH&quot;
                Services, are not available to individuals who are minors i.e.
                persons under the age of 18 (eighteen) years, person not having
                a demat account with any stock brokers or to any Users suspended
                from trading under SEBI rules and regulations or removed by
                &quot;DALROTI.TECH&quot; from accessing the Platform for any
                reason whatsoever. Users represent that they are of legal age to
                form a binding contract and are not a person barred from
                receiving, using, availing or accessing the Platform, and by
                extension, the &quot;DALROTI.TECH&quot; Services under
                applicable law.
              </p>

              <p>
                Any person who is of sound mind, proper age and eligible to form
                a binding contract shall be eligible to access the platform and
                avail the services provided through the platform.
              </p>

              <p>
                &quot;DALROTI.TECH&quot; reserves the right to refuse access to
                the Platform, at any time to new Users or to terminate or
                suspend access granted to existing Users at its own discretion
                and shall not be bound to provide any reason whatsoever.
              </p>

              <h6>REGISTRATION:</h6>

              <p>
                Every visitor/user who wishes to avail the services provided or
                to be provided by DALROTI.TECH shall register himself by using
                the sign in facility provided on the platform. The user shall
                mandatorily have a demat account and necessarily access the
                platform through his broker account.
              </p>

              <p>
                The user shall mention his credentials such as user id and
                password or One Time Password (OTP) or any specific mobile code
                as received on his email or mobile no and sign up on the
                platform.
              </p>

              <p>
                On signing up on the platform it shall be considered as a deemed
                acceptance to these terms of use (terms and conditions), risk
                disclosure policy, privacy policy and every login after any
                updation in the policies it shall be considered as a deemed
                acceptance to the updated (changes) in the terms and condition.
              </p>

              <p>
                After signing up the user shall select the specific plan which
                he wants to avail and pay the necessary subscription fee as
                defined below and can access the services provided through the
                platform.
              </p>

              <p>
                User's ability to continue using the Platform, DALROTI.TECH is
                subject to continued registration on the Platform. User will be
                required to enter his personal information including name,
                contact details, valid phone number, email id while registering
                on the Platform. The user also accepts to receive any otp as
                maybe send for registration verification purposes and for any
                further operational purposes.
              </p>

              <p>
                Verification of user details provided to the platform is the
                responsibility of the user and in no circumstances DALROTI.TECH
                platform shall be held liable. If the user provides any
                information that is untrue, inaccurate, not current or
                incomplete, or DALROTI.TECH platform has a reasonable ground to
                suspect that such information is untrue, inaccurate, not current
                or incomplete, we reserve the right to suspend or terminate the
                users account and refuse any and all current or future use of
                the Platform (or any portion thereof) at any time.
              </p>

              <p>
                You agree to notify COALSTONE TECH PRIVATE LIMITED by writing to
                info@coalstone.in , immediately upon your becoming aware of any
                unauthorized use of your account or any other breach of security
                involving your account. We will not be liable for any loss that
                you or any other party may incur as a result of someone else's
                use of your password or account, either with or without your
                knowledge.
              </p>

              <p>
                Any unauthorized use or anyone else using your account shall be
                deemed to be used by the registered user itself unless and until
                it has been specifically informed to us on the email id or any
                other source of contact as provided on the platform and the
                registered user shall be liable for any activities carried out
                in his account.
              </p>

              <h6>USE OF SERVICES:</h6>

              <p>
                Unless otherwise specified, &quot;DALROTI.TECH&quot; grants to
                you a non-exclusive and non-transferable limited right and
                license to access the platform. The users of this platform, for
                trading need to have opened a trading and demat account in any
                of the Broker(s) enabled on the platform. The Services provided
                on this platform is for your personal use only, provided that
                you agree with and comply fully with the provisions of this
                Agreement.
              </p>

              <p>
                Certain features of the Site or Services are available only to
                users who register with us via a Broker account and can differ
                between various devices.
              </p>

              <p>
                You acknowledge and understand that we provide tools and
                infrastructure designed to allow you to create, backtest, and
                deploy your strategies and strategic/systematic trading
                strategies live in the market, form your own strategies, back
                test them, deploy and accordingly place your orders for the
                same.
              </p>

              <p>
                In order to enable the necessary alters the user must accept
                cookies and enable pop settings or notifications so that the
                necessary alerts are accessible to the user.
              </p>

              <p>
                We employ the use of cookies. By accessing DALROTI.TECH, you
                agreed to use cookies in agreement with our Privacy Policy.
              </p>

              <p>
                Most interactive websites use cookies to let us retrieve the
                user's details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies and on the
                basis of your acceptance of these cookies certain advertising
                partners may use the same for advertising purpose to which you
                provide an unconditional acceptance.
              </p>

              <p>
                The user completely understands and agrees that these alerts are
                on the basis of strategy sample chosen by him or any new
                strategy made by him using the necessary tools as provided on
                the platform and inputs entered by the user on the platform and
                in no circumstances &quot;DALROTI.TECH&quot; shall be held
                liable for the same.
              </p>

              <p>
                To be precise and transparent, we do not provide any trading
                strategy or trading strategies with the purpose of advising any
                investments, and the sample strategies provided are just for the
                purpose of education of the users with regards to how they can
                make their own strategies and backtest the same and on the basis
                of their inputs place orders.
              </p>

              <p>
                We rather provide the tools to create them and the data with
                which you can test and use them. You can create your own
                strategies or use strategies made available on the Site to
                modify them as per your needs.
              </p>

              <p>
                If you log in directly with your email address some services
                like placing orders will not be enabled and you might not be
                shown prices of certain securities based on our discretion. You
                can add your broker(s) account and subscribe to our platform and
                avail all the services provided under the subscription.
              </p>

              <p>
                Sample strategy feature: Users can check the sample strategies
                provided on the dashboard to understand the working of
                indicators and how to use them.
              </p>

              <p>
                We do not promot any stock or indicator, this is just a sample
                which user on his own accord is responsible if used and which
                results in any profits/losses occurred after deploying sample
                strategies in the market.
              </p>

              <h6>SUBSCRIPTION:</h6>

              <p>
                The user in order to avail any services provided on our platform
                shall be required to pay a non-negotiable, non-refundable
                subscription fee which shall be of Rs. 999/- (per Month) or Rs.
                11988/- (per year).
              </p>

              <p>Month shall be considered as a period of 30 days</p>

              <p>Year shall be considered as a period of 365 days</p>

              <p>6 Months shall be considered as a period of 180 days.</p>

              <p>
                &quot;DALROTI.TECH&quot; charges subscription fees only for the
                usage and to have access to platform and the services available
                on the platform. We do not charge any fees for providing
                strategies or inputs on the platform.
              </p>

              <p>
                Once a user subscribes to a monthly or yearly or 6 monthly plan,
                the plan shall begin from the date of payment and in case of a
                monthly plan end after a period of 30 days, in case of 6 monthly
                plan end after a period of 180 days and in case of a yearly plan
                end after a period of 365 days.
              </p>

              <p>
                For eg - If a user has registered himself on 1st of February
                2023 then the monthly subscription plan shall end on 2nd of
                March and in case of a leap year on 1st of March of that
                respective year.
              </p>

              <p>
                The subscription plans shall get auto-renewed after the expiry
                of its designated period.In case of any communication otherwise
                it shall be deemed to have been renewed and accordingly the user
                shall be charged for the services.
              </p>

              <p>
                If a user wishes to terminate the registration and the services
                he shall atleast 24 hours prior to the auto renewal of his plan,
                communicate his willingness to discontinue the services by using
                the specific option provided on the dashboard of the platform.
              </p>

              <p>
                Once a user terminates or discontinues his services, he cannot
                access the services provided on the platform and shall only be
                eligible to use the services again after renewing his
                subscription.
              </p>

              <p>
                The subscription shall be linked with only one account
                (registered email id and mobile no) along with DP id and
                multiple user id's are not allowed to be registered under a
                single user.
              </p>

              <p>
                For each id the user shall create a separate registration and
                pay the separate subscription fees. The user registration shall
                be non- transferable.
              </p>

              <p>
                &quot;DALROTI.TECH&quot; reserves the rights to change the
                amount of its subscription fees, the services provided under the
                subscription fees along with the period of the subscription fees
                and can add, reduce any of the services at its own discretion
                and the user shall have to pay the necessary fees as per the
                changes made on completion of its existing plan if any.
              </p>

              <p>
                We also reserve the right to upgrade certain services and
                bifurcate the plans into any number of options. Further the
                upgradation of plan during an existing plan shall be allowed on
                payment of the differential amount, however the downgrading of
                plan shall be allowed only after the completion of the existing
                plan and in this case as well the user shall inform atleast 24
                hours before the auto renewal of his existing plan.
              </p>

              <h6>PROHIBITED ACTIVITIES</h6>

              <p> You acknowledge and agree that you will NOT:</p>

              <p>
                Copy, modify, publish, transmit, distribute, transfer or sell,
                create derivative works of, or in any way exploit, any of the
                Content accessible through the Site not submitted or provided by
                you, including by use of any robot, spider, scraper, scripting,
                deep link, bots, viruses, malware etc. or other similar
                automated data gathering or extraction tools, program,
                strategic/systematic or methodology, unless you obtain CoalStone
                Tech Pvt Ltd (dalroti.tech)'s prior written consent; copy,
                reverse engineer, reverse, assemble, otherwise attempt to
                discover the source code, distribute, transmit, display,
                perform, reproduce, publish, license, create derivative works
                from, transfer or sell any information, software, products or
                services obtained through the Site or the Services; access the
                Site or use the Services by any means other than through
                CoalStone Tech Pvt Ltd (dalroti.tech) provided or approved
                interfaces; transmit any Content that is unlawful, harmful,
                threatening, abusive, harassing, tortious, defamatory, vulgar,
                obscene, libelous, or otherwise objectionable or which may
                invade another's right of privacy or publicity;
              </p>

              <p>
                Use of the Site and/or the services for any purpose that is
                unlawful or prohibited by these terms and conditions; use the
                Site or the Services in any manner that could damage, disable,
                overburden or impair CoalStone Tech Pvt Ltd (dalroti.tech)'s
                servers or networks, or interfere with any other user's use and
                enjoyment of the Site and/or the Services; attempt to gain
                unauthorized access to any of the Site, Services, accounts,
                computer systems or networks connected CoalStone Tech Pvt Ltd
                (dalroti.tech) through hacking, password mining, brute force or
                any other means; obtain or attempt to obtain any Content through
                any means not intentionally made available as Shared Content
                through the Site or the Services; or knowingly provide any
                Content that is false or inaccurate or will become false or
                inaccurate at any time.
              </p>

              <p>
                Use of any third-party
                services/software/mechanisms/tool/plugins/code injections on
                DALROTI.TECH website/app or any other services.
              </p>

              <p>Back-testing and data.</p>

              <p>
                Back testing results are hypothetical and are simulated on
                historical data. The performance results have certain inherent
                limitations. Unlike the results shown in an actual performance
                record, these results do not represent actual trading. These
                trades have not actually been executed, these results may have
                under-or over-compensated for the impact especially when we have
                lack of liquidity in the market or news driven events or any
                other conditions. Simulated or hypothetical trading strategies
                in general are also subject to the fact that they are designed
                with the benefit of hindsight.No representation is being made
                that any account will or is likely to achieve profits or losses
                similar to those back-tested.
              </p>

              <p>
                Any strategy deployed shall not be 100 % (One Hundred) accurate
                and may not give the desired result and there is always a
                potential risk involved with the results provided by the
                strategy.
              </p>

              <p>
                Market is driven by various factors and there might be a
                potential chance that the strategy may or may not give the
                desired result.
              </p>

              <p>
                Further the strategy also depends on the inputs provided by the
                user and accordingly shall give the desired result and alerts on
                the basis of these inputs.
              </p>

              <p>
                In addition, hypothetical trading does not involve financial
                risk, and no hypothetical trading record can completely account
                for the impact of financial risk in actual trading. For example,
                the ability to withstand losses or to adhere to a particular
                trading Strategies in spite of trading losses are material
                points which can also adversely affect actual trading results.
                There are numerous other factors related to the markets in
                general or to the implementation of any specific Strategies
                trading which cannot be fully accounted for in the preparation
                of hypothetical performance results and all of which can
                adversely affect actual trading results.
              </p>

              <p>
                Chart data is subjected to minor variations from market time to
                post market times due to standard data adjustments.
              </p>

              <p>
                Data or source of information is also collected from third
                parties and there can be variations which cannot be completely
                accounted for.
              </p>

              <h6>Electronic Communications.</h6>

              <p>
                You acknowledge and understand that (a) we can only give you the
                benefits of accessing the Site and using the Services by
                conducting business through the Internet, and therefore we need
                you to consent to our giving you Communications (defined below)
                electronically, and (b) this Section informs you of your rights
                when receiving Communications from us electronically. For
                contractual purposes, you: (i) consent to receive communications
                from us in an electronic form and (ii) agree that all terms and
                conditions, agreements, notices, documents, disclosures, and
                other communications (&quot;Communications&quot;) that we
                provide to you electronically satisfy any legal requirement that
                such Communications would satisfy if they were in writing. Your
                consent to receive Communications and do business
                electronically, and our agreement to do so, applies to all of
                your interactions and transactions with us. The foregoing does
                not affect your non-waivable rights. You further agree to
                receive all service communications from DALROTI.TECH.
              </p>

              <p>
                The acceptance to terms and conditions electronically shall be
                deemed to be a written acceptance of the terms and conditions
                and shall be a binding contract between the user and .
              </p>

              <h6>INDEMNIFICATION AND LIABILITY:</h6>

              <p>
                You agree to indemnify, defend, and hold the tied up and
                non-tied up Broker(s), the exchange (National Stock Exchange(s),
                MCX), COALSTONE TECH PRIVATE LIMITED, DALROTI.TECH and its
                subsidiaries, affiliates, officers, promoters, directors,
                agents, co-branders, sponsors, distributors, or other partners,
                employees, consultants and representatives harmless from and
                against any and all claims, demands, actions, causes of action,
                damages, losses, costs or expenses (including reasonable
                attorneys' fees and disbursements) which arise or relate,
                directly or indirectly, out of, from or to (i) your breach of
                this Agreement or violation of any applicable law or regulation,
                (ii) any allegation that any materials that you submit to
                COALSTONE TECH PRIVATE LIMITED, infringe, misappropriate, or
                otherwise violate the copyright, trade secret, trademark or
                other intellectual property rights, or any other rights of a
                third party, or (iii) access or use of the Site and/or the
                Services by you or anyone using your DALROTI.TECH account. Users
                agree to hold the Indemnitees harmless against any claims made
                by any third party due to, or arising out of, or in connection
                with, such Users use of the Platform, DALROTI.TECH Services, any
                misrepresentation with respect to the data or information
                provided by Users in relation to their Account, Users violation
                of the Terms of Use, or Users violation of any rights of another
                etc. (iv) statements or conduct posted or made publicly
                available on the sites and/or the services.(v) any product or
                service purchased or obtained through the sites, (vi) any damage
                caused by mistakes, inaccuracies, omissions, errors,
                interruptions or loss of access to, deletion of, failure to
                store, failure to back up, or alteration of any content on the
                sites or the services, (vii) any other failure of performance of
                the site or services or other matter relating to the site and/or
                the services, in each case whether or not caused by events
                beyond the control of our directors, officers, employees,
                contractors, agents or sponsors, including, but not limited to,
                acts of nature, communications line failure, theft, destruction,
                or unauthorized access to the site or services or content stored
                therein. In no event shall CoalStone Tech Pvt Ltd
                (dalroti.tech)'s total liability to you for any and all damages,
                losses, and causes of action (whether in contract, tort,
                statutory, or otherwise) CoalStone Tech Pvt Ltd (dalroti.tech)
                reserves the right, at its own expense, to assume the exclusive
                defense and control of any matter otherwise subject to your
                indemnification. User will not, in any event, settle any such
                claim or matter without the prior written consent of CoalStone
                Tech Pvt Ltd (dalroti.tech).
              </p>

              <p>
                This Section shall survive in the event this Agreement is
                terminated for any reason.
              </p>

              <p>INTELLECTUAL PROPERTY RIGHTS:</p>

              <p>
                The Platform and process, and their selection and arrangement,
                including but not limited to, all text, videos, graphics, user
                interfaces, visual interfaces, sounds and music (if any),
                artwork, algorithm and computer code, formula, strategy, (and
                any combination thereof), compilations and databases of Accounts
                and Account details, except any third party software available
                on the Platform, is owned by DALROTI.TECH (&quot;DALROTI.TECH
                Property&quot;) and the design, structure, selection,
                co-ordination, expression, look and feel and arrangement of such
                DALROTI.TECH Property is protected by copyright, patent and
                trademark laws, and various other intellectual property rights.
                Users are not permitted to use DALROTI.TECH Property without the
                prior written consent of the owners
              </p>

              <p>
                The trademarks, logos and service marks displayed on the
                Platform (&quot;Marks&quot;) are the property of CoalStone Tech
                Pvt Ltd (dalroti.tech), except any trademark, logos and service
                marks of third parties available on the Platform. You are not
                permitted to use the Marks without the prior consent of
                CoalStone Tech Pvt Ltd (dalroti.tech) or such third party as may
                be applicable.
              </p>

              <p>
                c. If any User believes the Platform violates their intellectual
                property, such User must promptly notify us in writing at
                CoalStone Tech Pvt Ltd (dalroti.tech).
              </p>

              <p>
                These notifications should only be submitted by the owner of the
                intellectual property or an agent duly authorized to act on
                his/her behalf. However, any false claim by a User may result in
                the termination of such User's access to the Platform. Users are
                required to provide the following details in their notice:
              </p>

              <p>
                The intellectual property that the User believe is being
                infringed;
              </p>

              <p>
                The item that the User think is infringing and include
                sufficient information about where the material is located on
                the Platform;
              </p>

              <p>
                A statement that the User believes in good faith that the item
                the User has identified as infringing is not authorized by the
                intellectual property owner, its agent, or the law to be used in
                connection with the Platform;
              </p>

              <p>
                The User's contact details, such as the User's address,
                telephone number, and/or email;
              </p>

              <p>
                A statement that the information provided in the User's notice
                is accurate, and that the User is the intellectual property
                owner or an agent authorized to act on behalf of the
                intellectual property owner whose intellectual property is being
                infringed and the User's physical or electronic signature.
              </p>

              <p>OTHER TERMS AND CONDITIONS:</p>

              <p>DISPUTE RESOLUTION:</p>

              <p>
                All disputes and differences which shall arise between the user
                and the Company or between the legal representatives of the user
                and the Company such disputes and differences shall be resolved
                in the manner provided in this clause.
              </p>

              <p>
                The user and the Company also agree that they shall attempt to
                resolve through good faith consultation, disputes arising in
                connection with this Agreement, and such consultation shall
                begin promptly after a Party has delivered to the other Party a
                written request for such consultation.
              </p>

              <p>
                {" "}
                Any dispute, which could not be settled by the Parties through
                amicable settlement (as provided hereinabove), shall be finally
                settled by arbitration in accordance with the Indian Arbitration
                and Conciliation Act, 1996. A notice of the intent to refer the
                dispute to arbitration may be given by a Party to the other
                Party.
              </p>

              <p>
                The disputes shall be referred to a tribunal comprising three
                (3) arbitrators. The Respondent(s) and the Claimant(s) to the
                arbitration shall have the right to appoint one arbitrator each
                and the two arbitrators thus appointed shall choose the third
                arbitrator who will act as a presiding arbitrator of the
                tribunal (together forming the &quot;Arbitral Tribunal&quot;).
                In the event of failure by the Respondent(s) and/or the
                Claimant(s) to appoint their arbitrator(s) or by the two
                arbitrators appointed by the Respondent(s) and the Claimant(s)
                respectively to appoint the third arbitrator, the said
                arbitrator(s) shall be appointed by an Independent Company
                Secretary or Chartered Accountant in Practice.
              </p>

              <p>
                Such arbitration shall, unless otherwise agreeable to the
                Parties, be held at Pune, India. All proceedings of such
                arbitration shall be in the English language.
              </p>

              <p>
                The decision(s) of the Arbitral Tribunal shall be final and
                binding on the Parties.
              </p>

              <p>GOVERNING LAW:</p>

              <p>
                This Agreement shall be a contract under the laws of India and
                for all purposes shall be governed by and construed and enforced
                in accordance with the laws of India.
              </p>

              <p>SEVERABILITY:</p>

              <p>
                Any part, provision, representation or warranty of this
                Agreement which is prohibited or unenforceable or is held to be
                void or unenforceable in any jurisdiction shall be ineffective,
                as to such jurisdiction, to the extent of such prohibition or
                unenforceability without invalidating the remaining provisions
                hereof to the extent permitted by applicable law, the parties
                hereto waive any provision of law which prohibits or renders
                void or unenforceable any provision hereof. If the invalidity of
                any part, provision, representation or warranty of this
                Agreement shall deprive any party of the economic benefit
                intended to be conferred by this Agreement, the parties shall
                negotiate, in good-faith, to develop a structure the economic
                effect of which is as close as possible to the economic effect
                of this Agreement without regard to such invalidity.
              </p>

              <p>
                If, for any reason, a court of competent jurisdiction finds any
                provision of the Terms of Use, or any portion thereof, to be
                unenforceable, that provision shall be enforced to the maximum
                extent permissible so as to give effect to the intent of the
                parties as reflected by that provision, and the remainder of the
                Terms of Use shall continue in full force and effect.
              </p>

              <p>CONFLICT OF POLICIES:</p>

              <p>
                This Agreement includes, and incorporates by reference, the
                Privacy Policy and any other rules or policies available on the
                Platform including all other additional or modified terms and
                conditions and policies in relation to the Platform or any
                current or future services that may be offered by CoalStone Tech
                Pvt Ltd (dalroti.tech). In the event of any conflict between the
                terms of this Agreement and any provision of the policies and
                guidelines available on the Platform, the terms of this
                Agreement shall prevail in relation to the subject matter hereof
                and the terms of such policies and guidelines shall prevail in
                relation to the subject matter thereof. You are strongly advised
                to carefully read all such policies and guidelines, as available
                on the Platform.
              </p>

              <p>INVESTMENT DISCLAIMER:</p>

              <p>
                You acknowledge and understand that the Services of CoalStone
                Tech Pvt Ltd (dalroti.tech) are not intended to supply
                investment, financial, tax or legal advice. The Services are not
                investment advice and any observations concerning any security,
                trading strategy or investment strategy provided in the Services
                is not a recommendation to buy, sell or hold such investment or
                security or to make any other investment decisions. We offer no
                advice regarding the nature, potential value, risk or
                suitability of any particular investment strategy, trading
                strategy, transaction, security or investment. You acknowledge
                and agree that any use of the Services, any decisions made in
                reliance on the Services, including any trading or investment
                decisions or strategies, are made at your own risk. If
                investment, trading or other professional advice is required,
                the services of a competent, licensed professional should be
                sought. No employee, agent or representative of CoalStone Tech
                Pvt Ltd (dalroti.tech) is authorized to provide any such advice
                pursuant to this Agreement, and any such advice, if given, is in
                violation of CoalStone Tech Pvt Ltd (dalroti.tech)'s policies,
                and unauthorized and hence should not be relied upon.
              </p>

              <p>
                You are Solely Responsible for Input, Correctness, and Accuracy.
                The quality of the product's analysis and optimization depends
                on the user's inputs. After deployment, alerts have been made
                available in the product to ease and expedite your entry of the
                position, and you, as user of the system, are solely responsible
                for ensuring the quality of all its inputs. As such, you must
                carefully review all input parameters in all ways necessary to
                ensure their accuracy and fidelity. While there are other
                factors governing analysis and optimization accuracy, the
                quality of the product outputs depends on the accuracy of your
                inputs. The trades generated by the Service may increase beyond
                what is practical to execute, due to broker execution limits and
                the difficulties in executing a complex trade in an all-or-none
                fashion. Moreover, once the trade is executed, the management of
                a complex trade becomes more difficult than is normally the
                case. Another factor is that you may not be authorized to
                execute all contract types found in the solutions generated
                CoalStone Tech Pvt Ltd (dalroti.tech) makes no representation
                that a solution generated by the Service can be executed and
                effectively monitored and managed in practice. It is entirely
                your responsibility to assess the appropriateness, suitability,
                and practicality of the solutions generated by the optimizer. It
                is your responsibility to ensure the trade is executable and
                manageable, and appropriate for your needs.
              </p>

              <p>
                In no circumstances does DALROTI.TECH provide any investment
                advise or recommendation to the traders and the trade is
                completely executed on the basis of inputs set by the trader and
                the buy and sell order is matched to the inputs and accordingly
                the order is placed. DALROTI.TECH just provides the platform
                through which traders can use different strategies and place
                orders according to their understanding of a strategy.
              </p>

              <p>FORCE MAJUERE:</p>

              <p>
                Neither Party shall have any liability under or be deemed to be
                in breach of this user agreement or the Agreement for any delays
                or failures in performance of these Terms and Conditions and the
                Agreement which results from circumstances beyond the reasonable
                control of that Party such as acts of god, fire, earthquake,
                tempest, flood, lighting, violence of any army or mob or enemies
                of the country, pandemics, epidemics, strikes or any other
                circumstances making it impossible to carryout the obligations
                under this agreement.
              </p>

              <p>USE OF INTERNET:</p>

              <p>
                The Client is aware and acknowledges that trading over the
                internet involves many uncertain factors and complex hardware,
                software, systems, communication lines, peripherals, etc., which
                are susceptible to interruptions and dislocations; and the
                Online Trading Service provided by DALROTI.TECH may at any time
                be unavailable without further notice. Upon clicking on buy/sell
                on the order window, based on client's network speed, a network
                latency can be experienced, and any rapid clicks on the buy/sell
                button through same or different windows can lead to multiple
                order placements. Clients take full responsibility on making
                sure the actions on the notifications are their own actions and
                are fully aware of their positions and strategy status when on
                the buy/sell button.
              </p>

              <p>REFERRAL CREDITS.</p>

              <p>
                You acknowledge and understand that the Credit issued on the
                platform for referral are non-redeemable, non-refundable,
                non-withdrawable in any form of currency, are non-transferable
                and don't represent any legal tender, security, contract of any
                kind and merely represents a discount available to be usable
                during the purchase of DALROTI.TECH subscription plan that can
                be monthly, quarterly, biannual or annual and in no other way.
                Credit accumulated is automatically added during checkout and
                will reduce the cost of the subscription. Any portion of credit
                not used can be used during the payment of subsequent
                subscriptions.
              </p>

              <p>
                Credit is awarded to the user based on referral activity. The
                credit rewarded to users can differ based on a user's referral
                activity including but not limited to the sign-up activity of
                the referred user, purchase activity of the referred user, and
                the number of referrals of the user among others. Streak
                reserves the right to award these credits and also to stop
                awarding these credits or change the criteria for awarding these
                credits without having to notify the user.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermAndCondition;
