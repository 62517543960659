const checkConditionValid = (dataSources) => {
  let messages = [];
  for (const { data, source } of dataSources) {
    for (let key in data) {
      const match = key.match(/^(\w+(?: \w+)?)(\d+)$/);
      if (match) {
        const baseName = match[1];
        const displayName = conditions[baseName] || baseName;

        const entry = data[key];
        if (entry) {
          if (baseName === "Min" || baseName === "Max") {
            console.log("MESSAGE", baseName, displayName); 

            if (
              !entry.body1 ||
              entry.body1.length === 0 ||
              !entry.body2 ||
              entry.body2.length === 0
            ) {
              messages.push(
                `In ${source}, Please fill conditions with name "${displayName}" as one or both bodies are currently empty.`
              );
            }
          } else {
            if (entry.body && entry.body.length === 0) {
              messages.push(
                `In ${source}, Please fill condition with name "${displayName}" as it is currently empty.`
              );
            } else if (entry.body && entry.body.length > 0){
              let lastEntry = entry.body[entry.body.length -1] ;
              if (lastEntry.function_type_id === 4) {
                messages.push(`In "${displayName}" formula not valid`)
              }
            }
          }
        }
      }
    }
  }
  return messages;
};
const conditions = {
  "Period min": "Period min",
  "Period max": "Period max",
  Symbol: "Symbol",
  Floor: "Floor",
  Ceil: "Ceil",
  Abs: "Abs",
  Max: "Max",
  Min: "Min",
};
export { checkConditionValid };
